import i18n from 'i18next';
import { DEFAULT_SITE_NAME } from '@core/constants';

export const validateSiteName = (name: string) => {
  let message: string | null = null;

  const validationRules = {
    emptySiteName: (name: string) => name.length && [...name].every((char) => char === ' '),
    invalidSiteName: (name: string) => name === DEFAULT_SITE_NAME,
  };

  if (validationRules.emptySiteName(name)) {
    message = i18n.t('errors.emptySiteName');
  }

  if (validationRules.invalidSiteName(name)) {
    message = i18n.t('errors.invalidSiteName');
  }

  return message;
};
