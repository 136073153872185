import { FC } from 'react';
import ZonesTable from './components/ZonesTable/ZonesTable';
import { ITableBodyColumn, ITableHeadColumn } from './interfaces/table';
import styles from './styles.scss';

interface IProps {
  text: {
    notification: string;
  };
  head: ITableHeadColumn[];
  body: ITableBodyColumn[];
  onClick: (id: string, name: string) => void;
}

const ZonesBody: FC<IProps> = ({ head, body, text, onClick }) => {
  return (
    <div className={styles.body}>
      <h2 className={styles.notification}>{text.notification}</h2>
      <div className={styles.tableWrapper}>
        <ZonesTable head={head} body={body} onRowClick={onClick} />
      </div>
    </div>
  );
};

export default ZonesBody;
