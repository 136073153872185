import { ESTIMATED_TIME_OF_ZONES_GENERATION, FAKE_PERCENTAGE_STEP } from '@core/constants';
import { fromFakeToActualPercentage } from '@core/utils/converting/fromFakeToActualPercentage';

const MAX_PERCENT_VALUE = 100;

interface IFakePercentageProps {
  currentCalculationTime: number;
  currentFakePercentage: number;
}
export function getFakePercentage({
  currentCalculationTime,
  currentFakePercentage,
}: IFakePercentageProps) {
  const differenceTime = Date.now() - currentCalculationTime;
  const rawDeltaPercentage =
    (differenceTime * MAX_PERCENT_VALUE) / ESTIMATED_TIME_OF_ZONES_GENERATION;
  // NOTE: round from decimals to digits;
  const roundedDeltaPercentage =
    Math.round(rawDeltaPercentage / FAKE_PERCENTAGE_STEP) * FAKE_PERCENTAGE_STEP;

  const deltaPercentage =
    roundedDeltaPercentage < FAKE_PERCENTAGE_STEP ? 0 : roundedDeltaPercentage;

  return fromFakeToActualPercentage(currentFakePercentage + deltaPercentage);
}
