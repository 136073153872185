import { ESiteEditSections } from '@core/enums';
import { IEditSiteInitialState } from '@core/store/slices';

export const validateEditData = {
  [ESiteEditSections.Name]: (value: IEditSiteInitialState[ESiteEditSections.Name]) =>
    !!value.errorMessage,
  [ESiteEditSections.Picture]: (value: IEditSiteInitialState[ESiteEditSections.Picture]) =>
    !!value.errorMessage,
  // TODO: update validation
  // [ESiteEditSections.InspectionFrequency]:
  //   (value:  IEditSiteInitialState[ESiteEditSections.InspectionFrequency]) => !!value.errorMessage,
  [ESiteEditSections.Roles]: (value: IEditSiteInitialState[ESiteEditSections.Roles]) =>
    Object.values(value).some((roleList) => roleList.some(({ errorMessage }) => errorMessage)),
  [ESiteEditSections.Zones]: (value: IEditSiteInitialState[ESiteEditSections.Zones]) =>
    value.zones.some((zone) => zone.name.errorMessage),
};

export const areAllValuesEmpty = ({
  name,
  picture,
  inspectionFrequency,
  roles,
  zones,
}: IEditSiteInitialState) => {
  const isNameEmpty = !name.value;
  const isPictureEmpty = !picture.value;
  const isInspectionFrequencyEmpty = !inspectionFrequency;
  const areRolesEmpty = Object.values(roles).every((roleList) =>
    roleList.every(({ value }) => !value),
  );
  const areZonesEmpty = zones.zones.every((zone) => !zone.name.value);

  return [
    isNameEmpty,
    isPictureEmpty,
    isInspectionFrequencyEmpty,
    areRolesEmpty,
    areZonesEmpty,
  ].every((v) => v);
};
