import { ESidebar } from '@core/enums';
import { IReport } from '@core/interfaces/report';
import { getSolarPanelsFeatures } from './getSolarPanelsFeatures';
import { setFeatureCollectionData } from './mapboxConfig';
import { setSolarPanels } from './setSolarPanels';
import { EAnomalyLayers, ESolarPanelsLayers } from '../../enums/layers';
import { ESolarPanelsSources } from '../../enums/sources';
import { removeLayersAndSources } from '../removeLayersAndSources';

export interface ISolarPanelsProps {
  map: mapboxgl.Map | null;
  sidebar: ESidebar;
  sidebarPrevious: ESidebar | null;
  show: boolean;
  report?: IReport | null;
  color?: string;
  withControl?: boolean;
  isCompareMode: boolean;
}

let currentReportId: string | number | null = null;

export const addSolarPanels = ({
  map,
  report,
  show: showSolarPanels,
  ...restProps
}: ISolarPanelsProps) => {
  if (!map) return;

  const { color, sidebar } = restProps;

  const solarPanelsVisibility = showSolarPanels ? 'visible' : 'none';

  switch (sidebar) {
    case ESidebar.Sites:
    case ESidebar.Site:
      removeLayersAndSources(map, {
        [ESolarPanelsSources.SolarPanels]: [ESolarPanelsLayers.SolarPanels],
      });
      break;
    case ESidebar.Zone:
    case ESidebar.Anomaly:
      if (report) {
        if (currentReportId && currentReportId !== Number(report.id)) {
          removeLayersAndSources(map, {
            [ESolarPanelsSources.SolarPanels]: [ESolarPanelsLayers.SolarPanels],
          });
        }

        if (report?.solar_panels) {
          const solarPanelsFeatures = getSolarPanelsFeatures({ report, color });

          if (map.getSource(ESolarPanelsSources.SolarPanels)) {
            (map.getSource(ESolarPanelsSources.SolarPanels) as mapboxgl.GeoJSONSource).setData(
              setFeatureCollectionData(solarPanelsFeatures),
            );
          } else {
            const shouldSetSolarPanels =
              map.getLayer(EAnomalyLayers.Anomalies) &&
              !map.getLayer(ESolarPanelsLayers.SolarPanels);

            if (shouldSetSolarPanels) {
              setSolarPanels(map, solarPanelsFeatures, showSolarPanels);
            }
          }
        }
      } else {
        removeLayersAndSources(map, {
          [ESolarPanelsSources.SolarPanels]: [ESolarPanelsLayers.SolarPanels],
        });
      }

      if (map.getLayer(ESolarPanelsLayers.SolarPanels)) {
        map.setLayoutProperty(ESolarPanelsLayers.SolarPanels, 'visibility', solarPanelsVisibility);
      }
      break;
  }

  currentReportId = report ? Number(report.id) : null;
};
