import _ from 'lodash';
import { ISample } from '@core/interfaces';
import { addBatteriesPointsToMap, addFlightPathToMap } from './addDataToMap';
import { getFlightPathBatteriesFeatureCollection } from './getFeatureCollections';
import { EFlightPathLayers } from '../../enums/layers';
import { EFlightPathSources } from '../../enums/sources';
import { removeLayersAndSources } from '../removeLayersAndSources';

interface IProps {
  map: mapboxgl.Map | null;
  flightPlan?: any | null;
  show: boolean;
  flightPath?: ISample[] | null;
  drawBatteries?: boolean;
}

export const addFlightPath = ({ map, flightPlan, show, flightPath, drawBatteries }: IProps) => {
  if (!map) return;
  const shouldRemoveFlightPathData =
    (!show || !(flightPath?.length || flightPlan.length)) &&
    map.getSource(EFlightPathSources.FlightPath);

  const shouldRemoveFlightPathBatteriesData =
    (!show || !drawBatteries) && map.getSource(EFlightPathSources.Batteries);

  if (shouldRemoveFlightPathData) {
    removeLayersAndSources(map, {
      [EFlightPathSources.FlightPath]: [
        EFlightPathLayers.Triangle,
        EFlightPathLayers.Point,
        EFlightPathLayers.FlightPath,
      ],
    });
  }

  if (shouldRemoveFlightPathBatteriesData) {
    removeLayersAndSources(map, {
      [EFlightPathSources.Batteries]: [
        EFlightPathLayers.BatteryCircle,
        EFlightPathLayers.BatteryData,
      ],
    });
  }

  if (!show || !(flightPlan || flightPath)) return;

  const getFlightsFirstSamples = (samples: ISample[]): number[][] => {
    const firstSamples: number[][] = [];

    samples.forEach((sample: ISample, index: number) => {
      if (
        index !== samples.length - 1 &&
        index !== 0 &&
        sample?.flight_id !== samples[index - 1]?.flight_id
      ) {
        sample.geodetic_camera &&
          firstSamples.push([sample.geodetic_camera?.lon, sample.geodetic_camera?.lat]);
      }
    });

    return firstSamples;
  };

  if (flightPath?.length) {
    const coordinates = flightPath
      .filter((sample) => sample.geodetic_camera)
      .map((sample) => [sample.geodetic_camera?.lon, sample.geodetic_camera?.lat]) as number[][];
    addFlightPathToMap(map, coordinates);

    if (drawBatteries) {
      const flightPathBatteriesFeatureCollection = _.flowRight(
        getFlightPathBatteriesFeatureCollection,
        getFlightsFirstSamples,
      )(flightPath);

      addBatteriesPointsToMap(map, flightPathBatteriesFeatureCollection);
    }
  } else {
    if (flightPlan?.length) {
      const coordinates = flightPlan?.map((data) => [
        data['metadata.location.longitude'],
        data['metadata.location.latitude'],
      ]);

      addFlightPathToMap(map, coordinates);
    }
  }
};
