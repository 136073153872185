import { nonTenantAxiosInstance } from '@core/api/axios';

export interface IAccessTokenRequest {
  username: string;
  password: string;
}

export interface IAccessTokenResponse {
  access_token: string;
  token_type: string;
}

export const getAccessToken = async (body: IAccessTokenRequest) => {
  return nonTenantAxiosInstance
    .post<IAccessTokenResponse>('token', new URLSearchParams({ ...body }))
    .then((response) => {
      if (!response) throw new Error();
      return response?.data;
    });
};
