import { ISite, ISolarPanel } from '@core/interfaces';
import { geodetic2enu } from '../geodetic2enu';

export const transformSolarPanels = (solarPanels: ISolarPanel, currentSite: ISite) => {
  return solarPanels.features.map((feature: any) => ({
    id: feature.properties.id,
    enu: feature.geometry.coordinates[0].map(([lon, lat, alt]: number[]) =>
      geodetic2enu(lat, lon, alt, currentSite.lat, currentSite.long, currentSite.altitude),
    ),
  }));
};
