import compassImg from '@assets/images/compass.png';

export const customizeCompass = () => {
  const compassGroups = document.querySelectorAll(
    '.mapboxgl-ctrl-group',
  ) as NodeListOf<HTMLElement>;

  compassGroups.forEach((container) => {
    const compass = container.querySelector('.mapboxgl-ctrl-compass') as HTMLElement;
    const compassIcon = compass?.querySelector('.mapboxgl-ctrl-icon') as HTMLElement;

    const initStyles = (el: HTMLElement) => {
      if (el?.style) {
        el.style.width = '60px';
        el.style.height = '60px';
        el.style.borderRadius = '50%';
      }
    };

    initStyles(container);
    initStyles(compass);

    try {
      container.style.position = 'absolute';
      container.style.right = '0';
      container.style.bottom = '5px';
      container.style.boxShadow = 'none';
      if (compassIcon) {
        compassIcon.style.background = `url(${compassImg}) 0 0 / contain no-repeat`;
      }
    } catch (e) {
      console.error(e);
    }
  });
};
