import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { t } from 'i18next';
import _ from 'lodash';
import { api } from '@core/api';
import { IUpdateLocationRequest } from '@core/api/locations/updateById';
import { IAddNewUsersRequest, IInviteUsersRequest } from '@core/api/user/users';
import { ERROR_TOAST_DELAY } from '@core/constants';
import {
  EInspectionFrequency,
  ERoles,
  ESidebar,
  ESiteEditActions,
  ESiteEditSections,
} from '@core/enums';
import { useSelectorTyped } from '@core/hooks';
import {
  TEditRole,
  TEditZone,
  IProgram,
  ISite,
  TResetZonePayload,
  TRolesWithoutSuperuserPayload,
  TSelectZonePayload,
  TUpdateZonePayload,
  TZonesPayload,
  TSelectedZone,
  TRolesWithoutSuperuser,
} from '@core/interfaces';
import { RootState } from '@core/store';
import { setSidebar } from '@core/store/slices/sidebar';
import { addSites, resetSite } from '@core/store/slices/sites';
import { generateNameZoneKey } from '@modules/Sidebar/components/AccordionView/BodyOptions/Zones/utils';
import { setRoleInitialState } from '@modules/Sidebar/views/Site/utils';
import { addInspections } from './inspections';
import { reloadMap } from './map';
import { addPrograms } from './programs';
import { transformRolesDataForApiRequest } from '../utils';

export interface IEditSiteInitialState {
  [ESiteEditSections.Name]: {
    value: string;
    errorMessage: string | null;
  };
  [ESiteEditSections.Picture]: {
    value: string | null; // NOTE: base64 format
    errorMessage: string | null;
  };
  [ESiteEditSections.InspectionFrequency]: EInspectionFrequency | null;
  [ESiteEditSections.Roles]: {
    [key in TRolesWithoutSuperuser]: TEditRole[];
  };
  [ESiteEditSections.Zones]: {
    selectedZone: TSelectedZone | null;
    zones: TEditZone[];
  };
}

const initialState: IEditSiteInitialState = {
  [ESiteEditSections.Name]: {
    value: '',
    errorMessage: null,
  },
  [ESiteEditSections.Picture]: {
    value: '',
    errorMessage: null,
  },
  [ESiteEditSections.InspectionFrequency]: null,
  [ESiteEditSections.Roles]: {
    [ERoles.Admin]: [setRoleInitialState(ERoles.Admin)],
    [ERoles.Editor]: [setRoleInitialState(ERoles.Editor)],
    [ERoles.Viewer]: [setRoleInitialState(ERoles.Viewer)],
    [ERoles.Inspector]: [setRoleInitialState(ERoles.Inspector)],
  },
  [ESiteEditSections.Zones]: {
    selectedZone: null,
    zones: [],
  },
};

export const updateSiteAndPrograms = createAsyncThunk(
  'editSite/updateSiteAndPrograms',
  async (siteId: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const currentEditInfo = state.editSite;

    const programsBySiteId = state.programs.programs.filter(
      (program: IProgram) => program.location_id === siteId,
    );

    // 1. Prepare location data (3 cases)
    const locationData: Partial<IUpdateLocationRequest> = {};

    if (currentEditInfo[ESiteEditSections.Name].value) {
      locationData.name = currentEditInfo[ESiteEditSections.Name].value;
    }
    if (currentEditInfo[ESiteEditSections.Picture].value) {
      locationData.picture = currentEditInfo[ESiteEditSections.Picture].value;
    }
    if (currentEditInfo[ESiteEditSections.InspectionFrequency]) {
      locationData.inspection_frequency = currentEditInfo[ESiteEditSections.InspectionFrequency];
    }

    // 2. Prepare program data (1 case - zone)
    const programsData: Array<{ program_id: string; name: string }> = [];

    if (Array.isArray(currentEditInfo?.zones?.zones)) {
      programsData.push(
        ...currentEditInfo.zones.zones.map((zone: TEditZone) => ({
          program_id: zone.program_id,
          name: zone.name.value,
        })),
      );
    }

    // 3. Prepare email users data (add, invite) - (1 case - roles)
    const addNewUsersData: IAddNewUsersRequest[] = [];
    const inviteUsersData: IInviteUsersRequest[] = [];

    if (currentEditInfo[ESiteEditSections.Roles]) {
      const usersData = transformRolesDataForApiRequest(currentEditInfo[ESiteEditSections.Roles]);
      addNewUsersData.push(...usersData);
      inviteUsersData.push(...usersData);
    }

    const hasAddNewUsersData = !!addNewUsersData.length;
    const hasInviteUsersData = !!inviteUsersData.length;
    const hasLocationData = !!Object.keys(locationData).length;
    const hasProgramsData = !!programsData.length;

    try {
      const promises: Promise<any>[] = [];

      if (hasAddNewUsersData) {
        promises.push(api.user.addNewUsers(addNewUsersData));
      }

      if (hasLocationData) {
        promises.push(api.location.updateById(siteId, locationData));
      }

      if (hasProgramsData) {
        promises.push(
          ...programsData.map(({ program_id, ...data }) =>
            api.program.updateProgramById(program_id, data),
          ),
        );
      }

      const response = await Promise.all(promises);

      // Request after created Users
      if (hasInviteUsersData) {
        await api.user.inviteUsers(inviteUsersData);
      }

      if (hasAddNewUsersData) response.shift();

      if (hasLocationData) {
        const updatedSite = response.shift();

        const sites: ISite[] = state.sites.sites
          ? state.sites.sites.map((site) =>
              site.loc_id === updatedSite.loc_id ? { ...site, ...updatedSite } : site,
            )
          : [];

        dispatch(addSites(sites));
      }

      if (hasProgramsData) {
        const updatedPrograms = response;

        const programs: IProgram[] = programsBySiteId
          ? programsBySiteId.map((program) => {
              const foundUpdatedProgram = updatedPrograms.find(
                (updatedProgram) => updatedProgram.program_id === program.program_id,
              );
              return foundUpdatedProgram ? { ...program, ...foundUpdatedProgram } : program;
            })
          : [];

        dispatch(addPrograms(programs));
      }

      dispatch(reloadMap(true));
    } catch {
      toast.error(t('errors.updateSiteAndProgramsFail'), { autoClose: ERROR_TOAST_DELAY });
    }
  },
);

export const deleteSiteAndPrograms = createAsyncThunk(
  'sites/deleteSiteAndPrograms',
  async (siteId: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    try {
      await api.location.deleteById(siteId);

      const filteredSites = state.sites.sites.filter((site) => site.loc_id !== siteId);
      const filteredInspections = state.inspections.inspections.filter(
        (inspection) => inspection.location_id !== siteId,
      );

      dispatch(addSites(filteredSites));
      dispatch(addInspections(filteredInspections));
      dispatch(setSidebar(ESidebar.Sites));
      dispatch(resetSite());
    } catch (error) {
      toast.error(t('errors.deleteSiteFail'), { autoClose: ERROR_TOAST_DELAY });
    }
  },
);

export const updateEditSiteRoles = createAsyncThunk<
  void,
  TRolesWithoutSuperuserPayload,
  NonNullable<unknown>
>('editSite/updateEditSiteRoles', async (rolesDataPayload, { getState, dispatch }) => {
  const state = getState() as RootState;
  let editSiteRolesState = _.cloneDeep(state.editSite.roles);

  const { action: editAction, data: rolesData } = rolesDataPayload;
  const transformedRolesData = Object.entries(rolesData);

  if (transformedRolesData.length) {
    const [role, data] = transformedRolesData[0];

    switch (editAction) {
      case ESiteEditActions.AddOne: {
        editSiteRolesState = {
          ...editSiteRolesState,
          [role]: editSiteRolesState?.[role] ? [...editSiteRolesState[role], data] : [data],
        };
        break;
      }
      case ESiteEditActions.UpdateOne: {
        if (editSiteRolesState?.[role]) {
          editSiteRolesState = {
            ...editSiteRolesState,
            [role]: editSiteRolesState[role].map((item) => (item.name === data.name ? data : item)),
          };
        }
        break;
      }
      case ESiteEditActions.DeleteOne: {
        if (editSiteRolesState?.[role]) {
          editSiteRolesState = {
            ...editSiteRolesState,
            [role]: editSiteRolesState[role].filter((item) => item.name !== data.name),
          };
        }
        break;
      }
    }

    dispatch(setEditSiteRoles(editSiteRolesState));
  }
});

export const updateEditSiteZones = createAsyncThunk<void, TZonesPayload, NonNullable<unknown>>(
  'editSite/updateEditSiteZones',
  async (zonesData, { getState, dispatch }) => {
    const state = getState() as RootState;
    let editSiteZonesState = _.cloneDeep(state.editSite.zones);
    const { action: editAction, data: payloadData } = zonesData;

    switch (editAction) {
      case ESiteEditActions.SelectOne: {
        const selectOnePayloadData = payloadData as TSelectZonePayload['data'];
        const { id: zoneId, customName } = selectOnePayloadData;

        if (editSiteZonesState?.zones) {
          const foundZone = editSiteZonesState.zones.find(
            ({ program_id }) => program_id === zoneId,
          );

          if (!foundZone) {
            const payload = {
              program_id: zoneId,
              customName: '',
              name: {
                value: '',
                errorMessage: null,
              },
            };

            editSiteZonesState = {
              ...editSiteZonesState,
              zones: [...editSiteZonesState.zones, payload],
            };
          }

          editSiteZonesState = {
            ...editSiteZonesState,
            selectedZone: {
              program_id: zoneId,
              customName: customName,
            },
          };
        }
        break;
      }
      case ESiteEditActions.UpdateOne: {
        const updateOnePayloadData = payloadData as TUpdateZonePayload<TEditZone['name']>['data'];
        const { id: zoneId, value: updateFieldData } = updateOnePayloadData;
        const [field, fieldPayload] = updateFieldData;

        if (field === 'name') {
          editSiteZonesState = {
            ...editSiteZonesState,
            zones: editSiteZonesState.zones.map((zone) =>
              zone.program_id === zoneId
                ? {
                    ...zone,
                    customName: fieldPayload.value,
                    name: fieldPayload,
                  }
                : zone,
            ),
          };
        }

        break;
      }
      case ESiteEditActions.ResetOne: {
        const resetOnePayloadData = payloadData as TResetZonePayload['data'];

        if (editSiteZonesState?.[resetOnePayloadData.field]) {
          if (resetOnePayloadData.field === 'selectedZone') {
            editSiteZonesState = {
              ...editSiteZonesState,
              zones: editSiteZonesState.zones.filter((zone) =>
                zone.program_id === editSiteZonesState.selectedZone?.program_id && !zone.name.value
                  ? false
                  : true,
              ),
            };
          }

          editSiteZonesState = {
            ...editSiteZonesState,
            [resetOnePayloadData.field]: null,
          };
        }
        break;
      }
      case ESiteEditActions.SelectPreviousZone:
      case ESiteEditActions.SelectNextZone: {
        const currentSelectedZone = editSiteZonesState.selectedZone;
        const currentSelectedZoneIdx = state.programs.programs.findIndex(
          (program) => currentSelectedZone?.program_id === program.program_id,
        );

        let currentIndex = currentSelectedZoneIdx;
        let currentZone = state.programs.programs[currentIndex];

        if (zonesData.action === ESiteEditActions.SelectPreviousZone) {
          currentIndex = currentSelectedZoneIdx - 1;

          if (currentIndex < 0) {
            currentIndex = state.programs.programs.length - 1;
          }
        }

        if (zonesData.action === ESiteEditActions.SelectNextZone) {
          currentIndex = currentSelectedZoneIdx + 1;

          if (currentIndex > state.programs.programs.length - 1) {
            currentIndex = 0;
          }
        }

        currentZone = state.programs.programs[currentIndex];

        if (!currentZone) return;

        editSiteZonesState = {
          ...editSiteZonesState,
          selectedZone: {
            program_id: currentZone.program_id,
            customName: generateNameZoneKey(currentIndex),
          },
        };

        const isExistedZone = editSiteZonesState.zones.find(
          (zone) => zone.program_id === currentZone.program_id,
        );

        if (!isExistedZone) {
          const payload = {
            program_id: currentZone.program_id,
            name: {
              value: '',
              errorMessage: null,
            },
            customName: '',
          };

          editSiteZonesState = {
            ...editSiteZonesState,
            zones: [...editSiteZonesState.zones, payload],
          };
        }
        break;
      }
    }

    dispatch(setEditSiteZones(editSiteZonesState));
  },
);

const editSiteSlice = createSlice({
  name: 'editSite',
  initialState,
  reducers: {
    setEditSiteName: (
      state,
      action: PayloadAction<IEditSiteInitialState[ESiteEditSections.Name]>,
    ) => {
      state[ESiteEditSections.Name] = action.payload;
    },
    setEditSitePicture: (
      state,
      action: PayloadAction<IEditSiteInitialState[ESiteEditSections.Picture]>,
    ) => {
      state[ESiteEditSections.Picture] = action.payload;
    },
    setEditSiteInspectionFrequency: (
      state,
      action: PayloadAction<IEditSiteInitialState[ESiteEditSections.InspectionFrequency]>,
    ) => {
      state[ESiteEditSections.InspectionFrequency] = action.payload;
    },
    setEditSiteRoles: (
      state,
      action: PayloadAction<IEditSiteInitialState[ESiteEditSections.Roles]>,
    ) => {
      state[ESiteEditSections.Roles] = action.payload;
    },
    setEditSiteZones: (
      state,
      action: PayloadAction<IEditSiteInitialState[ESiteEditSections.Zones]>,
    ) => {
      state[ESiteEditSections.Zones] = action.payload;
    },
    resetEditSite: () => initialState,
  },
});

const {
  setEditSiteName,
  setEditSitePicture,
  setEditSiteInspectionFrequency,
  setEditSiteRoles,
  setEditSiteZones,
  resetEditSite,
} = editSiteSlice.actions;

const editSiteReducer = editSiteSlice.reducer;

const useSiteEditInfoSelector = () => useSelectorTyped((state) => state.editSite);
const useSiteEditInfoSelectedZoneSelector = () =>
  useSelectorTyped((state) => {
    const zones = state.editSite.zones;

    return zones?.selectedZone && zones?.zones
      ? zones.zones.reduce(
          (acc, zone) =>
            zones.selectedZone && zone.program_id === zones.selectedZone.program_id
              ? { ...zone, customName: zones.selectedZone.customName }
              : acc,
          {} as TEditZone,
        )
      : null;
  });

export {
  setEditSiteName,
  setEditSitePicture,
  setEditSiteInspectionFrequency,
  setEditSiteRoles,
  setEditSiteZones,
  resetEditSite,
  editSiteReducer,
  useSiteEditInfoSelector,
  useSiteEditInfoSelectedZoneSelector,
  initialState as editSiteInitialState,
};
