import { useCallback, useEffect, useMemo, useState } from 'react';
import { ReactSVG } from 'react-svg';
import cn from 'classnames';
import chevronIcon from '@assets/icons/chevron-right.svg';
import { EArrowDirection, EViewerType } from '@core/enums';
import { MAX_DOTS_NUMBER, OFFSET_X } from './constants/layout';
import styles from './styles.scss';
import { transformOptions } from './utils/transformOptions';

// TODO:
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
interface IProps<T extends unknown> {
  mode?: EViewerType;
  classNames?: string;
  title?: string;
  options: T[];
  selectedId: number | string;
  onArrowClick: ((index: number) => void) | null;
  onDotClick: ((index: number) => void) | null;
}

const mappingHelper = {
  [EArrowDirection.Left]: (currentIndex: number, optionsLength: number) =>
    currentIndex === 0 ? optionsLength - 1 : currentIndex - 1,
  [EArrowDirection.Right]: (currentIndex: number, optionsLength: number) =>
    currentIndex === optionsLength - 1 ? 0 : currentIndex + 1,
};
// TODO:
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const Switcher = <T extends unknown>(props: IProps<T>) => {
  const {
    mode = EViewerType.Viewer,
    classNames,
    options: items,
    title,
    selectedId,
    onArrowClick,
    onDotClick,
  } = props;
  const options = transformOptions(items);
  const [translateX, setTranslateX] = useState(0);

  const calculateTranslateX = useCallback((optionIndex: number) => {
    const translateX =
      optionIndex < MAX_DOTS_NUMBER - 1 ? 0 : (optionIndex - (MAX_DOTS_NUMBER - 1)) * -OFFSET_X;

    setTranslateX(translateX);
  }, []);

  const selectedIndex = useMemo(
    () => options.findIndex((option) => option.id === selectedId),
    [options, selectedId],
  );

  const dotsStyles = useMemo(
    () => ({
      transform: `translateX(${translateX}px)`,
    }),
    [translateX],
  );

  const handleDotClick = (index: number) => {
    onDotClick?.(index);
  };

  const handleArrowClick = (arrow: EArrowDirection) => {
    const newOptionIndex = mappingHelper[arrow](selectedIndex, options.length);
    onArrowClick?.(newOptionIndex);
  };

  useEffect(() => {
    calculateTranslateX(selectedIndex);
  }, [calculateTranslateX, selectedIndex]);

  return (
    <div
      className={cn(styles.container, classNames, {
        [styles.inspectionView]: mode === EViewerType.InspectionView,
      })}
    >
      <button
        className={styles.arrowLeft}
        disabled={!onArrowClick}
        onClick={handleArrowClick.bind(this, EArrowDirection.Left)}
      >
        <ReactSVG src={chevronIcon} />
      </button>
      {onDotClick && (
        <div className={styles.dotsWrapper}>
          {title && <div className={styles.dotsTitle}>{title}</div>}
          <div className={styles.dots} style={dotsStyles}>
            {options.map((option, i) => (
              <button
                key={option.id}
                className={cn(styles.dot, { [styles.active]: selectedId === option.id })}
                onClick={handleDotClick.bind(this, i)}
              />
            ))}
          </div>
        </div>
      )}
      <button
        className={styles.arrowRight}
        disabled={!onArrowClick}
        onClick={handleArrowClick.bind(this, EArrowDirection.Right)}
      >
        <ReactSVG src={chevronIcon} />
      </button>
    </div>
  );
};
