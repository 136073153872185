import { FeatureCollection } from 'geojson';
import mapboxgl from 'mapbox-gl';
import { ECreateSiteSteps } from '@core/constants/createSite';
import { ESidebar } from '@core/enums';
import { getColor } from '@core/utils';

interface IProps {
  map: mapboxgl.Map | null;
  sidebar: ESidebar;
  createSiteStep: ECreateSiteSteps;
  coordinates: { lng: number; lat: number };
}

export const addLocationCenter = (props: IProps) => {
  const { map, sidebar, coordinates, createSiteStep } = props;
  if (!map) return;

  if (
    sidebar !== ESidebar.CreateSite ||
    createSiteStep !== ECreateSiteSteps.SearchCity ||
    !coordinates
  ) {
    if (map?.getLayer('location-center')) {
      map.removeLayer('location-center');
      map.removeSource('location-center');
    }
    return;
  }

  const centerData: FeatureCollection = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        id: 'location-center',
        properties: {
          color: getColor('--outflier-blue'),
        },
        geometry: {
          type: 'Point',
          coordinates: [coordinates.lng, coordinates.lat],
        },
      },
    ],
  };

  if (map.getSource('location-center')) {
    (map.getSource('location-center') as mapboxgl.GeoJSONSource).setData(centerData);
  } else {
    map.addSource('location-center', {
      type: 'geojson',
      data: centerData,
    });

    map.addLayer({
      id: 'location-center',
      type: 'circle',
      source: 'location-center',
      paint: {
        'circle-color': ['get', 'color'],
        'circle-radius': 15,
      },
    });
  }
};
