import { FC } from 'react';
import { ProgressBarLayout } from '../ProgressBarLayout/ProgressBarLayout';

interface IProps {
  percentage: number;
  className?: string;
  title?: string;
  isTextHidden: boolean;
}

export const ActualProgressBar: FC<IProps> = (props) => {
  return <ProgressBarLayout {...props} />;
};
