import { ISolarPanel } from '@core/interfaces';
import { IAnomaly } from '@core/interfaces/anomaly';
import { IReport } from '@core/interfaces/report';

interface IUpdateReports {
  reports: IReport[];
  currentReport: IReport;
  solarPanels: ISolarPanel;
  anomalies: IAnomaly[];
}

export function updateReports({ reports, currentReport, solarPanels, anomalies }: IUpdateReports) {
  return reports.map((report) =>
    report.id === currentReport.id
      ? {
          ...report,
          solar_panels: solarPanels,
          anomalies: anomalies,
        }
      : report,
  );
}
