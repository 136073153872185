import i18n from 'i18next';

const EMAIL_PATTERN = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const validateEmail = (email: string) => {
  let message: string | null = null;

  const validationRules = {
    invalidEmail: (email: string) => email && !EMAIL_PATTERN.test(email),
  };

  if (validationRules.invalidEmail(email)) {
    message = i18n.t('errors.invalidEmail');
  }

  return message;
};
