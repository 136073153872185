import { Polygon } from 'geojson';
import { axiosInstance } from '@core/api/axios';

interface IPostPerimeterResponse {}

export const postPerimeter = async (
  id: string | number,
  perimeter: Polygon,
): Promise<IPostPerimeterResponse> => {
  return axiosInstance
    .post<IPostPerimeterResponse>(`/locations/${id}/perimeter`, perimeter)
    .then(({ data }) => data);
};
