import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import '@assets/styles/index.scss';
import { ViewersProvider } from '@core/contexts';
import '@core/i18n';
import {
  // persistor,
  store,
} from '@core/store';
// FIXME: temporary solution (revert persistor Cache issue)
// import PersistGate from '@components/PersistGate/PersistGate';
import { App } from './App';

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(
  <Provider store={store}>
    {/* <PersistGate store={store} persistor={persistor}> */}
    <ViewersProvider>
      <App />
    </ViewersProvider>
    {/* </PersistGate> */}
  </Provider>,
);
