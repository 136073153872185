import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import plusIcon from '@assets/icons/plus.svg';
import solarEnergyLogo from '@assets/icons/solar-energy-logo.svg';
import { ERoles, ESidebar, EViewer } from '@core/enums';
import { useDispatchTyped } from '@core/hooks';
import { ELocationStatus } from '@core/interfaces';
import {
  addSelectedSiteInfo,
  addSitesInfo,
  continueCreation,
  resetSite,
  setSidebar,
  setViewer,
  useSitesSelector,
  useAccessControlSelector,
} from '@core/store/slices';
import { Button } from '@components/Button';
import { Statuses } from '@components/Statuses';
import { Table, TableBody, TableHead, TableRow } from '@components/Table';
import styles from './styles.scss';
import { getStatuses } from './utils/getStatuses';
import { getTable } from './utils/getTable';

export const Sites = () => {
  const { t } = useTranslation();
  const { sitesSidebar } = useSitesSelector();

  const { columnWidth, head, body } = getTable(sitesSidebar);
  const dispatch = useDispatchTyped();
  const { user } = useAccessControlSelector();
  const isAdminOrSuperuser = user?.roles?.some((role: ERoles) =>
    [ERoles.Admin, ERoles.Superuser].includes(role),
  );

  const handleTableRowClick = useCallback((siteId: string, locationStatus: any) => {
    if (
      locationStatus &&
      [ELocationStatus.WaitingForApproval, ELocationStatus.UnderCreation].includes(locationStatus)
    ) {
      dispatch(continueCreation(siteId));
      return;
    }

    dispatch(addSelectedSiteInfo({ siteId }));
  }, []);

  // NOTE: load sites data
  useEffect(() => {
    dispatch(resetSite());
    dispatch(addSitesInfo());
  }, []);

  const handleClickAddNewSite = () => {
    dispatch(setViewer(EViewer.Map));
    dispatch(setSidebar(ESidebar.CreateSite));
  };

  return (
    <div className={styles.sites}>
      <div className={styles.sitesWrapper}>
        <div className={styles.logo}>
          <img src={solarEnergyLogo} alt='Solar Energy Logo' />
        </div>
        <div className={styles.sitesStatuses}>
          <div className={styles.sitesStatusesTitle}>{t('sites')}</div>
          <Statuses
            className={styles.statuses}
            statuses={getStatuses(sitesSidebar)}
            isStatusesClickable={false}
          />
        </div>
        <Table>
          <TableHead>
            <TableRow className={styles.locationHead}>
              {head.map(({ name, className }: any, i) => (
                <th key={name} style={{ width: columnWidth[i] }} className={className}>
                  {name}
                </th>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {body.map(
              ({
                data,
                id,
                locationStatus,
              }: {
                data: any[];
                id: string;
                locationStatus?: ELocationStatus;
              }) => (
                <TableRow key={id} onClick={handleTableRowClick.bind(null, id, locationStatus)}>
                  {data.map(({ name, className }: any, i: number) => (
                    <td
                      key={String(name + i)}
                      style={{ width: columnWidth[i] }}
                      className={className}
                    >
                      {name}
                    </td>
                  ))}
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      </div>
      <Button disabled={!isAdminOrSuperuser} onClick={handleClickAddNewSite} className={styles.btn}>
        {t('addNewSite')}
        <img src={plusIcon} alt='plus icon' />
      </Button>
    </div>
  );
};
