import React from 'react';
import cn from 'classnames';
import videoForwardIcon from '@assets/icons/video-forward.svg';
import videoPauseIcon from '@assets/icons/video-pause.svg';
import videoPlayIcon from '@assets/icons/video-play.svg';
import videoRewindIcon from '@assets/icons/video-rewind.svg';
import styles from './styles.scss';

interface IProps {
  visible: boolean;
  classNames?: string;
  config: {
    playing: boolean;
    onToggle: (playing: boolean) => void;
    onSkip: (direction: 'forward' | 'back') => void;
  };
}

export const VideoControls: React.FC<IProps> = ({ visible, classNames, config }) => {
  if (!visible) return null;

  return (
    <div className={cn(styles.container, classNames)}>
      <button className={styles.controlButton} onClick={() => config.onSkip('back')}>
        <img src={videoRewindIcon} alt='video rewind icon' />
      </button>
      <button className={styles.controlButton} onClick={() => config.onToggle(!config.playing)}>
        <img src={config.playing ? videoPauseIcon : videoPlayIcon} alt='video toggle icon' />
      </button>
      <button className={styles.controlButton} onClick={() => config.onSkip('forward')}>
        <img src={videoForwardIcon} alt='video rewind icon' />
      </button>
    </div>
  );
};
