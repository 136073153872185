import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Menu } from '@headlessui/react';
import userIcon from '@assets/icons/user.svg';
import { useDispatchTyped } from '@core/hooks';
import { logout, useAccessControlSelector } from '@core/store/slices';
import { Dropdown } from '@components/Dropdown';
import { IOption } from '@components/Dropdown/Dropdown';
import styles from './styles.scss';

interface IProps {}

export const User: React.FC<IProps> = () => {
  const { user, isDisabledLogout } = useAccessControlSelector();
  const dispatch = useDispatchTyped();
  const { t } = useTranslation();

  const options: IOption[] = useMemo(
    () => [
      {
        name: t('userDropdown.logoutButton'),
        disabled: isDisabledLogout,
        onClick: () => dispatch(logout()),
      },
    ],
    [dispatch, isDisabledLogout],
  );

  return (
    <Menu as='div' className={styles.userContainer}>
      {({ open }) => (
        <>
          <Menu.Button className={styles.user}>
            <span className={styles.userName}>{user?.full_name}</span>
            <img className={styles.userIcon} src={userIcon} alt='user icon' />
          </Menu.Button>
          <Dropdown open={open} options={options} />
        </>
      )}
    </Menu>
  );
};
