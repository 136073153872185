import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DEFAULT_FAR, DEFAULT_FOV, DEFAULT_NEAR } from '@core/constants';
import { EAsyncStatus, EMapViewerActive, ESidebar, EViewer, EViewerType } from '@core/enums';
import { useDispatchTyped } from '@core/hooks';
import { ICamera } from '@core/interfaces/model';
import { DataProcessing } from '@core/services';
import {
  loadModel3D,
  setCurrentAnomalyId,
  setShouldUpdateCenterAnomaly,
  setSidebar,
  updateModelData,
  updateModelState,
  useControlsSelector,
  useCurrentAnomalySelector,
  useCurrentSiteSelector,
  useModel3DFlagsSelector,
  useModel3DSelector,
  useModel3DStateSelector,
  useSidebarSelector,
  useViewerSelector,
} from '@core/store/slices';
import { useCurrentReportSelector } from '@core/store/slices/reports';
import { setSelectedSample, useSamplesSelector } from '@core/store/slices/samples';
import { isOneValueValid } from '@core/utils';
import { getActiveViewerByViewerKey } from '@modules/Sidebar/utils';
import { Controls } from '@modules/Viewers/components/Controls';
import ThreetsViewer from './components/ThreejsViewer/ThreetsViewer';
import { ESocketType } from './components/ThreejsViewer/enums';
import { getSelectedAnomalyCameraPosition } from './components/ThreejsViewer/utils/getSelectedAnomalyCameraPosition';
import { ModelViewerContext } from './contexts/modelViewer';
import { IModelState } from './interfaces/model';
import styles from './styles.scss';
import { transformAnomalies } from './utils/anomalies/transformAnomalies';
import { drawBatteries } from './utils/flightPath/drawBatteries';
import { drawFlightPath } from './utils/flightPath/drawFlightPath';
import { getCameraForDolly } from './utils/getCameraForDolly';
import { drawSamples } from './utils/samples/drawSamples';
import { drawSolarPanels } from './utils/solarPanels/drawSolarPanels';
import { transformSolarPanels } from './utils/solarPanels/transformSolarPanels';

interface IProps {
  container: HTMLDivElement | null;
  showControls?: boolean;
  type: EViewerType;
}

const initialModelSize = {
  width: 0,
  height: 0,
};

export const ModelViewer = memo(({ container, type, showControls = true }: IProps) => {
  const { sidebar } = useSidebarSelector();
  const { viewer, sidebarViewer } = useViewerSelector();
  const { selectedSample, anomalySamples, currentReportSamples } = useSamplesSelector();
  const { hasSolarPanels } = useControlsSelector();

  const { model: model3D, connections } = useModel3DSelector();
  const currentSite = useCurrentSiteSelector();
  const currentReport = useCurrentReportSelector();
  const currentAnomaly = useCurrentAnomalySelector();
  const controls = useControlsSelector();
  const state = useModel3DStateSelector();
  const modelFlags = useModel3DFlagsSelector();

  const [modelSize, setModelSize] = useState(initialModelSize);
  const [defaultCamera, setDefaultCamera] = useState<ICamera | null>(null);

  const loadedModelStatusRef = useRef<EAsyncStatus>(EAsyncStatus.Idle);
  loadedModelStatusRef.current = model3D.flags.loadedModel.fetchStatus;

  const dispatch = useDispatchTyped();

  const transformedSolarPanels = useMemo(
    () => model3D.data.solarPanels,
    [model3D.data.solarPanels],
  );
  const transformedAnomalies = useMemo(() => model3D.data.anomalies, [model3D.data.anomalies]);

  const DOLLY_STEP = 0.3;

  const handleHideDrawables = useCallback(() => {
    dispatch(updateModelState({ hide_drawables: true }));
  }, [dispatch]);

  const handleShowDrawables = useCallback(() => {
    dispatch(updateModelState({ hide_drawables: false }));
  }, [dispatch]);

  const handleStateChange = useCallback(
    (newState: Partial<IModelState>) => {
      dispatch(updateModelState(newState));
    },
    [dispatch],
  );

  const handleAnomalyClick = useCallback(
    (anomalyId: string) => {
      dispatch(setSidebar(ESidebar.Anomaly));
      dispatch(setCurrentAnomalyId(Number(anomalyId)));
    },
    [dispatch],
  );

  const handleSampleClick = useCallback(
    (sampleId: string) => {
      const sample = anomalySamples.find(({ id }) => id === Number(sampleId));

      if (sample) {
        dispatch(setSelectedSample(sample));
      }
    },
    [anomalySamples, dispatch],
  );

  const handleDolly = useCallback(
    (dollyIn: boolean) => {
      if (state.currentCamera) {
        const newCamera = getCameraForDolly(dollyIn, state.currentCamera, DOLLY_STEP);
        handleStateChange({ initCamera: newCamera });
      }
    },
    [state.currentCamera, handleStateChange],
  );

  const handleResetCamera = useCallback(() => {
    const resetEvent = new Event('reset_camera');
    window.dispatchEvent(resetEvent);
  }, []);

  const handleZoomIn = useCallback(() => handleDolly(true), [handleDolly]);
  const handleZoomOut = useCallback(() => handleDolly(false), [handleDolly]);

  // Load Model
  useEffect(() => {
    const shouldPreventLoadModel = isOneValueValid(
      type === EViewerType.InspectionView, // load only one model (main view)
      loadedModelStatusRef.current !== EAsyncStatus.Idle,
    );

    if (shouldPreventLoadModel) return;
    if (currentReport?.model_id && currentReport?.expname && currentSite?.loc_id) {
      dispatch(
        loadModel3D({
          site: currentSite,
          report: currentReport,
        }),
      );
    }
  }, [
    loadedModelStatusRef.current,
    currentReport?.model_id,
    currentReport?.expname,
    currentSite?.loc_id,
  ]);

  useEffect(() => {
    !defaultCamera && setDefaultCamera(state.initCamera);
  }, [state.initCamera]);

  // Add width, height and cameraProps for viewer
  useEffect(() => {
    if (!container || loadedModelStatusRef.current !== EAsyncStatus.Success) return;

    const width = container.clientWidth;
    const height = container.clientHeight;

    setModelSize({ width, height });
    handleStateChange({
      cameraProps: {
        fov: DEFAULT_FOV,
        aspect: width / height,
        near: DEFAULT_NEAR,
        far: DEFAULT_FAR,
      },
    });
  }, [container, loadedModelStatusRef.current, handleStateChange]);

  useEffect(() => {
    if (
      !currentSite ||
      !currentReport ||
      loadedModelStatusRef.current !== EAsyncStatus.Success ||
      type === EViewerType.InspectionView
    )
      return;

    if (currentReport.solar_panels && currentReport.anomalies) {
      const solarPanels = transformSolarPanels(currentReport.solar_panels, currentSite);
      const anomalies = transformAnomalies(currentReport.anomalies);

      dispatch(
        updateModelData({
          solarPanels,
          anomalies,
        }),
      );
    }
  }, [currentReport, currentSite, loadedModelStatusRef.current]);

  // Draw Solar Panels, Anomalies, Samples
  useEffect(() => {
    if (
      !transformedAnomalies ||
      !transformedSolarPanels ||
      !currentReport ||
      !currentReportSamples ||
      !sidebar ||
      loadedModelStatusRef.current !== EAsyncStatus.Success ||
      type === EViewerType.InspectionView
    )
      return;

    let drawables;

    switch (sidebar) {
      case ESidebar.Zone: {
        const solarPanels = drawSolarPanels({
          solarPanels: transformedSolarPanels,
          anomalies: transformedAnomalies,
          hasSolarPanels,
        });

        if (controls.hasFlightPath) {
          const flightPath = drawFlightPath(currentReportSamples);
          const batteries = drawBatteries(currentReportSamples);
          const samples = drawSamples(currentReportSamples, ESidebar.Zone);

          drawables = [...solarPanels, ...flightPath, ...samples, ...batteries];
        } else {
          drawables = [...solarPanels];
        }
        break;
      }
      case ESidebar.Anomaly: {
        const solarPanels = drawSolarPanels({
          solarPanels: transformedSolarPanels,
          anomalies: transformedAnomalies,
          hasSolarPanels,
          currentAnomaly,
        });

        if (controls.hasFlightPath) {
          const samples = drawSamples(anomalySamples, ESidebar.Anomaly, selectedSample);
          const flightPath = drawFlightPath(currentReportSamples);

          drawables = [...solarPanels, ...flightPath, ...samples];
        } else {
          drawables = [...solarPanels];
        }
        break;
      }
    }

    handleStateChange({ drawables });
  }, [
    type,
    loadedModelStatusRef.current,
    anomalySamples.length,
    selectedSample?.id,
    hasSolarPanels,
    currentReport,
    currentAnomaly,
    transformedSolarPanels,
    controls.hasFlightPath,
    currentReportSamples?.length,
    currentSite,
    sidebar,
    transformedAnomalies,
  ]);

  const handleSetCenterAnomaly = useCallback(
    (anomalyId: string | number) => {
      // NOTE: center camera position for selected anomaly
      const drawables = state.drawables;
      const datasetCameraPoses = state.datasetCameraPoses;
      const socketConnection = connections.socketConnection;

      if (!drawables.length || !datasetCameraPoses || !socketConnection?.ws) {
        return false;
      }

      const drawableAnomalyPattern = `with_anomaly_${anomalyId}`;
      const foundSolarPanel = drawables.find((drawable) =>
        drawable.id.includes(drawableAnomalyPattern),
      );

      if (foundSolarPanel && datasetCameraPoses) {
        const cameraView = getSelectedAnomalyCameraPosition(foundSolarPanel, datasetCameraPoses);

        if (socketConnection?.ws.readyState === WebSocket.OPEN) {
          const serializedBody = DataProcessing.serialize({
            type: ESocketType.CameraChange,
            payload: cameraView,
          });
          socketConnection?.ws.send(serializedBody);
        }

        dispatch(updateModelState({ initCamera: cameraView }));
        return true;
      }

      return false;
    },
    [state.drawables, state.datasetCameraPoses, connections.socketConnection, dispatch],
  );
  // NOTE: use case (click "3D" on Anomaly level)
  useEffect(() => {
    const shouldUpdateCenterAnomaly =
      modelFlags.shouldUpdateCenterAnomaly && currentAnomaly?.id && type === EViewerType.Viewer;

    if (shouldUpdateCenterAnomaly) {
      const isUpdatedCameraPosition = handleSetCenterAnomaly(currentAnomaly.id);

      if (isUpdatedCameraPosition) {
        dispatch(setShouldUpdateCenterAnomaly(false));
      }
    }
  }, [type, currentAnomaly?.id, modelFlags.shouldUpdateCenterAnomaly, handleSetCenterAnomaly]);

  const contextValue = useMemo(
    () => ({
      type,
      activeViewer:
        getActiveViewerByViewerKey(viewer, sidebarViewer, EViewer.Model) ?? EMapViewerActive.Viewer,
    }),
    [viewer, sidebarViewer, type],
  );

  return (
    <div className={styles.container}>
      <ModelViewerContext.Provider value={contextValue}>
        {modelSize.width && modelSize.height && (
          <ThreetsViewer
            {...state}
            {...modelSize}
            onHideDrawables={handleHideDrawables}
            onShowDrawables={handleShowDrawables}
            onStateChange={handleStateChange}
            onClickAnomaly={handleAnomalyClick}
            onSelectSample={handleSampleClick}
          />
        )}
      </ModelViewerContext.Provider>
      <Controls
        show={showControls}
        onZoomIn={handleZoomIn}
        onZoomOut={handleZoomOut}
        onDynamicZoom={handleResetCamera}
      />
    </div>
  );
});
