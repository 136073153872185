import { FeatureCollection, Geometry, GeoJsonProperties, Position } from 'geojson';
import { ISample } from '@core/interfaces';
import { getColor } from '@core/utils';

export const getSamplesFeatureCollection = (
  samples: ISample[],
  selectedSample?: ISample | null,
): FeatureCollection<Geometry, GeoJsonProperties> => ({
  type: 'FeatureCollection',
  features: samples.map((sample) => ({
    type: 'Feature',
    id: sample.id,
    properties: {
      color: getColor(sample.id === selectedSample?.id ? '--outflier-blue' : '--outflier-white'),
      border: getColor(sample.id === selectedSample?.id ? '--outflier-white' : '--outflier-blue'),
    },
    geometry: {
      type: 'Point',
      coordinates:
        sample.geodetic_camera?.lon && sample.geodetic_camera?.lat
          ? [sample.geodetic_camera?.lon, sample.geodetic_camera?.lat]
          : [],
    },
  })),
});

type TFrustumCoordinates = {
  polygon: Position[];
  borders: Position[];
  triangle: Position[];
};

export const getFrustumFeatureCollection = (
  frustumCoordinates: TFrustumCoordinates,
): FeatureCollection<Geometry, GeoJsonProperties> => ({
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [frustumCoordinates.polygon],
      },
    },
    {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: frustumCoordinates.borders,
      },
    },
    {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: frustumCoordinates.triangle,
      },
    },
  ],
});
