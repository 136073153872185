import React, { InputHTMLAttributes, useState } from 'react';
import { ReactSVG } from 'react-svg';
import cn from 'classnames';
import { useField } from 'formik';
import eyeHideIcon from '@assets/icons/eye-hide.svg';
import eyeShowIcon from '@assets/icons/eye-show.svg';
import styles from './styles.scss';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  name: string;
}

export const Input: React.FC<IProps> = ({ className, name, ...props }) => {
  const [field, meta] = useField(name);
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const inputClasses = cn(
    className,
    'input',
    'text-sm h-[45px] w-full px-[15px] rounded-[3px] bg-outflier-contain-background active:bg-opacity-75 placeholder:text-outflier-soft-gray text-white',
    {
      'pr-12': props.type === 'password',
    },
  );

  return (
    <div className='relative w-full min-h-[45px]'>
      <input
        className={inputClasses}
        {...field}
        {...props}
        type={props.type === 'password' && !isPasswordHidden ? 'text' : props.type}
      />
      {props.type === 'password' && (
        <button
          type='button'
          onClick={() => setIsPasswordHidden(!isPasswordHidden)}
          className={styles.eyeBtn}
        >
          <ReactSVG className={styles.eyeIcon} src={isPasswordHidden ? eyeHideIcon : eyeShowIcon} />
        </button>
      )}
      {meta.touched && meta.error && (
        <div className='text-outflier-red text-xs mt-1'>{meta.error}</div>
      )}
    </div>
  );
};
