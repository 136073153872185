import { TAxiosInstance } from '@core/api';

// import { OUTFLIER_TENANT } from '@core/constants';

export const tenantInterceptor = (axiosInstance: TAxiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      if (['token'].includes(String(config.url))) {
        return config;
      }

      // TODO a mechanism to identify a current tenant

      // NOTE: temporary solution (legacy endpoint)
      // config.url = `/${OUTFLIER_TENANT}${config.url}`;
      return config;
    },
    (error) => Promise.reject(error),
  );
};
