import { FeatureCollection, Polygon, Geometry, GeoJsonProperties } from 'geojson';
import { IProgram } from '@core/interfaces';
import { INoFlyZone, IObstacle } from '@core/interfaces/createSite';
import { getColor } from '@core/utils';

export const getObstaclesFeatureCollection = (obstacles: IObstacle[] = []): FeatureCollection => ({
  type: 'FeatureCollection',
  features: obstacles.map((obstacle) => ({
    type: 'Feature',
    properties: {
      id: obstacle.id,
    },
    geometry: obstacle.perimeter,
  })),
});

export const getNoFlyZonesFeatureCollection = (
  noFlyZones: INoFlyZone[] = [],
): FeatureCollection => ({
  type: 'FeatureCollection',
  features: noFlyZones
    .filter((zone) => zone.perimeter)
    .map((zone) => ({
      type: 'Feature',
      properties: {
        id: zone.id,
        name: zone.name,
        type: zone.type,
      },
      geometry: zone.perimeter,
    })),
});

export const getPerimeterFeatureCollection = (
  perimeter: Polygon | FeatureCollection<Geometry, GeoJsonProperties> | null | undefined,
) => {
  const perimeterFeatureCollection: FeatureCollection = {
    type: 'FeatureCollection',
    features: [],
  };

  switch (perimeter?.type) {
    case 'FeatureCollection':
      perimeterFeatureCollection.features = perimeter.features;
      break;
    case 'Polygon':
      perimeterFeatureCollection.features.push({
        type: 'Feature',
        properties: {},
        geometry: perimeter,
      });
      break;
  }
  return perimeterFeatureCollection;
};

export const getGeneratedProgramsFeatureCollection = (
  generatedPrograms: IProgram[] = [],
): FeatureCollection<Geometry, GeoJsonProperties> => ({
  type: 'FeatureCollection',
  features: generatedPrograms
    .filter((program) => Boolean(program.display_perimeter))
    .map((program, index) => {
      let zoneIndexBackgroundColor = getColor('--outflier-blue');
      let zoneIndexColor = getColor('--outflier-white');

      if (program.isViewed) {
        zoneIndexBackgroundColor = getColor('--outflier-green');
        zoneIndexColor = getColor('--outflier-black');
      }
      if (program.custom_overview_plan_requested || program.custom_inspection_plan_requested) {
        zoneIndexBackgroundColor = getColor('--outflier-yellow');
        zoneIndexColor = getColor('--outflier-black');
      }

      return {
        type: 'Feature',
        properties: {
          id: program.program_id,
          index: index + 1,
          zoneIndexBackgroundColor,
          zoneIndexColor,
        },
        geometry: program.display_perimeter as Geometry,
      };
    }),
});

export const getPerimeterDisplayFeatureCollection = (
  generatedProgram: IProgram,
): FeatureCollection => ({
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {},
      geometry: generatedProgram.display_perimeter as Polygon,
    },
  ],
});
