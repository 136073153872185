import { FC, useMemo } from 'react';
import { useCachedValue } from '@core/hooks';
import { IInspection } from '@core/interfaces';
import { useVisibleInspectionsSelector } from '@core/store/slices';
import { MonthInspectionsOptions } from '@components/Timeline/components/MonthInspectionsOptions';
import { groupInspectionsByMonthYear } from '@components/Timeline/utils/groupInspectionsByMonthYear';

export const TimelineItemsList: FC = () => {
  const visibleInspections = useVisibleInspectionsSelector();

  const cachedVisibleInspections = useCachedValue(visibleInspections);

  const preparedVisibleInspections: IInspection[][] = useMemo(
    () => groupInspectionsByMonthYear(cachedVisibleInspections),
    [cachedVisibleInspections],
  );

  const isInspectionSingle =
    preparedVisibleInspections.length === 1 && preparedVisibleInspections[0].length === 1;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '8px' }}>
      <div className='flex justify-between'>
        {preparedVisibleInspections.map((monthInspections, index) => (
          <MonthInspectionsOptions
            key={index}
            monthInspections={monthInspections}
            isOptionSingle={isInspectionSingle}
          />
        ))}
      </div>
    </div>
  );
};
