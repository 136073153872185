import mapboxgl from 'mapbox-gl';
import { EZonesLayers } from '../../enums/layers';
import { EZonesSources } from '../../enums/sources';

export const removeZones = (map: mapboxgl.Map) => {
  const layers = [EZonesLayers.Polygon, EZonesLayers.PolygonBorder];
  layers.forEach((layer) => map.getLayer(layer) && map.removeLayer(layer));

  if (map.getSource(EZonesSources.Zones)) {
    map.removeSource(EZonesSources.Zones);
  }
};
