import React, { useCallback, useEffect, useRef } from 'react';
import { ECookiesField } from '@core/constants';
import { EControlMode, ESidebar } from '@core/enums';
import { EReportSelector } from '@core/enums/report';
import { useDispatchTyped } from '@core/hooks';
import { IBrowserStorage } from '@core/interfaces/accessControl';
import { DataProcessing, cookiesStorage } from '@core/services';
import { EPersistorStatus } from '@core/services/persistor/enums';
import { EMergeStrategy } from '@core/services/persistor/strategies';
import { IPersistPermission } from '@core/services/storage/interfaces/permissions';
import { persistor } from '@core/store';
import {
  useAccessControlSelector,
  useControlsSelector,
  useSidebarSelector,
} from '@core/store/slices';
import { fetchAnomaliesAndSolarPanelsToReports } from '@core/store/slices/reports';
import ZoneCompareView from './components/CompareView/CompareView';
import ZoneDefaultView from './components/DefaultView/DefaultView';
import styles from './styles.scss';

interface IProps {
  toCompare?: boolean;
}

export enum EZoneView {
  DefaultView = 'DEFAULT_VIEW',
  CompareView = 'COMPARE_VIEW',
}

const zonesViews = {
  [EZoneView.DefaultView]: <ZoneDefaultView />,
  [EZoneView.CompareView]: <ZoneCompareView />,
};

export const Zone: React.FC<IProps> = ({ toCompare = false }: IProps) => {
  const { sidebarPrevious } = useSidebarSelector();
  const { mode, isCompareMode } = useControlsSelector();
  const { browserStorage } = useAccessControlSelector();
  const currentBrowserStorageRef = useRef<IBrowserStorage | null>(browserStorage);
  currentBrowserStorageRef.current = browserStorage;

  const dispatch = useDispatchTyped();

  const view = toCompare ? EZoneView.CompareView : EZoneView.DefaultView;

  const enableStoragePersistPermission = useCallback(() => {
    const serializedValue = DataProcessing.serialize({ isPermissionGranted: true });
    cookiesStorage.setItem(ECookiesField.StoragePersistPermission, serializedValue);
  }, []);

  const disableStoragePersistPermission = useCallback(() => {
    cookiesStorage.removeItem(ECookiesField.StoragePersistPermission);
  }, []);

  const updateStoragePersistPermission = useCallback((permissionsData: IPersistPermission) => {
    const serializedValue = DataProcessing.serialize(permissionsData);
    cookiesStorage.setItem(ECookiesField.StoragePersistPermission, serializedValue);
  }, []);

  const hasStoragePersistPermission = useCallback(() => {
    return Boolean(cookiesStorage.getItem(ECookiesField.StoragePersistPermission));
  }, []);

  const updateStateOnRefresh = useCallback(() => {
    // NOTE: set error if page is refreshed and app returned to the "Sites" mode
    if (hasStoragePersistPermission()) {
      if (currentBrowserStorageRef.current?.error.status) {
        updateStoragePersistPermission({
          isPermissionGranted: true,
          state: {
            mergeStrategy: EMergeStrategy.RehydratePartial,
            keyPaths: ['accessControl.browserStorage.error'],
            shouldResetPermissionAfterMerge: true,
          },
        });
      }
      // FIXME: temporary solution (revert persistor Cache issue)
      if (persistor.status === EPersistorStatus.Active) {
        persistor.unsubscribeFromStore();
        persistor.persist(
          {
            controls: {
              hasFlightPath: false,
            },
          },
          { strategy: EMergeStrategy.PersistPartial },
        );
      }
    }
  }, [hasStoragePersistPermission]);

  // NOTE: use case (common): set cookie permission to rehydrate saved data with state
  useEffect(() => {
    const enableToPersist = mode === EControlMode['2D'] && !isCompareMode;

    if (enableToPersist) {
      enableStoragePersistPermission();
    } else {
      disableStoragePersistPermission();
    }
  }, [mode, isCompareMode, enableStoragePersistPermission, disableStoragePersistPermission]);

  useEffect(() => {
    if (sidebarPrevious !== ESidebar.Anomaly) {
      dispatch(
        fetchAnomaliesAndSolarPanelsToReports({
          selector: EReportSelector.GetAllReportsBySelectedZone,
        }),
      );
    }
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', updateStateOnRefresh);
    return () => {
      window.removeEventListener('beforeunload', updateStateOnRefresh);
    };
  }, [updateStateOnRefresh]);

  return <div className={styles.zone}>{zonesViews[view]}</div>;
};
