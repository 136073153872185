import { ESortOrder } from '@core/enums';
import { IInspection, IProgram } from '@core/interfaces';
import { sortByGroup } from '@core/utils';

export function getLatestInspectionsForEachProgram(
  programs: IProgram[],
  inspections: IInspection[],
) {
  const programIds = programs.map(({ program_id }) => program_id);
  const programInspections = inspections.filter((inspection: IInspection) =>
    programIds.includes(inspection.program_id),
  );

  const groupedProgramInspections = sortByGroup(programInspections, {
    groupBy: 'program_id',
    sortBy: 'date',
    order: ESortOrder.Asc,
  });

  return Object.values(groupedProgramInspections).reduce<IInspection[]>((acc, inspections) => {
    const latestInspection = inspections[0];
    return !latestInspection ? acc : [...acc, latestInspection];
  }, []);
}
