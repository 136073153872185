import { FC } from 'react';
import cn from 'classnames';
import styles from './styles.scss';

interface IProps {
  active?: boolean;
  icon: string;
  onChange: (active: boolean) => void;
}

export const ToggleButton: FC<IProps> = ({ active, icon, onChange }) => (
  <button
    className={cn(styles.button, { [styles.active]: active })}
    onClick={onChange.bind(this, !active)}
  >
    <img src={icon} alt='toggle icon' />
  </button>
);
