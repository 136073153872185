import { FC, Fragment, memo } from 'react';
import { ReactSVG } from 'react-svg';
import { Dialog, Transition } from '@headlessui/react';
import cn from 'classnames';
import crossIcon from '@assets/icons/cross.svg';
import validIcon from '@assets/icons/valid.svg';
import styles from './styles.scss';

const mainTransitionProps = {
  enter: 'transition duration-100 ease-out',
  enterFrom: 'transform scale-95 opacity-0',
  enterTo: 'transform scale-100 opacity-100',
  leave: 'transition duration-75 ease-out',
  leaveFrom: 'transform scale-100 opacity-100',
  leaveTo: 'transform scale-95 opacity-0',
};

const overlayTransitionProps = {
  enter: 'ease-out duration-300',
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100',
  leave: 'ease-in duration-100',
  leaveFrom: 'opacity-100',
  leaveTo: 'opacity-0',
};

const panelTransitionProps = {
  enter: 'ease-out duration-300',
  enterFrom: 'opacity-0 scale-95',
  enterTo: 'opacity-100 scale-100',
  leave: 'ease-in duration-200',
  leaveFrom: 'opacity-100 scale-100',
  leaveTo: 'opacity-0 scale-95',
};

interface IProps {
  title: string;
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  onDecline: () => void;
  onConfirm: () => void;
}

const Modal: FC<IProps> = ({ title, isOpen, className, onClose, onDecline, onConfirm }) => {
  return (
    <Transition show={isOpen} as={Fragment} {...mainTransitionProps}>
      <Dialog className={cn('fixed z-10 inset-0 overflow-y-auto', className)} onClose={onClose}>
        <div className='flex min-h-full items-center justify-center'>
          <Transition.Child as={Fragment} {...overlayTransitionProps}>
            <Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-50' />
          </Transition.Child>
          <Transition.Child as={Fragment} {...panelTransitionProps}>
            <Dialog.Panel className={cn('relative', styles.panel)}>
              <Dialog.Title className={styles.title}>{title}</Dialog.Title>
              <div className={styles.controls}>
                <button className={cn(styles.btnControl, styles.decline)} onClick={onDecline}>
                  <ReactSVG className={styles.img} src={crossIcon} />
                </button>
                <button className={cn(styles.btnControl, styles.confirm)} onClick={onConfirm}>
                  <ReactSVG className={styles.img} src={validIcon} />
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default memo(Modal);
