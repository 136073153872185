export enum ESiteStatuses {
  Normal = 'normal',
  Issues = 'issues',
  Inspected = 'inspected',
  ReportProcessing = 'reportProcessing',
}

export enum ESiteEditSections {
  Name = 'name',
  Perimeter = 'perimeter',
  NoFlyZones = 'noFlyZones',
  Obstacles = 'obstacles',
  Picture = 'picture',
  InspectionFrequency = 'inspectionFrequency',
  Roles = 'roles',
  Zones = 'zones',
}

export enum ESiteEditActions {
  AddOne = 'addOne',
  UpdateOne = 'updateOne',
  DeleteOne = 'deleteOne',
  SelectOne = 'selectOne',
  ResetOne = 'resetOne',
  SelectPreviousZone = 'selectPreviousZone',
  SelectNextZone = 'selectNextZone',
}

// NOTE: the count is amount of weeks for defined period
export enum EInspectionFrequency {
  Monthly = 4,
  HalfYearly = 26,
  Yearly = 52,
}
