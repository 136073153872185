import { toast } from 'react-toastify';
import { t } from 'i18next';
import { ERROR_TOAST_DELAY } from '@core/constants';
import { IInspection, ISite } from '@core/interfaces';
import { ETimeUnit, getMilliseconds } from '@core/utils';
import { compareInspectionDates } from '../inspections';

interface IAggregatedCollection {
  [siteId: string]: {
    [programId: string]: IInspection;
  };
}

export function getAggregatedCollectionBySiteIdWithProgramLatestInspectionPair(
  inspections: IInspection[],
): IAggregatedCollection {
  return inspections.reduce((acc, inspection) => {
    if (inspection.location_id) {
      const hasLocationId = inspection.location_id in acc;

      // 1. Set an inspection per zone
      if (!hasLocationId) {
        return {
          ...acc,
          [inspection.location_id]: { [inspection.program_id]: { ...inspection } },
        };
      } else {
        const programInspection = acc[inspection.location_id][inspection.program_id];

        // 2. Set an inspection per zone and spread previous ones.
        if (!programInspection) {
          return {
            ...acc,
            [inspection.location_id]: {
              ...acc[inspection.location_id],
              [inspection.program_id]: { ...inspection },
            },
          };
        } else {
          const isLatestInspection = compareInspectionDates(
            inspection.date,
            programInspection.date,
          );
          const latestInspection = isLatestInspection ? inspection : programInspection;

          // 3. Update to the latest inspection per zone and spread previous ones.
          return {
            ...acc,
            [inspection.location_id]: {
              ...acc[inspection.location_id],
              [inspection.program_id]: { ...latestInspection },
            },
          };
        }
      }
    }
    return acc;
  }, {});
}

export function shouldAtLeastOneZoneBeInspected(
  aggregatedCollection: IAggregatedCollection,
  site: ISite,
) {
  try {
    if (!site.inspection_frequency) {
      const errorMessage = t('errors.missingInspectionFrequencyField', { siteId: site.loc_id });
      throw new Error(errorMessage);
    }

    const siteInspections = Object.values(aggregatedCollection[site.loc_id]);

    const currentTime = new Date().getTime();
    const inspectionFrequencyInMs = getMilliseconds(site.inspection_frequency, ETimeUnit.Week);

    let shouldZoneBeInspected;

    siteInspections.forEach((inspection) => {
      const inspectedTime = new Date(inspection.date).getTime();
      const timeDiffFromCurrentToLatestInspection = currentTime - inspectedTime;

      shouldZoneBeInspected = timeDiffFromCurrentToLatestInspection > inspectionFrequencyInMs;
    });

    return shouldZoneBeInspected;
  } catch (err) {
    if (err instanceof Error) {
      toast.error(err.message, { autoClose: ERROR_TOAST_DELAY });
    }

    return false;
  }
}
