import { EErrorStatus } from '@core/enums';

export class SampleError extends Error {
  public status: EErrorStatus;

  constructor(message: string, status: EErrorStatus) {
    super(message);
    this.name = 'SampleError';
    this.status = status;
  }
}
