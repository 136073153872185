import { HTTPS_PORT, HTTP_PORT } from '@core/constants';
import { defaultProps } from '../components/ThreejsViewer/constants/defaultProps';

const server_tls = true;
const server_port = server_tls ? HTTPS_PORT : HTTP_PORT;

export const initialModelState = {
  id: defaultProps.id,
  pointcloudSrc: defaultProps.pointcloudSrc,
  cameraProps: defaultProps.cameraProps,
  initCamera: defaultProps.initCamera,
  nextCameraCenter: defaultProps.nextCameraCenter,
  datasetCameraPoses: defaultProps.datasetCameraPoses,
  reportsData: defaultProps.reportsData,
  currentCamera: defaultProps.currentCamera,
  selectedAnomalies: defaultProps.selectedAnomalies,
  selectedDrawables: defaultProps.selectedDrawables,
  selectionMode: defaultProps.selectionMode,
  settings: { ...defaultProps.settings, motion_delay_ms: 500 },
  server_host: null,
  server_port: server_port,
  server_tls: server_tls,
  access_token: defaultProps.access_token,
  reservation_token: defaultProps.reservation_token,
  ice_servers: [
    {
      urls: 'stun:stun.outview.outflier.com:3478',
    },
    {
      urls: 'turn:turn.outview.outflier.com:3478',
      username: 'outflier',
      credential: '1234',
    },
  ],
  imageSrc: 'offer',
  drawables: [],
  hide_drawables: false,
};
