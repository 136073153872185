import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { browserLocalStorage } from '@core/services/storage';
import en from './locales/en.json';
import fr from './locales/fr.json';

const resources = {
  en: { translation: en },
  fr: { translation: fr },
};

i18next
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    debug: true,
    lng: browserLocalStorage.getItem('lang') || 'en',
  });

export default i18next;
