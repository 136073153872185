import { FC } from 'react';
import { EConnectionError, ESidebar } from '@core/enums';
import { useSelectorTyped } from '@core/hooks';
import { useNetworkConnectionSelector, useSidebarSelector } from '@core/store/slices';
import { isOneValueValid } from '@core/utils';
import Crosshair from '@components/Crosshair/crosshair';
import { SitePerimeterProgressBar } from './SitePerimeter';
import { ZonesPerimeterProgressBar } from './ZonesPerimeter';

export const SiteCreationPerimeterProgressBar: FC = () => {
  const { sidebar } = useSidebarSelector();
  const networkConnection = useNetworkConnectionSelector();
  const perimeterProcessingPercentage = useSelectorTyped(
    (state) => state.createSite.perimeterProcessingPercentage,
  );
  const isLoadingZones = useSelectorTyped((state) => state.createSite.isLoadingZones);

  const isHiddenSitePerimeterCreation = isOneValueValid(
    sidebar !== ESidebar.CreateSite,
    perimeterProcessingPercentage === null,
    networkConnection.error === EConnectionError.ConnectionLost,
  );

  const isHiddenZonesPerimeterCreation = isOneValueValid(
    sidebar !== ESidebar.CreateSite,
    !isLoadingZones,
    networkConnection.error === EConnectionError.ConnectionLost,
  );

  return (
    <>
      <SitePerimeterProgressBar
        percentage={perimeterProcessingPercentage ?? 0}
        isHidden={isHiddenSitePerimeterCreation}
      />
      <ZonesPerimeterProgressBar isHidden={isHiddenZonesPerimeterCreation} />
      <Crosshair />
    </>
  );
};
