import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { ELocalStorageField } from '@core/constants/storage';
import { EEnvironment } from '@core/enums';
import { EPersistorStatus } from '@core/services/persistor/enums';
import { Persistor } from '@core/services/persistor/persistor';
import { browserLocalStorage } from '@core/services/storage';
import { anomaliesReducer } from '@core/store/slices/anomalies';
import { inspectionsReducer } from '@core/store/slices/inspections';
import { programsReducer } from '@core/store/slices/programs';
import { reportsReducer } from '@core/store/slices/reports';
import {
  sitesReducer,
  accessControlReducer,
  preloaderReducer,
  viewerReducer,
  sidebarReducer,
  videoReducer,
  controlsReducer,
  createSitesReducer,
  networkConnectionReducer,
  editSiteReducer,
  model3DReducer,
} from './slices';
import { mapReducer } from './slices/map';
import { samplesReducer } from './slices/samples';

// FIXME: temporary solution (revert persistor Cache issue)
// import { getInitialState } from './utils/common/getInitialState';
// import { getPreloadedState } from './utils/common/getPreloadedState';

const reducers = combineReducers({
  accessControl: accessControlReducer,
  networkConnection: networkConnectionReducer,
  preloader: preloaderReducer,
  sites: sitesReducer,
  createSite: createSitesReducer,
  editSite: editSiteReducer,
  inspections: inspectionsReducer,
  programs: programsReducer,
  reports: reportsReducer,
  anomalies: anomaliesReducer,
  viewer: viewerReducer,
  sidebar: sidebarReducer,
  video: videoReducer,
  controls: controlsReducer,
  map: mapReducer,
  samples: samplesReducer,
  model3D: model3DReducer,
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const reduxLogger = createLogger({
  collapsed: true,
  duration: false,
  timestamp: false,
  level: 'info',
});

export const persistor = new Persistor({
  key: ELocalStorageField.PersistRoot,
  storage: browserLocalStorage,
  // FIXME: temporary solution (revert persistor Cache issue)
  status: EPersistorStatus.Inactive,
});

// NOTE: If you want to debug redux store in a console (redux-logger),
// you can add the following configuration to the "configureStore"

/*
  middleware: (getDefaultMiddleware) =>
    process.env.NODE_ENV === 'production'
      ? getDefaultMiddleware()
      : getDefaultMiddleware().concat(reduxLogger)
*/

export const store = configureStore({
  reducer: reducers,
  devTools: process.env.NODE_ENV !== EEnvironment.Production,
  // FIXME: temporary solution (revert persistor Cache issue)
  // preloadedState: getPreloadedState(persistor, getInitialState()),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatchType = typeof store.dispatch;
