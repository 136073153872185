import { axiosInstance } from '@core/api/axios';

interface IListPlanIdsRequest {
  loc_id: string;
  program_id: string;
  regex_name: string;
}

interface IListPlanIdsResponse {
  loc_id: string;
  name: string;
  plan_id: string;
  program_id: string;
}

export const getListPlanIds = async (data: IListPlanIdsRequest) => {
  return axiosInstance
    .post<IListPlanIdsResponse[]>('/flight_plans/list_plan_ids', data)
    .then((res) => res.data);
};
