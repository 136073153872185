/* eslint-disable @typescript-eslint/no-unused-vars */
import { Position } from '@turf/turf';
import * as d3 from 'd3';
import { EDetectionStatus } from '@core/enums';
import { EColorRGBA } from '@core/enums/colors';
import { ISampleDetection } from '@core/interfaces';
import { RGBA, hexToRGB, opacityToAlpha } from '@core/utils/converting/hexToRGB';
import { getBboxPolygon } from '@modules/Viewers/views/MapViewer/utils/geospatialUtils';

const CANVAS_PIXEL_SIZE = 4;
const MAX_ALPHA = 255;

type Point = [number, number];

type TAnomalyMaskParams = {
  [key in EDetectionStatus]: {
    detections: ISampleDetection[];
    lineWidth: number;
    color: string;
    opacity: number;
  };
};

type TAnomalyMasksRenderConfig = {
  [key in EDetectionStatus]: {
    color: string;
    lineWidth: number;
    opacity: number;
    polygonsCoordinates: Position[][];
  };
};

type TDetectionPolygon = {
  id: number;
  status?: EDetectionStatus;
  coordinates: Point[];
};

type TCanvasShapeRectPosition = {
  sx: number;
  sy: number;
  sw: number;
  sh: number;
};

const getCanvasShapeRectPosition = (coordinates: Position[]): TCanvasShapeRectPosition => {
  const bboxPolygon = getBboxPolygon(coordinates);
  const bboxCoordinates = bboxPolygon.geometry.coordinates[0];
  const [topLeft, topRight, bottomRight] = bboxCoordinates;

  const [shapeTopLeftX, shapeTopLeftY] = topLeft;
  const [shapeTopRightX] = topRight;
  const [_, shapeBottomRightY] = bottomRight;

  const shapeWidth = shapeTopRightX - shapeTopLeftX;
  const shapeHeight = shapeBottomRightY - shapeTopLeftY;

  return {
    sx: shapeTopLeftX,
    sy: shapeTopLeftY,
    sw: shapeWidth,
    sh: shapeHeight,
  };
};

const shiftCoordinatesToTopLeftPosition = (sx: number, sy: number, coordinates: any[]) =>
  coordinates.map(([x, y]) => [x - sx, y - sy]);

const getDetectionPolygon = ({ x, y, w, h }: ISampleDetection): { coordinates: Point[] } => {
  const topLeft: Point = [x, y];
  const topRight: Point = [x + w, y];
  const bottomRight: Point = [x + w, y + h];
  const bottomLeft: Point = [x, y + h];

  return {
    coordinates: [topLeft, topRight, bottomRight, bottomLeft],
  };
};

const pixelIndexes = {
  [EColorRGBA.Red]: (i: number) => i,
  [EColorRGBA.Green]: (i: number) => i + 1,
  [EColorRGBA.Blue]: (i: number) => i + 2,
  [EColorRGBA.Alpha]: (i: number) => i + 3,
};

const transformPixelToCoordinates = (index: number, canvasWidth: number): Point => {
  const x = Math.floor(index / CANVAS_PIXEL_SIZE) % canvasWidth;
  const y = Math.floor(index / CANVAS_PIXEL_SIZE / canvasWidth);

  return [x, y];
};

const getAlphaForEachPixel = (pixelsData: number[]) => {
  const alphaData: number[] = [];

  for (let i = 0; i < pixelsData.length; i += CANVAS_PIXEL_SIZE) {
    alphaData.push(pixelsData[pixelIndexes[EColorRGBA.Alpha](i)]);
  }

  return alphaData;
};

class Canvas {
  public canvasEl: HTMLCanvasElement;
  public ctx: CanvasRenderingContext2D | null;

  constructor(width: number, height: number) {
    this.canvasEl = document.createElement('canvas');
    this.canvasEl.width = width;
    this.canvasEl.height = height;
    this.ctx = this.canvasEl.getContext('2d', { willReadFrequently: true });
  }

  public drawFullImage(img: HTMLImageElement) {
    return this.ctx?.drawImage(img, 0, 0);
  }

  public getDefaultImageData() {
    return this.ctx?.getImageData(0, 0, this.canvasEl.width, this.canvasEl.height);
  }

  public renderOutline(points: Position[]) {
    // Start point
    const [x, y] = points[0];
    this.ctx?.beginPath();
    this.ctx?.moveTo(x, y);

    for (let i = 1; i < points.length; i += 1) {
      const [x, y] = points[i];
      this.ctx?.lineTo(x, y);
    }

    this.ctx?.closePath();
    this.ctx?.stroke();
  }

  public fillShape(
    imageData: ImageData,
    polygonPoints: Position[],
    rgba: RGBA,
    dx: number,
    dy: number,
  ) {
    const pixelsData = imageData.data;

    for (let i = 0; i < pixelsData.length; i += CANVAS_PIXEL_SIZE) {
      const point = transformPixelToCoordinates(i, imageData.width);
      const containPolygonPoint = d3.polygonContains(polygonPoints as Point[], point);

      if (containPolygonPoint) {
        const [r, g, b, a] = rgba;
        pixelsData[pixelIndexes[EColorRGBA.Red](i)] = r;
        pixelsData[pixelIndexes[EColorRGBA.Green](i)] = g;
        pixelsData[pixelIndexes[EColorRGBA.Blue](i)] = b;
        pixelsData[pixelIndexes[EColorRGBA.Alpha](i)] = a;
      }
    }

    this.ctx?.putImageData(imageData, dx, dy);
  }
}

export async function updateAnomalyMask(
  base64Str: string,
  params: TAnomalyMaskParams,
): Promise<string> {
  const { active, nonActive } = params;
  const { detections: activeDetections, ...activeRenderParams } = active;
  const { detections: nonActiveDetections, ...nonActiveRenderParams } = nonActive;

  const detections = [...activeDetections, ...nonActiveDetections];
  const anomalyMasksRenderConfig: TAnomalyMasksRenderConfig = {
    [EDetectionStatus.Active]: { ...activeRenderParams, polygonsCoordinates: [] },
    [EDetectionStatus.NonActive]: { ...nonActiveRenderParams, polygonsCoordinates: [] },
  };

  const img = new Image();
  img.src = base64Str;

  return new Promise((resolve) => {
    img.onload = () => {
      const draftCanvas = new Canvas(img.width, img.height);
      const finalCanvas = new Canvas(img.width, img.height);

      draftCanvas.drawFullImage(img);
      const imageData = draftCanvas.getDefaultImageData();
      const pixelsData = imageData?.data;

      if (pixelsData) {
        const alphaData = getAlphaForEachPixel(Array.from(pixelsData));

        const contours = d3
          .contours()
          .size([draftCanvas.canvasEl.width, draftCanvas.canvasEl.height])
          .thresholds([MAX_ALPHA]); // get only non transparent data
        const anomalyMasksPolygons = contours(alphaData);
        const detectionsPolygons = detections.map<TDetectionPolygon>((detection) => ({
          id: detection.id,
          status: detection.status,
          ...getDetectionPolygon(detection),
        }));

        // Prepare polygons points data to "anomalyMasksRenderConfig" by "status"
        if (anomalyMasksPolygons.length) {
          const polygons = anomalyMasksPolygons[0].coordinates;

          for (const detectionsPolygon of detectionsPolygons) {
            const { status, coordinates: detectionsPolygonCoordinates } = detectionsPolygon;

            for (const polygon of polygons) {
              const polygonCoordinates = polygon[0];

              for (const point of polygonCoordinates) {
                const containsPolygonPoint = d3.polygonContains(
                  detectionsPolygonCoordinates,
                  point as Point,
                );

                if (containsPolygonPoint && status) {
                  anomalyMasksRenderConfig[status].polygonsCoordinates.push(polygonCoordinates);
                  break;
                }
              }
            }
          }
        }

        // Set render options to "finalCanvas"
        Object.values(anomalyMasksRenderConfig).forEach((data) => {
          const { color, opacity, lineWidth, polygonsCoordinates } = data;

          polygonsCoordinates.forEach((polygonCoordinates) => {
            const { sx, sy, sw, sh } = getCanvasShapeRectPosition(polygonCoordinates);

            const rgbColor = hexToRGB(color);
            // NOTE: Select canvas rectangle with current anomaly
            const selectedCanvasImageData = draftCanvas.ctx?.getImageData(sx, sy, sw, sh);

            if (selectedCanvasImageData) {
              const shiftedPolygonCoordinates = shiftCoordinatesToTopLeftPosition(
                sx,
                sy,
                polygonCoordinates,
              );

              const rgba: RGBA = [...rgbColor, opacityToAlpha(opacity)];
              // 1. Fill area with color
              finalCanvas.fillShape(
                selectedCanvasImageData,
                shiftedPolygonCoordinates,
                rgba,
                sx,
                sy,
              );
            }

            // 2. Add styles for the outline
            if (finalCanvas.ctx) {
              finalCanvas.ctx.strokeStyle = color;
              finalCanvas.ctx.lineWidth = lineWidth;
            }

            // 3. Render outline for a filled area
            finalCanvas.renderOutline(polygonCoordinates);
          });
        });
      }
      resolve(finalCanvas.canvasEl.toDataURL());
    };
  });
}
