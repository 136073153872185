import { FC, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import solarPanelsIcon from '@assets/icons/solar-panels.svg';
import zoningIcon from '@assets/icons/zoning.svg';
import { EAspectRatio, EViewerType } from '@core/enums';
import { useDispatchTyped } from '@core/hooks';
import {
  editImageViewer,
  useCurrentAnomalyIdSelector,
  useCurrentInspectionSelector,
  useImageSettingSelector,
} from '@core/store/slices';
import {
  fetchSampleAnomalyMask,
  fetchSampleSegmentationMask,
  setSelectedSample,
  useSamplesSelector,
} from '@core/store/slices/samples';
import { createSampleName } from '@core/utils/formatting/stringFormat';
import { Switcher } from '@components/Switcher';
import { ToggleButton } from '@components/ToggleButton';
import { ImageFrame } from './components/ImageFrame';
import { Slider } from './components/Slider';
import styles from './styles.scss';
import { transformDetections } from './utils/transformDetections';

interface IProps {
  type: EViewerType;
  showControls?: boolean;
}

const initialLoadingState = {
  rgb: true,
  segmentation: true,
  thermal: true,
  anomaly: true,
};

export const ImageViewer: FC<IProps> = ({ type, showControls = true }) => {
  const { anomalySamples, selectedSample } = useSamplesSelector();
  const { thermal, segmentationMask, anomalyMask } = useImageSettingSelector();
  const anomalyId = useCurrentAnomalyIdSelector();
  const currentInspection = useCurrentInspectionSelector();
  const dispatch = useDispatchTyped();
  const [loadingStates, setLoadingStates] = useState(initialLoadingState);
  const handleImageLoad = (imageType: keyof typeof initialLoadingState) => {
    setLoadingStates((prevState) => ({ ...prevState, [imageType]: false }));
  };
  const containerRef = useRef<HTMLDivElement | null>(null);

  const rgbAspectRatio = useMemo(() => {
    const rgbAspectRatio = currentInspection?.flights?.[0]?.rgb_aspect_ratio;

    if (rgbAspectRatio) {
      const isAvailableAspectRatio = Object.values(EAspectRatio).includes(rgbAspectRatio);

      if (isAvailableAspectRatio) {
        return rgbAspectRatio;
      }
    }
    return EAspectRatio.ParrotAnafiDrone;
  }, [currentInspection?.flights]);

  useEffect(() => {
    setLoadingStates(initialLoadingState);
  }, [anomalySamples]);

  const transformedDetections = useMemo(
    () =>
      containerRef.current
        ? transformDetections({
            sample: selectedSample,
            type,
            containerHeight: containerRef.current.clientHeight,
            containerWidth: containerRef.current.clientWidth,
            aspectRatio: rgbAspectRatio,
          })
        : [],
    [selectedSample, type, rgbAspectRatio, containerRef.current],
  );
  const selectedSampleIndex = anomalySamples.findIndex(
    (sample) => selectedSample?.id === sample.id,
  );
  const sampleName = anomalyId ? createSampleName(anomalyId, selectedSampleIndex) : '';

  const handleSegmentationToggle = (segmentationMask: boolean) => {
    dispatch(editImageViewer({ segmentationMask }));
    if (segmentationMask) dispatch(fetchSampleSegmentationMask());
  };

  const handleAnomalyToggle = (anomalyMask: boolean) => {
    dispatch(editImageViewer({ anomalyMask }));
    if (anomalyMask) dispatch(fetchSampleAnomalyMask());
  };

  const handleThermalChange = (thermal: number) => {
    dispatch(editImageViewer({ thermal }));
  };

  const handleSwitch = (index: number) => dispatch(setSelectedSample(anomalySamples[index]));

  return selectedSample ? (
    <div ref={containerRef} className={styles.container}>
      <div className={cn(styles.images, { [styles.withControls]: showControls })}>
        <img
          src={selectedSample?.rgb_image}
          className={cn(styles.image, { [styles.imageLoaded]: !loadingStates.rgb })}
          alt='rgb image'
          onLoad={() => handleImageLoad('rgb')}
        />

        {segmentationMask && selectedSample?.segmentation_mask && (
          <img
            src={selectedSample.segmentation_mask}
            className={cn(styles.segmentationMask, {
              [styles.segmentationMaskLoaded]: !loadingStates.segmentation,
            })}
            alt='segmentation mask'
            onLoad={() => handleImageLoad('segmentation')}
          />
        )}

        <img
          src={selectedSample?.thermal_image}
          className={cn(styles.thermalImage, { [styles.imageLoaded]: !loadingStates.thermal })}
          style={loadingStates.thermal ? {} : { opacity: `${thermal}%` }}
          alt='thermal image'
          onLoad={() => handleImageLoad('thermal')}
        />

        {anomalyMask && selectedSample?.anomaly_mask && (
          <img
            src={selectedSample?.anomaly_mask}
            className={cn(styles.image, { [styles.imageLoaded]: !loadingStates.anomaly })}
            alt='anomaly mask'
            onLoad={() => handleImageLoad('anomaly')}
          />
        )}

        {(!loadingStates.rgb || !loadingStates.thermal) &&
          transformedDetections.map((detection) => (
            <ImageFrame key={detection.id} detection={detection} type={type} />
          ))}
      </div>
      {showControls && (
        <div className={styles.controls}>
          <div className={styles.controlsLeft}>
            <Slider
              initialValue={thermal}
              min={0}
              max={100}
              step={5}
              onChange={handleThermalChange}
            />
            <ToggleButton active={anomalyMask} icon={zoningIcon} onChange={handleAnomalyToggle} />
            <ToggleButton
              active={segmentationMask}
              icon={solarPanelsIcon}
              onChange={handleSegmentationToggle}
            />
          </div>
          <div className={styles.controlsCenter}>
            <Switcher
              title={sampleName}
              options={anomalySamples}
              selectedId={Number(selectedSample?.id)}
              onArrowClick={handleSwitch}
              onDotClick={handleSwitch}
            />
          </div>
        </div>
      )}
    </div>
  ) : null;
};
