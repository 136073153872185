import { Header } from './components/Header';
import { Main } from './components/Main';
import styles from './styles.scss';

export const Layout = () => {
  return (
    <div className={styles.layout}>
      <Header />
      <Main />
    </div>
  );
};
