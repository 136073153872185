import { axiosInstance } from '@core/api/axios';
import { ECamera } from '@core/enums';
import { ISample } from '@core/interfaces';
import { addQueryParamsToUrl } from '@core/utils';

export const getSamplesByReportId = async (
  reportId: number | string,
  camera?: ECamera | string,
) => {
  const endpoint = addQueryParamsToUrl(`/reports/${reportId}/samples`, { camera });
  return axiosInstance.get<ISample[]>(endpoint).then((res) => res.data);
};
