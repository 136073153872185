import { getColor } from '@core/utils';
import { ESampleLayers } from '../../enums/layers';
import { ESampleSources } from '../../enums/sources';

type TSampleLayerOptions = {
  isFlightPathSamples?: boolean;
};

export const layersStyles: {
  [key in ESampleLayers]: <T extends Record<string, any>>(deps?: T) => mapboxgl.AnyLayer;
} = {
  [ESampleLayers.Samples]: (deps?: TSampleLayerOptions) => ({
    id: ESampleLayers.Samples,
    type: 'circle',
    source: ESampleSources.Samples,
    paint: {
      'circle-color': ['get', 'color'],
      'circle-radius': [
        'interpolate',
        ['linear'],
        ['zoom'],
        19,
        4, // Radius = 4 if zoom = 19
        20,
        6, // Radius = 6 if zoom = 20
        21,
        10, // Radius = 10 if zoom >= 21
      ],
      'circle-stroke-color': ['get', 'border'],
      'circle-stroke-width': deps?.isFlightPathSamples ? 1 : 4,
    },
  }),
  [ESampleLayers.Frustum]: () => ({
    id: ESampleLayers.Frustum,
    type: 'fill',
    source: ESampleSources.Frustum,
    paint: {
      'fill-color': getColor('--outflier-blue'),
      'fill-opacity': 0.5,
    },
    filter: ['==', '$type', 'Polygon'],
  }),
  [ESampleLayers.FrustumLines]: () => ({
    id: ESampleLayers.FrustumLines,
    type: 'line',
    source: ESampleSources.Frustum,
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': getColor('--outflier-white'),
      'line-opacity': 0.6,
      'line-width': 3,
    },
    filter: ['==', '$type', 'LineString'],
  }),
};
