import { IReport } from '@core/interfaces/report';
import { EZonesReportsRelations } from '../../enums/ortho';

export function getZonesReportsRelationStatus(zonesIds: string[], reports: IReport[][]) {
  const validationsMapping = {
    areThereNoZones(zonesIds: string[]) {
      return zonesIds.length === 0;
    },

    isOneZone(zonesIds: string[]) {
      return zonesIds.length === 1;
    },

    areThereSeveralZones(zonesIds: string[]) {
      return zonesIds.length > 1;
    },

    isOneZoneWithOneReport(zonesIds: string[], reports: IReport[][]) {
      return this.isOneZone(zonesIds) && reports[0]?.length === 1;
    },

    areSeveralZonesWithOneReportPerEach(zonesIds: string[], reports: IReport[][]) {
      return (
        this.areThereSeveralZones(zonesIds) && reports.every((reports) => reports.length === 1)
      );
    },

    areSeveralZonesWithSeveralReportsPerEach(zonesIds: string[], reports: IReport[][]) {
      return this.areThereSeveralZones(zonesIds) && reports.every((reports) => reports.length > 1);
    },
  };

  let status: EZonesReportsRelations | undefined;

  if (validationsMapping.areThereNoZones(zonesIds)) {
    return;
  } else if (validationsMapping.isOneZone(zonesIds)) {
    if (validationsMapping.isOneZoneWithOneReport(zonesIds, reports)) {
      status = EZonesReportsRelations.OneZoneWithOneReport;
    } else {
      status = EZonesReportsRelations.OneZoneWithSeveralReports;
    }
  } else {
    if (validationsMapping.areSeveralZonesWithOneReportPerEach(zonesIds, reports)) {
      status = EZonesReportsRelations.SeveralZonesWithOneReportPerEach;
    } else if (validationsMapping.areSeveralZonesWithSeveralReportsPerEach(zonesIds, reports)) {
      status = EZonesReportsRelations.SeveralZonesWithSeveralReportsPerEach;
    } else {
      status = EZonesReportsRelations.SeveralZonesWithMixedReportsPerEach;
    }
  }

  return status;
}
