import { IAnomaly } from '@core/interfaces/anomaly';
import { getAnomalyColor } from '@core/utils';
import { IAnomalySolarPanel } from '../../interfaces/solarPanel';

export const transformAnomalies = (
  anomalies: IAnomaly[],
  currentAnomaly?: IAnomaly,
): IAnomalySolarPanel[] => {
  return anomalies.map((anomaly) => ({
    id: anomaly.id,
    solarPanelId: anomaly.features[0].properties?.id as number,
    color: getAnomalyColor(anomaly, currentAnomaly),
  }));
};
