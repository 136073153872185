import * as turf from '@turf/turf';
import { Feature, FeatureCollection, Geometry, GeoJsonProperties } from 'geojson';
import { IProgram } from '@core/interfaces';
import { isNumber } from '@core/utils';
import { EMapImages } from '../../../enums/layers';

export const getIconPointsFeatures = (noFlyZonesFeatureCollection: FeatureCollection) =>
  noFlyZonesFeatureCollection.features.map((feature: any) => {
    const centroid = turf.centroid(feature);
    centroid.properties = {
      ...centroid.properties,
      icon: EMapImages.NoFlyZoneIconId,
    };
    return centroid;
  });

export const getOverviewPlanLineStringFeature = (
  generatedProgram?: IProgram,
): Feature<Geometry, GeoJsonProperties> => ({
  type: 'Feature',
  properties: {},
  geometry: {
    type: 'LineString',
    coordinates:
      generatedProgram && generatedProgram.overview_plan?.plan
        ? generatedProgram.overview_plan.plan.map((item) => {
            const coordinate = [
              item['metadata.location.longitude'],
              item['metadata.location.latitude'],
            ];
            return coordinate;
          })
        : [],
  },
});

export const getFirstPlanLineStringFeature = (
  currentPlanIndex: number | null | undefined,
  generatedProgram?: IProgram,
): Feature<Geometry, GeoJsonProperties> => ({
  type: 'Feature',
  properties: {},
  geometry: {
    type: 'LineString',
    coordinates:
      isNumber(currentPlanIndex) && generatedProgram?.plans?.[currentPlanIndex]?.plan
        ? generatedProgram.plans[currentPlanIndex].plan.map((item) => {
            const coordinate = [
              item['metadata.location.longitude'],
              item['metadata.location.latitude'],
            ];
            return coordinate;
          })
        : [],
  },
});
