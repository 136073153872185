import { useCallback, useState } from 'react';
import { IInspection } from '@core/interfaces';
import { RadioGroupOption } from '../components/RadioGroupOption';

interface IProps {
  monthInspections: IInspection[];
  isOptionSingle: boolean;
}

export const MonthInspectionsOptions = ({ monthInspections, isOptionSingle }: IProps) => {
  const [lastViewChanged, setLastViewChanged] = useState(new Set());

  const onViewChange = useCallback(
    (index: number, inView: boolean) => {
      if (lastViewChanged.has(index)) {
        if (!inView) {
          lastViewChanged.delete(index);
        } else {
          lastViewChanged.add(index);
        }
      } else {
        if (inView) {
          lastViewChanged.add(index);
        }
      }
      setLastViewChanged(new Set([...lastViewChanged]));
    },
    [lastViewChanged, setLastViewChanged],
  );

  const arr = Array.from(lastViewChanged);
  arr.sort();

  return (
    <>
      {monthInspections.map((inspection, index) => (
        <RadioGroupOption
          key={inspection.id}
          inspection={inspection}
          viewChange={onViewChange}
          index={index}
          showLabel={(arr && arr[0] === index) || false}
          isOptionSingle={isOptionSingle}
        />
      ))}
    </>
  );
};
