import _ from 'lodash';
import { RootState } from '@core/store';
import { IPersistSaveStrategy, IRehydrateSaveStrategy } from '../interfaces/strategies';

export function fullPersistSaveStateStrategy({ appState }: IPersistSaveStrategy<RootState>) {
  return appState;
}

export function fullRehydrateSaveStateStrategy({
  appState,
  persistedState,
}: IRehydrateSaveStrategy<RootState>) {
  const newState = _.cloneDeep(appState);

  for (const key of Object.keys(newState)) {
    if (persistedState[key]) {
      const persistedItemCopy = _.cloneDeep(persistedState[key]);
      newState[key] = persistedItemCopy;
    }
  }
  return newState;
}
