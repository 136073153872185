import { memo } from 'react';
import cn from 'classnames';
import styles from './styles.scss';

interface IProps {
  src: string;
  alt?: string;
  className?: string;
}

const PreviewImage: React.FC<IProps> = ({ src, alt, className }) => {
  return (
    <div className={cn(styles.root, className)}>
      <img className={styles.previewImage} src={src} alt={alt} />
    </div>
  );
};

export default memo(PreviewImage);
