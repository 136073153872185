import { IProgram } from '@core/interfaces';
import { getAvgLatitude, getAvgLongitude, getCentroids } from './getCoordinates';

export function setLngLatToPrograms(programs: IProgram[]) {
  return programs.map((program) => {
    const displayPerimeter = program.display_perimeter;

    if (!displayPerimeter) return program;

    const features = [
      {
        id: program.program_id,
        type: 'Feature',
        geometry: { ...displayPerimeter },
        properties: {},
      },
    ];

    const centroids = getCentroids(features);
    const avgLongitude = getAvgLongitude(centroids);
    const avgLatitude = getAvgLatitude(centroids);

    return { ...program, long: avgLongitude, lat: avgLatitude };
  });
}
