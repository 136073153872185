import { FC, useEffect, useRef, useState, memo } from 'react';
import ReactPlayer from 'react-player';
import { OnProgressProps } from 'react-player/base';
import cn from 'classnames';
import { SKIP_VIDEO_SECONDS_TIME } from '@core/constants';
import { EViewer } from '@core/enums';
import { useDispatchTyped } from '@core/hooks';
import {
  setToCompareVideo,
  setVideo,
  useVideoSelector,
  useViewerSelector,
} from '@core/store/slices';
import { useCurrentReportSelector, useReportToCompareSelector } from '@core/store/slices/reports';
import { Switcher } from '@components/Switcher';
import { Progressbar } from './components/Progressbar';
import { VideoControls } from './components/VideoControls';
import styles from './styles.scss';

interface IProps {
  showControls?: boolean;
  toCompare?: boolean;
}

export const VideoViewer: FC<IProps> = memo(({ showControls = true, toCompare }) => {
  const dispatch = useDispatchTyped();
  const playerRef = useRef<ReactPlayer | null>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [durationTime, setDurationTime] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [visibleControls, setVisibleControls] = useState(false);

  const { video, toCompareVideo } = useVideoSelector();
  const { viewer } = useViewerSelector();
  const currentReport = useCurrentReportSelector();
  const reportToCompare = useReportToCompareSelector();
  const videos = currentReport?.inspection_videos ?? ([] as string[]);
  const toCompareVideos = reportToCompare?.inspection_videos ?? ([] as string[]);

  const handleToggle = (play?: boolean) => setPlaying((playing) => play ?? !playing);

  const handleDuration = (duration: number) => setDurationTime(duration);

  const handleChange = (time: number) => playerRef.current?.seekTo(time);

  const handleSwitch = (index: number) => {
    toCompare
      ? dispatch(setToCompareVideo(toCompareVideos[index]))
      : dispatch(setVideo(videos[index]));
    setCurrentTime(0);
    playerRef.current?.seekTo(0);
  };

  const handleProgress = (progress: OnProgressProps) => {
    if (!playing) return;

    setCurrentTime(progress.playedSeconds);

    const isVideoFinished = Math.floor(progress.playedSeconds) >= durationTime - 1;

    if (isVideoFinished) setPlaying(false);
  };

  const handleSkip = (direction: 'forward' | 'back') => {
    if (!playerRef.current) return;

    let newCurrentTime = currentTime;

    if (direction === 'forward') newCurrentTime += SKIP_VIDEO_SECONDS_TIME;
    if (direction === 'back') newCurrentTime -= SKIP_VIDEO_SECONDS_TIME;

    playerRef.current.seekTo(newCurrentTime);

    if (newCurrentTime >= durationTime) {
      setPlaying(false);
      setCurrentTime(0);
    }
  };

  useEffect(() => {
    if (toCompare) {
      !toCompareVideo && dispatch(setToCompareVideo(toCompareVideos[0]));
    } else {
      !video && dispatch(setVideo(videos[0]));
    }
  });

  useEffect(() => {
    if (viewer !== EViewer.Video) {
      setPlaying(false);
      setCurrentTime(0);
      playerRef.current?.seekTo(0);
    }
  }, [viewer]);

  const videoControlsConfig = { playing, onToggle: handleToggle, onSkip: handleSkip };

  return (
    <div className={styles.container}>
      <div
        className={cn(styles.video, { [styles.withControls]: showControls })}
        onMouseEnter={setVisibleControls.bind(this, true)}
        onMouseLeave={setVisibleControls.bind(this, false)}
      >
        <ReactPlayer
          ref={playerRef}
          width='100%'
          height='100%'
          url={toCompare ? toCompareVideo : video}
          playing={playing}
          stopOnUnmount={true}
          style={{ backgroundColor: 'black' }}
          controls={!showControls}
          onProgress={handleProgress}
          onDuration={handleDuration}
          onClick={() => setPlaying((state) => !state)}
          onPlay={handleToggle.bind(this, true)}
          onPause={handleToggle.bind(this, false)}
        />
        {showControls && (
          <VideoControls
            visible={visibleControls}
            classNames={styles.videoControls}
            config={videoControlsConfig}
          />
        )}
      </div>
      {showControls && (
        <div className={styles.controls}>
          <Progressbar
            currentTime={currentTime}
            durationTime={durationTime}
            onChange={handleChange}
          />
          <Switcher
            classNames={styles.switcher}
            options={videos}
            selectedId={videos.findIndex((url: string) => video === url)}
            onArrowClick={handleSwitch}
            onDotClick={handleSwitch}
          />
        </div>
      )}
    </div>
  );
});
