export enum EMapMoveType {
  Site = 'site',
  CreateSite = 'createSite',
  Zone = 'zone',
  Anomaly = 'anomaly',
}

export enum EZoomInitiator {
  Auto = 'AUTO', // dynamic zoom in zoom out ("flyTo" method)
  Control = 'CONTROL', // click on controls (zoom in, zoom out, center)
  Manual = 'MANUAL', // zoom in, zoom out by user
}
