import { fullPersistSaveStateStrategy, fullRehydrateSaveStateStrategy } from './fullSaveState';
import {
  partialPersistSaveStateStrategy,
  partialRehydrateSaveStateStrategy,
} from './partialSaveState';

export enum EMergeStrategy {
  PersistFull = 'persistFull',
  PersistPartial = 'persistPartial',
  RehydrateFull = 'rehydrateFull',
  RehydratePartial = 'rehydratePartial',
}

export const mergeStrategies: { [key in EMergeStrategy]: CallableFunction } = {
  [EMergeStrategy.PersistFull]: fullPersistSaveStateStrategy,
  [EMergeStrategy.PersistPartial]: partialPersistSaveStateStrategy,
  [EMergeStrategy.RehydrateFull]: fullRehydrateSaveStateStrategy,
  [EMergeStrategy.RehydratePartial]: partialRehydrateSaveStateStrategy,
};
