import { IInspection, IProgram } from '@core/interfaces';

export const getProgramsWithIsReportProcessing = (
  programs: IProgram[] | null,
  sortedInspections: IInspection[] | null,
): IProgram[] => {
  if (!programs) return [];
  if (!sortedInspections) return programs;

  return programs.map((program) => {
    const programInspections = sortedInspections.filter(
      (inspection) => program.program_id === inspection.program_id,
    );

    const lastProgramInspection = programInspections.at(-1);

    const isLastInspectionReportProcessing =
      lastProgramInspection?.uploaded && !lastProgramInspection.report_generated;

    return {
      ...program,
      isReportProcessing: isLastInspectionReportProcessing,
    };
  });
};
