import deleteIcon from '@assets/icons/delete.svg';
import { getColor } from '@core/utils';

export const customizeTrash = () => {
  const trashEl = document.querySelector('.mapbox-gl-draw_trash') as HTMLElement;
  const containerEl = trashEl.parentElement as HTMLElement;

  const styles = {
    trash: {
      width: '2.8125rem',
      height: '2.8125rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: getColor('--hard-black'),
      borderRadius: '0.375rem',
      cursor: 'pointer',
      backgroundImage: `url(${deleteIcon})`,
      backgroundSize: '2rem',
    },
    container: {
      background: 'unset',
    },
  };

  const initStyles = (el: HTMLElement | null, key: string) => {
    if (el && styles[key]) {
      for (const [prop, value] of Object.entries(styles[key])) {
        el.style[prop] = value;
      }
    }
  };

  initStyles(trashEl, 'trash');
  initStyles(containerEl, 'container');
};
