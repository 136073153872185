import { FeatureCollection } from 'geojson';
import { getColor } from '@core/utils';
import { ESolarPanelsLayers } from '../../enums/layers';
import { ESolarPanelsSources } from '../../enums/sources';

type LayerDependencies = {
  showSolarPanels: boolean;
};

export const layersStyles: {
  [key in ESolarPanelsLayers]: (deps: LayerDependencies) => mapboxgl.AnyLayer;
} = {
  [ESolarPanelsLayers.SolarPanels]: ({ showSolarPanels }: LayerDependencies) => ({
    id: ESolarPanelsLayers.SolarPanels,
    type: 'fill',
    source: ESolarPanelsSources.SolarPanels,
    paint: {
      'fill-color': ['get', 'color'],
      'fill-opacity': showSolarPanels ? 0.7 : 0,
      'fill-outline-color': getColor('--outflier-color5'),
    },
    filter: ['==', '$type', 'Polygon'],
  }),
};

export const setFeatureCollectionData = (solarPanels: any[]): FeatureCollection => ({
  type: 'FeatureCollection',
  features: [...solarPanels],
});
