import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';

const DEBOUNCE_DELAY = 700;

export const useRenderInputError = (value: string, error: string | null) => {
  const [shouldRenderError, setShouldRenderError] = useState(false);

  const enableRender = useCallback(() => setShouldRenderError(true), []);
  const disableRender = useCallback(() => setShouldRenderError(false), []);

  const debouncedEnableRender = useMemo(
    () => debounce(enableRender, DEBOUNCE_DELAY),
    [enableRender],
  );

  useEffect(() => {
    if (value && error) debouncedEnableRender();
    return () => disableRender();
  }, [value, error]);

  return shouldRenderError;
};
