import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EConnectionStatus } from '@core/enums/connection';
import { useSelectorTyped } from '@core/hooks';

interface IInitialState {
  status: EConnectionStatus | null;
  statusPrevious: EConnectionStatus | null;
  error: string | null;
  message: string | null;
  isVisible: boolean;
}

const initialState: IInitialState = {
  status: null,
  statusPrevious: null,
  error: null,
  message: null,
  isVisible: false,
};

const networkConnectionSlice = createSlice({
  name: 'networkConnection',
  initialState,
  reducers: {
    setConnectionStatus: (state, action: PayloadAction<EConnectionStatus>) => {
      state.statusPrevious = state.status;
      state.status = action.payload;
    },
    setConnectionMessage: (state, action: PayloadAction<string | null>) => {
      state.message = action.payload;
    },
    setConnectionError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    setIsVisibleNotification: (state, action: PayloadAction<boolean>) => {
      state.isVisible = action.payload;
    },
    updateConnectionData: (state, action: PayloadAction<Partial<IInitialState>>) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state[key] = value;
      });
    },
  },
});

const networkConnectionReducer = networkConnectionSlice.reducer;
const {
  setConnectionMessage,
  setConnectionStatus,
  setIsVisibleNotification,
  setConnectionError,
  updateConnectionData,
} = networkConnectionSlice.actions;

const useNetworkConnectionSelector = () => useSelectorTyped((state) => state.networkConnection);

export {
  setConnectionMessage,
  setConnectionError,
  setConnectionStatus,
  setIsVisibleNotification,
  updateConnectionData,
  networkConnectionReducer,
  useNetworkConnectionSelector,
  initialState as networkConnectionInitialState,
};
