export enum ETimeUnit {
  Second = 'second',
  Minute = 'minute',
  Hour = 'hour',
  Day = 'day',
  Week = 'week',
}

export function getMilliseconds(time: number, unit: ETimeUnit) {
  switch (unit) {
    case ETimeUnit.Second:
      return time * 1000;
    case ETimeUnit.Minute:
      return time * 1000 * 60;
    case ETimeUnit.Hour:
      return time * 1000 * 60 * 60;
    case ETimeUnit.Day:
      return time * 1000 * 60 * 60 * 24;
    case ETimeUnit.Week:
      return time * 1000 * 60 * 60 * 24 * 7;
  }
}
