import { getFileName } from './getFileName';
import { getFolderName } from './getFolderName';
import { IFileData } from '../components/UploadInspectionModal/UploadInspectionModal';

export const getFilesToUploadData = (uploadedFiles: FileList, existOnServerFiles: string[]) => {
  const filesData: IFileData[] = [];
  Array.from(uploadedFiles).forEach((file: File) => {
    if (!existOnServerFiles?.includes(getFileName(file.webkitRelativePath))) {
      const fileData = new File([file], file.name, { type: file.type });
      const folder = getFolderName(file.webkitRelativePath);
      const formData = new FormData();
      formData.append('file', fileData);
      filesData.push({ formData, folder });
    }
  });

  return filesData;
};
