import { FC, useEffect, useRef } from 'react';
import { FAKE_PERCENTAGE_STEP, FAKE_STEPS_LIMIT } from '@core/constants';
import { fromFakeToActualPercentage } from '@core/utils/converting/fromFakeToActualPercentage';
import { ProgressBarLayout } from '../ProgressBarLayout/ProgressBarLayout';

interface IFakeData {
  currentStep: number;
  percentage: number;
}

interface IProps {
  estimatedTimeMs: number;
  percentageStep?: number;
  currentStep: number;
  className?: string;
  title?: string;
  isTextHidden: boolean;
  percentage: number;
  onUpdate: (data: IFakeData) => void;
}

const DEFAULT_ESTIMATED_TIME_MS = 10000;

export const FakeProgressBar: FC<IProps> = ({
  title,
  isTextHidden,
  className,
  estimatedTimeMs = DEFAULT_ESTIMATED_TIME_MS,
  percentageStep = FAKE_PERCENTAGE_STEP,
  percentage,
  currentStep: initialStep,
  onUpdate,
}) => {
  const currentStepRef = useRef(initialStep);
  currentStepRef.current = initialStep;

  useEffect(() => {
    const intervalStep = estimatedTimeMs / percentageStep;

    const fakeProgressInterval = setInterval(() => {
      const isAllowedCurrentStep = currentStepRef.current + 1 < FAKE_STEPS_LIMIT;

      if (isAllowedCurrentStep) {
        currentStepRef.current += 1;
        const percentage = fromFakeToActualPercentage(currentStepRef.current * percentageStep);
        onUpdate({
          currentStep: currentStepRef.current,
          percentage: percentage,
        });
      } else {
        clearInterval(fakeProgressInterval);
      }
    }, intervalStep);

    return () => clearInterval(fakeProgressInterval);
  }, [estimatedTimeMs, percentageStep]);

  const progressBarPercentage = fromFakeToActualPercentage(percentage);

  return (
    <ProgressBarLayout
      title={title}
      className={className}
      percentage={progressBarPercentage}
      isTextHidden={isTextHidden}
    />
  );
};
