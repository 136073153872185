import * as turf from '@turf/turf';
import { ISite } from '@core/interfaces';

export const getMapboxBoundsBboxCoords = (bounds: mapboxgl.LngLatBounds): turf.Position[] => {
  const northWestCoords = bounds.getNorthWest();
  const northEastCoords = bounds.getNorthEast();
  const southEastCoords = bounds.getSouthEast();
  const southWestCoords = bounds.getSouthWest();

  return [
    [northWestCoords.lng, northWestCoords.lat],
    [northEastCoords.lng, northEastCoords.lat],
    [southEastCoords.lng, southEastCoords.lat],
    [southWestCoords.lng, southWestCoords.lat],
  ];
};

export const getSiteBboxCoords = (site: ISite) => {
  if (
    !site.gps_boundaries ||
    !site.gps_boundaries.top_left_lat ||
    !site.gps_boundaries.top_left_lng ||
    !site.gps_boundaries.bottom_right_lat ||
    !site.gps_boundaries.bottom_right_lng
  ) {
    throw new Error(`Invalid GPS boundaries for site ${site?.loc_id}`);
  }

  const { top_left_lat, top_left_lng, bottom_right_lat, bottom_right_lng } = site.gps_boundaries;

  const northWestCoords = [top_left_lng, top_left_lat];
  const northEastCoords = [bottom_right_lng, top_left_lat];
  const southEastCoords = [bottom_right_lng, bottom_right_lat];
  const southWestCoords = [top_left_lng, bottom_right_lat];

  return [northWestCoords, northEastCoords, southEastCoords, southWestCoords];
};

export const getBboxPolygon = (coordinates: turf.Position[]) => {
  const line = turf.lineString(coordinates);
  const bbox = turf.bbox(line);
  const polygon = turf.bboxPolygon(bbox);

  return polygon;
};

type BboxPolygon =
  | turf.Feature<turf.Polygon | turf.MultiPolygon>
  | turf.Polygon
  | turf.MultiPolygon;

export const getIntersectionBetweenTwoPolygons = (polygon1: BboxPolygon, polygon2: BboxPolygon) =>
  turf.intersect(polygon1, polygon2);

// To check for at least two intersecting polygons
export const checkIntersectingPolygons = (polygons: BboxPolygon[]): boolean => {
  const numPolygons = polygons.length;

  for (let i = 0; i < numPolygons - 1; i++) {
    for (let j = i + 1; j < numPolygons; j++) {
      const polygon1 = polygons[i];
      const polygon2 = polygons[j];

      const intersection = getIntersectionBetweenTwoPolygons(polygon1, polygon2);

      if (intersection) {
        return true;
      }
    }
  }

  return false;
};
