import React from 'react';
import { api } from '@core/api';
import { isNumber } from '@core/utils';
import CommentSectionBody from '@components/CommentSection/CommentSectionBody';

interface IProps {
  anomalyId?: number;
}

const CommentSectionAnomaly: React.FC<IProps> = ({ anomalyId }) => {
  const handleGetComments = async () => {
    return isNumber(anomalyId) ? api.anomalies.getComments(anomalyId) : [];
  };

  const handleSendComment = async (text?: string) => {
    if (!text) return;
    if (!anomalyId) return;
    return api.anomalies.createComment(anomalyId, { comment: text });
  };

  const handleUpdateComment = async (commentId: number, text?: string) => {
    if (!text) return;
    if (!commentId) return;
    return api.anomalies.updateComment(commentId, { comment: text });
  };

  const handleDeleteComment = async (commentId: number) => {
    return api.anomalies.deleteComment(commentId);
  };

  return (
    <CommentSectionBody
      targetId={anomalyId}
      onGetComments={handleGetComments}
      onSendComment={handleSendComment}
      onUpdateComment={handleUpdateComment}
      onDeleteComment={handleDeleteComment}
    />
  );
};

export default CommentSectionAnomaly;
