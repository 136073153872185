import { FeatureCollection, Feature } from 'geojson';

export const getFlightPathFeatureCollection = (coordinates: number[][]): FeatureCollection => ({
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {},
      geometry: {
        coordinates,
        type: 'LineString',
      },
    },
    {
      type: 'Feature',
      properties: { symbol: 'point' },
      geometry: {
        type: 'Point',
        coordinates: coordinates[0],
      },
    },
    {
      type: 'Feature',
      properties: { symbol: 'arrow' },
      geometry: {
        type: 'Point',
        coordinates: coordinates[coordinates.length - 1],
      },
    },
  ],
});

export const getFlightPathBatteriesFeatureCollection = (
  flightsFirstSamples: number[][],
): FeatureCollection => ({
  type: 'FeatureCollection',
  features: flightsFirstSamples
    .map((coordinates, index) => [
      {
        type: 'Feature',
        properties: { symbol: 'battery' },
        geometry: {
          type: 'Point',
          coordinates,
        },
      },
      {
        type: 'Feature',
        properties: {
          symbol: 'batteryData',
          text: `${index + 2}`,
        },
        geometry: {
          type: 'Point',
          coordinates,
        },
      },
    ])
    .flat() as Feature[],
});
