export enum ECookiesField {
  StoragePersistPermission = 'storagePersistPermission',
}

export enum ELocalStorageField {
  PersistRoot = 'persist:root',
  CreateSite = 'createSite',
}

export enum ECreateSiteLocalStorageField {
  CurrentZonesPerimeterCalculation = 'currentZonesPerimeterCalculation',
  CurrentZone = 'currentZone',
  CurrentStep = 'currentStep',
}
