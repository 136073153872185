import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EViewer } from '@core/enums';
import { useSelectorTyped } from '@core/hooks';

interface IImageSettings {
  thermal?: number;
  segmentationMask?: boolean;
  anomalyMask?: boolean;
}

interface IInitialState {
  viewer: EViewer;
  sidebarViewer: EViewer;
  imageSettings: IImageSettings;
}

const initialState: IInitialState = {
  viewer: EViewer.Map,
  sidebarViewer: EViewer.Video,
  imageSettings: {
    thermal: 50,
    segmentationMask: false,
    anomalyMask: false,
  },
};

const viewerSlice = createSlice({
  name: 'viewer',
  initialState,
  reducers: {
    toggleViewers: (state) => {
      const viewer = state.viewer;
      const sidebarViewer = state.sidebarViewer;
      state.viewer = sidebarViewer;
      state.sidebarViewer = viewer;
    },
    setViewer: (state, actions: PayloadAction<EViewer>) => {
      state.viewer = actions.payload;
    },
    setSidebarViewer: (state, actions: PayloadAction<EViewer>) => {
      state.sidebarViewer = actions.payload;
    },
    setDefaultImageViewerSettings: (state) => {
      state.imageSettings = initialState.imageSettings;
    },
    editImageViewer: (state, actions: PayloadAction<IImageSettings>) => {
      const { thermal, segmentationMask, anomalyMask } = state.imageSettings;

      state.imageSettings.thermal = actions.payload.thermal ?? thermal;
      state.imageSettings.segmentationMask = actions.payload.segmentationMask ?? segmentationMask;
      state.imageSettings.anomalyMask = actions.payload.anomalyMask ?? anomalyMask;
    },
    resetViewer: () => initialState,
  },
});

const viewerReducer = viewerSlice.reducer;

const {
  setViewer,
  setSidebarViewer,
  setDefaultImageViewerSettings,
  editImageViewer,
  toggleViewers,
  resetViewer,
} = viewerSlice.actions;

const useViewerSelector = () => useSelectorTyped((state) => state.viewer);
const useImageSettingSelector = () => useSelectorTyped((state) => state.viewer.imageSettings);

export {
  setViewer,
  setSidebarViewer,
  setDefaultImageViewerSettings,
  editImageViewer,
  resetViewer,
  toggleViewers,
  viewerReducer,
  useViewerSelector,
  useImageSettingSelector,
  initialState as viewerInitialState,
};
