export const geodetic2enu = (
  lat: number,
  lon: number,
  alt: number,
  refLat: number,
  refLon: number,
  refAlt: number,
) => {
  // Convert degrees to radians
  const deg2rad = Math.PI / 180;

  // Earth's radius (approximation)
  const R = 6371000; // in meters

  // Convert reference coordinates to radians
  const refLatRad = refLat * deg2rad;
  const refLonRad = refLon * deg2rad;

  // Convert target coordinates to radians
  const latRad = lat * deg2rad;
  const lonRad = lon * deg2rad;

  // Calculate differences in coordinates
  const dLat = latRad - refLatRad;
  const dLon = lonRad - refLonRad;
  const dAlt = alt - refAlt;

  // Calculate ENU coordinates
  const E = dLon * R * Math.cos(refLatRad);
  const N = dLat * R;
  const U = dAlt;

  return [E, N, U];
};
