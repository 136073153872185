import { ESidebar } from '@core/enums';
import { ISite } from '@core/interfaces';
import { MapClickEvent } from '@core/interfaces/map';
import { getSitesFeatureCollection } from './getFeatureCollections';
import { layersStyles, sourcesStyles } from './mapboxConfig';
import { ELocationLayers } from '../../enums/layers';
import { ELocationSources } from '../../enums/sources';
import { removeLayersAndSources } from '../removeLayersAndSources';

interface IProps {
  map: mapboxgl.Map | null;
  sites: ISite[];
  sidebar: ESidebar;
  onClick: (event: MapClickEvent) => void;
}

export const addPoints = ({ map, sites, sidebar, onClick }: IProps) => {
  if (!map) return;

  switch (sidebar) {
    case ESidebar.Sites: {
      const isNotUpdatedSites = sites.some((site) => !site.status && !site.location_status);
      if (isNotUpdatedSites) return;

      const locationsFeatureCollection = getSitesFeatureCollection(sites);

      if (map.getSource(ELocationSources.LocationsPoints)) {
        (map.getSource(ELocationSources.LocationsPoints) as mapboxgl.GeoJSONSource).setData(
          locationsFeatureCollection,
        );
      } else {
        map.addSource(
          ELocationSources.LocationsPoints,
          sourcesStyles[ELocationSources.LocationsPoints]({
            locationsFeatureCollection,
          }),
        );
        map.addLayer(layersStyles[ELocationLayers.Clusters]());
        map.addLayer(layersStyles[ELocationLayers.ClusterCount]());
        map.addLayer(layersStyles[ELocationLayers.UnclusteredPoint]());
      }
      map.on('click', ELocationLayers.UnclusteredPoint, onClick);
      break;
    }
    default: {
      removeLayersAndSources(map, {
        [ELocationSources.LocationsPoints]: [
          ELocationLayers.Clusters,
          ELocationLayers.ClusterCount,
          ELocationLayers.UnclusteredPoint,
        ],
      });

      onClick && map.off('click', ELocationLayers.UnclusteredPoint, onClick);
      break;
    }
  }
};
