import { EZonesLayers } from '../../enums/layers';

interface IOrthoTilesParams {
  orthoTileUrl: string;
  sourceId: string;
  layerId: string;
}

export const setOrthoTiles = (
  map: mapboxgl.Map,
  { orthoTileUrl, sourceId, layerId }: IOrthoTilesParams,
) => {
  map.addSource(sourceId, {
    type: 'raster',
    tiles: [orthoTileUrl],
    tileSize: 256,
  });

  map.addLayer(
    {
      id: layerId,
      type: 'raster',
      source: sourceId,
      minzoom: 0,
      maxzoom: 22,
    },
    EZonesLayers.Polygon,
  );
};
