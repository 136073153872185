import React, { memo } from 'react';
import { Listbox } from '@headlessui/react';
import cn from 'classnames';
import selectArrowIcon from '@assets/icons/select-arrow.svg';
import { ISelectOption } from '@core/interfaces';
import styles from './styles.scss';

interface IProps {
  options?: ISelectOption[];
  selected: ISelectOption | null;
  classNames?: {
    root?: string;
    selectedOption?: string;
    option?: string;
  };
  onChange: (option: ISelectOption | null) => void;
  isDisabled?: boolean;
}

export const Select: React.FC<IProps> = memo(
  ({ options = [], selected, classNames, onChange, isDisabled }) => {
    return (
      <Listbox
        as='div'
        className={cn(styles.select, classNames?.root)}
        disabled={isDisabled || !options.length}
        value={selected}
        onChange={onChange}
      >
        <Listbox.Button className={cn(styles.selectedItem)}>
          <div
            dangerouslySetInnerHTML={{ __html: selected?.name ?? 'Select Item' }}
            className={cn(styles.selectedItemName, classNames?.selectedOption, {
              [styles.placeholder]: selected?.name,
            })}
          ></div>
          {!isDisabled && <img src={selectArrowIcon} alt='select arrow icon' />}
        </Listbox.Button>
        <Listbox.Options className={styles.options}>
          {options.map((option) => (
            <Listbox.Option
              className={cn(styles.option, classNames?.option)}
              key={option?.id}
              value={option}
              disabled={option?.disabled}
              dangerouslySetInnerHTML={{ __html: option?.name }}
            ></Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    );
  },
);
