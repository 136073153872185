import React, { useContext, useMemo, useRef } from 'react';
import cn from 'classnames';
import { ViewersContext } from '@core/contexts';
import { EViewer, EViewerType } from '@core/enums';
import { useControlsSelector, useViewerSelector } from '@core/store/slices';
import { getViewers } from '@modules/Sidebar/utils/getViewers';
import styles from './styles.scss';

export const Viewers: React.FC = () => {
  const { viewer, sidebarViewer } = useViewerSelector();
  const { mode } = useControlsSelector();
  const viewers = useContext(ViewersContext);
  const containerRef = useRef<HTMLDivElement>(null);

  const viewersProps: Record<string, any> = useMemo(
    () => ({
      [EViewer.Model]: { container: containerRef.current, type: EViewerType.Viewer },
      [EViewer.Image]: { type: EViewerType.Viewer },
    }),
    [containerRef.current],
  );

  // Set model only when main viewer has model mode or compareMap
  const filteredViewers = useMemo(
    () =>
      getViewers({
        viewers: {
          state: viewers,
          viewer,
          sidebarViewer,
        },
        options: {
          mode,
          type: EViewerType.Viewer,
        },
      }),
    [viewers, viewer, sidebarViewer, mode],
  );

  return (
    <div ref={containerRef} className={styles.container}>
      {filteredViewers.map(({ name, component }) => {
        const classes = cn(styles.item, { [styles.hide]: viewer !== name });

        return (
          <div key={name} className={classes}>
            {component(viewersProps[name])}
          </div>
        );
      })}
    </div>
  );
};
