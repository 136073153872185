import { nonTenantAxiosInstance } from '@core/api/axios';
import { ERoles } from '@core/enums';

export interface IInviteUsersRequest {
  email: string;
  roles: ERoles[];
}

export const inviteUsers = async (body: IInviteUsersRequest[]) => {
  return nonTenantAxiosInstance.post<string>('users/invite', body).then((res) => res.data);
};
