import React, { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import deleteIcon from '@assets/icons/delete.svg';
import nonActiveDeleteIcon from '@assets/icons/non-active-delete.svg';
import styles from './styles.scss';

interface IProps<T> {
  value: T;
  onChange: (value: T) => void;
  onDelete: (value: T) => void;
}

const AddInput: FC<IProps<any>> = ({ value, onChange, onDelete }) => {
  const { t } = useTranslation();
  const [rawValue, setRawValue] = useState<null | string>(null);

  const handleDelete = () => {
    onDelete(value);
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ ...value, name: e.target.value });
  };

  const handleChangeAltitude = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setRawValue(inputValue);

    const altitudeValue = inputValue !== '' ? Number(inputValue) : undefined;
    onChange({ ...value, altitude: altitudeValue });
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!/[0-9.]/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div className={styles.root}>
      <input
        key={value.id}
        value={value.name}
        onChange={handleChangeName}
        disabled={true}
        placeholder={t(`sidebar.site.edit.sections.obstacles.namePlaceholder`) ?? ''}
        className={styles.addInput}
        style={{ width: 120 }}
      />

      <div className='relative'>
        <input
          key={value.id}
          value={value.altitude}
          type='number'
          onChange={handleChangeAltitude}
          placeholder={t(`sidebar.site.edit.sections.obstacles.altitude`) ?? ''}
          className={styles.addInput}
          onKeyPress={handleKeyPress}
          style={{ width: 160 }}
        />

        {typeof value.altitude === 'number' && (
          <div className='absolute flex items-center justify-start text-[14px] h-[41px] top-0 left-0 pointer-events-none p-[10px]'>
            <span className='opacity-0 select-none'>{rawValue ?? value.altitude}</span>
            <span>m</span>
          </div>
        )}
      </div>

      <button className={styles.deleteIcon} onClick={handleDelete}>
        <ReactSVG className={styles.delete} src={value ? deleteIcon : nonActiveDeleteIcon} />
      </button>
    </div>
  );
};

export default memo(AddInput);
