import { FC, memo } from 'react';

interface IProps {
  width: string;
  className?: string;
}

export const TimelinePath: FC<IProps> = memo(({ width, className }) => {
  return <div style={{ width }} className={className} />;
});
