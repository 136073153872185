// TODO:

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable @typescript-eslint/ban-types */

/* eslint-disable no-case-declarations */
import { toast } from 'react-toastify';
import { RootState } from '..';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { t } from 'i18next';
import { api } from '@core/api';
import { ERROR_TOAST_DELAY } from '@core/constants';
import { EReportSelector } from '@core/enums/report';
import { useSelectorTyped } from '@core/hooks';
import { IInspection } from '@core/interfaces';
import { IReport } from '@core/interfaces/report';
import { setFlightPath, setSolarPanels } from './controls';
import { reloadMap } from './map';
import { setPreloader } from './preloader';
import { resetCurrentReportSamples, setCurrentReportSamplesByReportId } from './samples';
import { checkIsNoReportError } from '../utils/reports/checkIsNoReportError';
import { updateAnomalies } from '../utils/reports/updateAnomalies';
import { updateReports } from '../utils/reports/updateReports';

interface IReportState {
  reports: Array<IReport>;
  loading: boolean;
  currentReportId: string | null;
  reportToCompareId: string | null;
}

const initialState: IReportState = {
  reports: [],
  currentReportId: null,
  reportToCompareId: null,
  loading: false,
};

export const fetchReportsByInspections = createAsyncThunk<void, IInspection[], {}>(
  'reports/fetchReportsByInspectionIds',
  async (inspections, { dispatch }) => {
    try {
      dispatch(startDataProcessing());

      const reports = (
        await Promise.all(
          inspections.map(async (inspection) => {
            try {
              const report = await api.reports.getReportByInspectionId(inspection.id);

              return {
                ...report,
                inspection_id: inspection.id,
                inspection_name: inspection.name,
                program_name: inspection.program_name,
                program_id: inspection.program_id,
              };
            } catch (error) {
              const responseData = (error as AxiosError).response?.data as { detail: string };
              if (responseData) {
                const isNoReportError = checkIsNoReportError(responseData?.detail);
                !isNoReportError &&
                  toast.error(t('errors.getReportsFail'), { autoClose: ERROR_TOAST_DELAY });
              } else {
                toast.error(t('errors.getReportsFail'), { autoClose: ERROR_TOAST_DELAY });
              }
            }
          }),
        )
      ).filter(Boolean) as IReport[];

      dispatch(addReports(reports));
    } catch {
      toast.error(t('errors.getReportsFail'), { autoClose: ERROR_TOAST_DELAY });
    } finally {
      dispatch(finishDataProcessing());
    }
  },
);

export const fetchAnomaliesAndSolarPanelsToReports = createAsyncThunk<
  void,
  { selector: EReportSelector },
  {}
>('reports/fetchAnomaliesAndSolarPanelsToReports', async (config, { dispatch, getState }) => {
  try {
    dispatch(setPreloader(true));
    dispatch(startDataProcessing());
    const state = getState() as RootState;

    switch (config.selector) {
      case EReportSelector.GetAllReportsBySelectedZone:
        const reportsBySelectedZone = state.reports.reports.filter(
          (report) => report.program_id === state.programs.currentProgramId,
        );
        // NOTE: remove anomalies, solar panels because it's a large amount of data
        const reportsFromOthersZones = state.reports.reports
          .filter((report) => report.program_id !== state.programs.currentProgramId)
          .map(({ anomalies, solar_panels, ...report }) => report);

        const updatedReports = (
          await Promise.all(
            reportsBySelectedZone.map(async (report) => {
              try {
                const [solarPanels, anomalies] = await Promise.all([
                  api.reports.getSolarPanelsByReportId(report.id),
                  api.reports.getAnomaliesByReportId(report.id),
                ]);

                const updatedAnomalies = updateAnomalies(anomalies, report);

                return {
                  ...report,
                  solar_panels: solarPanels,
                  anomalies: updatedAnomalies,
                };
              } catch (e) {
                toast.error(t('errors.getAnomalyAndPanelDataFail'), {
                  autoClose: ERROR_TOAST_DELAY,
                });
              }
            }),
          )
        ).filter(Boolean) as IReport[];

        dispatch(setReports([...reportsFromOthersZones, ...updatedReports]));
        break;
      case EReportSelector.GetBySelectedReport:
        const currentReport = state.reports.reports.find(
          (report) => report.id == state.reports.currentReportId,
        );

        if (currentReport) {
          const [solarPanels, anomalies] = await Promise.all([
            api.reports.getSolarPanelsByReportId(currentReport.id),
            api.reports.getAnomaliesByReportId(currentReport.id),
          ]);

          const updatedAnomalies = updateAnomalies(anomalies, currentReport);
          const updatedReports = updateReports({
            reports: state.reports.reports,
            currentReport,
            solarPanels,
            anomalies: updatedAnomalies,
          });

          dispatch(setReports(updatedReports));
        }
        break;
    }
  } catch {
    toast.error(t('errors.getAnomalyAndPanelDataFail'), {
      autoClose: ERROR_TOAST_DELAY,
    });
  } finally {
    dispatch(finishDataProcessing());
    dispatch(setPreloader(false));
    dispatch(reloadMap(true));
  }
});

const setCurrentReportIdByCurrentInspectionId = createAsyncThunk<void, string, {}>(
  'reports/setCurrentReportIdByCurrentInspectionId',
  (inspectionId, { dispatch, getState }) => {
    const state = getState() as RootState;
    const inspectionReport: IReport | undefined = state.reports.reports.find(
      (report: IReport) => report.inspection_id == inspectionId,
    );

    if (inspectionReport?.id) {
      dispatch(setCurrentReportId(String(inspectionReport.id)));
      dispatch(setCurrentReportSamplesByReportId(String(inspectionReport.id)));
      dispatch(setSolarPanels(true));
    } else {
      dispatch(setSolarPanels(false));
      dispatch(setFlightPath(false));
      dispatch(setCurrentReportId(null));
      dispatch(resetCurrentReportSamples());
    }
  },
);

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    startDataProcessing: (state) => {
      state.loading = true;
    },
    finishDataProcessing: (state) => {
      state.loading = false;
    },
    addReports: (state, action: PayloadAction<IReport[]>) => {
      state.reports = action.payload;
    },
    setReports: (state, action: PayloadAction<IReport[]>) => {
      state.reports = action.payload;
    },
    setCurrentReportId: (state, action: PayloadAction<string | null>) => {
      state.currentReportId = action.payload;
    },
    setReportToCompareId: (state, action: PayloadAction<string | null>) => {
      state.reportToCompareId = action.payload;
    },
    resetReports: () => initialState,
  },
});

const reportsReducer = reportsSlice.reducer;

const {
  startDataProcessing,
  finishDataProcessing,
  addReports,
  setCurrentReportId,
  resetReports,
  setReports,
  setReportToCompareId,
} = reportsSlice.actions;

const useReportsSelector = () => useSelectorTyped((state) => state.reports);

const useReportByInspectionIdSelector = (inspectionId: string | undefined) =>
  useSelectorTyped((state) =>
    state.reports.reports.find((report) => report.inspection_id === inspectionId),
  );

const useReportsByInspectionIdsSelector = (inspectionIds: string[]) =>
  useSelectorTyped((state) => {
    return state.reports.reports.filter((report) =>
      inspectionIds.includes(String(report.inspection_id)),
    );
  });

const useCurrentReportSelector = () =>
  useSelectorTyped((state) =>
    state.reports.reports.find((report) => report.id == state.reports.currentReportId),
  );

const useReportToCompareSelector = () =>
  useSelectorTyped((state) =>
    state.reports.reports.find(
      (report) =>
        report.program_id === state.programs.currentProgramId &&
        report.inspection_id === state.inspections.inspectionToCompareId,
    ),
  );

export {
  reportsReducer,
  useReportsSelector,
  setCurrentReportId,
  resetReports,
  setReports,
  setCurrentReportIdByCurrentInspectionId,
  setReportToCompareId,
  useReportByInspectionIdSelector,
  useCurrentReportSelector,
  useReportsByInspectionIdsSelector,
  useReportToCompareSelector,
  initialState as reportsInitialState,
};
