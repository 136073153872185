import { FC } from 'react';
import cn from 'classnames';
import outflierIcon from '@assets/icons/outflier.svg';
import styles from './styles.scss';

interface IProps {
  width?: number | string;
  height?: number | string;
  full?: boolean;
}

export const Preloader: FC<IProps> = ({ width = '100vw', height = '100vh', full = true }) => (
  <div className={cn(styles.container, { [styles.full]: full })} style={{ width, height }}>
    <div className={styles.containerLogo}>
      <img src={outflierIcon} alt='outflier icon' />
    </div>
  </div>
);
