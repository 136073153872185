import { IInspection } from '@core/interfaces';

export const groupInspectionsByMonthYear = (inspections: IInspection[]): IInspection[][] => {
  const groupedInspections = {};

  inspections.forEach((inspection) => {
    const date = new Date(inspection.date);
    const month = date.getMonth();
    const year = date.getFullYear();
    const key = `${month}-${year}`;

    if (!groupedInspections[key]) {
      groupedInspections[key] = [];
    }

    groupedInspections[key].push(inspection);
  });

  return Object.values(groupedInspections);
};
