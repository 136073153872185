import { IViewerInitialState } from '@core/contexts';
import { EControlMode, EMapViewerActive, EViewer, EViewerType } from '@core/enums';

interface IViewersProps {
  viewers: {
    state: IViewerInitialState[];
    viewer: EViewer;
    sidebarViewer: EViewer;
  };
  options: {
    type: EViewerType;
    mode: EControlMode;
  };
}

export const getViewers = ({ viewers, options }: IViewersProps) => {
  const { state, viewer, sidebarViewer } = viewers;
  const { type, mode } = options;

  let filteredViewers = state;

  switch (type) {
    case EViewerType.Viewer: {
      switch (viewer) {
        case EViewer.Model: {
          filteredViewers = state;
          break;
        }
        case EViewer.CompareMap: {
          const viewersWithoutModel = state.filter(
            (defaultViewer) => ![defaultViewer.name === EViewer.Model].some(Boolean),
          );
          filteredViewers = viewersWithoutModel;
          break;
        }
        case EViewer.Map: {
          const viewersWithoutModelAndCompareMap = state.filter(
            (defaultViewer) =>
              ![
                defaultViewer.name === EViewer.Model,
                defaultViewer.name === EViewer.CompareMap,
              ].some(Boolean),
          );
          filteredViewers = viewersWithoutModelAndCompareMap;
          break;
        }
        case EViewer.Image:
        case EViewer.Video: {
          if (mode === EControlMode['3D']) {
            filteredViewers = state;
          } else {
            const viewersWithoutModelAndCompareMap = state.filter(
              (defaultViewer) =>
                ![
                  defaultViewer.name === EViewer.Model,
                  defaultViewer.name === EViewer.CompareMap,
                ].some(Boolean),
            );
            filteredViewers = viewersWithoutModelAndCompareMap;
          }
          break;
        }
      }
      break;
    }
    case EViewerType.InspectionView: {
      switch (sidebarViewer) {
        case EViewer.Model: {
          filteredViewers = state;
          break;
        }
        case EViewer.CompareMap: {
          const viewersWithoutModel = state.filter(
            (defaultViewer) => ![defaultViewer.name === EViewer.Model].some(Boolean),
          );
          filteredViewers = viewersWithoutModel;
          break;
        }
        case EViewer.Map: {
          const viewersWithoutModelAndCompareMap = state.filter(
            (defaultViewer) =>
              ![
                defaultViewer.name === EViewer.Model,
                defaultViewer.name === EViewer.CompareMap,
              ].some(Boolean),
          );
          filteredViewers = viewersWithoutModelAndCompareMap;
          break;
        }
        case EViewer.Image:
        case EViewer.Video: {
          if (mode === EControlMode['3D']) {
            filteredViewers = state;
          } else {
            const viewersWithoutModelAndCompareMap = state.filter(
              (defaultViewer) =>
                ![
                  defaultViewer.name === EViewer.Model,
                  defaultViewer.name === EViewer.CompareMap,
                ].some(Boolean),
            );
            filteredViewers = viewersWithoutModelAndCompareMap;
          }
          break;
        }
      }
    }
  }

  return filteredViewers;
};

export function getActiveViewerByViewerKey(
  viewer: EViewer,
  sidebarViewer: EViewer,
  key: EViewer,
): EMapViewerActive | null {
  return viewer === key
    ? EMapViewerActive.Viewer
    : sidebarViewer === key
    ? EMapViewerActive.Sidebar
    : null;
}
