import { createInputName } from '@core/utils/formatting/stringFormat';
import { validateEmail } from '@core/utils/validating/validateEmail';

export const setRoleInitialState = <T extends string>(entity: T) => ({
  name: createInputName(entity),
  value: '',
  errorMessage: validateEmail(''),
});

export const setInputInitialState = <T extends string>(entity: T) => ({
  [createInputName(entity)]: {
    value: '',
    errorMessage: validateEmail(''),
  },
});
