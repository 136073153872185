import { FACES, FACES_OUTLINE } from '@core/constants';
import { getColor } from '@core/utils';
import { EModelSolarPanelsLayer } from '../../enums/layers';

export interface ISolarPanelsDrawableOptions {
  id: string;
  vertices: number[];
  faces: number[];
  color: string;
  line_width?: number;
  alpha: number;
  wireframe: boolean;
}

interface IDrawableDeps {
  hasSolarPanels: boolean;
}

export const drawableOptions: {
  [key in EModelSolarPanelsLayer]: (deps?: IDrawableDeps) => ISolarPanelsDrawableOptions;
} = {
  [EModelSolarPanelsLayer.Panel]: (deps) => ({
    id: '',
    alpha: 1,
    color: getColor(deps?.hasSolarPanels ? '--outflier-color2' : '--soft-grey'),
    faces: FACES,
    vertices: [],
    wireframe: false,
  }),
  [EModelSolarPanelsLayer.Outline]: () => ({
    id: '',
    alpha: 1,
    vertices: [],
    color: getColor('--outflier-color5'),
    line_width: 0.001,
    faces: FACES_OUTLINE,
    wireframe: true,
  }),
};
