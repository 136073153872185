import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const NoInspectionsView: FC = () => {
  const { t } = useTranslation();

  return (
    <span className='w-full text-sm text-outflier-soft-gray text-center select-none'>
      {t('header.timeline.noInspections')}
    </span>
  );
};
