// TODO:

/* eslint-disable @typescript-eslint/no-unused-vars */
import { toast } from 'react-toastify';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Feature, LineString, Polygon } from '@turf/turf';
import { FeatureCollection } from 'geojson';
import { t } from 'i18next';
import _ from 'lodash';
import { api } from '@core/api';
import { EJobStatus } from '@core/api/locations/jobStatus';
import { IUpdateLocationRequest } from '@core/api/locations/updateById';
import {
  ECreateSiteLocalStorageField,
  ELocalStorageField,
  ERROR_TOAST_DELAY,
} from '@core/constants';
import {
  DEFAULT_GENERATED_PROGRAM_NAME_REGEX,
  DEFAULT_SITE_NAME,
  ECreateSiteSteps,
  ENoFlyZoneType,
  INITIAL_CREATE_SITE_COORDINATES,
} from '@core/constants/createSite';
import {
  EErrorStatus,
  EInspectionFrequency,
  ERoles,
  ESidebar,
  ESiteEditActions,
  EViewer,
} from '@core/enums';
import { useSelectorTyped } from '@core/hooks';
import {
  IProgram,
  ISite,
  TEditRole,
  TRolesWithoutSuperuserPayload,
  TRolesWithoutSuperuser,
  TRolesWithoutSuperuserData,
} from '@core/interfaces';
import {
  IFakePerimeterProcessing,
  IMapboxCityData,
  INoFlyZone,
  IObstacle,
} from '@core/interfaces/createSite';
import { browserLocalStorage, DataProcessing } from '@core/services';
import { RootState } from '@core/store';
import { setSidebar } from '@core/store/slices/sidebar';
import { setViewer } from '@core/store/slices/viewer';
import { isNumber, isString, isTruthy } from '@core/utils';
import { getMaxStepForContinueSiteCreation } from '@modules/Sidebar/views/CreateSite/utils/getMaxStepForContinueSiteCreation';
import { setRoleInitialState } from '@modules/Sidebar/views/Site/utils';

export enum EGeneratedProgramPreview {
  Overview = 'Overview',
  Inspection = 'Inspection',
}

export interface ICreateSiteInitialState {
  loc_id: string | null;
  currentStep: number;
  maxCurrentStep: number;
  perimeterProcessingPercentage: number | null; // for site perimeter creation
  fakePerimeterProcessing: IFakePerimeterProcessing; // for zones perimeter creation
  currentPerimeterIndex: number | null;
  perimeters: Array<FeatureCollection | Polygon | any>;
  currentObstacleIndex: number | null;
  obstacles: IObstacle[];
  currentNoFlyZoneIndex: number | null;
  noFlyZones: INoFlyZone[];
  isLoadingZones: boolean;
  isLoadingSite: boolean;
  lat: number;
  lng: number;
  selectedLocation: IMapboxCityData | null;
  top_left_lat: number;
  top_left_lng: number;
  bottom_right_lat: number;
  bottom_right_lng: number;
  name: string;
  image: string | null;
  imageUrl: string | null;
  programs: IProgram[];
  currentProgramId: string;
  currentPlanIndex: null | number;
  currentOverviewIndex: null | number;
  programPreview: EGeneratedProgramPreview;
  inspectionFrequency: number;
  roles: { [key in TRolesWithoutSuperuser]: TEditRole[] };
}

const initialState: ICreateSiteInitialState = {
  loc_id: null,
  currentStep: ECreateSiteSteps.SearchCity,
  maxCurrentStep: ECreateSiteSteps.SearchCity,
  perimeterProcessingPercentage: null,
  fakePerimeterProcessing: {
    percentage: 0,
    currentStep: 0,
  },
  currentPerimeterIndex: null,
  perimeters: [],
  currentObstacleIndex: null,
  obstacles: [],
  currentNoFlyZoneIndex: null,
  noFlyZones: [],
  isLoadingZones: false,
  isLoadingSite: false,
  lat: INITIAL_CREATE_SITE_COORDINATES.lat,
  lng: INITIAL_CREATE_SITE_COORDINATES.lng,
  selectedLocation: null,
  top_left_lat: 0,
  top_left_lng: 0,
  bottom_right_lat: 0,
  bottom_right_lng: 0,
  name: '',
  image: '',
  imageUrl: '',
  programs: [],
  currentProgramId: '',
  currentOverviewIndex: null,
  currentPlanIndex: null,
  programPreview: EGeneratedProgramPreview.Overview,
  inspectionFrequency: EInspectionFrequency.Monthly,
  roles: {
    [ERoles.Admin]: [setRoleInitialState(ERoles.Admin)],
    [ERoles.Editor]: [setRoleInitialState(ERoles.Editor)],
    [ERoles.Viewer]: [setRoleInitialState(ERoles.Viewer)],
    [ERoles.Inspector]: [setRoleInitialState(ERoles.Inspector)],
  },
};

const createSiteSlice = createSlice({
  name: 'createSite',
  initialState,
  reducers: {
    setLocId: (state, action: PayloadAction<string | null>) => {
      state.loc_id = action.payload;
    },
    setCurrentPlanIndex: (state, action: PayloadAction<number | null>) => {
      state.currentPlanIndex = action.payload;
    },
    setCurrentOverviewIndex: (state, action: PayloadAction<number | null>) => {
      state.currentOverviewIndex = action.payload;
    },
    _setCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
      if (action.payload > state.maxCurrentStep) state.maxCurrentStep = action.payload;
      state.currentObstacleIndex = null;
      state.currentNoFlyZoneIndex = null;
    },
    _setMaxCurrentStep: (state, action: PayloadAction<number>) => {
      state.maxCurrentStep = action.payload;
    },
    setImage: (state, action: PayloadAction<string | null>) => {
      state.image = action.payload;
      if (!action.payload) state.imageUrl = null;
    },
    setProgramPreview: (state, action: PayloadAction<EGeneratedProgramPreview>) => {
      state.programPreview = action.payload;
    },
    setInspectionFrequency: (state, action: PayloadAction<number>) => {
      state.inspectionFrequency = action.payload;
    },
    _setPerimeters: (state, action: PayloadAction<any[]>) => {
      state.perimeters = action.payload;
      state.currentPerimeterIndex = 0;
    },
    setObstacles: (state, action: PayloadAction<IObstacle[]>) => {
      state.obstacles = action.payload;
      state.currentObstacleIndex = 0;
    },
    addObstacle: (state, action: PayloadAction<IObstacle | undefined>) => {
      const newObstacle = action.payload ? action.payload : createDefaultObstacle();
      state.obstacles.push(newObstacle);
      state.currentObstacleIndex = state.obstacles.length - 1;
    },
    updateCurrentObstaclePerimeter: (state, action: PayloadAction<Feature<any>>) => {
      if (state.currentObstacleIndex === null) return;
      const currentObstacle = state.obstacles[state.currentObstacleIndex];
      if (currentObstacle) {
        currentObstacle.id = action.payload.id ?? currentObstacle.id;
        currentObstacle.perimeter = action.payload.geometry as LineString;
      }
    },
    updateObstacle: (state, action: PayloadAction<IObstacle>) => {
      const index = state.obstacles.findIndex((obstacle) => obstacle.id === action.payload.id);
      if (index !== -1) {
        state.obstacles[index] = action.payload;
      }
    },
    deleteObstacle: (state, action: PayloadAction<number | IObstacle>) => {
      const idToDelete = typeof action.payload === 'number' ? action.payload : action.payload.id;
      const indexToDelete = state.obstacles.findIndex((obstacle) => obstacle.id === idToDelete);

      if (indexToDelete === -1) return;

      state.obstacles.splice(indexToDelete, 1);

      if (state.currentObstacleIndex !== null) {
        if (state.currentObstacleIndex === indexToDelete) {
          state.currentObstacleIndex = Math.min(
            state.currentObstacleIndex,
            state.obstacles.length - 1,
          );
        } else if (state.currentObstacleIndex > indexToDelete) {
          state.currentObstacleIndex -= 1;
        }
      }

      if (state.obstacles.length === 0) state.currentObstacleIndex = null;
    },
    setCurrentObstacleIndex: (state, action: PayloadAction<number>) => {
      state.currentObstacleIndex = action.payload;
    },
    updateCurrentObstacleIndexById: (state, action: PayloadAction<number>) => {
      if (state.currentStep !== ECreateSiteSteps.Obstacles) return;
      const index = state.obstacles.findIndex((obstacle) => obstacle.id === action.payload);
      if (index === -1) return;
      state.currentObstacleIndex = index;
    },
    setNoFlyZones: (state, action: PayloadAction<INoFlyZone[]>) => {
      state.noFlyZones = action.payload;
      state.currentNoFlyZoneIndex = 0;
    },
    addNoFlyZone: (state, action: PayloadAction<INoFlyZone | undefined>) => {
      const newNoFlyZone = action.payload ? action.payload : createDefaultNoFlyZone();
      state.noFlyZones.push(newNoFlyZone);
      state.currentNoFlyZoneIndex = state.noFlyZones.length - 1;
    },
    updateCurrentNoFlyZonePerimeter: (state, action: PayloadAction<Feature<any>>) => {
      if (state.currentNoFlyZoneIndex === null) return;
      const currentNoFlyZone = state.noFlyZones[state.currentNoFlyZoneIndex];
      if (currentNoFlyZone) {
        currentNoFlyZone.id = action.payload.id ?? currentNoFlyZone.id;
        currentNoFlyZone.perimeter = action.payload.geometry as Polygon;
      }
    },
    updateNoFlyZone: (state, action: PayloadAction<INoFlyZone>) => {
      const index = state.noFlyZones.findIndex((noFlyZone) => noFlyZone.id === action.payload.id);
      if (index !== -1) {
        state.noFlyZones[index] = action.payload;
      }
    },
    deleteNoFlyZone: (state, action: PayloadAction<number | INoFlyZone>) => {
      const idToDelete = typeof action.payload === 'number' ? action.payload : action.payload.id;
      const indexToDelete = state.noFlyZones.findIndex((noFlyZone) => noFlyZone.id === idToDelete);

      if (indexToDelete === -1) return;

      state.noFlyZones.splice(indexToDelete, 1);

      if (state.currentNoFlyZoneIndex !== null) {
        if (state.currentNoFlyZoneIndex === indexToDelete) {
          state.currentNoFlyZoneIndex = Math.min(
            state.currentNoFlyZoneIndex,
            state.noFlyZones.length - 1,
          );
        } else if (state.currentNoFlyZoneIndex > indexToDelete) {
          state.currentNoFlyZoneIndex -= 1;
        }
      }

      if (state.noFlyZones.length === 0) state.currentNoFlyZoneIndex = null;
    },
    setCurrentNoFlyZoneIndex: (state, action: PayloadAction<number>) => {
      state.currentNoFlyZoneIndex = action.payload;
    },
    updateCurrentNoFlyZoneIndexById: (state, action: PayloadAction<number>) => {
      if (state.currentStep !== ECreateSiteSteps.NoFlyZones) return;
      const index = state.noFlyZones.findIndex((noFlyZone) => noFlyZone.id === action.payload);
      if (index === -1) return;
      state.currentNoFlyZoneIndex = index;
    },
    setCreateSiteRoles: (state, action: PayloadAction<TRolesWithoutSuperuserData>) => {
      state.roles = action.payload;
    },
    setIsLoadingSite: (state, action: PayloadAction<boolean>) => {
      state.isLoadingSite = action.payload;
    },
    _setIsLoadingZones: (state, action: PayloadAction<boolean>) => {
      state.isLoadingZones = action.payload;
    },
    previousPerimeter: (state) => {
      if (state.currentPerimeterIndex === null) state.currentPerimeterIndex = 0;
      if (state.currentPerimeterIndex > 0)
        state.currentPerimeterIndex = state.currentPerimeterIndex - 1;
    },
    nextPerimeter: (state) => {
      if (state.currentPerimeterIndex === null) state.currentPerimeterIndex = 0;
      if (state.currentPerimeterIndex < state.perimeters.length - 1)
        state.currentPerimeterIndex = state.currentPerimeterIndex + 1;
    },
    updatePerimeter: (state, action: PayloadAction<FeatureCollection>) => {
      if (state.currentPerimeterIndex !== null) {
        const updatedPerimeters = state.perimeters.slice(0, state.currentPerimeterIndex + 1);
        updatedPerimeters.push(action.payload);
        state.perimeters = updatedPerimeters;
        state.currentPerimeterIndex = updatedPerimeters.length - 1;
      }
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setPrograms: (state, action: PayloadAction<IProgram[]>) => {
      state.programs = action.payload.map(({ name, ...rest }, index) =>
        name.match(DEFAULT_GENERATED_PROGRAM_NAME_REGEX)
          ? { ...rest, name: `Zone ${index + 1}` }
          : { name, ...rest },
      );
    },
    setCreateSiteCurrentProgramId: (state, action: PayloadAction<string>) => {
      state.currentProgramId = action.payload;
    },
    updateProgramById: (
      state,
      action: PayloadAction<{ id: string; changes: Partial<IProgram> }>,
    ) => {
      const index = state.programs.findIndex((program) => program.program_id === action.payload.id);
      if (index !== -1) {
        Object.assign(state.programs[index], action.payload.changes);
      }
    },
    nextCurrentProgramId: (state) => {
      const currentIndex = state.programs.findIndex(
        (program) => program.program_id === state.currentProgramId,
      );
      if (currentIndex !== -1) {
        const nextIndex = currentIndex === state.programs.length - 1 ? 0 : currentIndex + 1;
        state.programs[nextIndex].isViewed = true;
        state.currentProgramId = state.programs[nextIndex].program_id;
      }
    },
    previousCurrentProgramId: (state) => {
      const currentIndex = state.programs.findIndex(
        (program) => program.program_id === state.currentProgramId,
      );
      if (currentIndex !== -1) {
        const prevIndex = currentIndex === 0 ? state.programs.length - 1 : currentIndex - 1;
        state.programs[prevIndex].isViewed = true;
        state.currentProgramId = state.programs[prevIndex].program_id;
      }
    },
    setSelectedLocation: (state, action: PayloadAction<IMapboxCityData>) => {
      state.selectedLocation = action.payload;
    },
    setLng: (state, action: PayloadAction<number>) => {
      state.lng = action.payload;
    },
    setLat: (state, action: PayloadAction<number>) => {
      state.lat = action.payload;
    },
    setTopLeftLat: (state, action: PayloadAction<number>) => {
      state.top_left_lat = action.payload;
    },
    setTopLeftLng: (state, action: PayloadAction<number>) => {
      state.top_left_lng = action.payload;
    },
    setBottomRightLat: (state, action: PayloadAction<number>) => {
      state.bottom_right_lat = action.payload;
    },
    setBottomRightLng: (state, action: PayloadAction<number>) => {
      state.bottom_right_lng = action.payload;
    },
    setPerimeterProcessingPercentage: (state, action: PayloadAction<number | null>) => {
      state.perimeterProcessingPercentage = action.payload;
    },
    setFakePerimeterProcessing: (state, action: PayloadAction<IFakePerimeterProcessing>) => {
      state.fakePerimeterProcessing = action.payload;
    },

    resetCreateSite: () => {
      defaultObstacleIndex = 0;
      defaultNoFlyZoneIndex = 0;
      return { ...initialState };
    },
    populateState: (
      state,
      action: PayloadAction<{
        site: ISite;
        perimeter: FeatureCollection | null;
        noFlyZones: INoFlyZone[];
        obstacles: IObstacle[];
        programs: IProgram[];
      }>,
    ) => {
      const { name, loc_id, gps_boundaries, lat, long, picture, inspection_frequency } =
        action.payload.site;
      if (lat && long) {
        state.lat = lat;
        state.lng = long;
      }
      if (gps_boundaries) {
        state.top_left_lat = gps_boundaries.top_left_lat;
        state.top_left_lng = gps_boundaries.top_left_lng;
        state.bottom_right_lat = gps_boundaries.bottom_right_lat;
        state.bottom_right_lng = gps_boundaries.bottom_right_lng;
        state.currentStep = ECreateSiteSteps.Name;
        state.maxCurrentStep = ECreateSiteSteps.Name;
      }
      if (name) state.name = name;
      if (loc_id) state.loc_id = loc_id;
      if (action.payload.perimeter) {
        state.perimeters = [action.payload.perimeter];
        state.currentPerimeterIndex = 0;
      }
      if (action.payload.obstacles) {
        state.obstacles = action.payload.obstacles;
        defaultObstacleIndex = action.payload.noFlyZones?.length;
      }
      if (action.payload.noFlyZones) {
        state.noFlyZones = action.payload.noFlyZones;
        defaultNoFlyZoneIndex = action.payload.noFlyZones?.length;
      }
      if (action.payload.programs) state.programs = action.payload.programs;
      if (picture) state.imageUrl = picture;
      if (inspection_frequency) state.inspectionFrequency = inspection_frequency;

      // NOTE: localStorage persisted data
      const createSiteStorageState = DataProcessing.deserialize(
        browserLocalStorage.getItem(ELocalStorageField.CreateSite),
      );
      const persistedStep =
        createSiteStorageState?.[loc_id]?.[ECreateSiteLocalStorageField.CurrentStep];

      const step = getMaxStepForContinueSiteCreation(state, persistedStep);

      // NOTE: reset "site" name if the default name is set
      if (name === DEFAULT_SITE_NAME) {
        state.name = '';
      }
      state.currentStep = step;
      state.maxCurrentStep = step;
    },
  },
});

const setCurrentStep = createAsyncThunk<void, number, { dispatch: any; getState: any }>(
  'createSite/setCurrentStep',
  async (newStep, { dispatch, getState }) => {
    const state = getState() as RootState;
    const {
      bottom_right_lng,
      bottom_right_lat,
      top_left_lng,
      top_left_lat,
      perimeters,
      currentStep: previousStep,
      loc_id,
      currentPerimeterIndex,
    } = state.createSite;
    dispatch(_setCurrentStep(newStep));

    // NOTE: use case (changed step from "perimeter", "noFlyZones", "obstacles" to another)
    // Send updated perimeters to a server

    switch (previousStep) {
      case ECreateSiteSteps.Perimeter:
        if (previousStep !== newStep && isNumber(currentPerimeterIndex) && isString(loc_id)) {
          dispatch(
            updateLocationPerimeter({
              currentPerimeterIndex,
              loc_id,
              perimeters,
            }),
          );
        }
        break;
    }

    switch (newStep) {
      case ECreateSiteSteps.Name:
        if (state.createSite.loc_id) return;
        try {
          const midpoint_latitude = (top_left_lat + bottom_right_lat) / 2;
          const midpoint_longitude = (top_left_lng + bottom_right_lng) / 2;

          const resp = await api.location.create({
            name: DEFAULT_SITE_NAME,
            lat: midpoint_latitude,
            long: midpoint_longitude,
            gps_boundaries: {
              bottom_right_lat,
              bottom_right_lng,
              top_left_lat,
              top_left_lng,
            },
          });
          dispatch(setLng(midpoint_longitude));
          dispatch(setLat(midpoint_latitude));
          dispatch(setLocId(resp.loc_id));
          dispatch(
            updateLocationPerimeter({
              currentPerimeterIndex: perimeters.length - 1,
              loc_id: resp.loc_id,
              perimeters,
            }),
          );
        } catch (e: any) {
          toast.error(t('errors.unableToCreateSite'), { autoClose: ERROR_TOAST_DELAY });
        }
        break;
    }

    try {
      const { loc_id, name, image, inspectionFrequency } = state.createSite;
      if (!loc_id || !name) return;

      const normalizedName = name.trim();
      const updateData: Partial<
        Pick<IUpdateLocationRequest, 'name' | 'inspection_frequency' | 'picture'>
      > = {
        name: normalizedName,
        inspection_frequency: inspectionFrequency,
      };

      if (isTruthy(image)) updateData.picture = image;

      await api.location.updateById(loc_id, updateData);

      dispatch(setName(normalizedName));
    } catch (e: any) {
      toast.error(
        e?.message ?? t('errors.locationUpdateFail', { locationId: state.createSite.loc_id }),
        { autoClose: ERROR_TOAST_DELAY },
      );
    }
  },
);

const loadZones = createAsyncThunk<void, void, { dispatch: any; getState: any }>(
  'createSite/loadZones',
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { loc_id, isLoadingZones, noFlyZones, obstacles, perimeters } = state.createSite;
    dispatch(_setIsLoadingZones(true));

    if (!loc_id) {
      toast.error(t('errors.unableToGenerateZones'), { autoClose: false });
      dispatch(_setIsLoadingZones(false));
      return;
    }

    if (isLoadingZones) {
      dispatch(getJobStatus({ loc_id }));
      return;
    }

    const processedObstacles = obstacles.map(({ id, ...rest }) => rest);
    const processedNoFlyZones = noFlyZones.map(({ id, ...rest }) => rest);

    const allItems = [...processedObstacles, ...processedNoFlyZones];
    const promises = allItems.map((item) =>
      api.location.createNoFlyZonesByLocationId(loc_id, item),
    );

    try {
      await Promise.all(promises);
      await dispatch(
        updateLocationPerimeter({
          currentPerimeterIndex: perimeters.length - 1,
          loc_id,
          perimeters,
        }),
      );
    } catch (error: any) {
      toast.error(error?.response?.data?.detail ?? t('errors.noFlyZonesOrObstaclesFail'), {
        autoClose: ERROR_TOAST_DELAY,
      });
      dispatch(_setIsLoadingZones(false));
      return;
    }

    return api.location
      .postSetup(loc_id)
      .then(() => {
        dispatch(getJobStatus({ loc_id }));
      })
      .catch((error) => {
        dispatch(_setIsLoadingZones(false));
        toast.error(error?.response?.data?.detail ?? t('errors.somethingWentWrong'), {
          autoClose: ERROR_TOAST_DELAY,
        });
      });
  },
);

const getJobStatus = createAsyncThunk<
  void,
  { loc_id: string; isInitialRequest?: boolean },
  {
    dispatch: any;
    getState: any;
  }
>('createSite/getJobStatus', async ({ loc_id, isInitialRequest }, { dispatch, getState }) => {
  return api.location
    .getJobStatus(loc_id)
    .then((jobStatus) => {
      if (isInitialRequest) {
        if (jobStatus === EJobStatus.Unknown) return;
        if (jobStatus === EJobStatus.In_progress) {
          dispatch(_setIsLoadingZones(true));
          const state = getState() as RootState;
          const step = getMaxStepForContinueSiteCreation(state.createSite);
          dispatch(setCurrentStep(step));
        }
      }
      if (![EJobStatus.Failed, EJobStatus.Completed].includes(jobStatus)) {
        setTimeout(() => {
          dispatch(getJobStatus({ loc_id }));
        }, 3000);
      }

      if (jobStatus === EJobStatus.Failed) {
        dispatch(_setIsLoadingZones(false));
        toast.error(t('errors.zonesGenerateFail'), { autoClose: false });
      }

      if (jobStatus === EJobStatus.Completed) {
        setTimeout(() => {
          api.location
            .getProgramsByLocationId(loc_id)
            .then((programs) => {
              if (!programs || programs?.length === 0) {
                throw new Error('No zones found');
              }

              dispatch(setPrograms(programs));
              dispatch(_setIsLoadingZones(false));
            })
            .catch(() => {
              toast.error(t('errors.getZonesFail'), { autoClose: false });
              dispatch(_setIsLoadingZones(false));
            });
        }, 3000);
      }
    })
    .catch((error) => {
      // NOTE: disable "toast" notification if network connection is lost
      if (error?.code !== EErrorStatus.NetworkError) {
        toast.error(error?.response?.data?.detail ?? t('errors.somethingWentWrong'), {
          autoClose: false,
        });
        dispatch(_setIsLoadingZones(false));
      }
    });
});

const continueCreation = createAsyncThunk<void, string, { dispatch: any; getState: any }>(
  'createSite/continueCreation',
  async (loc_id, { dispatch }) => {
    dispatch(resetCreateSite());
    try {
      const [perimeterResult, noFlyZonesAndObstaclesResult, programsResult] =
        await Promise.allSettled([
          api.location.getPerimeterByLocationId(loc_id),
          api.location.getNoFlyZonesByLocId(loc_id),
          api.location.getProgramsByLocId(loc_id),
        ]);

      let perimeter: FeatureCollection | null = null;
      let noFlyZones: INoFlyZone[] = [];
      let obstacles: IObstacle[] = [];
      let programs: IProgram[] = [];

      if (perimeterResult.status === 'fulfilled') {
        perimeter = perimeterResult.value as FeatureCollection;
      }

      if (noFlyZonesAndObstaclesResult.status === 'fulfilled') {
        const noFlyZonesAndObstacles = noFlyZonesAndObstaclesResult.value;
        noFlyZones = noFlyZonesAndObstacles.filter(
          (item) => item.type === 'no_fly_zone',
        ) as INoFlyZone[];
        obstacles = noFlyZonesAndObstacles.filter(
          (item) => item.type === 'obstacle',
        ) as IObstacle[];
      }

      if (programsResult.status === 'fulfilled') {
        programs = programsResult.value as IProgram[];
      }

      await dispatch(getJobStatus({ loc_id, isInitialRequest: true }));
      // NOTE: waiting for "isLoadingZones" field
      await api.location
        .getById(loc_id)
        .then((site) => {
          if (!site) throw new Error('Unable to get location data');
          dispatch(populateState({ site, perimeter, noFlyZones, obstacles, programs }));
        })
        .catch((error) => {
          toast.error(error?.response?.data?.detail ?? t('errors.somethingWentWrong'), {
            autoClose: ERROR_TOAST_DELAY,
          });
        });

      dispatch(setViewer(EViewer.Map));
      dispatch(setSidebar(ESidebar.CreateSite));
    } catch {
      toast.error('errors.continueCreationFail', {
        autoClose: ERROR_TOAST_DELAY,
      });
    }
  },
);

const updateLocationPerimeter = createAsyncThunk<
  void,
  {
    loc_id: string;
    perimeters: any[];
    currentPerimeterIndex: number;
  },
  { dispatch: any; getState: any }
>(
  'createSite/updateLocationPerimeter',
  async ({ loc_id, perimeters, currentPerimeterIndex }, { dispatch }) => {
    const perimeterData = perimeters.at(currentPerimeterIndex ?? 0);

    let polygonData;
    if (perimeterData?.type === 'FeatureCollection' && perimeterData?.features?.length > 0) {
      const feature = perimeterData.features[0];
      if (feature?.geometry?.type === 'Polygon') {
        polygonData = feature.geometry;
      }
    } else if (perimeterData?.type === 'Polygon') {
      polygonData = perimeterData;
    }

    if (!polygonData) {
      toast.error(t('errors.perimeterOrPolygonInvalid'), { autoClose: ERROR_TOAST_DELAY });
      dispatch(_setIsLoadingZones(false));
      return;
    }
    await api.location.postPerimeter(loc_id, polygonData);
  },
);

const setPerimeters = createAsyncThunk<void, any[], { dispatch: any; getState: any }>(
  'createSite/setPerimeters',
  async (perimeters, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { loc_id } = state.createSite;
    if (loc_id) {
      dispatch(
        updateLocationPerimeter({
          currentPerimeterIndex: perimeters.length - 1,
          loc_id,
          perimeters,
        }),
      );
    }
    dispatch(_setPerimeters(perimeters));
  },
);

let defaultObstacleIndex = 0;
const createDefaultObstacle = (): IObstacle => {
  defaultObstacleIndex++;
  return {
    id: Date.now(),
    name: `${t('sidebar.site.edit.sections.obstacles.defaultName')} ${defaultObstacleIndex}`,
    type: ENoFlyZoneType.Obstacle,
    perimeter: {
      type: 'LineString',
      coordinates: [],
    },
  };
};

let defaultNoFlyZoneIndex = 0;
const createDefaultNoFlyZone = (): INoFlyZone => {
  defaultNoFlyZoneIndex++;
  return {
    id: Date.now(),
    name: `${t('sidebar.site.edit.sections.noFlyZones.defaultName')} ${defaultNoFlyZoneIndex}`,
    type: ENoFlyZoneType.NoFlyZone,
    perimeter: {
      type: 'Polygon',
      coordinates: [],
    },
  };
};

export const updateCreateSiteRoles = createAsyncThunk<
  void,
  TRolesWithoutSuperuserPayload,
  NonNullable<unknown>
>('createSite/updateCreateSiteRoles', async (rolesDataPayload, { getState, dispatch }) => {
  const state = getState() as RootState;
  let createSiteRolesState = _.cloneDeep(state.createSite.roles);

  const { action: editAction, data: rolesData } = rolesDataPayload;
  const transformedRolesData = Object.entries(rolesData);

  if (transformedRolesData.length) {
    const [role, data] = transformedRolesData[0];

    switch (editAction) {
      case ESiteEditActions.AddOne: {
        createSiteRolesState = {
          ...createSiteRolesState,
          [role]: createSiteRolesState?.[role] ? [...createSiteRolesState[role], data] : [data],
        };
        break;
      }
      case ESiteEditActions.UpdateOne: {
        if (createSiteRolesState?.[role]) {
          createSiteRolesState = {
            ...createSiteRolesState,
            [role]: createSiteRolesState[role].map((item) =>
              item.name === data.name ? data : item,
            ),
          };
        }
        break;
      }
      case ESiteEditActions.DeleteOne: {
        if (createSiteRolesState?.[role]) {
          createSiteRolesState = {
            ...createSiteRolesState,
            [role]: createSiteRolesState[role].filter((item) => item.name !== data.name),
          };
        }
        break;
      }
    }

    dispatch(setCreateSiteRoles(createSiteRolesState));
  }
});

const useCurrentPerimeterSelector = () =>
  useSelectorTyped((state) => {
    if (state.createSite.currentPerimeterIndex !== null)
      return state.createSite.perimeters.at(state.createSite.currentPerimeterIndex);
  });

const useCurrentCreateSiteProgramSelector = () =>
  useSelectorTyped((state) => {
    if (state.createSite.currentProgramId !== null)
      return state.createSite.programs.find(
        (program) => program.program_id === state.createSite.currentProgramId,
      );
  });

const useCurrentNoFlyZoneSelector = () =>
  useSelectorTyped((state) => {
    if (state.createSite.currentNoFlyZoneIndex !== null)
      return state.createSite.noFlyZones.at(state.createSite.currentNoFlyZoneIndex);
  });

const useCurrentObstacleSelector = () =>
  useSelectorTyped((state) => {
    if (state.createSite.currentObstacleIndex !== null)
      return state.createSite.obstacles.at(state.createSite.currentObstacleIndex);
  });

const useNewSiteLocationCoordinates = () =>
  useSelectorTyped((state: RootState) => {
    return { lng: state.createSite.lng, lat: state.createSite.lat };
  });

const useSiteSelectedLocation = () =>
  useSelectorTyped((state: RootState) => state.createSite.selectedLocation);

const {
  _setCurrentStep,
  setLocId,
  setName,
  setCurrentPlanIndex,
  setCurrentOverviewIndex,
  setPrograms,
  nextCurrentProgramId,
  previousCurrentProgramId,
  updateProgramById,
  setCreateSiteCurrentProgramId,
  setProgramPreview,
  setImage,
  setInspectionFrequency,
  _setPerimeters,
  nextPerimeter,
  previousPerimeter,
  updatePerimeter,
  setObstacles,
  addObstacle,
  setCurrentObstacleIndex,
  updateCurrentObstacleIndexById,
  updateObstacle,
  updateCurrentObstaclePerimeter,
  deleteObstacle,
  setNoFlyZones,
  addNoFlyZone,
  setCurrentNoFlyZoneIndex,
  updateCurrentNoFlyZoneIndexById,
  updateNoFlyZone,
  updateCurrentNoFlyZonePerimeter,
  deleteNoFlyZone,
  _setIsLoadingZones,
  setIsLoadingSite,
  setLng,
  setLat,
  setSelectedLocation,
  setBottomRightLat,
  setBottomRightLng,
  setTopLeftLng,
  setTopLeftLat,
  setPerimeterProcessingPercentage,
  setFakePerimeterProcessing,
  resetCreateSite,
  populateState,
  setCreateSiteRoles,
} = createSiteSlice.actions;

const createSitesReducer = createSiteSlice.reducer;

export {
  setCurrentStep,
  setName,
  setCurrentPlanIndex,
  setCurrentOverviewIndex,
  setCreateSiteCurrentProgramId,
  setIsLoadingSite,
  updateProgramById,
  nextCurrentProgramId,
  previousCurrentProgramId,
  setProgramPreview,
  setImage,
  setInspectionFrequency,
  setPerimeters,
  nextPerimeter,
  previousPerimeter,
  updatePerimeter,
  setObstacles,
  addObstacle,
  setCurrentObstacleIndex,
  updateCurrentObstacleIndexById,
  updateObstacle,
  updateCurrentObstaclePerimeter,
  deleteObstacle,
  setNoFlyZones,
  addNoFlyZone,
  setCurrentNoFlyZoneIndex,
  updateCurrentNoFlyZoneIndexById,
  updateNoFlyZone,
  updateCurrentNoFlyZonePerimeter,
  deleteNoFlyZone,
  loadZones,
  setLng,
  setLat,
  setSelectedLocation,
  setBottomRightLat,
  setBottomRightLng,
  setTopLeftLng,
  setTopLeftLat,
  createSitesReducer,
  setPerimeterProcessingPercentage,
  setFakePerimeterProcessing,
  resetCreateSite,
  continueCreation,
  setCreateSiteRoles,
  useCurrentNoFlyZoneSelector,
  useCurrentPerimeterSelector,
  useCurrentObstacleSelector,
  useNewSiteLocationCoordinates,
  useCurrentCreateSiteProgramSelector,
  useSiteSelectedLocation,
  initialState as createSiteInitialState,
};
