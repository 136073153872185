import { MutableRefObject, createContext, createRef, useContext } from 'react';
import type { OrbitControls as TOrbitControls } from 'three-stdlib';

interface IMainContext {
  model: {
    viewerOrbitControl: MutableRefObject<TOrbitControls | null>;
    sidebarOrbitControl: MutableRefObject<TOrbitControls | null>;
  };
}

const initialState: IMainContext = {
  model: {
    viewerOrbitControl: createRef(),
    sidebarOrbitControl: createRef(),
  },
};

export const MainContext = createContext<IMainContext>(initialState);
export const useMainContext = () => useContext(MainContext);
