import { getAnomaliesByReportId } from './anomalies';
import { getReportByInspectionId } from './inspections';
import {
  getSampleAnomalyMaskByReportId,
  getSampleSegmentationMaskByReportId,
  getSampleRgbImageByReportId,
  getSampleThermalImageByReportId,
  getSamplesByReportId,
  getSampleByReportId,
} from './samples';
import { getSolarPanelsByReportId } from './solarPanels';

export const reports = {
  getReportByInspectionId,
  getAnomaliesByReportId,
  getSampleAnomalyMaskByReportId,
  getSampleSegmentationMaskByReportId,
  getSampleRgbImageByReportId,
  getSampleThermalImageByReportId,
  getSamplesByReportId,
  getSampleByReportId,
  getSolarPanelsByReportId,
};
