import { FC, memo } from 'react';
import cn from 'classnames';
import { MAX_SITE_NAME_LENGTH } from '@core/constants/validation';
import { useRenderInputError } from '@core/hooks';
import styles from './styles.scss';

interface IProps {
  text: {
    placeholder: string;
    notification: string;
  };
  value: string;
  errorMessage?: string | null;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const NameBody: FC<IProps> = ({ text, value, errorMessage = null, onChange }) => {
  const shouldRenderError = useRenderInputError(value, errorMessage);

  return (
    <div className={styles.root}>
      <h2 className={styles.notification}>{text.notification}</h2>
      <div className={styles.inputWrapper}>
        <input
          className={styles.nameInput}
          name='addSiteName'
          placeholder={text.placeholder}
          autoComplete='off'
          value={value}
          onChange={onChange}
          max={MAX_SITE_NAME_LENGTH}
        />
        <span
          className={cn(styles.errorMessage, shouldRenderError ? styles.visible : styles.hidden)}
        >
          {errorMessage}
        </span>
      </div>
    </div>
  );
};

export default memo(NameBody);
