import { ESidebar } from '@core/enums';
import { ISample } from '@core/interfaces';
import { drawableOptions } from './drawableConfig';
import { getSampleFrustumVertices, getSamplePoseVertices } from './getVertices';
import { EModelSamplesLayer } from '../../enums/layers';

export const drawSamples = (
  samples: ISample[],
  sidebar: ESidebar,
  selectedSample?: ISample | null,
) => {
  const drawables: any[] = [];

  samples.forEach((sample: ISample, index: number) => {
    const vertices = getSamplePoseVertices(sample);
    const frustumVertices = getSampleFrustumVertices(sample);
    const isExtremeSampleIndex = index === 0 || index === samples.length - 1;

    switch (sidebar) {
      case ESidebar.Zone: {
        if (!isExtremeSampleIndex) {
          const flightPathSamplesOptions = drawableOptions[EModelSamplesLayer.FlightPathSamples]({
            vertices,
            sampleId: sample.id,
            selectedSampleId: selectedSample?.id,
          });
          drawables.push(flightPathSamplesOptions);
        }
        break;
      }
      case ESidebar.Anomaly: {
        const anomalySamplesOptions = drawableOptions[EModelSamplesLayer.AnomalySamples]({
          vertices,
          sampleId: sample.id,
          selectedSampleId: selectedSample?.id,
        });
        drawables.push(anomalySamplesOptions);

        const isSelectedSample = selectedSample?.id === sample.id;

        // set frustum options for selected sample
        if (isSelectedSample) {
          const frustumWireframeOptions = drawableOptions[EModelSamplesLayer.FrustumWireframe]({
            vertices: [...frustumVertices, ...vertices],
            sampleId: sample.id,
            selectedSampleId: selectedSample?.id,
          });

          const frustumOptions = drawableOptions[EModelSamplesLayer.Frustum]({
            vertices: [...frustumVertices, ...vertices],
            sampleId: sample.id,
            selectedSampleId: selectedSample?.id,
          });

          drawables.push(frustumWireframeOptions, frustumOptions);
        }
        break;
      }
    }
  });

  return drawables;
};
