import mapboxgl from 'mapbox-gl';
import { TITILER_URL } from '@core/constants';
import { ESidebar } from '@core/enums';
import { IReport } from '@core/interfaces/report';
import { generateRasterTileLayerId, generateRasterTileSourceId } from './generateId';
import { setOrthoTiles } from './setOrthoTiles';
import { EZonesLayers } from '../../enums/layers';
import { removeLayersAndSources } from '../removeLayersAndSources';

interface IReportToOrtho {
  map: mapboxgl.Map;
  sidebar: ESidebar;
  report: IReport;
}

export function addReportToOrthoTiles({ map, sidebar, report }: IReportToOrtho) {
  const rasterTilesSourceId = generateRasterTileSourceId(report);
  const rasterTilesLayerId = generateRasterTileLayerId(report);

  const encodedPath = encodeURIComponent(report.ortho_vsi_path);
  const orthoTileUrl = `${TITILER_URL}/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=${encodedPath}`;

  switch (sidebar) {
    case ESidebar.Sites:
      removeLayersAndSources(map, {
        [rasterTilesSourceId]: [rasterTilesLayerId],
      });
      break;
    case ESidebar.Site:
    case ESidebar.Zone:
    case ESidebar.Anomaly: {
      const shouldSetOrtho =
        map.getLayer(EZonesLayers.Polygon) &&
        !map.getSource(rasterTilesSourceId) &&
        !map.getLayer(rasterTilesLayerId);

      if (shouldSetOrtho) {
        setOrthoTiles(map, {
          orthoTileUrl,
          sourceId: rasterTilesSourceId,
          layerId: rasterTilesLayerId,
        });
      }
      break;
    }
  }
}
