import { nonTenantAxiosInstance } from '@core/api/axios';

export interface IRestorePasswordResponse {
  message: string;
}

export const restorePassword = async (email: string) => {
  return nonTenantAxiosInstance
    .post<IRestorePasswordResponse>(`users/restore_password`, null, {
      params: { email },
    })
    .then((response) => response.data);
};
