import { useRef, useEffect } from 'react';
import { isObject, isArray, isFunction } from '@core/utils';

/**
 * Tracks previous state of a value.
 *
 * @param value Props, state or any other calculated value.
 * @returns a new value
 * if this one is referenced value (any object) and doesn't equal previous one
 * else previous value.
 */

export function useCachedValue<T>(value: T) {
  const ref = useRef(value);

  useEffect(() => {
    const referenceGuards = [isObject, isArray, isFunction];

    const isReferencedValue = referenceGuards.some((guard) => guard(value));
    const isReferencedRef = referenceGuards.some((guard) => guard(ref.current));

    if (isReferencedValue && isReferencedRef) {
      const isChangedValue = JSON.stringify(ref.current) !== JSON.stringify(value);

      if (isChangedValue) {
        ref.current = value;
      }
    } else {
      ref.current = value;
    }
  }, [value]);

  return ref.current;
}
