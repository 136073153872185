export enum EViewer {
  Map = 'Map',
  CompareMap = 'CompareMap',
  Model = 'Model',
  Video = 'Video',
  Image = 'Image',
}

export enum EViewerType {
  Viewer = 'viewer',
  InspectionView = 'inspectionView',
}

export enum EMapViewerActive {
  Viewer = 'viewer',
  Sidebar = 'sidebar',
}
