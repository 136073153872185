import { FC } from 'react';
import { ESidebar } from '@core/enums';
import { useSidebarSelector } from '@core/store/slices';
import { useVisibleInspectionsSelector } from '@core/store/slices/inspections';
import { IUpdateScrollPosition } from '@modules/Layout/components/Header/interfaces';
import { MainView } from './views/Main/MainView';
import { NoInspectionsView } from './views/NoInspections/NoInspectionsView';

interface IProps {
  onSetScrollPosition: (handler: (options: IUpdateScrollPosition) => void) => void;
}

export const Timeline: FC<IProps> = ({ onSetScrollPosition }) => {
  const visibleInspections = useVisibleInspectionsSelector();
  const { sidebar } = useSidebarSelector();

  const isNoInspectionsView = !visibleInspections.length || sidebar === ESidebar.CreateSite;

  return isNoInspectionsView ? (
    <NoInspectionsView />
  ) : (
    <MainView onSetScrollPosition={onSetScrollPosition} />
  );
};
