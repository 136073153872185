import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EInspectionFrequency } from '@core/enums';
import { useDispatchTyped, useSelectorTyped } from '@core/hooks';
import { ISelectOption } from '@core/interfaces';
import { setInspectionFrequency } from '@core/store/slices';
import { convertTheFirstCharToLowerCase } from '@core/utils/formatting/stringFormat';
import InspectionFrequencyBody from './InspectionFrequencyBody';

type TInspectionFrequencyState = {
  id: number;
  name: ISelectOption['name'];
};

const InspectionFrequencyCreate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatchTyped();
  const inspectionFrequency = useSelectorTyped((state) => state.createSite.inspectionFrequency);

  const [selectedDate, setSelectedDate] = useState<TInspectionFrequencyState | null>(null);

  const options = useMemo(
    () => [
      {
        id: EInspectionFrequency.Monthly,
        name: t(`sidebar.site.edit.sections.inspectionFrequency.dates.monthly`),
      },
      {
        id: EInspectionFrequency.HalfYearly,
        name: t(`sidebar.site.edit.sections.inspectionFrequency.dates.halfYearly`),
      },
      {
        id: EInspectionFrequency.Yearly,
        name: t(`sidebar.site.edit.sections.inspectionFrequency.dates.yearly`),
      },
    ],
    [],
  );

  const handleSelectChange = useCallback((option: ISelectOption | null) => {
    if (!option) return;
    dispatch(setInspectionFrequency(Number(option.id)));
  }, []);

  useEffect(() => {
    if (inspectionFrequency) {
      const foundFrequency = Object.entries(EInspectionFrequency).find(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([_, value]) => inspectionFrequency === value,
      );

      if (foundFrequency) {
        const key = foundFrequency[0];
        setSelectedDate({
          id: inspectionFrequency,
          name: t(
            `sidebar.site.edit.sections.inspectionFrequency.dates.${convertTheFirstCharToLowerCase(
              key,
            )}`,
          ),
        });
      }
    }
  }, [inspectionFrequency]);

  return (
    <>
      <InspectionFrequencyBody
        text={{
          notification: t('sidebar.site.edit.sections.inspectionFrequency.notification'),
        }}
        selected={selectedDate}
        options={options}
        onSelect={handleSelectChange}
      />
    </>
  );
};

export default InspectionFrequencyCreate;
