import i18n from 'i18next';
import { object, string } from 'yup';
import { ELoginMode } from '@core/enums/accessControl';

export const getValidationSchema = (mode: ELoginMode) => {
  if (mode === ELoginMode.ForgetPassword) {
    return object({ username: string().required(String(i18n.t('errors.emailRequired'))) });
  }

  object({
    username: string().required(String(i18n.t('errors.emailRequired'))),
    password: string().required(String(i18n.t('errors.passwordRequired'))),
  });
};
