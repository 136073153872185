import { restorePassword } from './passwords';
import { getAccessToken, getRefreshToken } from './tokens';
import { addNewUsers, inviteUsers, usersMe } from './users';

export const user = {
  usersMe,
  getAccessToken,
  getRefreshToken,
  restorePassword,
  addNewUsers,
  inviteUsers,
};
