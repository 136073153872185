import { ECookiesField } from '@core/constants';
import { DataProcessing } from '@core/services/processing';
import { cookiesStorage } from '@core/services/storage';
import { IPersistPermission } from '@core/services/storage/interfaces/permissions';
import { isString } from '@core/utils';

export function getPermissionToRehydratePersistedState(): IPersistPermission | null {
  const storedPermissionCookie = cookiesStorage.getItem(ECookiesField.StoragePersistPermission);

  const deserializedPersistPermission = isString(storedPermissionCookie)
    ? DataProcessing.deserialize(storedPermissionCookie)
    : null;

  return deserializedPersistPermission;
}

export function resetPermissionToRehydratePersistedState() {
  cookiesStorage.removeItem(ECookiesField.StoragePersistPermission);
}
