import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import { ThreeEvent, useThree } from '@react-three/fiber';
import _ from 'lodash';
import * as THREE from 'three';
import type { OrbitControls as TOrbitControls } from 'three-stdlib';
import { EMapViewerActive, EViewerType } from '@core/enums';
import { ICamera } from '@core/interfaces/model';
import { areAllValuesValid } from '@core/utils';
import { useMainContext } from '@modules/Layout/contexts/main';
import BackgroundStream from './BackgroundStream';
import CameraExport from './CameraExport';
import { useModelViewerContext } from '../../../contexts/modelViewer';
import { ICameraProps } from '../../../interfaces/camera';
import { IDrawable } from '../../../interfaces/drawable';

interface IProps {
  width: number;
  height: number;
  imageSrc: string;
  access_token: string | null;
  reservation_token: string | null;
  server_host: string | null;
  server_port: number;
  server_tls: boolean;
  ice_servers: RTCIceServer[];
  initCamera: ICamera | null;
  drawables: IDrawable[];
  cameraProps: ICameraProps | null;
  nextCameraCenter: any;
  hide_drawables: boolean;
  onCameraChange: (camera: ICamera) => void;
  onImageClick: (event: ThreeEvent<MouseEvent>) => void;
  onImageDoubleClick: (event: ThreeEvent<MouseEvent> | null) => void;
}

const Scene: React.FC<IProps> = ({
  width,
  height,
  imageSrc,
  server_host,
  server_port,
  server_tls,
  access_token,
  reservation_token,
  ice_servers,
  initCamera,
  drawables,
  cameraProps,
  nextCameraCenter,
  hide_drawables,
  onCameraChange,
  onImageClick,
  onImageDoubleClick,
}) => {
  const { type, activeViewer } = useModelViewerContext();
  const {
    model: { viewerOrbitControl, sidebarOrbitControl },
  } = useMainContext();
  const [controlsInit, setControlsInit] = useState<ICamera | null>(null);
  const controlsRef = type === EViewerType.Viewer ? viewerOrbitControl : sidebarOrbitControl;

  const activeViewerRef = useRef<EMapViewerActive | null>(null);
  activeViewerRef.current = activeViewer;

  const { camera, raycaster } = useThree();
  raycaster.firstHitOnly = true;

  useEffect(() => {
    const handleResetEvent = () => {
      if (controlsRef.current && initCamera) {
        camera.up.set(initCamera.up.x, initCamera.up.y, initCamera.up.z);
        camera.position.set(initCamera.eye.x, initCamera.eye.y, initCamera.eye.z);
        controlsRef.current.target.set(
          initCamera.center.x,
          initCamera.center.y,
          initCamera.center.z,
        );
        controlsRef.current.update();
      }
    };

    window.addEventListener('reset_camera', handleResetEvent);
    return () => {
      window.removeEventListener('reset_camera', handleResetEvent);
    };
  }, [controlsRef, camera, initCamera]);

  useEffect(() => {
    if (initCamera && (!controlsInit || !_.isEqual(controlsInit, initCamera))) {
      camera.up.set(initCamera.up.x, initCamera.up.y, initCamera.up.z);
      camera.position.set(initCamera.eye.x, initCamera.eye.y, initCamera.eye.z);

      if (controlsRef.current) {
        controlsRef.current.target.set(
          initCamera.center.x,
          initCamera.center.y,
          initCamera.center.z,
        );

        controlsRef.current.update();
        controlsRef.current.saveState();
      }

      setControlsInit(initCamera);
    }
  }, [camera, initCamera]);

  useEffect(() => {
    if (controlsRef.current && nextCameraCenter !== null) {
      const point = new THREE.Vector3(nextCameraCenter.x, nextCameraCenter.y, nextCameraCenter.z);
      onImageDoubleClick(null);

      controlsRef.current.target.set(point.x, point.y, point.z);
      controlsRef.current.update();
    }
  }, [nextCameraCenter]);

  const handleSynchronizeScenes = useCallback(
    (currentOrbitControl: TOrbitControls, synchronizedOrbitControl: TOrbitControls) => {
      synchronizedOrbitControl.target.copy(currentOrbitControl.target);
      synchronizedOrbitControl.object.position.copy(currentOrbitControl.object.position);
      synchronizedOrbitControl.object.rotation.copy(currentOrbitControl.object.rotation);
      synchronizedOrbitControl.object.zoom = currentOrbitControl.object.zoom;

      synchronizedOrbitControl.update();
    },
    [],
  );

  const handleSynchronizeControls = useCallback(
    _.debounce(() => {
      const shouldSynchronizeInspectionViewSceneWithViewerOne = areAllValuesValid(
        type === EViewerType.InspectionView,
        activeViewerRef.current === EMapViewerActive.Sidebar,
      );

      const shouldSynchronizeViewerSceneWithInspectionViewOne = areAllValuesValid(
        type === EViewerType.Viewer,
        activeViewerRef.current === EMapViewerActive.Viewer,
      );

      if (
        viewerOrbitControl.current &&
        controlsRef.current &&
        shouldSynchronizeInspectionViewSceneWithViewerOne
      ) {
        handleSynchronizeScenes(
          controlsRef.current as TOrbitControls,
          viewerOrbitControl.current as TOrbitControls,
        );
      }

      if (
        sidebarOrbitControl.current &&
        controlsRef.current &&
        shouldSynchronizeViewerSceneWithInspectionViewOne
      ) {
        handleSynchronizeScenes(
          controlsRef.current as TOrbitControls,
          sidebarOrbitControl.current as TOrbitControls,
        );
      }
    }, 200),
    [],
  );

  useEffect(() => {
    controlsRef.current?.addEventListener('change', handleSynchronizeControls);

    return () => {
      controlsRef.current?.removeEventListener('change', handleSynchronizeControls);
    };
  }, [controlsRef.current]);

  useEffect(() => {
    // NOTE: use case (prevent synchronize in a background):
    // mounted "inspectionView" scene sometimes impacts Viewer
    if (
      (type === EViewerType.Viewer && activeViewerRef.current === EMapViewerActive.Viewer) ||
      (type === EViewerType.InspectionView && activeViewerRef.current === EMapViewerActive.Sidebar)
    ) {
      handleSynchronizeControls();
    }
  }, [type, activeViewerRef.current]);

  return (
    <>
      <BackgroundStream
        width={width}
        height={height}
        src={imageSrc}
        server_host={server_host}
        access_token={access_token}
        reservation_token={reservation_token}
        server_port={server_port}
        server_tls={server_tls}
        ice_servers={ice_servers}
        drawables={drawables}
        onImageClick={onImageClick}
        onImageDoubleClick={onImageDoubleClick}
        hide_drawables={hide_drawables}
      />
      <OrbitControls ref={controlsRef} enableDamping={false} />
      <PerspectiveCamera makeDefault={true} {...cameraProps} />
      <CameraExport ref={controlsRef} onChange={onCameraChange} />
    </>
  );
};

export default memo(Scene);
