import { DEFAULT_SITE_NAME, ECreateSiteSteps } from '@core/constants/createSite';
import { ICreateSiteInitialState } from '@core/store/slices';

export const getMaxStepForContinueSiteCreation = (
  site: ICreateSiteInitialState,
  persistedStep?: number,
) => {
  let step = ECreateSiteSteps.Zones;

  if (!site.programs || site.programs.length === 0) step = ECreateSiteSteps.Roles;
  if (!site.inspectionFrequency) step = ECreateSiteSteps.Picture;

  if (!site.image && !site.imageUrl) {
    if (persistedStep) {
      step = persistedStep;
    } else {
      if (site.perimeters.length) step = ECreateSiteSteps.Perimeter;
      if (site.noFlyZones.length) step = ECreateSiteSteps.NoFlyZones;
      if (site.obstacles.length) step = ECreateSiteSteps.Obstacles;
    }
  }

  if (!site.name || site.name === DEFAULT_SITE_NAME) step = ECreateSiteSteps.Name;
  if (!site.perimeters || !site.perimeters.length) step = ECreateSiteSteps.SearchCity;

  if (site.isLoadingZones && step < ECreateSiteSteps.Picture) step = ECreateSiteSteps.Picture;
  if (site.programs.length) step = ECreateSiteSteps.Zones;

  return step;
};
