import { IAnomaly } from '@core/interfaces/anomaly';
import { getColor } from '@core/utils';
import { ISolarPanelsDrawableOptions, drawableOptions } from './drawableConfig';
import { generateSolarPanelId, generateSolarPanelOutlineId } from './generateId';
import { EModelSolarPanelsLayer } from '../../enums/layers';
import { IAnomalySolarPanel, ISolarPanel } from '../../interfaces/solarPanel';
import { getVertices } from '../common/getVertices';

type TransformedAnomalySolarPanel = {
  [solarPanelId: string]: IAnomalySolarPanel;
};

interface IProps {
  solarPanels: ISolarPanel[];
  anomalies: IAnomalySolarPanel[];
  hasSolarPanels: boolean;
  currentAnomaly?: IAnomaly;
}

export const drawSolarPanels = ({
  solarPanels,
  anomalies,
  hasSolarPanels,
  currentAnomaly,
}: IProps) => {
  const drawables: ISolarPanelsDrawableOptions[] = [];

  // NOTE: for optimization
  const transformedAnomalies = Object.values(anomalies).reduce<TransformedAnomalySolarPanel>(
    (acc, value) => ({
      ...acc,
      [value.solarPanelId]: value,
    }),
    {},
  );

  let panelDrawableOptions = drawableOptions[EModelSolarPanelsLayer.Panel]({
    hasSolarPanels,
  });
  let outlineDrawableOptions = drawableOptions[EModelSolarPanelsLayer.Outline]();

  for (const { id, enu } of solarPanels) {
    const vertices = getVertices(enu);
    const anomaly = transformedAnomalies[id];

    if (anomaly) {
      // Selected Anomaly
      if (currentAnomaly) {
        const isSelectedAnomaly = currentAnomaly.id === anomaly.id;

        if (isSelectedAnomaly) {
          outlineDrawableOptions.color = getColor('--outflier-blue');
          outlineDrawableOptions.line_width = 0.005;
        } else {
          panelDrawableOptions.alpha = 0.6;
          outlineDrawableOptions.alpha = panelDrawableOptions.alpha;
        }
      }
    } else {
      // Solar panel without anomaly
      panelDrawableOptions.alpha = hasSolarPanels ? 1 : 0;
      outlineDrawableOptions.alpha = panelDrawableOptions.alpha;
    }

    const solarPanelOptions = {
      ...panelDrawableOptions,
      id: generateSolarPanelId(id, anomaly),
      vertices,
      color: anomaly?.color ?? panelDrawableOptions.color,
    };

    const solarPanelOutlineOptions = {
      ...outlineDrawableOptions,
      id: generateSolarPanelOutlineId(id),
      vertices,
    };

    drawables.push(solarPanelOptions, solarPanelOutlineOptions);

    panelDrawableOptions = drawableOptions[EModelSolarPanelsLayer.Panel]({ hasSolarPanels });
    outlineDrawableOptions = drawableOptions[EModelSolarPanelsLayer.Outline]();
  }

  return drawables;
};
