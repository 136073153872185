import React from 'react';
import cn from 'classnames';
import { EAnomalyStatus } from '@core/interfaces/anomaly';

interface IProps {
  status: EAnomalyStatus | undefined;
  isReportProcessing?: string;
}

const StatusCircle: React.FC<IProps> = ({
  status = EAnomalyStatus.ToReview,
  isReportProcessing,
}) => {
  const circleClassName = cn('h-[10px] w-[10px] min-h-[10px] min-w-[10px] rounded-full', {
    'bg-outflier-red': status === EAnomalyStatus.Major && !isReportProcessing,
    'bg-outflier-yellow': status === EAnomalyStatus.ToReview && !isReportProcessing,
    'bg-outflier-green': status === EAnomalyStatus.False && !isReportProcessing,
    'bg-outflier-white': status === EAnomalyStatus.Minor && !isReportProcessing,
    'bg-outflier-blue': isReportProcessing,
  });

  return <div className={circleClassName}></div>;
};

export default StatusCircle;
