import { PropsWithChildren, createContext } from 'react';
import { EViewer } from '@core/enums';
import { CompareMapViewer } from '@modules/Viewers/views/CompareMapViewer';
import { ImageViewer } from '@modules/Viewers/views/ImageViewer';
import { MapViewer } from '@modules/Viewers/views/MapViewer';
import { ModelViewer } from '@modules/Viewers/views/ModelViewer';
import { VideoViewer } from '@modules/Viewers/views/VideoViewer';

export interface IViewerInitialState {
  name: EViewer;
  component: (props?: any) => JSX.Element;
}

const initialState: IViewerInitialState[] = [
  { name: EViewer.Map, component: (props?) => <MapViewer {...props} /> },
  {
    name: EViewer.CompareMap,
    component: (props?) => <CompareMapViewer {...props} />,
  },
  { name: EViewer.Image, component: (props?) => <ImageViewer {...props} /> },
  { name: EViewer.Video, component: (props?) => <VideoViewer {...props} /> },
  { name: EViewer.Model, component: (props?) => <ModelViewer {...props} /> },
];

const ViewersContext = createContext(initialState);

const ViewersProvider = ({ children }: PropsWithChildren) => {
  return <ViewersContext.Provider value={initialState}>{children}</ViewersContext.Provider>;
};

export { ViewersContext, ViewersProvider };
