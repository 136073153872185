import { ESiteStatuses } from '@core/enums';
import { ISite } from '@core/interfaces';

export const sortSitesByStatus = (sites: ISite[]): ISite[] => {
  if (!sites.length) return [];

  const issues = sites.filter((site) => site.status === ESiteStatuses.Issues);
  const inspected = sites.filter((site) => site.status === ESiteStatuses.Inspected);
  const normal = sites.filter((site) => site.status === ESiteStatuses.Normal);
  const reportProcessing = sites.filter((site) => site.status === ESiteStatuses.ReportProcessing);

  return [...reportProcessing, ...issues, ...inspected, ...normal];
};
