import { nonTenantAxiosInstance } from '@core/api/axios';

export interface IRefreshTokenResponse {
  access_token: string;
  token_type: string;
}

export const getRefreshToken = async () => {
  return nonTenantAxiosInstance
    .post<IRefreshTokenResponse>('token/refresh')
    .then(({ data }) => data);
};
