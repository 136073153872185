import React from 'react';
import cn from 'classnames';
import styles from './styles.scss';

interface IProps {
  icon?: string;
  text?: string;
  active?: boolean;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
}

export const Control: React.FC<IProps> = ({ text, icon, active, className, onClick, disabled }) => {
  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  return (
    <div
      className={cn(styles.control, className, {
        [styles.active]: active,
        [styles.disabled]: disabled,
      })}
      onClick={handleClick}
    >
      {icon ? <img src={icon} alt='control icon' /> : <span>{text}</span>}
    </div>
  );
};
