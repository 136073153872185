import { FC } from 'react';
import { Portal } from '@headlessui/react';
import { ActualProgressBar, ProgressOverlay } from '@components/ProgressBar';

interface IProps {
  isHidden: boolean;
  percentage: number;
}

export const SitePerimeterProgressBar: FC<IProps> = ({ isHidden, percentage }) => {
  return (
    <Portal>
      <ProgressOverlay isHidden={isHidden} text={'Calculating\nSite'}>
        <ActualProgressBar percentage={percentage} isTextHidden />
      </ProgressOverlay>
    </Portal>
  );
};
