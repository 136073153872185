import React from 'react';
import { ReactSVG } from 'react-svg';
import windTurbine from '@assets/icons/wind-turbine.svg';

interface IProps {
  children: React.ReactNode;
  isHidden: boolean;
  text?: string;
}

export const ProgressOverlay: React.FC<IProps> = ({ isHidden, children, text = 'Loading' }) => {
  if (isHidden) return null;

  const textLines = text.split('\n').map((line, index) => <span key={index}>{line}</span>);

  return (
    <div className='absolute top-0 left-0 h-full w-full z-10 bg-black bg-opacity-80 flex items-center justify-center'>
      <div className='bg-outflier-contain-background flex flex-col w-[250px] p-4 pb-5 items-center rounded-[3px]'>
        <ReactSVG src={windTurbine} className='w-[135px]' />
        <div className='h-[80px] text-white text-xl flex flex-col leading-6 items-center'>
          {textLines}
        </div>
        {children}
      </div>
    </div>
  );
};
