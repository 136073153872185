import React, { memo } from 'react';
import { Table, TableBody, TableHead, TableRow } from '@components/Table';
import styles from './styles.scss';
import { ITableBodyColumn, ITableHeadColumn } from '../../interfaces/table';

interface IProps {
  head: ITableHeadColumn[];
  body: ITableBodyColumn[];
  onRowClick: (id: string, name: string) => void;
}

export const ZonesTable: React.FC<IProps> = ({ head, body, onRowClick }) => {
  return (
    <Table className={styles.table}>
      <TableHead className={styles.tableHead}>
        <TableRow className={styles.tableHeadRow}>
          {head.map(({ name, className }) => (
            <th key={name} className={className}>
              {name}
            </th>
          ))}
        </TableRow>
      </TableHead>
      <TableBody className={styles.tableBody}>
        {body.map(({ data, id, name }) => (
          <TableRow key={id} className={styles.tableBodyRow} onClick={() => onRowClick(id, name)}>
            {data.map(({ id, name, formattedName, className }) => (
              <td key={id} className={className}>
                <span>{formattedName ?? name}</span>
              </td>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default memo(ZonesTable);
