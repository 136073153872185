import { TAxiosInstance } from '@core/api';
import { OUTFLIER_ACCESS_TOKEN } from '@core/constants';
import { browserLocalStorage } from '@core/services/storage';

export const accessTokenInterceptor = (axiosInstance: TAxiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      if (['token'].includes(String(config.url))) {
        return config;
      }

      const accessToken = browserLocalStorage.getItem(OUTFLIER_ACCESS_TOKEN);
      if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;

      return config;
    },
    (error) => Promise.reject(error),
  );
  axiosInstance.interceptors.response.use(
    (response) => {
      if (response.config.url === 'token' && response.data.access_token) {
        browserLocalStorage.setItem(OUTFLIER_ACCESS_TOKEN, response.data.access_token);
      }
      return response;
    },
    async (error) => {
      return Promise.reject(error);
    },
  );
};
