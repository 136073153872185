import { api } from '@core/api';
import { IProgram } from '@core/interfaces';

export const getProgramsWithFlightPlan = async (programs: IProgram[]): Promise<IProgram[]> => {
  return (
    await Promise.all(
      programs.map(async (program) => {
        try {
          const plans = await api.planning.getListPlanIds({
            loc_id: String(program.location_id),
            program_id: program.program_id,
            regex_name: '',
          });

          const fetchedFlightPlan = await Promise.all(
            plans.map(async ({ plan_id }) => (await api.planning.getFlightPlans(plan_id)).plan),
          );
          const flattenedFlightPlan = fetchedFlightPlan.flat();

          return { ...program, flightPlan: flattenedFlightPlan };
        } catch (err) {
          console.error(err);
        }
      }),
    )
  ).filter(Boolean) as IProgram[];
};
