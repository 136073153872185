import React, { useEffect, useState } from 'react';
import { IInspection } from '@core/interfaces';

export const useScrollPosition = (
  ref: React.RefObject<HTMLDivElement>,
  inspections: IInspection[],
) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    if (ref.current) {
      const maxScrollLeft = ref.current.scrollWidth - ref.current.clientWidth;
      ref.current.scrollTo({
        left: maxScrollLeft,
      });
      setScrollPosition(maxScrollLeft);
    }
  }, [inspections, ref.current?.scrollWidth]);

  return [scrollPosition, setScrollPosition] as const;
};
