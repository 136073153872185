import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelectorTyped } from '@core/hooks';

interface IInitialState {
  video: string;
  toCompareVideo: string;
}

const initialState: IInitialState = {
  video: '',
  toCompareVideo: '',
};

const viewerSlice = createSlice({
  name: 'viewer',
  initialState,
  reducers: {
    setVideo: (state, actions: PayloadAction<string>) => {
      state.video = actions.payload;
    },
    setToCompareVideo: (state, actions: PayloadAction<string>) => {
      state.toCompareVideo = actions.payload;
    },
    resetToCompareVideo: () => initialState,
    resetVideos: (state) => {
      (state.video = ''), (state.toCompareVideo = '');
    },
  },
});

const videoReducer = viewerSlice.reducer;

const { setVideo, setToCompareVideo, resetVideos } = viewerSlice.actions;

const useVideoSelector = () => useSelectorTyped((state) => state.video);

export {
  setVideo,
  videoReducer,
  useVideoSelector,
  setToCompareVideo,
  resetVideos,
  initialState as videoInitialState,
};
