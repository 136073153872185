import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import cn from 'classnames';
import { ERROR_TOAST_DELAY } from '@core/constants';
import { ERoles } from '@core/enums';
import { useDispatchTyped } from '@core/hooks';
import { IComment } from '@core/interfaces';
import { setPreloader, useAccessControlSelector } from '@core/store/slices';
import { Comment } from '@components/CommentSection/Comment';
import { Textarea } from '@components/Textarea';
import styles from './styles.scss';

interface IProps {
  targetId: number | string | undefined;
  onGetComments: () => Promise<IComment[]>;
  onSendComment: (text: string | undefined) => Promise<any>;
  onUpdateComment: (commentId: number, text: string | undefined) => Promise<any>;
  onDeleteComment: (value: number) => Promise<any>;
  allowedToCommentRoles?: ERoles[];
}

const CommentSectionBody: React.FC<IProps> = ({
  targetId,
  onGetComments,
  onSendComment,
  onUpdateComment,
  onDeleteComment,
  allowedToCommentRoles = [ERoles.Editor],
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatchTyped();
  const [comments, setComments] = useState<IComment[]>([]);
  const { user } = useAccessControlSelector();
  const isAllowedToComment =
    user?.roles.some((role) => allowedToCommentRoles.includes(role as ERoles)) || false;

  useEffect(() => {
    handleRefreshComments();
  }, [targetId]);

  const handleCommentSend = (text: string | undefined) => {
    dispatch(setPreloader(true));
    (async () => {
      try {
        await onSendComment(text);
        await handleRefreshComments();
      } catch {
        toast.error(t('errors.commentSendFail'), { autoClose: ERROR_TOAST_DELAY });
      } finally {
        dispatch(setPreloader(false));
      }
    })();
  };

  const handleCommentDelete = (commentId: number) => {
    dispatch(setPreloader(true));
    (async () => {
      try {
        await onDeleteComment(commentId);
        await handleRefreshComments();
      } catch {
        toast.error(t('errors.commentDeleteFail'), { autoClose: ERROR_TOAST_DELAY });
      } finally {
        dispatch(setPreloader(false));
      }
    })();
  };

  const handleRefreshComments = async () => {
    try {
      const comments = await onGetComments();
      setComments(comments);
    } catch {
      toast.error(t('errors.commentsFetchFail'), { autoClose: ERROR_TOAST_DELAY });
    }
  };

  return (
    <div className={styles.comments}>
      <div className={styles.commentsTitle}>{t('sidebar.comments')}</div>
      <div className={cn(styles.commentsItems)}>
        {comments.map((comment, index) => (
          <Comment
            key={comment.id}
            comment={comment}
            onUpdate={onUpdateComment}
            onDelete={() => handleCommentDelete(comment.id)}
            className={index === 0 ? styles.roundedTop : ''}
          />
        ))}
      </div>
      {isAllowedToComment && (
        <Textarea
          className={cn(
            styles.roundedBottom,
            styles.foo,
            { [styles.roundedTop]: comments?.length === 0 },
            styles.textareaWrapper,
          )}
          placeholder={String(t('sidebar.addComment'))}
          onSend={handleCommentSend}
        />
      )}
    </div>
  );
};

export default CommentSectionBody;
