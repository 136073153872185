import { FeatureCollection } from 'geojson';
import { siteStatusesColor } from '@core/constants';
import { ISite } from '@core/interfaces';

export const getSitesFeatureCollection = (sites: ISite[]): FeatureCollection => ({
  type: 'FeatureCollection',
  features: sites.map((site) => ({
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [site.long, site.lat],
    },
    properties: {
      type: site.status ?? site.location_status,
      color: site.status
        ? siteStatusesColor[site.status]
        : site.location_status
        ? siteStatusesColor[site.location_status]
        : null,
      loc_id: site?.loc_id,
    },
  })),
});
