import React, { ButtonHTMLAttributes } from 'react';
import cn from 'classnames';
import styles from './styles.scss';

export enum EButtonColor {
  Blue = 'blue',
  Yellow = 'yellow',
  Red = 'red',
  Green = 'green',
}

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: EButtonColor;
}

export const Button: React.FC<IProps> = ({ children, color = 'blue', className, ...props }) => {
  const buttonClasses = cn(
    styles.button,
    'text-sm h-[45px] w-full rounded-[3px] disabled:hover:opacity-100',
    {
      'bg-outflier-blue text-[#FDFDFD]': color === 'blue',
      'bg-outflier-green hover:!bg-outflier-green hover:!opacity-70 text-[#FDFDFD]':
        color === 'green',
      'bg-outflier-yellow text-[#FDFDFD]': color === 'yellow',
      'bg-outflier-red hover:!bg-outflier-red hover:!opacity-70 text-[#FDFDFD]': color === 'red',
    },
    className,
  );

  return (
    <button className={buttonClasses} {...props}>
      {children}
    </button>
  );
};
