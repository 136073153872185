import cn from 'classnames';
import { ESidebar } from '@core/enums';
import { useNetworkConnectionSelector, useSidebarSelector } from '@core/store/slices';
import CreateSite from '@modules/Sidebar/views/CreateSite/SiteCreation';
import styles from './styles.scss';
import { Anomaly, Site, Sites, Zone } from './views';
import EditSite from './views/EditSite/EditSite';

interface IProps {
  toCompare?: boolean;
}

export const Sidebar = ({ toCompare = false }: IProps) => {
  const { sidebar } = useSidebarSelector();
  const { isVisible: isVisibleNotification } = useNetworkConnectionSelector();

  const views = {
    [ESidebar.Anomaly]: <Anomaly />,
    [ESidebar.Site]: <Site />,
    [ESidebar.Sites]: <Sites />,
    [ESidebar.Zone]: <Zone toCompare={toCompare} />,
    [ESidebar.CreateSite]: <CreateSite />,
    [ESidebar.EditSite]: <EditSite />,
  };

  return (
    <div className={cn(styles.container, { [styles.withNotification]: isVisibleNotification })}>
      <div className={styles.sidebar}>{views[sidebar]}</div>
    </div>
  );
};
