import * as turf from '@turf/turf';

type Centroid = turf.helpers.Feature<turf.helpers.Point, turf.helpers.Properties>;

export const getCentroids = (geojsonFeatures: any[]) =>
  geojsonFeatures.map((feature) => turf.centroid(feature));

export const getAvgLongitude = (centroids: Centroid[]) =>
  centroids.reduce((total, centroid) => total + centroid.geometry.coordinates[0], 0) /
  centroids.length;

export const getAvgLatitude = (centroids: Centroid[]) =>
  centroids.reduce((total, centroid) => total + centroid.geometry.coordinates[1], 0) /
  centroids.length;
