import * as turf from '@turf/turf';
import { Feature, Polygon, GeoJsonProperties, MultiPolygon } from 'geojson';

export const mergePolygons = (
  features: Feature<Polygon, GeoJsonProperties>[],
): Feature<Polygon | MultiPolygon, GeoJsonProperties> => {
  let polygon: Feature<Polygon | MultiPolygon, GeoJsonProperties> = features[0];

  features.slice(1).forEach((feature) => {
    const unionPolygon = turf.union(polygon, feature);

    if (unionPolygon) {
      polygon = unionPolygon;
    }
  });

  return polygon;
};
