import { FeatureCollection, Geometry, GeoJsonProperties } from 'geojson';
import { siteStatusesColor } from '@core/constants';
import { ESiteStatuses } from '@core/enums';
import { ELocationStatus } from '@core/interfaces';
import { ELocationLayers } from '../../enums/layers';
import { ELocationSources } from '../../enums/sources';

interface ISourcesStyles {
  locationsFeatureCollection: FeatureCollection<Geometry, GeoJsonProperties>;
}

type SourcesStyles = {
  [key in ELocationSources]: (deps: ISourcesStyles) => mapboxgl.AnySourceData;
};

type LayersStyles = {
  [key in ELocationLayers]: () => mapboxgl.AnyLayer;
};

export const sourcesStyles: SourcesStyles = {
  [ELocationSources.LocationsPoints]: ({ locationsFeatureCollection }: ISourcesStyles) => ({
    type: 'geojson',
    data: locationsFeatureCollection,
    cluster: true,
    clusterMaxZoom: 14,
    clusterRadius: 50,
    clusterProperties: {
      type: ['string', ['get', 'type']],
    },
  }),
};

export const layersStyles: LayersStyles = {
  [ELocationLayers.Clusters]: () => ({
    id: ELocationLayers.Clusters,
    type: 'circle',
    source: ELocationSources.LocationsPoints,
    filter: ['==', 'cluster', true],
    paint: {
      'circle-radius': 30,
      'circle-color': [
        'match',
        ['get', 'type'],
        ESiteStatuses.Normal,
        siteStatusesColor[ESiteStatuses.Normal],
        ESiteStatuses.Issues,
        siteStatusesColor[ESiteStatuses.Issues],
        ESiteStatuses.Inspected,
        siteStatusesColor[ESiteStatuses.Inspected],
        ESiteStatuses.ReportProcessing,
        siteStatusesColor[ESiteStatuses.ReportProcessing],
        ELocationStatus.UnderCreation,
        siteStatusesColor[ELocationStatus.UnderCreation],
        ELocationStatus.WaitingForApproval,
        siteStatusesColor[ELocationStatus.WaitingForApproval],
        '#0677FF',
      ],
    },
  }),
  [ELocationLayers.ClusterCount]: () => ({
    id: ELocationLayers.ClusterCount,
    type: 'symbol',
    source: ELocationSources.LocationsPoints,
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['Open Sans SemiBold'],
      'text-size': 20,
    },
    paint: {
      'text-color': '#00000f',
    },
  }),
  [ELocationLayers.UnclusteredPoint]: () => ({
    id: ELocationLayers.UnclusteredPoint,
    type: 'circle',
    source: ELocationSources.LocationsPoints,
    filter: ['!', ['has', 'point_count']],
    paint: {
      'circle-radius': 10,
      'circle-color': ['get', 'color'],
    },
  }),
};
