export enum ESocketType {
  SettingsChange = 'settingsChange',
  CameraChange = 'cameraChange',
  NextCameraCenterPixelChange = 'nextCameraCenterPixelChange',
}

export enum ESocketMessageType {
  NextCameraCenter = 'nextCameraCenter',
  InitCamera = 'initCamera',
  DatasetCameraPoses = 'datasetCameraPoses',
}
