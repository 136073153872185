import { ICamera } from '@core/interfaces/model';

export const getCameraForDolly = (dollyIn: boolean, camera: ICamera, step: number) => {
  const { center, eye, up } = camera;
  const newEye = {};
  const newCenter = {};
  const newUp = {};

  const properties = ['x', 'y', 'z'];

  properties.forEach((prop) => {
    const eyeValue = parseFloat(eye[prop]);
    const centerValue = parseFloat(center[prop]);

    if (dollyIn) {
      newEye[prop] = (1 - step) * eyeValue + step * centerValue;
    } else {
      newEye[prop] = (eyeValue - step * centerValue) / (1 - step);
    }

    newCenter[prop] = parseFloat(center[prop]);
    newUp[prop] = parseFloat(up[prop]);
  });

  return { center: newCenter, eye: newEye, up: newUp } as ICamera;
};
