import swiperImg from '@assets/images/swiper.png';

export const customizeSwiper = (zoomToAnomaly = false) => {
  const swiper = document.querySelector('.compare-swiper-vertical') as HTMLElement;
  const compareLine = document.querySelector('.mapboxgl-compare') as HTMLElement;

  const initStyles = (el: HTMLElement) => {
    if (el?.style) {
      el.style.width = '40.55px';
      el.style.height = '41.74px';
      el.style.background = `url(${swiperImg}) 0 0 / contain no-repeat`;
      el.style.borderRadius = '10%';
      el.style.boxShadow = 'none';
      el.style.left = '-19px';
    }
  };

  initStyles(swiper);
  compareLine.style.width = '4px';

  if (zoomToAnomaly) {
    swiper.style.marginTop = '20px';
  } else {
    swiper.style.marginTop = '0';
  }
};
