import { EViewerType } from '@core/enums';

type TDetectionImageFrameConfig = {
  [key in EViewerType]: {
    defaultFrameLengthInPixels: number;
    factors: {
      frameLength: number;
      strokeWidth: number;
    };
  };
};

export const detectionImageFrameConfig: TDetectionImageFrameConfig = {
  [EViewerType.Viewer]: {
    defaultFrameLengthInPixels: 8,
    factors: {
      frameLength: 0.33,
      strokeWidth: 1,
    },
  },
  [EViewerType.InspectionView]: {
    defaultFrameLengthInPixels: 2,
    factors: {
      frameLength: 0.33,
      strokeWidth: 4,
    },
  },
};

type TFrameOptions = {
  [key in EViewerType]: Array<{ id: string; className: string }>;
};

export const framesOptions: TFrameOptions = {
  [EViewerType.Viewer]: [
    {
      id: 'top-left',
      className: `absolute -top-2 -left-2 rotate-180`,
    },
    {
      id: 'top-right',
      className: `absolute -top-2 -right-2 -rotate-90`,
    },
    {
      id: 'bottom-left',
      className: `absolute -bottom-2 -left-2 rotate-90`,
    },
    {
      id: 'bottom-right',
      className: `absolute -bottom-2 -right-2`,
    },
  ],
  [EViewerType.InspectionView]: [
    {
      id: 'top-left',
      className: `absolute -top-0.5 -left-0.5 rotate-180`,
    },
    {
      id: 'top-right',
      className: `absolute -top-0.5 -right-0.5 -rotate-90`,
    },
    {
      id: 'bottom-left',
      className: `absolute -bottom-0.5 -left-0.5 rotate-90`,
    },
    {
      id: 'bottom-right',
      className: `absolute -bottom-0.5 -right-0.5`,
    },
  ],
};
