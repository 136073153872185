export enum EClientErrors {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  UnprocessableEntity = 422,
}

export enum EServerErrors {
  InternalServerError = 500,
  BadGateway = 502,
  ServiceUnavailable = 503,
}

export enum EErrorStatus {
  MaxStorageSize = 'MAX_STORAGE_SIZE',
  MissingSampleDetections = 'MISSING_SAMPLE_DETECTIONS',
  NetworkError = 'ERR_NETWORK',
}

export enum EErrorMessage {
  NetworkError = 'Network Error',
}
