import { FeatureCollection } from 'geojson';
import { EAnomalyLayers } from '../../enums/layers';
import { EAnomalySources } from '../../enums/sources';

export const layersStyles: { [key in EAnomalyLayers]: () => mapboxgl.AnyLayer } = {
  [EAnomalyLayers.Anomalies]: () => ({
    id: EAnomalyLayers.Anomalies,
    type: 'fill',
    source: EAnomalySources.Anomalies,
    paint: {
      'fill-color': ['get', 'color'],
      'fill-opacity': ['get', 'opacity'],
    },
  }),
  [EAnomalyLayers.AnomaliesBorder]: () => ({
    id: EAnomalyLayers.AnomaliesBorder,
    type: 'line',
    source: EAnomalySources.Anomalies,
    paint: {
      'line-color': ['get', 'borderColor'],
      'line-width': ['get', 'borderWidth'],
    },
  }),
};

export const setFeatureCollectionData = (anomalies: any[]): FeatureCollection => ({
  type: 'FeatureCollection',
  features: [...anomalies],
});
