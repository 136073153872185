import { Position } from '@turf/turf';

export const sanitizePolygonCoordinates = (polygonCoordinates: Position[]) => {
  return polygonCoordinates.reduce<Position[]>((acc, coordinate) => {
    let sanitizedCoordinate = coordinate;
    // NOTE: length === 3 ->  [lng, lat, alt];
    if (coordinate.length === 3) {
      sanitizedCoordinate = coordinate.slice(0, 2);
    }
    return [...acc, sanitizedCoordinate];
  }, []);
};
