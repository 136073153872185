import { getComments, createComment, updateComment, deleteComment } from './comments';
import { getSamplesByAnomalyId } from './samples';
import { updateStatus } from './status';

export const anomalies = {
  getSamplesByAnomalyId,
  getComments,
  createComment,
  updateComment,
  deleteComment,
  updateStatus,
};
