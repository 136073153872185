import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import arrowLeftLg from '@assets/icons/arrow-left-lg.svg';
import { ESidebar, ESiteEditActions, ESiteEditSections } from '@core/enums';
import { EMode } from '@core/enums/mode';
import { useDispatchTyped } from '@core/hooks';
import { TEditZone, TUpdateZonePayload } from '@core/interfaces';
import {
  deleteSiteAndPrograms,
  resetEditSite,
  setPreloader,
  setSidebar,
  updateEditSiteZones,
  updateSiteAndPrograms,
  useCurrentSiteSelector,
  useSiteEditInfoSelectedZoneSelector,
  useSiteEditInfoSelector,
} from '@core/store/slices';
import { useProgramsForCurrentLocationSelector } from '@core/store/slices/programs';
import { sliceString } from '@core/utils';
import Accordion from '@components/Accordion/Accordion';
import Modal from '@components/Modal/Modal';
import styles from './styles.scss';
import InspectionFrequencyEdit from '../../components/AccordionView/BodyOptions/InspectionFrequency/InspectionFrequencyEdit';
import NameEdit from '../../components/AccordionView/BodyOptions/Name/NameEdit';
import NoFlyZonesPanel from '../../components/AccordionView/BodyOptions/NoFlyZones/NoFlyZonesBody';
import ObstaclesPanel from '../../components/AccordionView/BodyOptions/Obstacles/ObstaclesBody';
import PerimeterPanel from '../../components/AccordionView/BodyOptions/Perimeter/PerimeterBody';
import PictureEdit from '../../components/AccordionView/BodyOptions/Picture/PictureEdit';
import RolesEdit from '../../components/AccordionView/BodyOptions/Roles/RolesEdit';
import { IUpdateInputData } from '../../components/AccordionView/BodyOptions/Roles/interfaces/updateItem';
import ZonesEdit from '../../components/AccordionView/BodyOptions/Zones/ZonesEdit';
import SelectedZone from '../../components/AccordionView/BodyOptions/Zones/components/SelectedZone/SelectedZone';
import AccordionHeadOption from '../../components/AccordionView/HeadOption/AccordionHead';
import { areAllValuesEmpty, validateEditData } from '../Site/utils/validateEditData';

export const EditSite = () => {
  const { t } = useTranslation();
  const currentSite = useCurrentSiteSelector();
  const dispatch = useDispatchTyped();
  const programsForCurrentLocation = useProgramsForCurrentLocationSelector();
  const editSite = useSiteEditInfoSelector();
  const selectedZone = useSiteEditInfoSelectedZoneSelector();
  const [isOpenDeleteSiteModal, setIsOpenDeleteSiteModal] = useState(false);

  const areDisabledSwitcherButtons = programsForCurrentLocation.length === 1;

  const isDisabledSaveButton = useMemo(() => {
    let isDisabled = false;

    if (editSite) {
      const areEditInfoValuesEmpty = areAllValuesEmpty(editSite);

      if (areEditInfoValuesEmpty) {
        isDisabled = areEditInfoValuesEmpty;
      } else {
        for (const [key, value] of Object.entries(editSite)) {
          if (key in validateEditData) {
            const hasError = validateEditData[key](value);

            if (hasError) {
              isDisabled = hasError;
              break;
            }
          }
        }
      }
    }

    return isDisabled;
  }, [editSite]);

  const editableOptions: Array<{
    head: JSX.Element;
    body: JSX.Element;
    disabled: boolean;
    id: string;
  }> = useMemo(
    () => [
      {
        id: ESiteEditSections.Name,
        disabled: false,
        head: <AccordionHeadOption title={t('sidebar.site.edit.sections.name.title')} />,
        body: <NameEdit />,
      },
      {
        id: ESiteEditSections.Perimeter,
        disabled: true,
        head: <AccordionHeadOption title={t('sidebar.site.edit.sections.perimeter.title')} />,
        body: <PerimeterPanel />,
      },
      {
        id: ESiteEditSections.NoFlyZones,
        disabled: true,
        head: <AccordionHeadOption title={t('sidebar.site.edit.sections.noFlyZones.title')} />,
        body: <NoFlyZonesPanel />,
      },
      {
        id: ESiteEditSections.Obstacles,
        disabled: true,
        head: <AccordionHeadOption title={t('sidebar.site.edit.sections.obstacles.title')} />,
        body: <ObstaclesPanel />,
      },
      {
        id: ESiteEditSections.Picture,
        disabled: false,
        head: <AccordionHeadOption title={t('sidebar.site.edit.sections.picture.title')} />,
        body: <PictureEdit />,
      },
      {
        id: ESiteEditSections.InspectionFrequency,
        disabled: false,
        head: (
          <AccordionHeadOption title={t('sidebar.site.edit.sections.inspectionFrequency.title')} />
        ),
        body: <InspectionFrequencyEdit />,
      },
      {
        id: ESiteEditSections.Roles,
        disabled: false,
        head: <AccordionHeadOption title={t('sidebar.site.edit.sections.roles.title')} />,
        body: <RolesEdit />,
      },
      {
        id: ESiteEditSections.Zones,
        disabled: false,
        head: <AccordionHeadOption title={t('sidebar.site.edit.sections.zones.title')} />,
        body: <ZonesEdit />,
      },
    ],
    [t],
  );

  const handleBackClick = useCallback(() => {
    dispatch(resetEditSite());
    dispatch(setSidebar(ESidebar.Site));
  }, [dispatch]);

  const handleSelectedZoneBack = useCallback(() => {
    dispatch(
      updateEditSiteZones({
        action: ESiteEditActions.ResetOne,
        data: {
          field: 'selectedZone',
        },
      }),
    );
  }, [dispatch]);

  const handlePreviousSelectedZone = useCallback(() => {
    dispatch(
      updateEditSiteZones({
        action: ESiteEditActions.SelectPreviousZone,
      }),
    );
  }, [dispatch]);

  const handleNextSelectedZone = useCallback(() => {
    dispatch(
      updateEditSiteZones({
        action: ESiteEditActions.SelectNextZone,
      }),
    );
  }, [dispatch]);

  // Api handlers
  const handleSubmitDeleteSite = useCallback(() => {
    return new Promise((resolve, reject) => {
      currentSite?.loc_id
        ? resolve(dispatch(deleteSiteAndPrograms(currentSite.loc_id)))
        : reject(new Error('Current site is not found'));
    });
  }, [dispatch, currentSite?.loc_id]);

  const handleSubmitUpdateSite = useCallback(() => {
    return new Promise((resolve, reject) => {
      currentSite?.loc_id
        ? resolve(dispatch(updateSiteAndPrograms(currentSite.loc_id)))
        : reject(new Error('Current site is not found'));
    });
  }, [dispatch, currentSite?.loc_id]);

  const showDeleteSiteModal = useCallback(() => setIsOpenDeleteSiteModal(true), []);
  const closeDeleteSiteModal = useCallback(() => setIsOpenDeleteSiteModal(false), []);

  const handleDeclineDeleteSite = useCallback(() => {
    closeDeleteSiteModal();
  }, [closeDeleteSiteModal]);

  const handleConfirmDeleteSite = useCallback(async () => {
    try {
      closeDeleteSiteModal();
      dispatch(setPreloader(true));
      await handleSubmitDeleteSite();
    } catch (err) {
      alert((err as Error).message);
    } finally {
      dispatch(setSidebar(ESidebar.Sites));
      dispatch(setPreloader(false));
    }
  }, [dispatch, closeDeleteSiteModal, handleSubmitDeleteSite]);

  const handleDeleteSite = useCallback(() => showDeleteSiteModal(), [showDeleteSiteModal]);
  const handleSaveSite = useCallback(async () => {
    try {
      dispatch(setPreloader(true));
      await handleSubmitUpdateSite();
    } catch (err) {
      alert((err as Error).message);
    } finally {
      dispatch(setPreloader(false));
      handleBackClick();
    }
  }, [handleBackClick, handleSubmitUpdateSite]);

  const handleChangeZoneName = useCallback(
    (data: IUpdateInputData) => {
      const transformedData = Object.entries(data);

      if (transformedData.length === 1) {
        const [zoneId, data] = transformedData[0];

        dispatch(
          updateEditSiteZones({
            action: ESiteEditActions.UpdateOne,
            data: {
              id: zoneId,
              value: ['name', data],
            },
          } as TUpdateZonePayload<TEditZone['name']>),
        );
      }
    },
    [dispatch],
  );

  return (
    <div className={styles.root}>
      <Modal
        title={t('sidebar.site.edit.modals.delete')}
        isOpen={isOpenDeleteSiteModal}
        onClose={closeDeleteSiteModal}
        onDecline={handleDeclineDeleteSite}
        onConfirm={handleConfirmDeleteSite}
      />
      {selectedZone && (
        <SelectedZone
          key={selectedZone.program_id}
          mode={EMode.Edit}
          zone={selectedZone}
          siteName={currentSite?.name}
          areDisabledSwitcherButtons={areDisabledSwitcherButtons}
          onLeftClick={handlePreviousSelectedZone}
          onBackClick={handleSelectedZoneBack}
          onRightClick={handleNextSelectedZone}
          onChange={handleChangeZoneName}
        />
      )}
      <header className={styles.header}>
        <button className={styles.back} onClick={handleBackClick}>
          <ReactSVG src={arrowLeftLg} />
        </button>
        <div className={styles.info}>
          <h3 className={styles.siteName}>{sliceString(currentSite?.name || '', 20)}</h3>
          <div className={styles.subHeader}>{t('sidebar.site.edit.subHeader')}</div>
        </div>
      </header>
      <div className={styles.main}>
        <ul className={styles.editableList}>
          <Accordion
            as='li'
            arrowSettings={{
              position: 'before',
              direction: 'right',
              className: styles.accordionArrow,
            }}
            className={styles.accordionItem}
            options={editableOptions}
          />
        </ul>
      </div>
      <div className={styles.buttonsControls}>
        <button className={styles.deleteBtn} onClick={handleDeleteSite}>
          {t('sidebar.site.edit.buttons.delete')}
        </button>
        <button disabled={isDisabledSaveButton} className={styles.saveBtn} onClick={handleSaveSite}>
          {t('sidebar.site.edit.buttons.save')}
        </button>
      </div>
    </div>
  );
};

export default EditSite;
