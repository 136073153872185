import { IStorage } from '../interfaces/storage';

export class SessionStorageAdapter implements IStorage {
  private data = window.sessionStorage;

  public setItem(key: string, value: string): void {
    return this.data.setItem(key, JSON.stringify(value));
  }

  public getItem(key: string): string | null {
    try {
      return this.data.getItem(key);
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  public removeItem(key: string): void {
    return this.data.removeItem(key);
  }

  public clear(): void {
    return this.data.clear();
  }
}
