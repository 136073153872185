import { FC } from 'react';
import addPicture from '@assets/icons/add-picture.svg';
import crossIcon from '@assets/icons/cross.svg';
import FileUploader from '@components/FileUploader/FileUploader';
import PreviewImage from '@components/PreviewImage/PreviewImage';
import styles from './styles.scss';

interface IProps {
  text: {
    notification: string;
    upload: string;
  };
  picture?: {
    value: string | null; // NOTE: base64 image
    errorMessage: string | null;
  };
  onUpload: (file: File) => void;
  onDelete: (e: React.MouseEvent<HTMLButtonElement>) => void;
  pictureUrl?: string | null;
}

const PictureBody: FC<IProps> = ({ text, picture, pictureUrl, onUpload, onDelete }) => {
  const visibleImage = pictureUrl || (picture?.value as string);

  const isPreviewMode = visibleImage && !picture?.errorMessage;

  return (
    <div className={styles.body}>
      <h2 className={styles.notification}>{text.notification}</h2>
      <div className={styles.upload}>
        <div className={styles.uploadController}>
          <FileUploader onUpload={onUpload}>
            {isPreviewMode ? (
              <PreviewImage className={styles.previewImage} src={visibleImage} alt='preview' />
            ) : (
              <img src={addPicture} alt='Add Picture' />
            )}
          </FileUploader>
          {isPreviewMode && (
            <button className={styles.closeBtn} onClick={onDelete}>
              <img src={crossIcon} alt='close' />
            </button>
          )}
        </div>
        <span className={styles.uploadText}>{text.upload}</span>
      </div>
    </div>
  );
};

export default PictureBody;
