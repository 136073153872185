import { toast } from 'react-toastify';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { api } from '@core/api';
import { ERROR_TOAST_DELAY, OUTFLIER_ACCESS_TOKEN } from '@core/constants';
import { EAsyncStatus } from '@core/enums';
import { useSelectorTyped } from '@core/hooks';
import { ISite } from '@core/interfaces';
import { IReport } from '@core/interfaces/report';
import { browserLocalStorage } from '@core/services';
import { ERTCPeerConnection } from '@modules/Viewers/views/ModelViewer/components/ThreejsViewer/enums/webrtc';
import VideoClient from '@modules/Viewers/views/ModelViewer/components/ThreejsViewer/network/videoClient';
import WebRTCClient from '@modules/Viewers/views/ModelViewer/components/ThreejsViewer/network/webRtcClient';
import WebSocketClient from '@modules/Viewers/views/ModelViewer/components/ThreejsViewer/network/websocketClient';
import { initialModelState } from '@modules/Viewers/views/ModelViewer/constants/config';
import { IModelState } from '@modules/Viewers/views/ModelViewer/interfaces/model';
import {
  IAnomalySolarPanel,
  ISolarPanel,
} from '@modules/Viewers/views/ModelViewer/interfaces/solarPanel';
import { setPreloader } from './preloader';

export interface IModelData {
  solarPanels: ISolarPanel[];
  anomalies: IAnomalySolarPanel[];
}

export interface IModelFlags {
  shouldPreventSocketInitCamera: boolean;
  shouldUpdateCenterAnomaly: boolean;
  loadedModel: {
    fetchStatus: EAsyncStatus;
    error: unknown | null;
  };
}

export interface IModelRenderer {
  video: VideoClient | null;
}

interface IInitialState {
  model: {
    state: IModelState;
    data: IModelData;
    flags: IModelFlags;
    renderer: IModelRenderer;
  };
  connections: {
    webRTCConnection: WebRTCClient | null;
    socketConnection: WebSocketClient | null;
  };
}

const initialState: IInitialState = {
  model: {
    state: initialModelState,
    data: {
      solarPanels: [],
      anomalies: [],
    },
    renderer: {
      video: null,
    },
    flags: {
      shouldPreventSocketInitCamera: false,
      shouldUpdateCenterAnomaly: false,
      loadedModel: {
        fetchStatus: EAsyncStatus.Idle,
        error: null,
      },
    },
  },
  connections: {
    webRTCConnection: null,
    socketConnection: null,
  },
};

export const loadModel3D = createAsyncThunk<
  void,
  { site: ISite; report: IReport },
  NonNullable<unknown>
>('model3D/loadModel3D', async ({ site, report }, { dispatch }) => {
  try {
    // NOTE: add spinner at the beginning and reset in WebRTC section.
    dispatch(setPreloader(true));
    dispatch(setLoadedModelStatus(EAsyncStatus.Pending));

    const reserveMachineResponse = await api.loadModel.reserveMachine();
    const access_token = browserLocalStorage.getItem(OUTFLIER_ACCESS_TOKEN) as string;

    const isLoadedModel = await api.loadModel.loadModelById({
      hostname: reserveMachineResponse.hostname,
      locId: site.loc_id,
      modelId: report.model_id,
      expname: report.expname,
      access_token: access_token,
      reservation_token: reserveMachineResponse.reservation_token,
    });

    if (!isLoadedModel) {
      throw new Error();
    }

    dispatch(
      updateModelState({
        access_token,
        reservation_token: reserveMachineResponse.reservation_token,
        server_host: reserveMachineResponse.hostname,
      }),
    );

    dispatch(setLoadedModelStatus(EAsyncStatus.Success));
  } catch {
    toast.error(t('errors.loadModelFail'), { autoClose: ERROR_TOAST_DELAY });
    dispatch(setLoadedModelStatus(EAsyncStatus.Error));
  } finally {
    dispatch(setPreloader(false));
  }
});

const model3DSlice = createSlice({
  name: 'model3D',
  initialState,
  reducers: {
    updateModelState: (state, action: PayloadAction<Partial<IModelState>>) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state.model.state[key] = value;
      });
    },
    updateModelData: (state, action: PayloadAction<Partial<IModelData>>) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state.model.data[key] = value;
      });
    },
    setShouldUpdateCenterAnomaly: (state, action: PayloadAction<boolean>) => {
      state.model.flags.shouldUpdateCenterAnomaly = action.payload;
    },
    setShouldPreventSocketInitCamera: (state, action: PayloadAction<boolean>) => {
      state.model.flags.shouldPreventSocketInitCamera = action.payload;
    },
    setLoadedModelStatus: (state, action: PayloadAction<EAsyncStatus>) => {
      state.model.flags.loadedModel.fetchStatus = action.payload;
    },
    setLoadedModelError: (state, action: PayloadAction<unknown | null>) => {
      state.model.flags.loadedModel.error = action.payload;
    },
    setBackgroundSceneVideo: (state, action: PayloadAction<any>) => {
      state.model.renderer.video = action.payload;
    },
    addWebRTCConnection: (state, action: PayloadAction<WebRTCClient>) => {
      state.connections.webRTCConnection?.close?.();
      state.connections.webRTCConnection = action.payload;
    },
    addSocketConnection: (state, action: PayloadAction<WebSocketClient>) => {
      state.connections.socketConnection?.ws?.close?.();
      state.connections.socketConnection = action.payload;
    },
    removeWebRTCConnection: (state) => {
      state.connections.webRTCConnection?.pc.getTransceivers().forEach((transceiver) => {
        transceiver.stop();
      });
      state.connections.webRTCConnection?.close?.();
      state.connections.webRTCConnection = null;
    },
    removeSocketConnection: (state) => {
      state.connections.socketConnection?.ws?.close?.();
      state.connections.socketConnection = null;
    },
    resetModel3DState: (state) => {
      if (state.connections.webRTCConnection?.pc.connectionState !== ERTCPeerConnection.Closed) {
        state.connections.webRTCConnection?.pc.getTransceivers().forEach((transceiver) => {
          transceiver.stop();
        });
        state.connections.webRTCConnection?.close?.();
      }
      state.connections.socketConnection?.ws?.close?.();

      return initialState;
    },
  },
});

const model3DReducer = model3DSlice.reducer;

const {
  updateModelState,
  updateModelData,
  setLoadedModelStatus,
  setLoadedModelError,
  setBackgroundSceneVideo,
  setShouldUpdateCenterAnomaly,
  setShouldPreventSocketInitCamera,
  addWebRTCConnection,
  addSocketConnection,
  removeWebRTCConnection,
  removeSocketConnection,
  resetModel3DState,
} = model3DSlice.actions;

const useModel3DSelector = () => useSelectorTyped((state) => state.model3D);
const useModel3DSocketConnectionSelector = () =>
  useSelectorTyped((state) => state.model3D.connections.socketConnection);
const useModel3DWebRTCConnectionSelector = () =>
  useSelectorTyped((state) => state.model3D.connections.webRTCConnection);
const useModel3DBackgroundSceneVideoSelector = () =>
  useSelectorTyped((state) => state.model3D.model.renderer.video);
const useModel3DStateSelector = () => useSelectorTyped((state) => state.model3D.model.state);
const useModel3DDataSelector = () => useSelectorTyped((state) => state.model3D.model.data);
const useModel3DFlagsSelector = () => useSelectorTyped((state) => state.model3D.model.flags);

export {
  model3DReducer,
  useModel3DSelector,
  useModel3DSocketConnectionSelector,
  useModel3DWebRTCConnectionSelector,
  useModel3DBackgroundSceneVideoSelector,
  useModel3DStateSelector,
  useModel3DDataSelector,
  useModel3DFlagsSelector,
  setShouldPreventSocketInitCamera,
  updateModelState,
  updateModelData,
  setLoadedModelStatus,
  setLoadedModelError,
  setBackgroundSceneVideo,
  setShouldUpdateCenterAnomaly,
  addWebRTCConnection,
  addSocketConnection,
  removeWebRTCConnection,
  removeSocketConnection,
  resetModel3DState,
  initialState as model3DInitialState,
};
