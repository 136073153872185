export enum EFlightPathLayers {
  FlightPath = 'flight-path',
  Triangle = 'triangle',
  Point = 'point',
  BatteryCircle = 'battery-circle',
  BatteryData = 'battery-data',
}

export enum ESampleLayers {
  Samples = 'samples',
  Frustum = 'frustum',
  FrustumLines = 'frustum-lines',
}

export enum ECreateSiteLayers {
  ObstaclesLine = 'obstacles-line',
  ObstaclesBuffer = 'obstacles-buffer',
  Perimeter = 'perimeter',
  PerimeterDisplay = 'perimeter-display',
  NoFlyZonesBorder = 'no-fly-zones-border',
  NoFlyZonesFill = 'no-fly-zones-fill',
  NoFlyZoneIcons = 'no-fly-zone-icons',
  OverviewPlan = 'overview-plan',
  FirstPlan = 'first-plan',
  GeneratedProgramsLine = 'generated-programs-line',
  GeneratedProgramsFill = 'generated-programs-fill',
  GeneratedProgramsText = 'generated-programs-text',
}

export enum EMapImages {
  NoFlyZoneIconId = 'no-fly-zone-icon-id',
}

export enum ESolarPanelsLayers {
  SolarPanels = 'solar-panels',
}

export enum EAnomalyLayers {
  Anomalies = 'anomalies',
  AnomaliesBorder = 'anomalies-border',
}

export enum EZonesLayers {
  Polygon = 'zone-polygon',
  PolygonBorder = 'zone-polygon-border',
}

export enum ELocationLayers {
  Clusters = 'clusters',
  ClusterCount = 'cluster-count',
  UnclusteredPoint = 'unclustered-point',
}
