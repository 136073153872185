import { anomaliesContrastTextColor, anomaliesStatusesColor } from '@core/constants';
import { getColor } from '@core/utils';

export const getStyleButton = (name: string, active: boolean) => {
  if (!active) return { background: getColor('--contain-bg') };

  return {
    background: anomaliesStatusesColor[name],
    color: anomaliesContrastTextColor[name],
  };
};
