import React from 'react';
import cn from 'classnames';
import styles from './styles.scss';

interface IProps {
  children: React.ReactNode;
  className?: string;
}

export const Table: React.FC<IProps> = ({ children, className }) => {
  return <table className={cn(styles.table, className)}>{children}</table>;
};
