import PropTypes from 'prop-types';

export const defaultProps = {
  id: 'threejs-viewer',
  width: 1280,
  height: 720,
  cameraProps: null,
  imageSrc: 'offer',
  pointcloudSrc: null,
  server_host: null,
  server_port: null,
  server_tls: false,
  access_token: null,
  reservation_token: null,
  ice_servers: [
    {
      urls: 'stun:stun.outview.outflier.com:3478',
    },
    {
      urls: 'turn:turn.outview.outflier.com:3478',
      username: 'outflier',
      credential: '1234',
    },
  ],
  initCamera: {
    eye: {
      x: 1.25,
      y: 1.25,
      z: 1.25,
    },
    center: {
      x: 0.0,
      y: 0.0,
      z: 0.0,
    },
    up: {
      x: 0.0,
      y: 0.0,
      z: 1.0,
    },
  },
  nextCameraCenter: null,
  datasetCameraPoses: null,
  reportsData: null,
  drawables: null,
  currentCamera: null,
  selectedAnomalies: [],
  selectedDrawables: [],
  selectionMode: 'single',
  settings: {
    show_menu: false,
    axes_helper: false,
    background: false,
    display_poses: true,
    display_poses_near: 1.0,
    display_poses_far: 5.0,
    mode: 'image',
    monitoring: false,
    render_mode: 'RGB',
    blending: 0.25,
    pointcloud_voxel_size: null,
    point_size: 2.0,
    wireframe: false,
    display_anomalies: true,
    motion_delay_ms: 0,
    motion_samples_pct_bg: 40,
    motion_samples_pct_fg: 40,
    motion_resolution_pct_bg: 12,
    motion_resolution_pct_fg: 25,
  },
};
export const propTypes = {
  /**
   * The ID used to identify this component in Dash callbacks.
   */
  id: PropTypes.string.isRequired,

  /**
   * Width of the viewer (in pixels).
   */
  width: PropTypes.number.isRequired,

  /**
   * Height of the viewer (in pixels).
   */
  height: PropTypes.number.isRequired,

  /**
   * Properties of the scene's perspective camera.
   */
  cameraProps: PropTypes.object,

  /**
   * Sources for the point cloud.
   * Dictionary mapping a name to an URL.
   */
  pointcloudSrc: PropTypes.object,

  /**
   * Source for background image. Either the url of a video like this one
   * http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4
   * or the endpoint to be use as offer for webrtc - usually just offer
   */
  imageSrc: PropTypes.string,

  /**
   * Server host
   */
  server_host: PropTypes.string,

  /**
   * Port of the server.
   */
  server_port: PropTypes.number,

  /**
   * Whether the server uses TLS.
   */
  server_tls: PropTypes.bool,

  /**
   * Turn server credential
   */
  ice_servers: PropTypes.array,

  /**
   * Initial camera state (eye, center and up vectors).
   */
  initCamera: PropTypes.object,

  /**
   * Next camera center determined from a double-click event.
   */
  nextCameraCenter: PropTypes.object,

  /**
   * List of camera poses from the dataset (list of row-major 4D matrices).
   * Can be used to display the drone trajectory.
   */
  datasetCameraPoses: PropTypes.array,

  /**
   * Reports data containing anomaly details.
   */
  reportsData: PropTypes.array,

  /**
   * Drawables: a list of dictionaries with the following fields:
   *   - `id` (str): unique id for each drawable.
   *   - `vertices` (n x 3 flattened float matrix): list of 3D vertices
   *   - `faces` (n x 3 unsigned integer matrix): list of 3D faces (integers represent vertex indices)
   *   - `transform` (4D float matrix): transform applied to the point/vertices positions.
   *   - `color` (hex triplet or CSS string) color, default = `0xffffff`
   *   - `alpha` (float in [0, 1]): transparency
   *   - `selected_color` (hex triplet or CSS string) color when object is selected, default = `0xffffff`
   *   - `selected_alpha` (float in [0, 1]): transparency when object is selected
   *   - `point_size` (float): size of the points, default = ???
   *   - `line_width` (float): width of mesh lines, default = ???
   *   - `wireframe` (bool): display as wireframe, default = false.
   *   - `coordinate_convention` (str): coordinate convention used, "three.js" or "colmap", default = "three.js".
   */
  drawables: PropTypes.array,

  /**
   * [Output] Current camera state (eye, center and up vectors).
   */
  currentCamera: PropTypes.object,

  /**
   * [Output] List of selected anomalies.
   */
  selectedAnomalies: PropTypes.array,

  /**
   * [Output] List of selected drawables.
   */
  selectedDrawables: PropTypes.array,

  /**
   * The selection mode: "single" or "multi".
   */
  selectionMode: PropTypes.string,

  /**
   * [Output] Settings of the viewer.
   */
  settings: PropTypes.object,

  /**
   * Dash-assigned callback that should be called to report property changes
   * to Dash, to make them available for callbacks.
   */
  setProps: PropTypes.func,
};
