import { axiosInstance } from '@core/api/axios';
import { COLOR_SEGMENTATION_MASK } from '@core/constants/colors';

export const getSampleSegmentationMaskByReportId = async (
  reportId: number | string,
  sampleId: number | string,
) => {
  return axiosInstance
    .get(
      `/reports/${reportId}/samples/${sampleId}/segmentation_mask?color=%23${COLOR_SEGMENTATION_MASK}`,
      { responseType: 'blob' },
    )
    .then((res) => URL.createObjectURL(res.data));
};
