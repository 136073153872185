import { axiosInstance } from '@core/api/axios';
import { IInspection } from '@core/interfaces';
import { addQueryParamsToUrl } from '@core/utils';

interface IInspectionQueryParams {
  from?: string;
  to?: string;
  active?: boolean;
}

export const getInspections = async (
  { active = true, ...restParams }: IInspectionQueryParams = {} as IInspectionQueryParams,
): Promise<IInspection[]> => {
  const endpoint = addQueryParamsToUrl('/inspections', { active, ...restParams });
  return axiosInstance.get<IInspection[]>(endpoint).then((resp) => resp.data);
};
