import { FC, memo } from 'react';
import { ThreeEvent } from '@react-three/fiber';
import BackgroundScene from './BackgroundScene';
import { Drawables } from './Drawables';
import { IDrawable } from '../../../interfaces/drawable';

interface IProps {
  width: number;
  height: number;
  src: string;
  access_token: string | null;
  reservation_token: string | null;
  server_host: string | null;
  server_port: number;
  server_tls: boolean;
  ice_servers: RTCIceServer[];
  drawables: IDrawable[];
  hide_drawables: boolean;
  onImageClick: (event: ThreeEvent<MouseEvent>) => void;
  onImageDoubleClick: (event: ThreeEvent<MouseEvent> | null) => void;
}

const BackgroundStream: FC<IProps> = memo(
  ({
    width,
    height,
    src,
    server_host,
    server_port,
    server_tls,
    ice_servers,
    access_token,
    reservation_token,
    drawables,
    hide_drawables,
    onImageClick,
    onImageDoubleClick,
  }) => {
    return (
      <>
        <group onClick={onImageClick} onDoubleClick={onImageDoubleClick} visible={!hide_drawables}>
          <Drawables drawables={drawables} />
        </group>

        <ambientLight color={0xffffff} intensity={1.0} />
        <BackgroundScene
          width={width}
          height={height}
          src={src}
          server_host={server_host}
          server_port={server_port}
          server_tls={server_tls}
          ice_servers={ice_servers}
          access_token={access_token}
          reservation_token={reservation_token}
        />
      </>
    );
  },
);

export default memo(BackgroundStream);
