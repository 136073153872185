/* eslint-disable @typescript-eslint/no-unused-vars */
import * as turf from '@turf/turf';
import { EDetectionStatus } from '@core/enums';
import { ISampleDetection } from '@core/interfaces';
import { getDetectionCoordinates } from './getDetectionsCoordinates';
import {
  getIntersectionBetweenTwoPolygons,
  getBboxPolygon,
} from '../../MapViewer/utils/geospatialUtils';
import { IDetectionsMergedPolygonData } from '../interfaces/detections';

type TDetectionsByStatus = {
  [key in EDetectionStatus]: ISampleDetection[];
};

const getDetectionIndex = (
  mergedPolygonsWithDetections: IDetectionsMergedPolygonData[],
  detectionId: number,
) => {
  return mergedPolygonsWithDetections.findIndex((polygonsData) =>
    polygonsData.detections.map(({ id }) => id).includes(detectionId),
  );
};

export function getSeparatedDetectionsByStatus(
  detections: ISampleDetection[],
): TDetectionsByStatus {
  return detections.reduce(
    (acc, { status, ...detection }) => {
      return status && acc[status] ? { ...acc, [status]: [...acc[status], detection] } : acc;
    },
    {
      [EDetectionStatus.Active]: [],
      [EDetectionStatus.NonActive]: [],
    },
  );
}

const detectionHelpers: {
  [key in EDetectionStatus]: (detections: ISampleDetection[]) => IDetectionsMergedPolygonData[];
} = {
  [EDetectionStatus.Active]: getMergedDetectionsIntoOnePolygon,
  [EDetectionStatus.NonActive]: getMergedDetectionIntoPolygons,
};

function getMergedDetectionsIntoOnePolygon(
  detections: ISampleDetection[],
): IDetectionsMergedPolygonData[] {
  const getters = {
    getX1: (d: ISampleDetection) => d.x,
    getY1: (d: ISampleDetection) => d.y,
    getX2: (d: ISampleDetection) => d.x + d.w,
    getY2: (d: ISampleDetection) => d.y + d.h,
    getMin: (axis: number[]) => Math.min(...axis),
    getMax: (axis: number[]) => Math.max(...axis),
    getHeight: (y1: number, y2: number) => y2 - y1,
    getWidth: (x1: number, x2: number) => x2 - x1,
  };

  const x1Axis = detections.map(getters.getX1);
  const y1Axis = detections.map(getters.getY1);
  const x2Axis = detections.map(getters.getX2);
  const y2Axis = detections.map(getters.getY2);

  const minX = getters.getMin(x1Axis);
  const maxX = getters.getMax(x2Axis);
  const minY = getters.getMin(y1Axis);
  const maxY = getters.getMax(y2Axis);

  const height = getters.getHeight(minY, maxY);
  const width = getters.getWidth(minX, maxX);

  const coordinates = getDetectionCoordinates({
    ...detections[0],
    x: minX,
    y: minY,
    w: width,
    h: height,
  });
  const polygon = getBboxPolygon(coordinates);

  return [{ detections, ...polygon }];
}

function getMergedDetectionIntoPolygons(detections: ISampleDetection[]) {
  const mergedPolygonsWithDetections: IDetectionsMergedPolygonData[] = [];

  for (let i = 0; i < detections.length; i += 1) {
    const currentDetection = detections[i];
    const currentCoordinates = getDetectionCoordinates(currentDetection);
    const currentPolygon = getBboxPolygon(currentCoordinates);

    let currentDetectionIndex = getDetectionIndex(
      mergedPolygonsWithDetections,
      currentDetection.id,
    );

    if (currentDetectionIndex === -1) {
      mergedPolygonsWithDetections.push({ detections: [currentDetection], ...currentPolygon });
      currentDetectionIndex = mergedPolygonsWithDetections.length - 1;
    }

    for (let k = i + 1; k < detections.length; k += 1) {
      const candidateDetection = detections[k];
      const candidateCoordinates = getDetectionCoordinates(candidateDetection);
      const candidatePolygon = getBboxPolygon(candidateCoordinates);

      let polygon = currentPolygon;

      if (mergedPolygonsWithDetections[currentDetectionIndex]) {
        // TODO:
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { detections, ...unionPolygon } = mergedPolygonsWithDetections[currentDetectionIndex];
        polygon = unionPolygon;
      }

      const intersection = getIntersectionBetweenTwoPolygons(polygon, candidatePolygon);

      if (intersection) {
        const candidateDetectionIndex = getDetectionIndex(
          mergedPolygonsWithDetections,
          candidateDetection.id,
        );

        if (candidateDetectionIndex === -1) {
          const { detections, ...currentUnionPolygon } =
            mergedPolygonsWithDetections[currentDetectionIndex];
          const updatedUnionPolygon = turf.union(
            currentUnionPolygon,
            candidatePolygon,
          ) as turf.Feature<turf.Polygon, turf.Properties>;

          mergedPolygonsWithDetections[currentDetectionIndex] = {
            ...updatedUnionPolygon,
            detections: [...detections, candidateDetection],
          };
        }
      }
    }
  }
  return mergedPolygonsWithDetections;
}

// NOTE: "getMergedPolygonsWithDetections" find intersected detections
// and create a collection for each intersected group
/*
  Example:
  [
    {
      detections: [],
      geometry:{ type: 'Polygon', coordinates: Array(1) } - // common polygon for mentioned detections
      properties: {}
      type: "Feature"
    }
  ]
*/

export function getMergedPolygonsWithDetections(separatedDetectionsByStatus: TDetectionsByStatus) {
  const mergedPolygonsWithDetections: {
    [key in EDetectionStatus]?: IDetectionsMergedPolygonData[];
  } = {};

  for (const [status, detections] of Object.entries(separatedDetectionsByStatus)) {
    if (detections.length) {
      const detectionsWithStatuses = detections.map((detection) => ({ ...detection, status }));
      const polygonData: IDetectionsMergedPolygonData[] =
        detectionHelpers[status](detectionsWithStatuses);

      mergedPolygonsWithDetections[status] = polygonData;
    }
  }

  return Object.values(mergedPolygonsWithDetections).flat();
}
