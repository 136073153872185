export const TYPE_DRAWABLE = 'drawable';
export const DEFAULT_DRAWABLE = {
  id: null,
  vertices: [],
  faces: [],
  transform: [
    [1, 0, 0, 0],
    [0, 1, 0, 0],
    [0, 0, 1, 0],
    [0, 0, 0, 1],
  ],
  color: 0xffffff,
  alpha: 1.0,
  selected_color: 0xffffff,
  selected_alpha: 1.0,
  point_size: 1.0,
  line_width: 0.01,
  wireframe: false,
  depth_write: true,
  coordinate_convention: 'three.js',
};

export const drawableObjectRenderOrder = new Map([
  ['mesh', 0],
  ['wireframe', 1],
  ['flight_path', 2],
  ['sample', 3],
  ['battery', 4],
]);
