import { toast } from 'react-toastify';
import { t } from 'i18next';
import { Dispatch } from 'redux';
import { API_URL } from '@core/config';
import { ERROR_TOAST_DELAY, OUTFLIER_ACCESS_TOKEN } from '@core/constants';
import { browserLocalStorage } from '@core/services/storage';
import {
  setIsLoadingSite,
  setPerimeterProcessingPercentage,
  setPerimeters,
} from '@core/store/slices';
import { reloadMap } from '@core/store/slices/map';
import { isString } from '@core/utils';

export interface CreatePerimeterParameters {
  top_left_lat: number;
  top_left_lng: number;
  bottom_right_lat: number;
  bottom_right_lng: number;
}

function createUrl({
  top_left_lat,
  top_left_lng,
  bottom_right_lat,
  bottom_right_lng,
}: CreatePerimeterParameters): string {
  const url = new URL(`${API_URL}/perimeter`);

  url.searchParams.set('top_left_lat', top_left_lat.toString());
  url.searchParams.set('top_left_lng', top_left_lng.toString());
  url.searchParams.set('bottom_right_lat', bottom_right_lat.toString());
  url.searchParams.set('bottom_right_lng', bottom_right_lng.toString());

  // NOTE: temporary solution (legacy endpoint)
  // url.searchParams.set('tenant', OUTFLIER_TENANT);

  return url.toString();
}

function handleError(message?: string) {
  toast.error(message ?? t('errors.somethingWentWrong'), { autoClose: false });

  return {
    hasError: true,
  };
}

function handleResponse(response: Response, dispatch: Dispatch<any>) {
  const reader = response?.body?.getReader();
  let isPerimeterCreated = false;

  async function read() {
    if (reader) {
      try {
        const { done, value } = await reader.read();

        if (done) {
          if (!isPerimeterCreated) {
            handleError(t('errors.unableToDetectPerimeter') ?? undefined);
          }
          setTimeout(() => {
            dispatch(setPerimeterProcessingPercentage(null));
          }, 500);

          dispatch(setIsLoadingSite(false));

          return {
            hasError: !isPerimeterCreated,
          };
        }

        const chunk = new TextDecoder().decode(value);
        const formattedChunk = chunk?.replace(/'/g, '"');

        const regex = /{[^}]+}/g;
        const jsonObjects = formattedChunk.match(regex);

        jsonObjects?.forEach((jsonObjectStr) => {
          const jsonObject = JSON.parse(jsonObjectStr);
          if (jsonObject?.type) {
            isPerimeterCreated = true;
            dispatch(setIsLoadingSite(false));
            dispatch(setPerimeters([jsonObject]));
            dispatch(reloadMap(true));
            dispatch(setPerimeterProcessingPercentage(100));
          }

          if (isString(jsonObject?.progress)) {
            const [completed, total] = jsonObject.progress.split('/').map(Number);
            const percentage = (completed / total) * 100;
            dispatch(setPerimeterProcessingPercentage(percentage));
          }
        });

        read();
      } catch {
        toast.error(t('errors.createPerimeterFail'), { autoClose: ERROR_TOAST_DELAY });
      }
    }
  }

  return read();
}

export const createPerimeter = (
  perimeterParameters: CreatePerimeterParameters,
  dispatch: Dispatch<any>,
) => {
  const accessToken = browserLocalStorage.getItem(OUTFLIER_ACCESS_TOKEN);
  const url = createUrl(perimeterParameters);

  return fetch(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((response) => handleResponse(response, dispatch))
    .catch((error: Error) => {
      handleError(error.message);
      dispatch(setIsLoadingSite(false));
    });
};
