import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.scss';
import {
  getPercentageFromSeconds,
  getSecondsFromPercentage,
  getTimeFromSeconds,
} from '../../utils';

interface IProps {
  currentTime: number;
  durationTime: number;
  onChange: (time: number) => void;
}

export const Progressbar: React.FC<IProps> = ({ currentTime, durationTime, onChange }) => {
  const [value, setValue] = useState('0%');
  const progressBarRef = useRef<HTMLDivElement | null>(null);
  const progressBarThumbRef = useRef<HTMLDivElement | null>(null);

  const handleThumbDragStart = (event: any) => {
    event.preventDefault();

    window.addEventListener('mousemove', handleThumbDrag);
    window.addEventListener('touchmove', handleThumbDrag);
    window.addEventListener('mouseup', handleThumbDragStop);
    window.addEventListener('touchend', handleThumbDragStop);
  };

  const handleThumbDragStop = () => {
    window.removeEventListener('mousemove', handleThumbDrag);
    window.removeEventListener('touchmove', handleThumbDrag);
    window.removeEventListener('mouseup', handleThumbDragStop);
    window.removeEventListener('touchend', handleThumbDragStop);
  };

  const handleThumbDrag = ({ type, touches, clientX }: any) => {
    if (!progressBarRef || !progressBarThumbRef) return;

    const range = progressBarRef.current as HTMLDivElement;
    const thumb = progressBarThumbRef.current as HTMLDivElement;

    const rangeWidth = range.offsetWidth;
    const rangeLeft = range.getBoundingClientRect().left;
    const rangeRight = range.getBoundingClientRect().right;

    const mouseX = type === 'touchmove' ? touches[0].clientX : clientX;
    const thumbPosition = ((mouseX - rangeLeft) / rangeWidth) * 100;
    const currentTime = getSecondsFromPercentage(Number(thumbPosition), durationTime);

    if (mouseX < rangeLeft || mouseX > rangeRight) {
      thumb.style.left = mouseX < rangeLeft ? '0%' : '100%';
      setValue(mouseX < rangeLeft ? '0%' : '100%');
    } else {
      thumb.style.left = `${thumbPosition}%`;
      setValue(`${thumbPosition}%`);
    }

    onChange(currentTime);
  };

  useEffect(() => {
    setValue(`${getPercentageFromSeconds(currentTime, durationTime)}%`);
  }, [currentTime]);

  return (
    <div className={styles.container}>
      <div ref={progressBarRef} className={styles.progressBar}>
        <div
          ref={progressBarThumbRef}
          className={styles.progressBarThumb}
          style={{ left: value }}
          onMouseDown={handleThumbDragStart}
          onTouchStart={handleThumbDragStart}
        ></div>
        <div className={styles.progressBarLoaded} style={{ width: value }}></div>
      </div>
      <div className={styles.time}>
        <span>{getTimeFromSeconds(currentTime)}</span>
        <span>-{getTimeFromSeconds(durationTime - currentTime)}</span>
      </div>
    </div>
  );
};
