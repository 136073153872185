import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as THREE from 'three';
import { ERROR_TOAST_DELAY } from '@core/constants';
import { EViewerType } from '@core/enums';
import { useDispatchTyped } from '@core/hooks';
import {
  addWebRTCConnection,
  setBackgroundSceneVideo,
  useModel3DBackgroundSceneVideoSelector,
  useModel3DWebRTCConnectionSelector,
} from '@core/store/slices';
import { useModelViewerContext } from '../../../contexts/modelViewer';
import { ERTCPeerConnection } from '../enums/webrtc';
import VideoClient from '../network/videoClient';
import WebRTCClient from '../network/webRtcClient';

interface IProps {
  width: number;
  height: number;
  src: string;
  access_token: string | null;
  reservation_token: string | null;
  server_host: string | null;
  server_port: number;
  server_tls: boolean;
  ice_servers: RTCIceServer[];
}

const BackgroundScene: React.FC<IProps> = ({
  src,
  server_host,
  server_port,
  server_tls,
  ice_servers,
  access_token,
  reservation_token,
  width,
  height,
}) => {
  const { t } = useTranslation();
  const modelViewerContextValue = useModelViewerContext();
  const webRTCConnection = useModel3DWebRTCConnectionSelector();
  const webRTCVideo = useModel3DBackgroundSceneVideoSelector();

  const [isConnectedWebRTC, setIsConnectedWebRTC] = useState(false);
  const [isInitializedTexture, setIsInitializedTexture] = useState(false);

  const modelViewerContextValueRef = useRef(modelViewerContextValue);
  modelViewerContextValueRef.current = modelViewerContextValue;

  const webRTCServerRef = useRef<WebRTCClient | null>(null);
  webRTCServerRef.current = webRTCConnection ?? null;

  const videoRef = useRef<VideoClient | null>(null);
  videoRef.current = webRTCVideo;

  const textureRef = useRef<THREE.VideoTexture | null>(null);

  const dispatch = useDispatchTyped();

  useEffect(() => {
    if (webRTCServerRef.current?.pc.connectionState === ERTCPeerConnection.Connected) {
      setIsConnectedWebRTC(true);
    }
  }, [webRTCServerRef.current?.pc.connectionState]);

  useEffect(() => {
    if (!src || !server_host || !access_token || !reservation_token) return;
    if (videoRef.current || webRTCServerRef.current) return;
    if (modelViewerContextValue.type === EViewerType.InspectionView) return;

    (async () => {
      const videoClient = new VideoClient({
        crossOrigin: 'Anonymous',
        loop: true,
        muted: true,
        preload: 'none',
        autoplay: true,
      });

      const webRTCConnection = new WebRTCClient({
        host: server_host,
        port: server_port,
        use_tls: server_tls,
        endpoint: src,
        ice_servers,
        access_token,
        reservation_token,
      });

      try {
        if (webRTCConnection) {
          webRTCConnection.trackVideo(videoClient.video);
          await webRTCConnection.waitForServer();
          await webRTCConnection.initiate();

          webRTCConnection?.pc.addEventListener('connectionstatechange', () => {
            if (webRTCConnection?.pc.connectionState === ERTCPeerConnection.Connected) {
              dispatch(addWebRTCConnection(webRTCConnection));
              dispatch(setBackgroundSceneVideo(videoClient));
            }
          });
        }
      } catch (error) {
        toast.error(error instanceof Error ? error.message : t('errors.webRTCConnectFail'), {
          autoClose: ERROR_TOAST_DELAY,
        });
      }
    })();
  }, [server_host, server_port, server_tls, ice_servers, access_token, reservation_token, src]);

  const updateVideoTexture = useCallback((width: number, height: number) => {
    if (!textureRef.current || !videoRef.current) return;

    const targetAspect = width / height;

    const imageAspect = videoRef.current.video.videoWidth / videoRef.current.video.videoHeight;
    const factor = imageAspect / targetAspect;

    textureRef.current.offset.x = factor > 1 ? (1 - 1 / factor) / 2 : 0;
    textureRef.current.repeat.x = factor > 1 ? 1 / factor : 1;
    textureRef.current.offset.y = factor > 1 ? 0 : (1 - factor) / 2;
    textureRef.current.repeat.y = factor > 1 ? 1 : factor;
  }, []);

  const loadVideoMetadata = useCallback(
    () => updateVideoTexture(width, height),
    [updateVideoTexture, width, height],
  );

  // WARNING: this seems unnecessary for Firefox but required for Chrome/Safari
  useEffect(() => {
    if (videoRef.current && isConnectedWebRTC) {
      const playPromise = videoRef.current.video.play();

      if (playPromise) {
        playPromise.catch(() =>
          toast.error(t('errors.videoPlayFail'), { autoClose: ERROR_TOAST_DELAY }),
        );
      }

      if (textureRef.current) return;

      textureRef.current = new THREE.VideoTexture(videoRef.current?.video);
      textureRef.current.minFilter = THREE.LinearFilter;
      textureRef.current.magFilter = THREE.LinearFilter;
      textureRef.current.format = THREE.RGBAFormat;
      textureRef.current.colorSpace = THREE.SRGBColorSpace;

      videoRef.current.video.addEventListener('loadedmetadata', loadVideoMetadata);
      setIsInitializedTexture(true);
    }
  }, [videoRef.current, isConnectedWebRTC, loadVideoMetadata]);

  useEffect(() => {
    const resizeVideo = () => updateVideoTexture(width, height);

    videoRef.current?.video.addEventListener('resize', resizeVideo);
    return () => {
      videoRef.current?.video.removeEventListener('resize', resizeVideo);
    };
  }, [width, height, updateVideoTexture]);

  // NOTE: Close video, WebRTC connections when page is closed or refreshed
  useEffect(() => {
    const handleCloseConnections = () => {
      if (videoRef.current) {
        videoRef.current.video.removeEventListener('loadedmetadata', loadVideoMetadata);
      }

      if (
        webRTCServerRef.current &&
        webRTCServerRef.current?.pc.connectionState !== ERTCPeerConnection.Closed
      ) {
        webRTCServerRef.current.pc.getTransceivers().forEach((transceiver) => {
          transceiver.stop();
        });

        webRTCServerRef.current.close();
      }
    };

    window.addEventListener('beforeunload', handleCloseConnections);

    return () => {
      window.removeEventListener('beforeunload', handleCloseConnections);
    };
  }, []);

  if (!textureRef.current || !isInitializedTexture) return null;

  return <primitive key='video_texture' attach='background' object={textureRef.current} />;
};

export default memo(BackgroundScene);
