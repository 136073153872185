import { Position } from 'geojson';
import { ISample } from '@core/interfaces';

export const getFrustumCoordinates = (selectedSample?: ISample | null) => {
  const frustumCoordinates: Position[] = selectedSample?.geodetic_camera?.frustum?.map(
    ({ lat, lon }) => [lon, lat],
  ) as Position[];

  let frustumBordersCoordinates: Position[] = [];
  let frustumTriangleCoordinates: Position[] = [];
  const cameraFrustum = selectedSample?.geodetic_camera?.frustum;
  const cameraLon = selectedSample?.geodetic_camera?.lon;
  const cameraLat = selectedSample?.geodetic_camera?.lat;

  if (cameraFrustum && cameraLon && cameraLat) {
    const sampleCoordinates = {
      lon: cameraLon,
      lat: cameraLat,
    };
    const frustumTriangleData = [...cameraFrustum].slice(0, 2);
    frustumTriangleData.push(sampleCoordinates);
    frustumTriangleData.unshift(sampleCoordinates);
    frustumTriangleCoordinates = frustumTriangleData?.map(({ lat, lon }) => [lon, lat]);

    const frustumBordersData = [...cameraFrustum].slice(0, 3);
    frustumBordersData.push(sampleCoordinates);
    frustumBordersData.unshift(...[...cameraFrustum].slice(3, 4));
    frustumBordersData.unshift(sampleCoordinates);
    frustumBordersCoordinates = frustumBordersData?.map(({ lat, lon }) => [lon, lat]);
  }

  return {
    frustumCoordinates,
    frustumTriangleCoordinates,
    frustumBordersCoordinates,
  };
};
