import { EAspectRatio, EViewerType } from '@core/enums';
import { droneDimensions } from '../constants/viewer';

interface IHeightOptions {
  detectionHeight: number;
  containerHeight: number;
}

interface IWidthOptions {
  detectionWidth: number;
  containerWidth: number;
}
interface ISampleDetectionHeightOptions extends IHeightOptions {
  type: EViewerType;
  aspectRatio: EAspectRatio;
}

interface ISampleDetectionWidthOptions extends IWidthOptions {
  type: EViewerType;
  aspectRatio: EAspectRatio;
}

export const getSampleDetectionHeight = ({
  detectionHeight,
  containerHeight,
  type,
  aspectRatio,
}: ISampleDetectionHeightOptions) =>
  type === EViewerType.Viewer
    ? detectionHeight * (containerHeight / droneDimensions[aspectRatio][EViewerType.Viewer].height)
    : detectionHeight /
      (droneDimensions[aspectRatio][EViewerType.Viewer].height /
        droneDimensions[aspectRatio][EViewerType.InspectionView].height);

export const getSampleDetectionWidth = ({
  detectionWidth,
  containerWidth,
  type,
  aspectRatio,
}: ISampleDetectionWidthOptions) =>
  type === EViewerType.Viewer
    ? detectionWidth * (containerWidth / droneDimensions[aspectRatio][EViewerType.Viewer].width)
    : detectionWidth /
      (droneDimensions[aspectRatio][EViewerType.Viewer].width /
        droneDimensions[aspectRatio][EViewerType.InspectionView].width);
