import i18n from 'i18next';
import { siteStatusesColor } from '@core/constants';
import { ESiteStatuses } from '@core/enums';
import { ISite, IStatus } from '@core/interfaces';
import { sortSitesByStatus } from '@modules/Viewers/views/MapViewer/utils/sortSitesByStatus';

export const getStatuses = (sites: ISite[] | null): IStatus[] => {
  if (!sites) return [];

  const sortSites = sortSitesByStatus(sites);

  const normalAmount = sortSites.filter((site) => site.status === ESiteStatuses.Normal).length;
  const inspectedAmount = sortSites.filter(
    (site) => site.status === ESiteStatuses.Inspected,
  ).length;
  const issuesAmount = sortSites.filter((site) => site.status === ESiteStatuses.Issues).length;

  return [
    {
      name: i18n.t('statuses.normal'),
      amount: normalAmount,
      color: siteStatusesColor[ESiteStatuses.Normal],
    },
    {
      name: i18n.t('statuses.toBeInspected'),
      amount: inspectedAmount,
      color: siteStatusesColor[ESiteStatuses.Inspected],
    },
    {
      name: i18n.t('statuses.issues'),
      amount: issuesAmount,
      color: siteStatusesColor[ESiteStatuses.Issues],
    },
  ];
};
