import { FACES_FRUSTUM } from '@core/constants';
import { getColor } from '@core/utils';
import {
  generateFlightPathSampleId,
  generateSampleFrustumId,
  generateSampleId,
} from './generateId';
import { EModelSamplesLayer } from '../../enums/layers';

export interface IFlightPathDrawableOptions {
  id: string;
  vertices: number[];
  color: string;
  point_size?: number;
  line_width?: number;
  border_width?: number;
  alpha?: number;
}

interface IDrawableDeps {
  vertices: number[];
  sampleId: number;
  selectedSampleId?: number;
}

export const drawableOptions: {
  [key in EModelSamplesLayer]: (deps: IDrawableDeps) => IFlightPathDrawableOptions;
} = {
  [EModelSamplesLayer.FlightPathSamples]: ({
    vertices,
    sampleId,
    selectedSampleId,
  }: IDrawableDeps) => ({
    id: generateFlightPathSampleId(sampleId),
    vertices,
    point_size: 10,
    color: getColor(selectedSampleId === sampleId ? '--outflier-blue' : '--outflier-white'),
    border_color: getColor(selectedSampleId === sampleId ? '--outflier-white' : '--outflier-blue'),
    border_width: 2,
    alpha: 1,
  }),
  [EModelSamplesLayer.AnomalySamples]: ({
    vertices,
    sampleId,
    selectedSampleId,
  }: IDrawableDeps) => ({
    id: generateSampleId(sampleId),
    vertices,
    point_size: 15,
    color: getColor(selectedSampleId === sampleId ? '--outflier-blue' : '--outflier-white'),
    border_color: getColor(selectedSampleId === sampleId ? '--outflier-white' : '--outflier-blue'),
    border_width: 2,
    alpha: 1,
  }),
  [EModelSamplesLayer.FrustumWireframe]: ({ sampleId, vertices = [] }: IDrawableDeps) => ({
    id: generateSampleFrustumId(sampleId, 1),
    vertices,
    faces: FACES_FRUSTUM,
    color: getColor('--outflier-blue'),
    wireframe: true,
    line_width: 0.0005,
  }),
  [EModelSamplesLayer.Frustum]: ({ sampleId, vertices = [] }: IDrawableDeps) => ({
    id: generateSampleFrustumId(sampleId, 2),
    vertices,
    faces: FACES_FRUSTUM,
    color: getColor('--outflier-blue'),
    alpha: 0.25,
    wireframe: false,
    depth_write: false,
  }),
};
