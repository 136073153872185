import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { useSelectorTyped } from '@core/hooks';

interface IInitialState {
  visible: boolean;
}

const initialState: IInitialState = {
  visible: true,
};

const preloaderSlice = createSlice({
  name: 'preloader',
  initialState,
  reducers: {
    setPreloader: (state, actions: PayloadAction<boolean>) => {
      state.visible = actions.payload;
    },
  },
});

const preloaderReducer = preloaderSlice.reducer;

const { setPreloader } = preloaderSlice.actions;

const usePreloaderSelector = () => useSelectorTyped((state) => state.preloader.visible);

export {
  preloaderReducer,
  usePreloaderSelector,
  setPreloader,
  initialState as preloaderInitialState,
};
