import React from 'react';

interface IProps {
  children: React.ReactNode;
  fallback: (errorMessage: string | null) => React.ReactNode | null;
}

interface IState {
  error: {
    hasError: boolean;
    message: string | null;
  };
}

class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      error: { hasError: false, message: null },
    };
  }

  static getDerivedStateFromError(error) {
    return {
      error: {
        hasError: true,
        message: error.toString(),
      },
    };
  }

  componentDidCatch(error, info) {
    console.error(error, info.componentStack);
  }

  render() {
    const { fallback, children } = this.props;
    return this.state.error.hasError ? fallback(this.state.error.message) : children;
  }
}

export default ErrorBoundary;
