import mapboxgl from 'mapbox-gl';

type TParams<Source extends string, Layer extends string> = {
  [key in Source]: Layer[];
};

export const removeLayersAndSources = <Source extends string, Layer extends string>(
  map: mapboxgl.Map | null,
  params: TParams<Source, Layer>,
) => {
  if (!map) return;

  for (const [source, layers] of Object.entries(params)) {
    for (const layer of layers as Layer[]) {
      if (map.getLayer(layer)) {
        map.removeLayer(layer);
      }
    }

    if (map.getSource(source)) {
      map.removeSource(source);
    }
  }
};
