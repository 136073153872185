import { axiosInstance } from '@core/api/axios';

interface ISetupByLocationIdResponse {
  pipeline_id: string;
}

export const postSetup = async (id: string | number): Promise<ISetupByLocationIdResponse> => {
  return axiosInstance
    .post<ISetupByLocationIdResponse>(`/locations/${id}/setup`)
    .then(({ data }) => data);
};
