/* eslint-disable no-case-declarations */
import { AnyAction, Store, Unsubscribe } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RootState } from '@core/store';
import { setBrowserStorageError } from '@core/store/slices';
import { InitialState } from '@core/store/utils/common/getInitialState';
import { isNumber, isString } from '@core/utils';
import { EPersistorStatus } from './enums';
import {
  IPersistOptions,
  IPersistorConfig,
  IRehydrateOptions,
  ISubscriptionOptions,
} from './interfaces';
import { RecursivePartial } from './interfaces/common';
import { EMergeStrategy, mergeStrategies } from './strategies';
import { getPermissionToRehydratePersistedState } from './utils';
import { BrowserLocalStorageError } from '../errors';
import { DataProcessing } from '../processing';
import { IStorage } from '../storage/interfaces/storage';

export class Persistor {
  private readonly storage: IStorage;
  private unsubscribe: Unsubscribe | null = null;

  public key: string;
  public whitelist?: string[];
  // FIXME: temporary solution (revert persistor Cache issue)
  public status?: EPersistorStatus;
  constructor({ key, storage, ...optional }: IPersistorConfig) {
    this.key = key;
    this.storage = storage;

    Object.entries(optional).forEach(([key, value]) => {
      this[key] = value;
    });
  }

  public getState(deserialize = true) {
    const state = this.storage.getItem(this.key);
    return deserialize && isString(state) ? DataProcessing.deserialize(state) : state;
  }

  // NOTE: merge initial state with persisted one
  public rehydrate(
    initialState: InitialState,
    {
      strategy = EMergeStrategy.RehydrateFull,
      ...restOptions
    }: IRehydrateOptions = {} as IRehydrateOptions,
  ) {
    try {
      const isAllowedStrategy = [
        EMergeStrategy.RehydrateFull,
        EMergeStrategy.RehydratePartial,
      ].includes(strategy);

      if (!isAllowedStrategy) {
        return initialState;
      }

      const persistedState: Partial<InitialState> | null = this.getState();

      return mergeStrategies[strategy]
        ? mergeStrategies[strategy]({
            appState: initialState,
            persistedState,
            ...restOptions,
          })
        : initialState;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  public persist(
    state: RootState | RecursivePartial<RootState>,
    {
      strategy = EMergeStrategy.PersistFull,
      ...restOptions
    }: IPersistOptions = {} as IPersistOptions,
  ) {
    const copiedState = _.cloneDeep(state);

    if (this.whitelist) this.filterWithWhitelist(copiedState);

    const preparedState = mergeStrategies[strategy]({
      appState: copiedState,
      persistedState: this.getState(),
      ...restOptions,
    });

    this.storage.setItem(this.key, DataProcessing.serialize(preparedState));
  }

  public purge() {
    return this.storage.removeItem(this.key);
  }

  private filterWithWhitelist(state: RootState | RecursivePartial<RootState>) {
    Object.keys(state).forEach((key) =>
      !this.whitelist?.includes(key) ? delete state[key] : null,
    );
  }

  public subscribeToStore(
    store: Store<any, AnyAction>,
    options: ISubscriptionOptions = {} as ISubscriptionOptions,
  ) {
    const subscription = (store: Store<any, AnyAction>) => {
      try {
        this.persist(store.getState());
      } catch (error) {
        // NOTE: dispatch error for browser storage
        if (error instanceof BrowserLocalStorageError) {
          const state = store.getState() as RootState;
          const browserStorageErrorStatus = state.accessControl.browserStorage.error.status;

          if (browserStorageErrorStatus !== error.status) {
            const permissionToRehydratePersistedState = getPermissionToRehydratePersistedState();

            // NOTE: set error only when app place is persisted
            if (permissionToRehydratePersistedState?.isPermissionGranted) {
              const errorPayload = {
                status: error.status,
                message: error.message,
              };
              const partialAppState = {
                accessControl: { browserStorage: { error: errorPayload } },
              };

              store.dispatch(setBrowserStorageError(errorPayload));
              this.persist(partialAppState, { strategy: EMergeStrategy.PersistPartial });
            }
          }
        }
      }
    };

    const enhancedSubscription = isNumber(options.throttledInterval)
      ? _.throttle(subscription, options.throttledInterval)
      : subscription;

    this.unsubscribe = store.subscribe(() => enhancedSubscription(store));
  }

  public unsubscribeFromStore() {
    this.unsubscribe?.();
  }
}
