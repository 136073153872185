import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ESiteEditSections } from '@core/enums';
import { useDispatchTyped } from '@core/hooks';
import {
  IEditSiteInitialState,
  setEditSitePicture,
  useSiteEditInfoSelector,
} from '@core/store/slices';
import { isNull, isString } from '@core/utils';
import { fileToBase64 } from '@core/utils/processing/file';
import { validateFile } from '@core/utils/validating/validateFile';
import PictureBody from './PictureBody';

const PictureEdit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatchTyped();
  const editInfo = useSiteEditInfoSelector();

  const handleUploadImage = useCallback(
    async (file: File | undefined) => {
      if (file instanceof File) {
        const payload: IEditSiteInitialState[ESiteEditSections.Picture] = {
          value: null,
          errorMessage: null,
        };

        try {
          const errorMessage = validateFile(file.name);

          if (!errorMessage) {
            const base64File = await fileToBase64(file);
            payload.value = isString(base64File) || isNull(base64File) ? base64File : null;
          } else {
            payload.value = null;
          }

          payload.errorMessage = errorMessage;
        } catch (err) {
          payload.value = null;
          payload.errorMessage = (err as Error).message;
        } finally {
          dispatch(setEditSitePicture(payload));
        }
      }
    },
    [dispatch],
  );

  const handleDeleteImage = useCallback(() => {
    dispatch(
      setEditSitePicture({
        value: null,
        errorMessage: null,
      }),
    );
  }, [dispatch]);

  return (
    <>
      <PictureBody
        text={{
          notification: t('sidebar.site.edit.sections.picture.notification'),
          upload: t('sidebar.site.edit.sections.picture.addText'),
        }}
        picture={editInfo?.picture}
        onUpload={handleUploadImage}
        onDelete={handleDeleteImage}
      />
    </>
  );
};

export default PictureEdit;
