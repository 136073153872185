type Red = number;
type Green = number;
type Blue = number;
type Alpha = number;

export type RGB = [Red, Green, Blue];
export type RGBA = [Red, Green, Blue, Alpha];

// Example:
// #ff5500 -> [255, 0, 0] - red color
export const hexToRGB = (hex: string): [Red, Green, Blue] => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return [r, g, b];
};

export const opacityToAlpha = (opacity: number) => opacity * 255;
