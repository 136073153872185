import React, { FC, useRef } from 'react';
import styles from './styles.scss';

interface IProps {
  onUpload: (file: File) => void;
  children: React.ReactNode;
}

const FileUploader: FC<IProps> = ({ onUpload, children }) => {
  const hiddenFileInputRef = useRef<HTMLInputElement | null>(null);

  // TODO:
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleUploadClick = (event: React.MouseEvent<HTMLElement>) => {
    hiddenFileInputRef.current && hiddenFileInputRef.current.click();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    const fileUploaded = event.target.files[0];
    onUpload(fileUploaded);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = [...event.dataTransfer.files];

    if (files.length > 0) {
      const fileUploaded = files[0];
      onUpload(fileUploaded);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDragStart = (event) => {
    event.dataTransfer.setData('text/plain', event.target.id);
  };

  return (
    <>
      <label
        className={styles.label}
        onClick={handleUploadClick}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragStart={handleDragStart}
      >
        {children}
      </label>
      <input
        type='file'
        ref={hiddenFileInputRef}
        onChange={handleChange}
        style={{ display: 'none' }}
        accept='.png, .jpg, .jpeg'
      />
    </>
  );
};
export default FileUploader;
