import { errorsMap } from '@core/constants';
import { EErrorStatus } from '@core/enums';
import { BrowserLocalStorageError } from '@core/services/errors';
import {
  getLocalStorageSize,
  getApproximateSizeForLocalStorageItem,
  toFixedNumber,
} from '@core/utils';
import { IStorage } from '../interfaces/storage';

export class LocalStorageAdapter implements IStorage {
  private data = window.localStorage;
  public MAX_STORAGE_SIZE = 5000; // KB

  public setItem(key: string, value: string): void {
    try {
      const approximateItemSize = getApproximateSizeForLocalStorageItem(key, value);
      const currentStorageSizeWithoutItem = getLocalStorageSize({ blacklistKeys: [key] });
      const totalSize = toFixedNumber(approximateItemSize + currentStorageSizeWithoutItem, 2);

      if (this.MAX_STORAGE_SIZE < totalSize) {
        throw new BrowserLocalStorageError(
          errorsMap[EErrorStatus.MaxStorageSize],
          EErrorStatus.MaxStorageSize,
        );
      }
      this.data.setItem(key, value);
    } catch (error) {
      if (error instanceof BrowserLocalStorageError) {
        throw error;
      }
      console.error(error);
    }
  }

  public getItem(key: string): string | null {
    try {
      return this.data.getItem(key);
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  public removeItem(key: string): void {
    return this.data.removeItem(key);
  }

  public clear(): void {
    return this.data.clear();
  }
}
