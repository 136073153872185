import { EViewer } from '@core/enums';

export const getHeader = (mode: EViewer) => {
  const views: Record<EViewer, string> = {
    [EViewer.Map]: 'map',
    [EViewer.CompareMap]: 'compare map',
    [EViewer.Image]: 'picture',
    [EViewer.Model]: 'model',
    [EViewer.Video]: 'video',
  };

  return `Inspection ${views[mode]}`;
};
