import React from 'react';
import { createPortal } from 'react-dom';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import cn from 'classnames';
import { format } from 'date-fns';
import { FORMAT_DATE_SHORT_WITH_TIME } from '@core/constants';
import { ETooltipPlacement } from '@core/enums/tooltip';
import styles from './styles.scss';

interface IProps {
  isChecked?: boolean;
  date?: string;
  isToCompare?: boolean;
}

export const TimelineItem: React.FC<IProps> = ({
  isChecked = false,
  date,
  isToCompare = false,
}) => {
  const { getTooltipProps, getArrowProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      placement: ETooltipPlacement.Bottom,
      offset: [0, 10],
    });

  const dateWithTime = date ? format(new Date(date), FORMAT_DATE_SHORT_WITH_TIME) : '';

  const timelineItemClasses = cn('rounded relative select-none', {
    '!cursor-pointer': Boolean(date),
  });

  const bgClasses = cn('w-[25px] rounded');

  const dotClasses = cn(
    {
      '!h-2.5 !w-2.5 !bg-[#0677FF] ring-8 ring-[#0677FF]/50 !opacity-100': isChecked,
    },
    {
      '!h-[15px] !w-[15px] !bg-[#FDFDFD] !opacity-100': isToCompare,
    },
    'relative h-[5px] w-[5px] bg-white opacity-[0.35] rounded-full',
  );

  const dotWrapperClasses = cn(
    {
      '!bottom-[15px]': isChecked,
    },
    {
      '!bottom-[14px]': isToCompare,
    },
    'absolute bottom-[18px] w-[25px] flex justify-center',
  );

  const tooltipClass = cn(
    {
      'bg-outflier-contain-background-hover': !isChecked,
    },
    {
      'bg-outflier-blue': isChecked,
    },
    styles.tooltipContainer,
  );

  const arrowClass = cn(
    {
      'border-b-outflier-contain-background-hover': !isChecked,
    },
    {
      'border-b-outflier-blue': isChecked,
    },
    styles.tooltipArrow,
  );

  return (
    <div className={timelineItemClasses}>
      <div className={styles.tooltipTrigger} ref={setTriggerRef}>
        <div className={dotWrapperClasses}>
          <div className={dotClasses}></div>
        </div>
        {visible &&
          createPortal(
            <div
              ref={setTooltipRef}
              {...getTooltipProps({
                className: tooltipClass,
              })}
            >
              <div {...getArrowProps({ className: arrowClass })} />
              {dateWithTime}
            </div>,
            document.body,
          )}
        <div className={bgClasses}></div>
      </div>
    </div>
  );
};
