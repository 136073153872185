import mapboxgl from 'mapbox-gl';
import { removeLayersAndSources } from '../removeLayersAndSources';

// NOTE: it's possible that report id will probably have another type (not a number)
const SOURCE_RASTER_TILE_PATTERN = /^raster-tiles-\d+$/;

export function removeRasterTilesLayers(map: mapboxgl.Map, callback?: (rasterId: string) => void) {
  const rasterTilesLayers = map
    .getStyle()
    .layers.filter((layer) => (layer as any)?.source?.match(SOURCE_RASTER_TILE_PATTERN));

  const rasterTilesLayersIds = rasterTilesLayers.map(({ id }) => id);

  rasterTilesLayersIds.forEach((rasterId) => {
    callback
      ? callback(rasterId)
      : removeLayersAndSources(map, {
          [rasterId]: [rasterId],
        });
  });
}
