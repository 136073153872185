type VideoClientConfig = {
  crossOrigin: string;
  loop: boolean;
  muted: boolean;
  preload: string;
  autoplay: boolean;
};

interface IVideoClient {
  video: HTMLVideoElement;
}

class VideoClient implements IVideoClient {
  public video: HTMLVideoElement;

  constructor(config: VideoClientConfig) {
    const videoElement = document.createElement('video');

    this.video = Object.assign(videoElement, config);
  }
}

export default VideoClient;
