export enum EAsyncStatus {
  Idle = 'idle',
  Pending = 'pending',
  Success = 'success',
  Error = 'error',
}

export enum EConnectionStatus {
  Online = 'online',
  Offline = 'offline',
}

export enum EConnectionError {
  ConnectionLost = 'connectionLost',
}
