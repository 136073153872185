import React from 'react';
import { api } from '@core/api';
import { ERoles } from '@core/enums';
import CommentSectionBody from '@components/CommentSection/CommentSectionBody';

interface IProps {
  programId: string;
}

const CommentSectionProgram: React.FC<IProps> = ({ programId }) => {
  const handleGetComments = async () => {
    return api.program.getComments(programId);
  };

  const handleSendComment = async (text?: string) => {
    if (!text) return;
    if (!programId) return;
    return api.program.postComment(programId, { comment: text });
  };

  const handleUpdateComment = async (commentId: number, text?: string) => {
    if (!text) return;
    if (!commentId) return;
    return api.program.updateCommentById(commentId, { comment: text });
  };

  const handleDeleteComment = async (commentId: number) => {
    return api.program.deleteCommentById(commentId);
  };

  return (
    <CommentSectionBody
      targetId={programId}
      onGetComments={handleGetComments}
      onSendComment={handleSendComment}
      onUpdateComment={handleUpdateComment}
      onDeleteComment={handleDeleteComment}
      allowedToCommentRoles={[ERoles.Admin, ERoles.Superuser]}
    />
  );
};

export default CommentSectionProgram;
