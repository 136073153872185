import * as THREE from 'three';

export const createCircleTextureWithText = (
  color: string,
  size: number,
  text?: string,
  textColor?: string,
  borderColor?: string,
  borderWidth?: number,
) => {
  // Determine the power-of-two size for the texture
  const powerOfTwoSize = Math.pow(2, Math.ceil(Math.log2(size)));

  // Create the main canvas for the circle
  const pointCanvas = document.createElement('canvas');
  pointCanvas.width = pointCanvas.height = powerOfTwoSize;
  const pointContext = pointCanvas.getContext('2d');

  const center = powerOfTwoSize / 2;
  if (pointContext) {
    pointContext.imageSmoothingEnabled = true;
    pointContext.beginPath();
    pointContext.arc(center, center, powerOfTwoSize / 2, 0, 2 * Math.PI, false);
    pointContext.closePath();
    pointContext.fillStyle = color;
    pointContext.fill();

    if (borderColor && borderWidth) {
      pointContext.lineWidth = borderWidth;
      pointContext.strokeStyle = borderColor;
      pointContext.stroke();
    }
  }

  // Create the texture canvas for the text
  const textCanvas = document.createElement('canvas');
  textCanvas.width = textCanvas.height = powerOfTwoSize;
  const textContext = textCanvas.getContext('2d');

  // Draw text
  if (text && textColor && textContext) {
    textContext.font = `${size * 2}px`;
    textContext.textAlign = 'right';
    textContext.textBaseline = 'middle';
    textContext.fillStyle = textColor;
    textContext.fillText(text, textCanvas.width / 2, textCanvas.height / 2);
  }

  // Draw charging icon
  if (textContext && textColor && text) {
    const iconSize = size;
    const iconX = textCanvas.width / 2 + textContext.measureText(text).width / 2 + iconSize / 2.5;
    const iconY = textCanvas.height / 2;
    textContext.textBaseline = 'middle';
    textContext.fillStyle = textColor;
    textContext.fillText('\u26A1', iconX, iconY);
  }

  // Composite the text canvas onto the point canvas
  if (pointContext && textContext) {
    pointContext.globalCompositeOperation = 'source-over';
    pointContext.drawImage(textCanvas, 0, 0);
  }

  const pointTexture = new THREE.CanvasTexture(pointCanvas);
  pointTexture.needsUpdate = true;

  return pointTexture;
};
