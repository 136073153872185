import { nonTenantAxiosInstance } from '@core/api/axios';
import { TRolesWithoutSuperuser } from '@core/interfaces';

export interface IAddNewUsersRequest {
  email: string;
  roles: TRolesWithoutSuperuser[];
}

export const addNewUsers = async (body: IAddNewUsersRequest[]) => {
  return nonTenantAxiosInstance.post<string>('users', body).then((res) => res.data);
};
