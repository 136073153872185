import mapboxgl, { LngLatLike } from 'mapbox-gl';
import { ESidebar } from '@core/enums';
import { IProgram } from '@core/interfaces';
import { MapClickEvent } from '@core/interfaces/map';
import { getRightmostCoordinateFromPolygonCoordinates } from '@core/utils/geospatial/feature';
import { createZoneLabel } from './createZoneLabel';
import { getZonesFeatureCollection } from './getFeatureCollection';
import { layersStyles } from './mapboxConfig';
import { removeZones } from './removeZones';
import { EZonesLayers } from '../../enums/layers';
import { EZonesSources } from '../../enums/sources';

interface IProps {
  map: mapboxgl.Map | null;
  programs: IProgram[] | null;
  sidebar: ESidebar;
  onClick?: (e: MapClickEvent) => void;
}

let markers: mapboxgl.Marker[] = [];

export const addZones = ({ map, programs, sidebar, onClick }: IProps) => {
  if (!map) return;

  switch (sidebar) {
    case ESidebar.Sites: {
      removeZones(map);
      markers.forEach((marker) => marker.remove());
      markers = [];
      break;
    }
    case ESidebar.Site:
    case ESidebar.EditSite: {
      const zonesPerimeterFeatures = getZonesFeatureCollection(programs, {
        isEditMode: sidebar === ESidebar.EditSite,
      });

      if (map.getSource(EZonesSources.Zones)) {
        (map.getSource(EZonesSources.Zones) as mapboxgl.GeoJSONSource).setData(
          zonesPerimeterFeatures,
        );
      } else {
        map.addSource(EZonesSources.Zones, {
          type: 'geojson',
          data: zonesPerimeterFeatures,
        });
        map.addLayer(layersStyles[EZonesLayers.Polygon]());
        map.addLayer(layersStyles[EZonesLayers.PolygonBorder]());
      }
      // Add label layer
      programs?.forEach((program) => {
        if (!program.display_perimeter) return;
        const label = createZoneLabel({ program, sidebar });

        const rightmostCoordinate = getRightmostCoordinateFromPolygonCoordinates(
          program.display_perimeter.coordinates,
        ) as LngLatLike;

        const marker = new mapboxgl.Marker(label).setLngLat(rightmostCoordinate).addTo(map);
        markers.push(marker);
      });

      onClick && map.on('click', EZonesLayers.Polygon, onClick);
      break;
    }
    case ESidebar.Zone:
    case ESidebar.Anomaly: {
      const zonesPerimeterFeatures = getZonesFeatureCollection(programs, {
        isEditMode: false,
      });
      if (map.getSource(EZonesSources.Zones)) {
        (map.getSource(EZonesSources.Zones) as mapboxgl.GeoJSONSource).setData(
          zonesPerimeterFeatures,
        );
      } else {
        map.addSource(EZonesSources.Zones, {
          type: 'geojson',
          data: zonesPerimeterFeatures,
        });
        map.addLayer(layersStyles[EZonesLayers.Polygon]());
        map.addLayer(layersStyles[EZonesLayers.PolygonBorder]());
      }

      onClick && map.off('click', EZonesLayers.Polygon, onClick);
      markers.forEach((marker) => marker.remove());
      markers = [];
      break;
    }
  }
};
