import scaleControlIcon from '@assets/icons/scale-control.svg';
import styles from './styles.scss';

interface IProps {
  scale?: string | null;
}

const INITIAL_SCALE_VALUE = 0;

export const ScaleControl: React.FC<IProps> = ({ scale }) => {
  const fallbackScaleValue = document.querySelector('.mapboxgl-ctrl-scale')?.textContent;

  return (
    <div className={styles.scaleControl}>
      <div className={styles.scaleControlValues}>
        <span>{INITIAL_SCALE_VALUE}</span>
        <span>{scale ?? fallbackScaleValue}</span>
      </div>
      <div className={styles.scaleControlIcon}>
        <img src={scaleControlIcon} alt='scale control icon' />
      </div>
    </div>
  );
};
