import React, { useEffect, useRef } from 'react';
import { RadioGroup } from '@headlessui/react';
import { format } from 'date-fns';
import { FORMAT_DATE_WITH_MONTH_AND_YEAR } from '@core/constants';
import { useIsInViewport } from '@core/hooks/useIsInViewport';
import { IInspection } from '@core/interfaces';
import { useInspectionToCompareSelector } from '@core/store/slices/inspections';
import styles from './styles.scss';
import { TimelineItem } from '../TimelineItem';

interface IProps {
  inspection: IInspection;
  viewChange: (index: number, inView: boolean) => void;
  index: number;
  showLabel: boolean;
  isOptionSingle: boolean;
}

export const RadioGroupOption: React.FC<IProps> = ({
  inspection,
  viewChange,
  index,
  showLabel,
  isOptionSingle,
}) => {
  const inspectionToCompare = useInspectionToCompareSelector();
  const isAddControl = inspection.id === inspectionToCompare?.id;

  const markerStartRef = useRef<HTMLDivElement | null>(null);

  const isStartInView = useIsInViewport(markerStartRef);

  useEffect(() => {
    viewChange(index, isStartInView);
  }, [isStartInView]);

  const formatDateLabel = (date: string) => {
    return format(new Date(date), FORMAT_DATE_WITH_MONTH_AND_YEAR);
  };

  return (
    <div
      className={`${styles.optionsContainer} ${isOptionSingle ? styles.singleOptionContainer : ''}`}
    >
      {showLabel && (
        <div className={styles.dateContainer}>
          <span className={styles.date}>{formatDateLabel(inspection.date)}</span>
        </div>
      )}
      <RadioGroup.Option
        ref={markerStartRef}
        key={inspection.id}
        value={inspection.id}
        className={() => `flex justify-center focus-visible:outline-dashed outline-white h-14`}
        disabled={isAddControl}
      >
        {({ checked }) => (
          <TimelineItem date={inspection.date} isChecked={checked} isToCompare={isAddControl} />
        )}
      </RadioGroup.Option>
    </div>
  );
};

export default RadioGroupOption;
