import { useEffect } from 'react';

export const useAutosize = (textarea: HTMLTextAreaElement | null, value: string) => {
  useEffect(() => {
    if (!textarea) return;

    textarea.style.height = '0px';
    const scrollHeight = textarea.scrollHeight;

    textarea.style.height = scrollHeight + 'px';
  }, [textarea, value]);
};
