import { useCallback, useRef } from 'react';
import outboardLogo from '@assets/icons/outboard-logo.svg';
import {
  useCurrentInspectionSelector,
  useDropdownVisibleInspectionsSelector,
} from '@core/store/slices/inspections';
import { Timeline } from '@components/Timeline';
import { InspectionsDate } from './components/InspectionsDate';
import { User } from './components/User';
import { IUpdateScrollPosition } from './interfaces';
import styles from './styles.scss';

export const Header = () => {
  const currentInspection = useCurrentInspectionSelector();
  const visibleInspections = useDropdownVisibleInspectionsSelector();
  const updateScrollPositionByInspectionDateRef = useRef<
    ((options: IUpdateScrollPosition) => void) | null
  >(null);

  const setScrollPositionByInspectionDateHandler = useCallback(
    (handler: (options: IUpdateScrollPosition) => void) => {
      updateScrollPositionByInspectionDateRef.current = handler;
    },
    [],
  );

  const handleUpdateScrollPositionByInspectionDate = useCallback(
    (options: IUpdateScrollPosition) => {
      updateScrollPositionByInspectionDateRef.current?.(options);
    },
    [],
  );

  return (
    <header className={styles.header}>
      <div className={styles.logoWrapper}>
        <img className={styles.logo} src={outboardLogo} alt='outflier logo' />
      </div>
      <div className={styles.timelineWrapper}>
        <Timeline onSetScrollPosition={setScrollPositionByInspectionDateHandler} />
      </div>
      <div className={styles.dateDropdownWrapper}>
        <InspectionsDate
          onUpdateScrollPosition={handleUpdateScrollPositionByInspectionDate}
          toCompare={false}
          inspection={currentInspection}
          inspectionsOptions={visibleInspections}
        />
      </div>
      <div className={styles.userDropdownWrapper}>
        <User />
      </div>
    </header>
  );
};
