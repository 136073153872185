import Matrix from 'ml-matrix';
import { IDrawable } from '../../../interfaces/drawable';

export function getSelectedAnomalyCameraPosition(
  solarPanel: IDrawable,
  datasetCameraPoses: [number, number, number, number][],
) {
  const [x, y, z] = solarPanel.vertices;
  const enuDatasetCameraPoses = datasetCameraPoses.map((cameraPose) =>
    cameraPose.slice(0, 3).map((coord) => coord[3]),
  );
  const matrixFrobeniusNorm = enuDatasetCameraPoses.map((enu) =>
    Matrix.rowVector([enu[0] - x, enu[1] - y, enu[2] - z]).norm('frobenius'),
  );
  const bestPoseIdx = matrixFrobeniusNorm.indexOf(Math.min(...matrixFrobeniusNorm));

  const view = {
    up: { x: 0, y: 0, z: 1 },
    center: { x, y, z },
    eye: {
      x: enuDatasetCameraPoses[bestPoseIdx][0],
      y: enuDatasetCameraPoses[bestPoseIdx][1],
      z: enuDatasetCameraPoses[bestPoseIdx][2],
    },
  };

  return view;
}
