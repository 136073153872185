export enum EAppAction {
  Login = 'login',
  Logout = 'logout',

  SidebarSiteBackClick = 'sidebarSiteBackClick',
  SidebarZoneBackClick = 'sidebarZoneBackClick',
  SidebarAnomalyBackClick = 'sidebarAnomalyBackClick',

  SidebarSitesTableClick = 'sidebarSitesTableClick',
  SidebarSiteTableClick = 'sidebarSiteTableClick',
  SidebarZoneTableClick = 'sidebarZoneTableClick',

  SidebarSiteListingClick = 'sidebarSiteListingClick',
  SidebarZoneListingClick = 'sidebarZoneListingClick',
  SidebarAnomalyListingClick = 'sidebarAnomalyListingClick',

  SidebarEditSiteClick = 'sidebarEditSiteClick',
  SidebarCreateSiteClick = 'sidebarCreateSiteClick',

  HeaderInspectionDropdownSelect = 'headerInspectionDropdownSelect',
  HeaderInspectionTimelineSelect = 'headerInspectionTimelineSelect',
  HeaderCompareModeClick = 'headerCompareModeClick',
  HeaderTimelineScrollClick = 'headerTimelineScrollClick',

  MapSiteClick = 'mapSiteClick',
  MapZoneClick = 'mapZoneClick',
  MapAnomalyClick = 'mapAnomalyClick',
  MapZoom = 'mapZoom',
  MapZoomIn = 'mapZoomIn',
  MapZoomOut = 'mapZoomOut',

  ControlsZoomInMap = 'controlsZoomInMap',
  ControlsZoomOutMap = 'controlsZoomOutMap',
  ControlsCenterMap = 'controlsCenterMap',
  ControlsSolarPanels = 'controlsSolarPanels',
  ControlsFlightPath = 'controlsFlightPath',
  ControlsMode = 'controlsMode',
}
