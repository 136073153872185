import _ from 'lodash';
import { RootState } from '@core/store';
import { getKeyPathsFromObject } from '@core/utils/transformation';
import { RecursivePartial } from '../interfaces/common';
import { IPersistSaveStrategy, IRehydrateSaveStrategy } from '../interfaces/strategies';

export function partialPersistSaveStateStrategy({
  appState,
  persistedState,
}: IPersistSaveStrategy<RecursivePartial<RootState>>) {
  const copiedPersistedState = _.cloneDeep(persistedState);
  const keyPaths = getKeyPathsFromObject(appState);

  keyPaths.forEach((path) => {
    const partialStateValue = _.get(appState, path);
    // NOTE: mutate "persistedState" by partial value
    _.set(copiedPersistedState, path, partialStateValue);
  });

  return copiedPersistedState;
}

export function partialRehydrateSaveStateStrategy({
  appState,
  persistedState,
  keyPaths = [],
}: IRehydrateSaveStrategy<RecursivePartial<RootState>>) {
  const copiedAppState = _.cloneDeep(appState);
  const copiedPersistedState = _.cloneDeep(persistedState);

  keyPaths.forEach((path) => {
    const partialPersistedStateValue = _.get(copiedPersistedState, path);
    // NOTE: mutate "app state" by partial value
    _.set(copiedAppState, path, partialPersistedStateValue);
  });

  return copiedAppState;
}
