/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EInspectionFrequency } from '@core/enums';
import { useDispatchTyped } from '@core/hooks';
import { ISelectOption } from '@core/interfaces';
import { setEditSiteInspectionFrequency, useSiteEditInfoSelector } from '@core/store/slices';
import { convertTheFirstCharToLowerCase } from '@core/utils/formatting/stringFormat';
import InspectionFrequencyBody from './InspectionFrequencyBody';

type TInspectionFrequencyState = {
  id: number;
  name: ISelectOption['name'];
};

const InspectionFrequencyEdit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatchTyped();
  const editInfo = useSiteEditInfoSelector();

  const [selectedDate, setSelectedDate] = useState<TInspectionFrequencyState | null>(null);

  const options = useMemo(
    () => [
      {
        id: EInspectionFrequency.Monthly,
        name: t(`sidebar.site.edit.sections.inspectionFrequency.dates.monthly`),
      },
      {
        id: EInspectionFrequency.HalfYearly,
        name: t(`sidebar.site.edit.sections.inspectionFrequency.dates.halfYearly`),
      },
      {
        id: EInspectionFrequency.Yearly,
        name: t(`sidebar.site.edit.sections.inspectionFrequency.dates.yearly`),
      },
    ],
    [],
  );

  const handleSelectChange = useCallback((option: ISelectOption | null) => {
    if (option) {
      dispatch(setEditSiteInspectionFrequency(option.id as EInspectionFrequency));
    }
  }, []);

  useEffect(() => {
    if (editInfo?.inspectionFrequency) {
      const foundFrequency = Object.entries(EInspectionFrequency).find(
        ([_, value]) => editInfo.inspectionFrequency === value,
      );

      if (foundFrequency) {
        const key = foundFrequency[0];
        setSelectedDate({
          id: editInfo.inspectionFrequency,
          name: t(
            `sidebar.site.edit.sections.inspectionFrequency.dates.${convertTheFirstCharToLowerCase(
              key,
            )}`,
          ),
        });
      }
    }
  }, [editInfo?.inspectionFrequency]);

  return (
    <>
      <InspectionFrequencyBody
        text={{
          notification: t('sidebar.site.edit.sections.inspectionFrequency.notification'),
        }}
        selected={selectedDate}
        options={options}
        onSelect={handleSelectChange}
      />
    </>
  );
};

export default InspectionFrequencyEdit;
