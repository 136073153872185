import { loadModel } from './3Dmodel';
import { anomalies } from './anomalies';
import { inspection } from './inspections';
import { location } from './locations';
import { perimeter } from './perimeter';
import { planning } from './planning';
import { program } from './programs';
import { reports } from './report';
import { user } from './user';

export const api = {
  user,
  location,
  program,
  inspection,
  reports,
  planning,
  loadModel,
  perimeter,
  anomalies,
};
