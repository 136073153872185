import { axiosInstance } from '@core/api/axios';

export enum EJobStatus {
  Closed = 'closed',
  Completed = 'completed',
  Created = 'created',
  Failed = 'failed',
  In_progress = 'in_progress',
  Published = 'published',
  Publishing = 'publishing',
  Queued = 'queued',
  Stopped = 'stopped',
  Unknown = 'unknown',
}

export const getJobStatus = async (id: string | number): Promise<EJobStatus> => {
  return axiosInstance.get<EJobStatus>(`/locations/${id}/job_status`).then(({ data }) => data);
};
