import styles from './styles.scss';

interface IProps {
  title: string;
}

const AccordionHeadOption: React.FC<IProps> = ({ title }) => {
  return <span className={styles.head}>{title}</span>;
};

export default AccordionHeadOption;
