import React, { useContext, useMemo, useRef } from 'react';
import cn from 'classnames';
import TallerIcon from '@assets/icons/taller.svg';
import { ViewersContext } from '@core/contexts';
import { EViewer, EViewerType } from '@core/enums';
import { useDispatchTyped } from '@core/hooks';
import { toggleViewers, useControlsSelector, useViewerSelector } from '@core/store/slices';
import { getViewers } from '@modules/Sidebar/utils/getViewers';
import { Switcher } from '@components/Switcher';
import styles from './styles.scss';
import { getHeader } from './utils/getHeader';

interface IProps {
  classNames?: string;
  options: unknown[];
  selectedId: number | string;
  isDisabledActions?: boolean;
  onSwitch?: (index: number) => void;
  toCompare?: boolean;
}

export const InspectionsView: React.FC<IProps> = (props) => {
  const { classNames, options, selectedId, isDisabledActions = false, onSwitch, toCompare } = props;

  const { viewer, sidebarViewer } = useViewerSelector();
  const { mode } = useControlsSelector();
  const viewers = useContext(ViewersContext);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatchTyped();
  const { isCompareMode } = useControlsSelector();

  const classes = cn(styles.container, classNames, {
    [styles.overflow]: ![EViewer.Video, EViewer.Image].includes(sidebarViewer),
  });

  const handleSwitch = (index: number) => onSwitch && onSwitch(index);

  const handleModeChange = () => dispatch(toggleViewers());

  const viewersProps: Record<string, any> = useMemo(
    () => ({
      [EViewer.Model]: {
        container: contentRef.current,
        showControls: false,
        type: EViewerType.InspectionView,
      },
      [EViewer.Map]: { type: EViewerType.InspectionView, showControls: false, minZoom: 17 },
      [EViewer.Video]: {
        type: EViewerType.InspectionView,
        showControls: false,
        toCompare: toCompare,
      },
      [EViewer.Image]: { type: EViewerType.InspectionView, showControls: false },
    }),
    [contentRef.current, toCompare],
  );

  const filteredViewers = useMemo(
    () =>
      getViewers({
        viewers: {
          state: viewers,
          viewer,
          sidebarViewer,
        },
        options: {
          mode,
          type: EViewerType.InspectionView,
        },
      }),

    [viewers, viewer, sidebarViewer, mode],
  );

  return (
    <div className={classes}>
      <div className={styles.header}>
        <h3 className={styles.headerTitle}>{getHeader(sidebarViewer)}</h3>
        <button
          className={styles.headerTallerButton}
          onClick={handleModeChange}
          disabled={isCompareMode || isDisabledActions}
        >
          <img src={TallerIcon} alt='taller icon' />
        </button>
      </div>
      <div ref={contentRef} className={cn(styles.content)}>
        {filteredViewers.map(({ name, component }) => {
          const classes = cn(styles.item, { [styles.hide]: sidebarViewer !== name });

          return (
            <div key={name} className={classes}>
              {component(viewersProps[name])}
            </div>
          );
        })}
      </div>
      {[EViewer.Video, EViewer.Image].includes(sidebarViewer) && (
        <div className={styles.footer}>
          <Switcher
            mode={EViewerType.InspectionView}
            options={options}
            selectedId={selectedId}
            onArrowClick={!isDisabledActions ? handleSwitch : null}
            onDotClick={!isDisabledActions ? handleSwitch : null}
          />
        </div>
      )}
    </div>
  );
};
