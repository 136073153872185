import { FC, memo } from 'react';
import { ReactSVG } from 'react-svg';
import cn from 'classnames';
import deleteIcon from '@assets/icons/delete.svg';
import nonActiveDeleteIcon from '@assets/icons/non-active-delete.svg';
import { useRenderInputError } from '@core/hooks/useRenderInputError';
import styles from './styles.scss';

export enum EDeleteMode {
  ClearInput = 'clearInput',
  RemoveInput = 'removeInput',
}
interface IProps {
  value: string;
  errorMessage: string | null;
  name: string;
  mode: EDeleteMode;
  placeholder?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDelete: () => void;
  onClear: () => void;
}

const AddInput: FC<IProps> = ({
  value,
  errorMessage,
  name,
  placeholder,
  mode,
  onChange,
  onClear,
  onDelete,
}) => {
  const shouldRenderError = useRenderInputError(value, errorMessage);

  return (
    <div className={styles.root}>
      <input
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={styles.addInput}
      />
      <div
        className={styles.deleteIcon}
        onClick={mode === EDeleteMode.RemoveInput ? onDelete : onClear}
      >
        <ReactSVG className={styles.delete} src={value ? deleteIcon : nonActiveDeleteIcon} />
      </div>
      <span className={cn(styles.errorMessage, shouldRenderError ? styles.visible : styles.hidden)}>
        {errorMessage}
      </span>
    </div>
  );
};

export default memo(AddInput);
