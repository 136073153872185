import { EAspectRatio, EViewerType } from '@core/enums';
import { ISample, ISampleDetection } from '@core/interfaces';
import {
  convertXFromPixelsToPercents,
  convertYFromPixelsToPercents,
} from './convertFromPixelsToPercents';
import { getSampleDetectionHeight, getSampleDetectionWidth } from './getDetectionMeasures';
import {
  getMergedPolygonsWithDetections,
  getSeparatedDetectionsByStatus,
} from './getMergedPolygonsWithDetections';
import { updatedDetectionsByPolygons } from './updateDetectionsByPolygons';

interface ITransformDetectionsOptions {
  sample: ISample | null;
  type: EViewerType;
  containerHeight: number;
  containerWidth: number;
  aspectRatio: EAspectRatio;
}

export const transformDetections = ({
  sample,
  type,
  containerHeight,
  containerWidth,
  aspectRatio,
}: ITransformDetectionsOptions): ISampleDetection[] => {
  if (!sample || !sample.detections?.length) return [];

  const mergedPolygonsWithDetections = getMergedPolygonsWithDetections(
    getSeparatedDetectionsByStatus(sample.detections),
  );
  const updatedDetections = updatedDetectionsByPolygons(mergedPolygonsWithDetections);

  return updatedDetections.map((detection) => ({
    ...detection,
    xPercent: convertXFromPixelsToPercents({ detection, aspectRatio }),
    yPercent: convertYFromPixelsToPercents({ detection, aspectRatio }),
    h: getSampleDetectionHeight({
      detectionHeight: detection.h,
      containerHeight,
      type,
      aspectRatio,
    }),
    w: getSampleDetectionWidth({
      detectionWidth: detection.w,
      containerWidth,
      type,
      aspectRatio,
    }),
  }));
};
