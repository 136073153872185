import mapboxgl from 'mapbox-gl';
import { MapClickEvent, MapMouseEnterEvent, MapMouseLeaveEvent } from '@core/interfaces/map';
import { EAnomalyLayers } from '../../enums/layers';

interface IAnomalyHandlers {
  onClick?: (e: MapClickEvent) => void | null;
  onMouseEnter?: (e: MapMouseEnterEvent) => void | null;
  onMouseLeave?: (e: MapMouseLeaveEvent) => void | null;
}

export const setAnomaliesHandlers = (
  map: mapboxgl.Map,
  { onClick, onMouseEnter, onMouseLeave }: IAnomalyHandlers,
) => {
  if (onClick) {
    map.on('click', EAnomalyLayers.Anomalies, onClick);
  }
  if (onMouseEnter) {
    map.on('mouseenter', EAnomalyLayers.Anomalies, onMouseEnter);
  }
  if (onMouseLeave) {
    map.on('mouseleave', EAnomalyLayers.Anomalies, onMouseLeave);
  }
};
