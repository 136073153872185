import { IGpsBoundaries } from '@core/api/locations/create';
import { ESiteStatuses } from '@core/enums';

export enum ELocationStatus {
  UnderCreation = 'Under creation',
  WaitingForApproval = 'Waiting for approval',
  Approved = 'Approved',
}

export interface ISite {
  status?: ESiteStatuses;
  last_inspection_date?: string;
  name: string;
  lat: number;
  long: number;
  altitude: number;
  picture: string;
  location_status?: ELocationStatus;
  inspection_frequency?: number;
  gps_boundaries: IGpsBoundaries;
  loc_id: string;
}
