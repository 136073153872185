import { LocalStorageAdapter, SessionStorageAdapter } from '../adapters';
import { CookiesStorageAdapter } from '../adapters/cookies';
import { IStorage } from '../interfaces/storage';

export enum EStorage {
  LocalStorage = 'localStorage',
  SessionStorage = 'sessionStorage',
  CookiesStorage = 'cookiesStorage',
}

export class StorageFactory {
  public static getStorage(type: EStorage): IStorage {
    switch (type) {
      case EStorage.LocalStorage:
        return new LocalStorageAdapter();
      case EStorage.SessionStorage:
        return new SessionStorageAdapter();
      case EStorage.CookiesStorage:
        return new CookiesStorageAdapter();
      default:
        throw new Error('Incorrect storage');
    }
  }
}
