import { ESidebar } from '@core/enums';
import { isOneValueValid } from '@core/utils';

export function isZoomInBySidebar(
  currentSidebar: ESidebar | null,
  previousSidebar: ESidebar | null,
) {
  if (!currentSidebar || !previousSidebar) {
    return false;
  }

  const zoomInToSite =
    currentSidebar === ESidebar.Site && [ESidebar.Sites].includes(previousSidebar);
  const zoomInToZone =
    currentSidebar === ESidebar.Zone && [ESidebar.Sites, ESidebar.Site].includes(previousSidebar);
  const zoomInToAnomaly =
    currentSidebar === ESidebar.Anomaly &&
    [ESidebar.Sites, ESidebar.Site, ESidebar.Zone].includes(previousSidebar);

  return isOneValueValid(zoomInToSite, zoomInToZone, zoomInToAnomaly);
}

export function isZoomOutBySidebar(
  currentSidebar: ESidebar | null,
  previousSidebar: ESidebar | null,
) {
  if (!currentSidebar || !previousSidebar) {
    return false;
  }

  const zoomOutToSites =
    currentSidebar === ESidebar.Sites &&
    [ESidebar.Site, ESidebar.Zone, ESidebar.Anomaly].includes(previousSidebar);
  const zoomOutToSite =
    currentSidebar === ESidebar.Site && [ESidebar.Zone, ESidebar.Anomaly].includes(previousSidebar);
  const zoomOutToZone =
    currentSidebar === ESidebar.Zone && [ESidebar.Anomaly].includes(previousSidebar);

  return isOneValueValid(zoomOutToSites, zoomOutToSite, zoomOutToZone);
}
