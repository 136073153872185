import { ToastContainer } from 'react-toastify';
import { AUTO_LOGOUT_TIMEOUT } from '@core/constants';
import { useAutoLogin, useAutoLogout } from '@core/hooks';
import { usePreloaderSelector, useAccessControlSelector } from '@core/store/slices';
import { Layout } from '@modules/Layout';
import { Login } from '@modules/Login';
import { Preloader } from '@components/Preloader';

export const App = () => {
  const { user, loading } = useAccessControlSelector();
  const preloader = usePreloaderSelector();

  useAutoLogin();
  useAutoLogout(AUTO_LOGOUT_TIMEOUT);

  if (loading) return <Preloader />;

  return (
    <>
      <ToastContainer position='bottom-center' />
      {preloader && <Preloader />}
      {user ? <Layout /> : <Login />}
    </>
  );
};
