import i18n from 'i18next';
import { anomaliesStatusesColor } from '@core/constants/anomalies';
import { IStatus } from '@core/interfaces';
import { EAnomalyStatus, IAnomaly } from '@core/interfaces/anomaly';

export const getStatusesForAnomalies = (anomalies: IAnomaly[] | null): IStatus[] => {
  if (!anomalies) return [];

  let toReviewCounter = 0;
  let falseCounter = 0;
  let minorCounter = 0;
  let majorCounter = 0;

  anomalies.forEach((anomaly) => {
    if (anomaly.status === EAnomalyStatus.ToReview) {
      toReviewCounter++;
    }
    if (anomaly.status === EAnomalyStatus.False) {
      falseCounter++;
    }
    if (anomaly.status === EAnomalyStatus.Minor) {
      minorCounter++;
    }
    if (anomaly.status === EAnomalyStatus.Major) {
      majorCounter++;
    }
  });

  return [
    {
      name: i18n.t('statusesAnomalies.toReview'),
      amount: toReviewCounter,
      color: anomaliesStatusesColor[EAnomalyStatus.ToReview],
    },
    {
      name: i18n.t('statusesAnomalies.false'),
      amount: falseCounter,
      color: anomaliesStatusesColor[EAnomalyStatus.False],
    },
    {
      name: i18n.t('statusesAnomalies.minor'),
      amount: minorCounter,
      color: anomaliesStatusesColor[EAnomalyStatus.Minor],
    },
    {
      name: i18n.t('statusesAnomalies.major'),
      amount: majorCounter,
      color: anomaliesStatusesColor[EAnomalyStatus.Major],
    },
  ];
};
