import { IReport } from '@core/interfaces/report';

// To move selected report to the end of reports array
export const reorderReports = (zonesReports: IReport[][], selectedInspectionId: string) => {
  const copiedReports = [...zonesReports];
  const selectedZoneIndex = copiedReports.findIndex((reports) =>
    reports.some((item) => item.inspection_id === selectedInspectionId),
  );

  if (selectedZoneIndex !== -1) {
    const selectedZoneReports = copiedReports.splice(selectedZoneIndex, 1)[0];

    const selectedIndex = selectedZoneReports.findIndex(
      (item) => item.inspection_id === selectedInspectionId,
    );

    if (selectedIndex !== -1) {
      const selectedObject = selectedZoneReports.splice(selectedIndex, 1)[0];
      selectedZoneReports.push(selectedObject);
    }

    copiedReports.push(selectedZoneReports);
  }

  return copiedReports;
};
