import { axiosInstance } from '@core/api/axios';

export const getSampleAnomalyMaskByReportId = async (
  reportId: string | number,
  sampleId: string | number,
) => {
  return axiosInstance
    .get(`/reports/${reportId}/samples/${sampleId}/anomaly_mask`, { responseType: 'blob' })
    .then((res) => URL.createObjectURL(res.data));
};
