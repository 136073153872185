import { IDetectionsMergedPolygonData } from '../interfaces/detections';

// NOTE: "updatedDetectionsByPolygons" sets the largest captured area
export function updatedDetectionsByPolygons(
  polygonsWithDetections: IDetectionsMergedPolygonData[],
) {
  return polygonsWithDetections.map(({ detections, ...polygon }) => {
    const coordinates = polygon.geometry.coordinates[0];

    const xAxis = coordinates.map((point) => point[0]);
    const yAxis = coordinates.map((point) => point[1]);

    const minX = Math.min(...xAxis);
    const minY = Math.min(...yAxis);
    const maxX = Math.max(...xAxis);
    const maxY = Math.max(...yAxis);

    const height = maxY - minY;
    const width = maxX - minX;

    return {
      ...detections[0],
      x: minX,
      y: minY,
      w: width,
      h: height,
    };
  });
}
