// Haversine function
function haversine(lat1: number, lon1: number, lat2: number, lon2: number) {
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in km
}

// Helper function to convert degrees to radians
function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}

// Calculate the distance using haversine function
export function calculateDistance(locationItem: ILocationItem, lat: number, long: number) {
  if (!locationItem.lat || !locationItem.long) return Infinity;
  return haversine(locationItem.lat, locationItem.long, lat, long);
}

interface ILocationItem {
  long?: number;
  lat?: number;
}

export function findClosestByLongLat<T extends ILocationItem>(
  items: T[],
  long: number,
  lat: number,
): T | null {
  let closestItem: T | null = null;
  let closestDistance = Infinity;
  for (const item of items) {
    const distance = calculateDistance(item, lat, long);
    if (distance < closestDistance) {
      closestItem = item;
      closestDistance = distance;
    }
  }

  return closestItem;
}
