import { FC, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { toast } from 'react-toastify';
import cn from 'classnames';
import _ from 'lodash';
import closeModal from '@assets/icons/close-modal.svg';
import uploadIcon from '@assets/icons/upload.svg';
import { api } from '@core/api';
import { ERROR_TOAST_DELAY, OUTFLIER_ACCESS_TOKEN } from '@core/constants';
import { EConnectionStatus, EErrorMessage } from '@core/enums';
import { EUploadingStep } from '@core/enums/uploadModal';
import { useDispatchTyped } from '@core/hooks';
import { browserLocalStorage } from '@core/services';
import {
  addInspections,
  useCurrentInspectionIdSelector,
  useInspectionsSelector,
  useNetworkConnectionSelector,
} from '@core/store/slices';
import { ProgressBarLayout } from '@components/ProgressBar';
import styles from './styles.scss';
import { checkIsNumberMultiple } from '../../utils/checkIsNumberMultiple';
import { getFilesToUploadData } from '../../utils/getFilesToUploadData';
import { getPercentage } from '../../utils/getPercentage';

interface IProps {
  onClose: () => void;
  initialStep?: EUploadingStep;
}

export interface IFileData {
  formData: FormData;
  folder: string;
}

const MAX_PARALLEL_REQUESTS = 5;
const PROGRESS_UPDATE_INTERVAL = 10;
const FULL_PERCENTAGE = 100;
const ZERO_PERCENTAGE = 0;
const INITIAL_UPLOADED_FILES = 0;
const MODAL_CHANGE_DELAY = 1000;

export const UploadInspectionModal: FC<IProps> = ({
  onClose,
  initialStep = EUploadingStep.Upload,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatchTyped();

  const currentInspectionId = useCurrentInspectionIdSelector();
  const { inspections } = useInspectionsSelector();
  const { status, statusPrevious } = useNetworkConnectionSelector();

  const hiddenFileInputRef = useRef<HTMLInputElement | null>(null);
  const isUploadCanceledRef = useRef(false);
  const uploadedFilesCountRef = useRef(0);

  const [step, setStep] = useState<EUploadingStep>(initialStep);
  const [progressPercentage, setProgressPercentage] = useState(0);

  const uploadAttributes = { type: 'file', directory: '', webkitdirectory: '', mozdirectory: '' };
  const progressBarTitle = t('sidebar.zone.modals.loadingProgressHeader');
  const isConnectionLost =
    status === EConnectionStatus.Offline && statusPrevious === EConnectionStatus.Online;

  const handleUploadFiles = () => {
    hiddenFileInputRef?.current?.click();
  };

  const handleUploadCancel = () => {
    if (step === EUploadingStep.Progress) {
      isUploadCanceledRef.current = true;
      setProgressPercentage(ZERO_PERCENTAGE);
    }

    setStep(EUploadingStep.Upload);
  };

  const handleBatchUpload = async (
    batch: IFileData[],
    isLastBatch: boolean,
    filesCount: number,
  ) => {
    if (!currentInspectionId || isUploadCanceledRef.current) return Promise.all([]);

    return Promise.all(
      batch?.map((file) => {
        return api.inspection.createInspectionFile(currentInspectionId, file.formData, file.folder);
      }),
    )
      .then(() => {
        const uploadedFiles = uploadedFilesCountRef.current + MAX_PARALLEL_REQUESTS;

        if (!isLastBatch) {
          uploadedFilesCountRef.current = uploadedFiles;

          const isMultipleOfBatchSize = checkIsNumberMultiple(
            uploadedFilesCountRef.current,
            PROGRESS_UPDATE_INTERVAL,
          );

          if (isMultipleOfBatchSize) {
            setProgressPercentage(getPercentage(uploadedFilesCountRef.current, filesCount));
          }
        } else {
          completeUpload(currentInspectionId);
        }
      })
      .catch((error: Error) => {
        if (error.message !== EErrorMessage.NetworkError) {
          toast.error(t('errors.somethingWentWrong'), { autoClose: ERROR_TOAST_DELAY });
        }
      });
  };

  const completeUpload = (inspectionId: string) => {
    api.inspection
      .updateInspectionUploadedById(inspectionId)
      .then(() => {
        const updatedInspections = inspections?.map((inspection) => {
          if (inspection.id === inspectionId) {
            return { ...inspection, uploaded: true };
          } else {
            return inspection;
          }
        });
        dispatch(addInspections(updatedInspections));
        setProgressPercentage(FULL_PERCENTAGE);
        setTimeout(() => {
          setStep(EUploadingStep.Complete);
        }, MODAL_CHANGE_DELAY);
      })
      .catch((error: Error) => {
        if (error.message !== EErrorMessage.NetworkError) {
          toast.error(t('errors.somethingWentWrong'), { autoClose: ERROR_TOAST_DELAY });
        }
      });
  };

  const handleChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files || !currentInspectionId) return;
      setStep(EUploadingStep.Progress);
      const filesUploaded = event.target.files;
      let existFilesNames: string[] = [];

      try {
        existFilesNames = await api.inspection.getUploadedFilesByInspectionId(currentInspectionId);
        const filesData: IFileData[] = getFilesToUploadData(filesUploaded, existFilesNames);

        if (filesData.length) {
          const batchCalls = _.chunk(filesData, MAX_PARALLEL_REQUESTS);
          const lastBatchIndex = batchCalls.length - 1;

          for (let i = 0; i < batchCalls.length; i++) {
            const accessToken = browserLocalStorage.getItem(OUTFLIER_ACCESS_TOKEN);
            if (isUploadCanceledRef.current || !accessToken || isConnectionLost) {
              isUploadCanceledRef.current = false;
              uploadedFilesCountRef.current = INITIAL_UPLOADED_FILES;
              break;
            }
            await handleBatchUpload(batchCalls[i], i === lastBatchIndex, filesUploaded.length);
          }
        } else {
          completeUpload(currentInspectionId);
        }
      } catch {
        isUploadCanceledRef.current = true;
        uploadedFilesCountRef.current = INITIAL_UPLOADED_FILES;
        toast.error(t('errors.failedUpload'), { autoClose: ERROR_TOAST_DELAY });
      }
    },
    [isConnectionLost],
  );

  useEffect(() => {
    if (isConnectionLost) {
      isUploadCanceledRef.current = true;
      uploadedFilesCountRef.current = INITIAL_UPLOADED_FILES;
      setProgressPercentage(ZERO_PERCENTAGE);
      setStep(EUploadingStep.Upload);
    }
  }, [isConnectionLost]);

  return (
    <div className={styles.modalBackground}>
      <div className={styles.modal}>
        {step === EUploadingStep.Upload && (
          <ReactSVG src={closeModal} className={styles.closeIcon} onClick={onClose}></ReactSVG>
        )}
        {[EUploadingStep.Upload, EUploadingStep.Progress].includes(step) && (
          <Fragment>
            <h3 className={styles.title}>{t('sidebar.zone.modals.uploadModalHeader')}</h3>
            <div className={styles.description}>
              {t('sidebar.zone.modals.uploadModalNotification')}
            </div>
            <div className={styles.bottomWrapper}>
              {step === EUploadingStep.Upload && (
                <Fragment>
                  <button
                    className={cn(styles.uploadButton)}
                    disabled={isConnectionLost}
                    onClick={handleUploadFiles}
                  >
                    <ReactSVG src={uploadIcon} />
                    <span>{t('buttons.uploadToServer')}</span>
                  </button>

                  <input
                    multiple
                    {...uploadAttributes}
                    onChange={handleChange}
                    ref={hiddenFileInputRef}
                    style={{ display: 'none' }}
                  />
                </Fragment>
              )}
              {step === EUploadingStep.Progress && (
                <div className={styles.progressModalBottom}>
                  <div className={styles.progressBarWrapper}>
                    <ProgressBarLayout
                      title={progressBarTitle}
                      percentage={progressPercentage}
                      isTextHidden={false}
                      titleClassName='mb-[10px]'
                      textClassName='text-white text-sm leading-6'
                    />
                  </div>
                  <button className={styles.cancelUploadingIcon} onClick={handleUploadCancel}>
                    <ReactSVG src={closeModal}></ReactSVG>
                  </button>
                </div>
              )}
            </div>
          </Fragment>
        )}
        {step === EUploadingStep.Complete && (
          <Fragment>
            <h3 className={styles.title}>{t('sidebar.zone.modals.loadingCompleteHeader')}</h3>
            <div className={styles.description}>
              {t('sidebar.zone.modals.loadingCompleteNotification')}
            </div>
            <div className={styles.bottomWrapper}>
              <button className={cn(styles.uploadButton)} onClick={onClose}>
                <span>{t('buttons.continue')}</span>
              </button>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};
