import { useEffect } from 'react';
import { api } from '@core/api';
import { addUser, setPreloader } from '@core/store/slices';
import { useDispatchTyped } from './useReduxTyped';

export const useAutoLogin = () => {
  const dispatch = useDispatchTyped();

  useEffect(() => {
    (async () => {
      await api.user
        .usersMe()
        .then((user) => dispatch(addUser(user)))
        .catch(() => {
          dispatch(addUser(null));
          dispatch(setPreloader(false));
        });
    })();
  }, []);
};
