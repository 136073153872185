import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { EMode } from '@core/enums';
import { useDispatchTyped, useSelectorTyped } from '@core/hooks';
import { TEditZone } from '@core/interfaces';
import {
  nextCurrentProgramId,
  previousCurrentProgramId,
  setCreateSiteCurrentProgramId,
  updateProgramById,
  useCurrentCreateSiteProgramSelector,
} from '@core/store/slices/createSite';
import { IUpdateInputData } from '@modules/Sidebar/components/AccordionView/BodyOptions/Roles/interfaces/updateItem';
import SelectedZone from '@modules/Sidebar/components/AccordionView/BodyOptions/Zones/components/SelectedZone/SelectedZone';
import { BodyItem, StyledTable } from '@components/StyledTable/StyledTable';
import styles from '../NoFlyZones/styles.scss';

const ZonesCreate = () => {
  const { t } = useTranslation();
  const programs = useSelectorTyped((state) => state.createSite.programs);
  const siteName = useSelectorTyped((state) => state.createSite.name);
  const currentProgramId = useSelectorTyped((state) => state.createSite.currentProgramId);
  const currentProgram = useCurrentCreateSiteProgramSelector();
  const dispatch = useDispatchTyped();

  const handleBackClick = () => {
    dispatch(setCreateSiteCurrentProgramId(''));
  };

  const handleTableRowClick = (zoneId: string) => {
    dispatch(setCreateSiteCurrentProgramId(zoneId));
    dispatch(updateProgramById({ id: zoneId, changes: { isViewed: true } }));
  };

  const columnWidth = ['200px', '100px'];

  const body: BodyItem[] = programs.map((program) => {
    const { isViewed, custom_inspection_plan_requested, custom_overview_plan_requested } = program;

    let name = t('sidebar.site.edit.sections.zones.table.toReview');
    if (isViewed) name = t('sidebar.site.edit.sections.zones.table.toValidate');
    if (custom_overview_plan_requested || custom_inspection_plan_requested)
      name = t('sidebar.site.edit.sections.zones.table.toReadjust');

    return {
      id: program.program_id,
      data: [
        {
          name: program.name,
          className: 'pr-3',
        },
        {
          name: name,
          className: cn(
            {
              'text-outflier-yellow':
                custom_overview_plan_requested || custom_inspection_plan_requested,
              'text-outflier-green': isViewed,
              'text-outflier-soft-gray':
                !isViewed && !custom_overview_plan_requested && !custom_inspection_plan_requested,
            },
            'flex flex-end font-normal',
          ),
        },
      ],
    };
  });

  const handleChange = (data: IUpdateInputData) => {
    const firstKey = Object.keys(data)[0];
    if (firstKey) {
      const firstValue = data[firstKey].value;
      dispatch(updateProgramById({ id: currentProgramId, changes: { name: firstValue } }));
    }
  };

  const handleNextClick = () => {
    dispatch(nextCurrentProgramId());
  };

  const handlePreviousClick = () => {
    dispatch(previousCurrentProgramId());
  };

  const zoneData: TEditZone = {
    program_id: currentProgram?.program_id ?? '',
    customName: currentProgram?.name ?? 'Site name',
    customInspectionPlanRequested: currentProgram?.custom_inspection_plan_requested,
    customOverviewPlanRequested: currentProgram?.custom_overview_plan_requested,
    name: {
      value: currentProgram?.name ?? '',
      errorMessage: null,
    },
  };
  if (currentProgram?.plans) {
    zoneData.plans = currentProgram?.plans;
  }

  return (
    <div className={styles.body}>
      {currentProgramId && (
        <SelectedZone
          mode={EMode.Create}
          zone={zoneData}
          siteName={siteName}
          onBackClick={handleBackClick}
          onChange={handleChange}
          onRightClick={handleNextClick}
          onLeftClick={handlePreviousClick}
        />
      )}
      <h2 className={styles.notification}>{t('sidebar.site.edit.sections.zones.notification')}</h2>
      <span className='px-2.5 text-sm font-medium'>
        {t('sidebar.site.edit.sections.zones.table.head.column1.keyWithCount', {
          count: programs.length ?? 0,
        })}
      </span>
      <div className={styles.wrapper}>
        <StyledTable
          body={body}
          head={[]}
          columnWidth={columnWidth}
          isSortable={false}
          handleTableRowClick={handleTableRowClick}
        />
      </div>
    </div>
  );
};

export default ZonesCreate;
