export enum EModelFlightPathLayer {
  FlightPath = 'flight_path',
  FlightPathStart = 'flight_path_start',
  FlightPathEnd = 'flight_path_end',
}

export enum EModelSolarPanelsLayer {
  Panel = 'solar_panel',
  Outline = 'solar_panel_outline',
}

export enum EModelSamplesLayer {
  FlightPathSamples = 'flight_path_samples',
  AnomalySamples = 'anomaly_samples',
  Frustum = 'frustum',
  FrustumWireframe = 'frustum_wireframe',
}
