import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelectorTyped } from '@core/hooks';

interface IInitialState {
  reload: boolean;
  isDynamicZoom: boolean;
  previousZoom: number;
}

const initialState: IInitialState = {
  reload: false,
  isDynamicZoom: true,
  previousZoom: 0,
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    reloadMap: (state, actions: PayloadAction<boolean>) => {
      state.reload = actions.payload;
    },
    setIsDynamicZoom: (state, actions: PayloadAction<boolean>) => {
      state.isDynamicZoom = actions.payload;
    },
    setPreviousZoom: (state, actions: PayloadAction<number>) => {
      state.previousZoom = actions.payload;
    },
  },
});

const mapReducer = mapSlice.reducer;

const { reloadMap, setPreviousZoom, setIsDynamicZoom } = mapSlice.actions;

const useMapSelector = () => useSelectorTyped((state) => state.map);

export {
  reloadMap,
  setPreviousZoom,
  setIsDynamicZoom,
  mapReducer,
  useMapSelector,
  initialState as mapInitialState,
};
