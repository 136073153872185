import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_SITE_NAME_LENGTH } from '@core/constants/validation';
import { useDispatchTyped } from '@core/hooks';
import { setEditSiteName, useSiteEditInfoSelector } from '@core/store/slices';
import { validateSiteName } from '@core/utils';
import NameBody from './NameBody';

const NameEdit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatchTyped();
  const editInfo = useSiteEditInfoSelector();

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.target.value;

      if (name.length < MAX_SITE_NAME_LENGTH) {
        dispatch(
          setEditSiteName({
            value: name,
            errorMessage: validateSiteName(name),
          }),
        );
      }
    },
    [dispatch],
  );

  return (
    <NameBody
      text={{
        notification: t('sidebar.site.edit.sections.name.notification'),
        placeholder: t('sidebar.site.edit.sections.name.placeholderHintName'),
      }}
      errorMessage={editInfo?.name.errorMessage ?? null}
      value={editInfo?.name.value ?? ''}
      onChange={handleChange}
    />
  );
};

export default NameEdit;
