import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IUsersMeResponse } from '@core/api/user/users';
import { OUTFLIER_ACCESS_TOKEN } from '@core/constants';
import { ECookiesField, ELocalStorageField } from '@core/constants/storage';
import { useSelectorTyped } from '@core/hooks';
import { IBrowserStorage } from '@core/interfaces/accessControl';
import { browserLocalStorage, cookiesStorage } from '@core/services/storage';
import { RootState } from '@core/store';
import { resetCreateSite } from '@core/store/slices/createSite';
import { resetAnomalies } from './anomalies';
import { resetControls } from './controls';
import { resetInspections } from './inspections';
import { resetModel3DState } from './model3D';
import { resetPrograms } from './programs';
import { resetReports } from './reports';
import { resetAnomalySamples } from './samples';
import { resetSidebar } from './sidebar';
import { resetSites } from './sites';
import { resetVideos } from './video';
import { resetViewer } from './viewer';

interface IInitialState {
  user: IUsersMeResponse | null;
  isDisabledLogout: boolean;
  browserStorage: IBrowserStorage;
  loading: boolean;
}

const initialState: IInitialState = {
  user: null,
  isDisabledLogout: false,
  browserStorage: {
    error: {
      status: null,
      message: null,
    },
  },
  loading: true,
};

const logout = createAsyncThunk('accessControl/logout', async (_, { dispatch, getState }) => {
  const state = getState() as RootState;
  if (state.createSite.isLoadingZones) {
    return;
  }
  // NOTE: remove all "create site" data
  browserLocalStorage.removeItem(ELocalStorageField.CreateSite);
  // NOTE: remove persist permission for "SiteCreation"
  cookiesStorage.removeItem(ECookiesField.StoragePersistPermission);
  // NOTE: remove access token
  browserLocalStorage.removeItem(OUTFLIER_ACCESS_TOKEN);

  // Reset store
  dispatch(resetModel3DState());
  dispatch(resetAccessControl());
  dispatch(resetAnomalies());
  dispatch(resetControls());
  dispatch(resetInspections());
  dispatch(resetPrograms());
  dispatch(resetReports());
  dispatch(resetAnomalySamples());
  dispatch(resetSidebar());
  dispatch(resetSites());
  dispatch(resetVideos());
  dispatch(resetViewer());
  dispatch(resetCreateSite());
});

const accessControlSlice = createSlice({
  name: 'accessControl',
  initialState,
  reducers: {
    addUser: (state, actions: PayloadAction<IUsersMeResponse | null>) => {
      state.user = actions.payload;
      state.loading = false;
    },
    removeUser: (state) => {
      state.user = null;
    },
    setIsDisabledLogout: (state, action: PayloadAction<boolean>) => {
      state.isDisabledLogout = action.payload;
    },
    setBrowserStorageError: (state, action: PayloadAction<IBrowserStorage['error'] | null>) => {
      state.browserStorage.error.status = action.payload?.status ?? null;
      state.browserStorage.error.message = action.payload?.message ?? null;
    },
    resetAccessControl: () => ({ ...initialState, loading: false }),
  },
});

const accessControlReducer = accessControlSlice.reducer;

const { addUser, removeUser, setIsDisabledLogout, setBrowserStorageError, resetAccessControl } =
  accessControlSlice.actions;

const useAccessControlSelector = () => useSelectorTyped((state) => state.accessControl);

export {
  accessControlReducer,
  addUser,
  logout,
  removeUser,
  setIsDisabledLogout,
  resetAccessControl,
  useAccessControlSelector,
  setBrowserStorageError,
  initialState as accessControlInitialState,
};
