import { Position } from 'geojson';

// NOTE: it's an unused function after migration from "geojson_features" to "display_perimeter".
export const getRightmostTopCoordinateFromFeature = (feature: any): number[] => {
  let rightmostTopCoordinate: number[] = [Number.MIN_SAFE_INTEGER, Number.MIN_SAFE_INTEGER];

  if (feature.geometry.type === 'Polygon') {
    feature.geometry.coordinates[0].forEach((coord: number[]) => {
      if (
        coord[0] > rightmostTopCoordinate[0] ||
        (coord[0] === rightmostTopCoordinate[0] && coord[1] > rightmostTopCoordinate[1])
      ) {
        rightmostTopCoordinate = coord;
      }
    });
  }
  if (feature.geometry.type === 'MultiPolygon') {
    feature.geometry.coordinates.forEach((polygon: number[][][]) => {
      polygon.forEach((ring: number[][]) => {
        ring.forEach((coord: number[]) => {
          if (
            coord[0] > rightmostTopCoordinate[0] ||
            (coord[0] === rightmostTopCoordinate[0] && coord[1] > rightmostTopCoordinate[1])
          ) {
            rightmostTopCoordinate = coord;
          }
        });
      });
    });
  }
  return rightmostTopCoordinate;
};

export const getRightmostCoordinateFromPolygonCoordinates = (coordinates: Position[][]) => {
  const polygonCoordinates = coordinates[0];
  const [startLng, startLat] = polygonCoordinates[0];
  let rightmostCoordinate: number[] = [startLng, startLat];

  polygonCoordinates.slice(1).forEach((coord) => {
    const [lng, lat] = coord;
    if (lng > rightmostCoordinate[0]) rightmostCoordinate = [lng, lat];
  });

  return rightmostCoordinate;
};
