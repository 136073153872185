import { EAspectRatio, EViewerType } from '@core/enums';

export const droneDimensions: {
  [key in EAspectRatio]: {
    [key in EViewerType]: {
      width: number;
      height: number;
    };
  };
} = {
  [EAspectRatio.ParrotAnafiDrone]: {
    [EViewerType.Viewer]: {
      width: 1280,
      height: 720,
    },
    [EViewerType.InspectionView]: {
      width: 350,
      height: 200,
    },
  },
  [EAspectRatio.DJIDrone]: {
    [EViewerType.Viewer]: {
      width: 1280,
      height: 960,
    },
    [EViewerType.InspectionView]: {
      width: 350,
      height: 262.5,
    },
  },
};
