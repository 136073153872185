import cn from 'classnames';
import i18n from 'i18next';
import { ESortOrder } from '@core/enums';
import { ISite } from '@core/interfaces';
import { sortGroupedSitesByStatusPriority } from '@core/store/utils/sites/sortSitesByPriority';
import { sliceString, sortByGroup } from '@core/utils';
import { getInspectedRowData } from '@modules/Sidebar/views/Sites/utils/getInspectedRowData';
import { getStatusStyle } from '@modules/Sidebar/views/Sites/utils/getStatusStyle';
import styles from '../styles.scss';

export const styleSiteStatuses: Record<string, string> = {
  normal: styles.normalStatus,
  issues: styles.issuesStatus,
  inspected: styles.inspectedStatus,
  processing: styles.processingStatus,
  reportProcessing: styles.processingStatus,
};

const initialState = {
  columnWidth: [],
  head: [],
  body: [],
};

export const getTable = (sites: ISite[] | null) => {
  if (!sites) return initialState;

  const tableColumnWidth = ['157px'];
  const tableHead = [{ name: i18n.t('location') }, { name: i18n.t('inspected') }];

  const sitesWithLocationsStatus = sites.filter((site) => site.location_status);
  const sitesWithoutLocationStatus = sites.filter((site) => !site.location_status);

  const sortedSitesByStatus = sortByGroup(sitesWithoutLocationStatus, {
    groupBy: 'status',
    sortBy: 'name',
    order: ESortOrder.Desc,
  });
  const sortedSitesByLocationStatus = sortByGroup(sitesWithLocationsStatus, {
    groupBy: 'location_status',
    sortBy: 'name',
    order: ESortOrder.Desc,
  });

  const sortedSitesByStatusPriority = sortGroupedSitesByStatusPriority({
    ...sortedSitesByStatus,
    ...sortedSitesByLocationStatus,
  });

  const tableBody = sortedSitesByStatusPriority.map((site) => {
    const { name, status, last_inspection_date, loc_id, location_status } = site;

    const styleSiteStatus = getStatusStyle(status, location_status);
    const inspectedRowData = getInspectedRowData(last_inspection_date, location_status, status);

    return {
      id: loc_id,
      locationStatus: location_status,
      data: [
        {
          name: sliceString(name, 15),
          className: cn(styles.locationBody, styleSiteStatus),
        },
        {
          name: inspectedRowData,
          className: styles.inspected,
        },
      ],
    };
  });

  return {
    columnWidth: tableColumnWidth,
    head: tableHead,
    body: tableBody,
  };
};
