import { useEffect, useRef } from 'react';
import { logout } from '@core/store/slices';
import { ETimeUnit, getMilliseconds } from '@core/utils';
import { useDispatchTyped, useSelectorTyped } from './useReduxTyped';

export const useAutoLogout = (timeoutInMinutes: number) => {
  const timeoutRef = useRef<number | NodeJS.Timeout>(0);
  const dispatch = useDispatchTyped();
  const timeoutInMs = getMilliseconds(timeoutInMinutes, ETimeUnit.Minute);
  const { isLoadingZones, isLoadingSite } = useSelectorTyped((state) => state.createSite);

  const logoutUser = () => {
    if (isLoadingZones || isLoadingSite) {
      resetTimer();
      return;
    }

    dispatch(logout());
    resetTimer();
  };

  const resetTimer = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(logoutUser, timeoutInMs);
  };

  useEffect(() => {
    timeoutRef.current = setTimeout(logoutUser, timeoutInMs);
    document.addEventListener('mousemove', resetTimer);
    document.addEventListener('click', resetTimer);

    return () => {
      clearTimeout(timeoutRef.current);
      document.removeEventListener('mousemove', resetTimer);
      document.removeEventListener('click', resetTimer);
    };
  }, [timeoutInMinutes]);
};
