import { ESiteStatuses } from '@core/enums';
import { ELocationStatus } from '@core/interfaces';

const documentStyle = getComputedStyle(document.documentElement);

export const siteStatusesColor: Record<string, string> = {
  [ESiteStatuses.Normal]: documentStyle.getPropertyValue('--green'),
  [ESiteStatuses.Issues]: documentStyle.getPropertyValue('--red'),
  [ESiteStatuses.Inspected]: documentStyle.getPropertyValue('--outflier-yellow'),
  [ESiteStatuses.ReportProcessing]: documentStyle.getPropertyValue('--outflier-blue'),
  [ELocationStatus.UnderCreation]: documentStyle.getPropertyValue('--outflier-blue'),
  [ELocationStatus.WaitingForApproval]: documentStyle.getPropertyValue('--outflier-blue'),
};

export const siteStatusesPriority = new Map<string, number>([
  [ELocationStatus.WaitingForApproval, 1],
  [ESiteStatuses.ReportProcessing, 2],
  [ELocationStatus.UnderCreation, 3],
  [ESiteStatuses.Issues, 4],
  [ESiteStatuses.Inspected, 5],
  [ESiteStatuses.Normal, 6],
]);
