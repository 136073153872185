import mapboxgl from 'mapbox-gl';
import { ISample } from '@core/interfaces';
import { MapClickEvent, MapMouseEnterEvent, MapMouseLeaveEvent } from '@core/interfaces/map';
import { getFrustumFeatureCollection, getSamplesFeatureCollection } from './getFeatureCollection';
import { getFrustumCoordinates } from './getFrustumCoordinates';
import { layersStyles } from './mapboxConfig';
import { ESampleLayers } from '../../enums/layers';
import { ESampleSources } from '../../enums/sources';
import { removeLayersAndSources } from '../removeLayersAndSources';

export interface ISamplesProps {
  map: mapboxgl.Map | null;
  samples: ISample[];
  selectedSample?: ISample | null;
  show: boolean;
  isFlightPathSamples?: boolean;
  onClick?: (e: MapClickEvent) => void;
  onMouseEnter?: (e: MapMouseEnterEvent) => void;
  onMouseLeave?: (e: MapMouseLeaveEvent) => void;
}

export const addSamples = ({
  map,
  samples,
  selectedSample,
  show,
  isFlightPathSamples,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: ISamplesProps) => {
  if (!map) return;
  if (!selectedSample || !show) {
    removeLayersAndSources(map, {
      [ESampleSources.Samples]: [ESampleLayers.Samples],
      [ESampleSources.Frustum]: [ESampleLayers.Frustum, ESampleLayers.FrustumLines],
    });
    onClick && map.off('click', ESampleLayers.Samples, onClick);
    onMouseEnter && map.off('mouseenter', ESampleLayers.Samples, onMouseEnter);
    onMouseLeave && map.off('mouseleave', ESampleLayers.Samples, onMouseLeave);
  }
  if (!show || (!selectedSample && !isFlightPathSamples)) return;

  const updatedSamples = isFlightPathSamples ? samples.slice(1, -1) : samples;
  const samplesFeatureCollection = getSamplesFeatureCollection(updatedSamples, selectedSample);

  if (map.getSource(ESampleSources.Samples)) {
    (map.getSource(ESampleSources.Samples) as mapboxgl.GeoJSONSource).setData(
      samplesFeatureCollection,
    );
  } else {
    map.addSource(ESampleSources.Samples, {
      type: 'geojson',
      data: samplesFeatureCollection,
    });
    map.addLayer(layersStyles[ESampleLayers.Samples]({ isFlightPathSamples }));
  }

  if (!isFlightPathSamples) {
    const { frustumCoordinates, frustumTriangleCoordinates, frustumBordersCoordinates } =
      getFrustumCoordinates(selectedSample);
    const frustumFeatureCollection = getFrustumFeatureCollection({
      polygon: frustumCoordinates,
      borders: frustumBordersCoordinates,
      triangle: frustumTriangleCoordinates,
    });

    if (map.getSource(ESampleSources.Frustum)) {
      (map.getSource(ESampleSources.Frustum) as mapboxgl.GeoJSONSource).setData(
        frustumFeatureCollection,
      );
    } else {
      map.addSource(ESampleSources.Frustum, {
        type: 'geojson',
        data: frustumFeatureCollection,
      });
      map.addLayer(layersStyles[ESampleLayers.Frustum](), ESampleLayers.Samples);
      map.addLayer(layersStyles[ESampleLayers.FrustumLines](), ESampleLayers.Samples);
    }
  }

  onClick && map.on('click', ESampleLayers.Samples, onClick);
  onMouseEnter && map.on('mouseenter', ESampleLayers.Samples, onMouseEnter);
  onMouseLeave && map.on('mouseleave', ESampleLayers.Samples, onMouseLeave);
};
