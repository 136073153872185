import { FC, memo } from 'react';
import { ISelectOption } from '@core/interfaces';
import { Select } from '@components/Select';
import styles from './styles.scss';

type TInspectionFrequencyOption = {
  id: number;
  name: ISelectOption['name'];
};

interface IProps {
  text: {
    notification: string;
  };
  selected: TInspectionFrequencyOption | null;
  options: TInspectionFrequencyOption[];
  onSelect: (option: ISelectOption | null) => void;
}

const InspectionFrequencyBody: FC<IProps> = ({ text, selected, options, onSelect }) => {
  return (
    <div className={styles.body}>
      <h2 className={styles.notification}>{text.notification}</h2>
      <div className={styles.wrapper}>
        <Select
          options={options}
          selected={selected}
          classNames={{
            root: styles.select,
            selectedOption: styles.selectedOption,
            option: styles.option,
          }}
          onChange={onSelect}
        />
      </div>
    </div>
  );
};

export default memo(InspectionFrequencyBody);
