import { axiosInstance } from '@core/api/axios';
import { EAnomalyStatus } from '@core/interfaces/anomaly';

export interface IUpdateStatusResponse {
  id: number;
  status: EAnomalyStatus;
}

export const updateStatus = async (anomalyId: number, data: EAnomalyStatus) => {
  const requestData = { status: data };
  return axiosInstance
    .put<IUpdateStatusResponse>(`/anomalies/${anomalyId}/status`, requestData)
    .then((res) => res.data);
};
