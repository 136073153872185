import { ESidebar } from '@core/enums';
import { IProgram } from '@core/interfaces';
import { IReport } from '@core/interfaces/report';
import { addReportToOrthoTiles } from './addReportToOrthoTiles';
import { generateRasterTileSourceId } from './generateId';
import { getProgramsWithReports } from './getProgramsWithReports';
import { getZonesReportsRelationStatus } from './getZonesReportsRelationStatus';
import { removeRasterTilesLayers } from './removeRasterTiles';
import { reorderReports } from './reorderReports';
import { EZonesReportsRelations } from '../../enums/ortho';
import { removeLayersAndSources } from '../removeLayersAndSources';

interface IProps {
  map: mapboxgl.Map | null;
  currentInspectionId: string | null;
  programs: IProgram[] | null;
  reports: IReport[];
  sidebar: ESidebar;
  report?: IReport;
  isCompareMode?: boolean;
}

export const addOrtho = ({
  map,
  programs,
  currentInspectionId,
  reports,
  sidebar,
  report,
  isCompareMode = false,
}: IProps) => {
  if (!map || !reports.length) return;

  if (report && isCompareMode) {
    removeRasterTilesLayers(map, (rasterId: string) => {
      if (rasterId !== generateRasterTileSourceId(report)) {
        removeLayersAndSources(map, { [rasterId]: [rasterId] });
      }
    });
    addReportToOrthoTiles({ map, sidebar, report });
  } else {
    const programsWithReports = getProgramsWithReports(programs, reports);
    const programIds = Object.keys(programsWithReports);
    let programsReports = Object.values(programsWithReports);
    const zonesReportsRelationStatus = getZonesReportsRelationStatus(programIds, programsReports);

    switch (zonesReportsRelationStatus) {
      case EZonesReportsRelations.OneZoneWithOneReport: {
        const selectedProgramReports = programsReports[0];
        const selectedReport = selectedProgramReports[0];

        addReportToOrthoTiles({ map, sidebar, report: selectedReport });
        break;
      }
      case EZonesReportsRelations.OneZoneWithSeveralReports: {
        const selectedProgramReports = programsReports[0];
        const selectedReport = selectedProgramReports.find(
          (programReport) => currentInspectionId === programReport.inspection_id,
        );

        if (selectedReport) {
          removeRasterTilesLayers(map, (rasterId: string) => {
            if (rasterId !== generateRasterTileSourceId(selectedReport)) {
              removeLayersAndSources(map, { [rasterId]: [rasterId] });
            }
          });
          addReportToOrthoTiles({ map, sidebar, report: selectedReport });
        }
        break;
      }
      case EZonesReportsRelations.SeveralZonesWithOneReportPerEach: {
        if (currentInspectionId) {
          const reorderedReports = reorderReports(programsReports, currentInspectionId);
          programsReports = reorderedReports;
          removeRasterTilesLayers(map);
        }

        programsReports.forEach((selectedProgramReports) => {
          const selectedReport = selectedProgramReports[0];

          addReportToOrthoTiles({ map, sidebar, report: selectedReport });
        });
        break;
      }
      // NOTE: need to be tested if such a case occurs in the future
      case EZonesReportsRelations.SeveralZonesWithSeveralReportsPerEach:
      case EZonesReportsRelations.SeveralZonesWithMixedReportsPerEach: {
        const inspectionIdsFromReports = reports.map(({ inspection_id }) => inspection_id);

        if (currentInspectionId) {
          const reorderedReports = reorderReports(programsReports, currentInspectionId);
          programsReports = reorderedReports;
          removeRasterTilesLayers(map);
        }

        programsReports.forEach((selectedProgramReports) => {
          const selectedReport = selectedProgramReports.find(
            (report) =>
              report.inspection_id && inspectionIdsFromReports.includes(report.inspection_id),
          );

          if (selectedReport) {
            addReportToOrthoTiles({ map, sidebar, report: selectedReport });
          }
        });
        break;
      }
      default: {
        removeRasterTilesLayers(map);
        break;
      }
    }
  }
};
