import React from 'react';
import { useTranslation } from 'react-i18next';
import { ELoginMode } from '@core/enums/accessControl';
import styles from './styles.scss';

interface IProps {
  mode: ELoginMode;
  onClick(): void;
}

export const ToggleButton: React.FC<IProps> = ({ mode, onClick }) => {
  const { t } = useTranslation();

  if (mode === ELoginMode.SuccessNewPassword) return null;

  return (
    <button type='button' className={styles.forgotPasswordButton} onClick={onClick}>
      {mode === ELoginMode.Login ? t('loginPage.forgotPasswordButton') : t('loginPage.loginButton')}
    </button>
  );
};
