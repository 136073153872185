const RESTRICTED_QUERY_VALUES = [undefined, null, NaN];

export const addQueryParamsToUrl = (url: string, query: Record<string, any>) => {
  const filteredQueries = Object.entries(query).reduce(
    (acc, [query, value]) =>
      RESTRICTED_QUERY_VALUES.includes(value) ? acc : { ...acc, [query]: value },
    {},
  );
  const queryParams = new URLSearchParams(filteredQueries);

  return [url, queryParams.toString()].filter(Boolean).join('?');
};
