import { getColor } from '@core/utils';

export interface ICustomStyleArguments {
  outlineColor?: string;
  pointColor?: string;
  midpointColor?: string;
  fillColor?: string;
}

export const customStyles = ({
  outlineColor = getColor('--outflier-white'),
  pointColor = getColor('--outflier-white'),
  midpointColor = getColor('--outflier-white'),
  fillColor = getColor('--transparent'),
}: ICustomStyleArguments) => {
  return [
    // LINE STYLE for line_string mode
    {
      id: 'gl-draw-line',
      type: 'line',
      filter: [
        'all',
        ['==', '$type', 'LineString'],
        ['!=', 'mode', 'static'],
        ['==', 'active', 'true'],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': outlineColor,
        'line-width': 4,
      },
    },
    // Style for static line after drawing or editing is complete
    {
      id: 'gl-draw-line-static',
      type: 'line',
      filter: ['all', ['==', 'active', 'false'], ['==', '$type', 'LineString']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': outlineColor,
        'line-width': 4,
      },
    },

    // AREA STYLE for polygon mode
    {
      id: 'gl-draw-polygon-fill',
      type: 'fill',
      filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
      paint: {
        'fill-color': fillColor,
        'fill-outline-color': outlineColor,
        'fill-opacity': 0.1, // Adjust to desired opacity for the fill of the polygon
      },
    },
    // LINE STYLE for polygon outlines
    {
      id: 'gl-draw-polygon-stroke',
      type: 'line',
      filter: [
        'all',
        ['==', '$type', 'Polygon'],
        ['!=', 'mode', 'static'],
        ['==', 'active', 'true'],
      ],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': outlineColor,
        'line-width': 4,
      },
    },
    // POINT STYLES for polygon midpoints (between vertices)
    {
      id: 'gl-draw-polygon-midpoint',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static'],
        ['==', 'meta', 'midpoint'],
      ],
      paint: {
        'circle-radius': 5,
        'circle-color': midpointColor,
      },
    },

    // Style for static polygon after drawing or editing is complete
    {
      id: 'gl-draw-polygon-static',
      type: 'line',
      filter: ['all', ['==', 'active', 'false'], ['==', '$type', 'Polygon']],
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': outlineColor,
        'line-width': 4,
      },
    },

    // POINT STYLES for line_string mode (endpoints) - ACTIVE
    {
      id: 'gl-draw-point',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static'],
        ['!=', 'meta', 'midpoint'],
        ['==', 'active', 'true'],
      ],
      paint: {
        'circle-radius': 10,
        'circle-color': pointColor,
      },
    },

    // POINT STYLES for line_string mode (endpoints) - INACTIVE
    {
      id: 'gl-draw-point-inactive',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static'],
        ['!=', 'meta', 'midpoint'],
        ['==', 'active', 'false'],
      ],
      paint: {
        'circle-radius': 8,
        'circle-color': pointColor,
      },
    },

    // POINT STYLES for polygon (vertices) - ACTIVE
    {
      id: 'gl-draw-polygon-point',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static'],
        ['==', 'meta', 'vertex'],
        ['!=', 'meta', 'midpoint'],
        ['==', 'active', 'true'],
      ],
      paint: {
        'circle-radius': 10,
        'circle-color': pointColor,
      },
    },

    // POINT STYLES for non-active polygon (vertices)
    {
      id: 'gl-draw-polygon-point-inactive',
      type: 'circle',
      filter: [
        'all',
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static'],
        ['==', 'meta', 'vertex'],
        ['!=', 'meta', 'midpoint'],
        ['==', 'active', 'false'],
      ],
      paint: {
        'circle-radius': 8,
        'circle-color': pointColor,
      },
    },
  ];
};

export const MapStylePerimeter = customStyles({
  outlineColor: getColor('--outflier-blue'),
  fillColor: getColor('--outflier-white'),
  midpointColor: getColor('--outflier-blue'),
});

export const MapStyleNoFlyZone = customStyles({
  outlineColor: getColor('--red'),
  fillColor: getColor('--red'),
});

export const MapStyleObstacle = customStyles({
  outlineColor: getColor('--outflier-white'),
});
