import { createContext, useContext } from 'react';
import { EMapViewerActive, EViewerType } from '@core/enums';

export interface IViewerInitialState {
  activeViewer: EMapViewerActive;
  type: EViewerType;
}

const initialState: IViewerInitialState = {
  activeViewer: EMapViewerActive.Viewer,
  type: EViewerType.Viewer,
};

export const ModelViewerContext = createContext(initialState);
export const useModelViewerContext = () => useContext(ModelViewerContext);
