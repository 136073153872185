import { ISampleDetection } from '@core/interfaces';

export function getDetectionCoordinates(detection: ISampleDetection) {
  const { x, y, h, w } = detection;

  const topLeft = [x, y];
  const topRight = [x + w, y];
  const bottomRight = [x + w, y + h];
  const bottomLeft = [x, y + h];

  return [topLeft, topRight, bottomRight, bottomLeft];
}
