import { FeatureCollection } from 'geojson';
import { axiosInstance } from '@core/api/axios';
import { IProgram } from '@core/interfaces';

export interface IUpdateProgramRequest {
  name: string;
  geojson_id: string;
  active: boolean;
  geojson_feature_id: string;
  overview_plan_id: string;
}

export interface IUpdateProgramResponse {
  program_id: string;
  name: string;
  date: string;
  active: boolean;
  geojson_features: FeatureCollection;
  overview_plan_id: string;
}

export const updateProgramById = async (programId: string, body: Partial<IProgram>) => {
  return axiosInstance
    .put<IUpdateProgramResponse>(`/programs/${programId}`, body)
    .then((res) => res.data);
};
