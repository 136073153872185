/* eslint-disable @typescript-eslint/ban-ts-comment */
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { ERROR_TOAST_DELAY } from '@core/constants';
import { createBearerToken } from '@core/utils';
import { delay } from '@core/utils/delay';
import { ENetworkProtocol } from '../enums';
import { ERTCPeerConnection } from '../enums/webrtc';

export interface IWebRTCClient {
  serverUrl: string;
  endpoint: string;
  pc: RTCPeerConnection;
  waitForServer: () => void;
  initiate: () => void;
}

type WebRTCClientConfig = {
  host: string;
  port: number;
  use_tls: boolean;
  endpoint: string;
  ice_servers: RTCIceServer[];
  access_token: string;
  reservation_token: string;
};

class WebRTCClient implements IWebRTCClient {
  public serverUrl: string;
  public endpoint: string;
  public pc: RTCPeerConnection;
  private accessToken: string;
  private reservationToken: string;

  constructor(config: WebRTCClientConfig) {
    const { host, port, use_tls, endpoint, ice_servers, access_token, reservation_token } = config;
    const protocol = use_tls ? ENetworkProtocol.Https : ENetworkProtocol.Http;

    this.serverUrl = `${protocol}://${host}:${port}`;

    this.endpoint = endpoint;
    this.accessToken = access_token;
    this.reservationToken = reservation_token;
    this.endpoint = endpoint;

    this.pc = new RTCPeerConnection({
      // @ts-ignore
      sdpSemantics: 'unified-plan',
      iceServers: ice_servers,
    });

    console.info('Ice servers:', ice_servers);
  }

  private async getAnswer() {
    const offer: RTCSessionDescription | null = this.pc.localDescription;

    const response = await fetch(`${this.serverUrl}/${this.endpoint}`, {
      body: JSON.stringify({
        sdp: offer?.sdp,
        type: offer?.type,
      }),
      headers: {
        'Content-Type': 'application/json',
        token: this.reservationToken,
        Authorization: createBearerToken(this.accessToken),
      },
      method: 'POST',
    });
    const answer = await response.json();
    console.info('Setting remote description with answer');
    return answer;
  }

  private async waitForIceGathering() {
    return new Promise((resolve) => {
      if (this.pc.iceGatheringState === 'complete') {
        resolve(void 0);
      } else {
        const checkState = () => {
          if (this.pc.iceGatheringState === 'complete') {
            this.pc.removeEventListener('icegatheringstatechange', checkState);
            resolve(void 0);
          }
        };
        this.pc.addEventListener('icegatheringstatechange', checkState);
      }
    });
  }

  public trackVideo(video: any) {
    this.pc.ontrack = (event) => {
      if (video) {
        video.srcObject = event.streams[0];
      }
    };
  }

  public async waitForServer() {
    let response;
    const pingEndpoint = `${this.serverUrl}/ping`;

    while (!response || response.ok !== true) {
      console.info(`Waiting for server at ${pingEndpoint}`);
      response = await fetch(pingEndpoint, {
        headers: { Authorization: createBearerToken(this.accessToken) },
      }).catch(() => {
        toast.error(t('errors.serverWaitFail'), { autoClose: ERROR_TOAST_DELAY });
        return null;
      });

      if (!response || response.ok !== true) {
        await delay(1000);
      }
    }
    console.info(`Successfully ping the server at ${pingEndpoint}`);
  }

  public async initiate() {
    try {
      this.pc.addTransceiver('video', { direction: 'recvonly' });

      const offer = await this.pc.createOffer();

      await this.pc.setLocalDescription(offer);
      await this.waitForIceGathering();

      const answer = await this.getAnswer();
      return this.pc.setRemoteDescription(answer);
    } catch (error) {
      // NOTE: add additional flag to re-render component
      if (this.pc.connectionState !== ERTCPeerConnection.Closed) {
        this.pc.getTransceivers().forEach((transceiver) => transceiver.stop());
        this.close();
      }
      toast.error(t('errors.webRTCCommunicationFail', { serverUrl: this.serverUrl }), {
        autoClose: ERROR_TOAST_DELAY,
      });
    }
  }

  public close() {
    console.info(`Closing the WebRTC connection with ${this.serverUrl}`);
    this.pc.close();
  }
}

export default WebRTCClient;
