import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EControlMode, ERoles, ESidebar, EViewer } from '@core/enums';
import { useDispatchTyped } from '@core/hooks';
import { EAnomalyStatus } from '@core/interfaces/anomaly';
import {
  setCurrentAnomalyId,
  setPreloader,
  setSidebar,
  setSidebarViewer,
  setViewer,
  updateAnomalyStatus,
  useControlsSelector,
  useCurrentAnomalySelector,
  useCurrentSiteSelector,
  useAccessControlSelector,
  useCurrentReportAnomaliesSelector,
} from '@core/store/slices';
import { useCurrentProgramSelector } from '@core/store/slices/programs';
import { useCurrentReportSelector } from '@core/store/slices/reports';
import {
  setAnomalySamples,
  setSelectedSample,
  useSamplesSelector,
} from '@core/store/slices/samples';
import { useMainContext } from '@modules/Layout/contexts/main';
import { Navbar } from '@modules/Sidebar/components';
import { InspectionsView } from '@modules/Sidebar/components/InspectionsView';
import CommentSectionAnomaly from '@components/CommentSection/CommentSectionAnomaly';
import { Statuses } from '@components/Statuses';
import { statuses } from './constants';
import styles from './styles.scss';
import { getAnomalyId } from './utils/getAnomalyId';

export const Anomaly = () => {
  const {
    model: { sidebarOrbitControl },
  } = useMainContext();
  const { t } = useTranslation();
  const currentSite = useCurrentSiteSelector();
  const zone = useCurrentProgramSelector();
  const { mode } = useControlsSelector();
  const currentReport = useCurrentReportSelector();
  const anomaly = useCurrentAnomalySelector();
  const anomalies = useCurrentReportAnomaliesSelector();
  const { anomalySamples, selectedSample } = useSamplesSelector();
  const { user } = useAccessControlSelector();
  const dispatch = useDispatchTyped();

  const isEditor = user?.roles?.includes(ERoles.Editor);

  const handleSwitch = (index: number) => dispatch(setSelectedSample(anomalySamples[index]));

  const handleBackClick = () => {
    if (mode === EControlMode['3D']) {
      // 1. use case (click on anomaly in a sidebar):
      // Problem with closures when obviously unmounting a component (Inspection View).
      sidebarOrbitControl.current?.dispose();
      sidebarOrbitControl.current = null;
      dispatch(setViewer(EViewer.Model));
    } else {
      dispatch(setViewer(EViewer.Map));
    }
    dispatch(setSidebarViewer(EViewer.Video));
    dispatch(setCurrentAnomalyId(null));
    dispatch(setSidebar(ESidebar.Zone));
  };

  const handleAnomalySwitch = (arrow: 'left' | 'right') => {
    if (!anomaly || !currentReport?.anomalies) return;
    const newAnomalyId = getAnomalyId({
      arrow,
      anomalies: currentReport.anomalies,
      currentAnomaly: anomaly,
    });
    dispatch(setCurrentAnomalyId(newAnomalyId));
  };

  const handleStatusSelect = async (status: EAnomalyStatus | null) => {
    if (status === null || !anomaly) return;

    try {
      dispatch(setPreloader(true));

      await dispatch(updateAnomalyStatus({ anomalyId: anomaly.id, status: status }));
    } catch {
      alert('Failed to change status anomaly');
    } finally {
      dispatch(setPreloader(false));
    }
  };

  useEffect(() => {
    if (mode === EControlMode['3D']) {
      dispatch(setViewer(EViewer.Model));
    }
    dispatch(setSidebarViewer(EViewer.Image));
  }, [mode]);

  useEffect(() => {
    if (anomaly?.id) {
      dispatch(setAnomalySamples(anomaly.id));
    }
  }, [anomaly?.id]);

  const areDisabledSwitcherButtons = anomalies.length === 1;

  return (
    <div className={styles.container}>
      <Navbar
        header={String(t('sidebar.anomaly.title', { id: anomaly?.id }))}
        subheader={`${currentSite?.name} - ${zone?.name}`}
        areDisabledSwitcherButtons={areDisabledSwitcherButtons}
        onBackClick={handleBackClick}
        onLeftClick={handleAnomalySwitch.bind(this, 'left')}
        onRightClick={handleAnomalySwitch.bind(this, 'right')}
      />
      <div className={styles.wrapper}>
        <InspectionsView
          classNames={styles.mediaInspections}
          options={anomalySamples}
          selectedId={Number(selectedSample?.id)}
          onSwitch={handleSwitch}
        />
        <div className={styles.statuses}>
          <div className={styles.statusesTitle}>{t('sidebar.status')}</div>
          <Statuses
            mode='anomaly'
            statuses={statuses}
            selectedStatus={anomaly?.status}
            onClick={handleStatusSelect}
            isStatusesClickable={isEditor}
          />
        </div>
        <CommentSectionAnomaly anomalyId={anomaly?.id} />
      </div>
    </div>
  );
};
