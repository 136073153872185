import { isObject } from '../guards';

/* Example:
  Input: {
    user: {
      name: 'John',
      surname: 'Doe'
    }
  };
  Output: [
    ['user', 'name'],
    ['user', 'surname']
  ]
*/

export function getKeyPathsFromObject(object: Record<string, any>) {
  const result: string[][] = [];

  function iter(obj: unknown, path: string[]) {
    if (isObject(obj)) {
      const keys = Object.keys(obj);

      if (keys.length) {
        keys.forEach((key) => iter(obj[key], [...path, key]));
        return;
      }
    }

    result.push(path);
  }

  iter(object, []);
  return result;
}
