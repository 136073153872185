import { memo, useCallback, useMemo } from 'react';
import _ from 'lodash';
import plusIcon from '@assets/icons/plus.svg';
import { validateEmail } from '@core/utils/validating/validateEmail';
import { setInputInitialState } from '@modules/Sidebar/views/Site/utils/setRoleInitialState';
import styles from './styles.scss';
import AddInput, { EDeleteMode } from '../AddInput/AddInput';

type TData = {
  value: string;
  errorMessage: string | null;
};

interface IProps<T> {
  entity: T;
  addText: string;
  placeholder?: string;
  data: Array<TData & { name: string }>;
  onAdd: (data: { [name: string]: TData }) => void;
  onDelete: (data: { entity: T; name: string }) => void;
  onChange: (data: Record<string, TData>) => void;
}

const AddItem = <T extends string>({
  entity,
  addText,
  placeholder,
  data,
  onDelete,
  onAdd,
  onChange,
}: IProps<T>) => {
  const handleAddInput = useCallback(
    () => onAdd({ ...setInputInitialState(entity) }),
    [onAdd, entity],
  );

  const handleDelete = useCallback(
    (inputName: string) => () => onDelete({ entity, name: inputName }),
    [onDelete, entity],
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange({
        [e.target.name]: {
          value: e.target.value,
          errorMessage: validateEmail(e.target.value),
        },
      });
    },
    [onChange],
  );

  const handleClear = useCallback(
    (inputName: string) => () => {
      onChange({
        [inputName]: {
          value: '',
          errorMessage: validateEmail(''),
        },
      });
    },
    [onChange],
  );

  return (
    <div className={styles.root}>
      <h2 className={styles.title}>{_.capitalize(entity)}</h2>
      <ul className={styles.inputsList}>
        {useMemo(
          () =>
            data.map(({ name, value, errorMessage }, _, arr) => (
              <AddInput
                key={name}
                name={name}
                value={value}
                errorMessage={errorMessage}
                placeholder={placeholder}
                mode={arr.length === 1 ? EDeleteMode.ClearInput : EDeleteMode.RemoveInput}
                onChange={handleChange}
                onClear={handleClear(name)}
                onDelete={handleDelete(name)}
              />
            )),
          [data],
        )}
      </ul>
      <button className={styles.addBtn} onClick={handleAddInput}>
        <div className={styles.addBtnIcon}>
          <img className={styles.addIcon} src={plusIcon} alt='add' />
        </div>
        <span className={styles.btnText}>{addText}</span>
      </button>
    </div>
  );
};

export default memo(AddItem);
