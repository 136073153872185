import { ISample } from '@core/interfaces';
import { getColor } from '@core/utils';
import { generateBatteryId } from './generateId';

export const drawBatteries = (samples: ISample[]) => {
  if (!samples.length) return [];

  const drawables: any[] = [];

  let batteryIndex = 1;

  samples.forEach((sample, index, samples) => {
    const vertices = sample.enu_camera?.pose.slice(0, 3).map((pos) => pos[3]) as number[];

    if (
      sample?.flight_id !== samples[index - 1]?.flight_id &&
      index !== samples.length - 1 &&
      index !== 0
    ) {
      batteryIndex += 1;

      drawables.push({
        id: generateBatteryId(sample.id),
        index: batteryIndex,
        vertices: vertices,
        point_size: 22,
        font_size: 18,
        color: getColor('--outflier-blue'),
      });
    }
  });

  return drawables;
};
