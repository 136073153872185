import { axiosInstance } from '@core/api/axios';
import { ELocationStatus } from '@core/interfaces';

export interface IUpdateLocationRequest {
  name: string;
  lat: number;
  long: number;
  altitude: number;
  gps_boundaries: any;
  picture: string;
  inspection_frequency: number;
  location_status?: ELocationStatus;
}

export interface IUpdateProgramResponse {
  name: string;
  lat: number;
  long: number;
  altitude: number;
  gps_boundaries: any;
  picture: string;
  inspection_frequency: number;
  loc_id: string;
  tenant_name: string;
}

export const updateById = async (locationId: string, body: Partial<IUpdateLocationRequest>) => {
  return axiosInstance
    .put<IUpdateProgramResponse>(`/locations/${locationId}`, body)
    .then((res) => res.data);
};
