import { createComment } from '@core/api/programs/comments/createComment';
import { deleteCommentById } from '@core/api/programs/comments/deleteCommentById';
import { getComments } from '@core/api/programs/comments/getCommentsByProgramId';
import { updateCommentById } from '@core/api/programs/comments/updateCommentById';
import { updateProgramById } from './updateById';

export const program = {
  updateProgramById,
  getComments,
  deleteCommentById,
  postComment: createComment,
  updateCommentById,
};
