import { IAnomaly } from '@core/interfaces/anomaly';
import { getPolygonCenter } from '@core/utils';

interface IAnomalyConfig {
  isCompareMode?: boolean;
  isInitialZoom?: boolean;
  zoom: number;
}

export const handleZoomToAnomaly = (
  map: mapboxgl.Map | null,
  anomaly: IAnomaly,
  config: IAnomalyConfig,
) => {
  if (!map) return;

  const coordinates = anomaly.features[0].geometry.coordinates[0];

  const [lng, lat] = getPolygonCenter(anomaly.features[0]) ?? coordinates[0];

  if (config?.isCompareMode && !config?.isInitialZoom) {
    map.setCenter([lng, lat]);
    map.setZoom(config.zoom);
  } else {
    map.flyTo({ center: [lng, lat], zoom: config.zoom });
  }
};
