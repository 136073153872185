import React, { useCallback, useMemo, useState } from 'react';
import { sortByDate } from '@core/utils';
import { IUpdateScrollPosition } from '@modules/Layout/components/Header/interfaces';

export const useScrollControl = (
  scrollPosition: number,
  setScrollPosition: (pos: number) => void,
  ref: React.RefObject<HTMLDivElement>,
  itemWidth: number,
  pageSize: number,
) => {
  const [canScrollRight, setCanScrollRight] = useState(false);
  const offset = useMemo(() => pageSize * 3 * itemWidth, [pageSize, itemWidth]);

  const handleNextClick = useCallback((): void => {
    if (ref.current) {
      const maxScrollLeft = ref.current.scrollWidth - ref.current.clientWidth;
      const nextScrollPosition = Math.min(scrollPosition + offset, maxScrollLeft);
      ref.current.scrollTo({
        left: nextScrollPosition,
        behavior: 'smooth',
      });
      setScrollPosition(nextScrollPosition);
      if (nextScrollPosition >= maxScrollLeft) {
        setCanScrollRight(false);
      }
    }
  }, [scrollPosition, offset]);

  const handlePreviousClick = useCallback((): void => {
    if (ref.current) {
      const previousScrollPosition = Math.max(scrollPosition - offset, 0);
      ref.current.scrollTo({
        left: previousScrollPosition,
        behavior: 'smooth',
      });

      setScrollPosition(previousScrollPosition);
      if (previousScrollPosition < scrollPosition) {
        setCanScrollRight(true);
      }
    }
  }, [scrollPosition, offset]);

  const handleUpdatePositionBySelectedInspectionDate = useCallback(
    ({ inspectionId, visibleInspections }: IUpdateScrollPosition): void => {
      if (ref.current) {
        const allTimelinePagesNumber = Math.ceil(ref.current.scrollWidth / offset);
        const sortedVisibleInspections = sortByDate(visibleInspections);
        const sortedVisibleInspectionsIds = sortedVisibleInspections.map(({ id }) => id);
        const currentInspectionIndexPosition = sortedVisibleInspectionsIds.indexOf(inspectionId);

        const inspectionsPerTimelinePage = Math.floor(
          sortedVisibleInspectionsIds.length / allTimelinePagesNumber,
        );

        let currentTimelinePageNumber = allTimelinePagesNumber;

        for (let page = 1; page <= allTimelinePagesNumber; page += 1) {
          const latestInspectionIndexPositionOnPage = page * inspectionsPerTimelinePage;
          if (currentInspectionIndexPosition < latestInspectionIndexPositionOnPage) {
            currentTimelinePageNumber = page - 1;
            break;
          }
        }

        const scrollPosition =
          ref.current.scrollWidth -
          Math.abs(allTimelinePagesNumber - currentTimelinePageNumber) * offset;
        const normalizedScrollPosition = scrollPosition < 0 ? 0 : scrollPosition;
        const isTheLastPage = normalizedScrollPosition >= ref.current.scrollWidth - offset;

        ref.current.scrollTo({
          left: normalizedScrollPosition,
          behavior: 'smooth',
        });

        setScrollPosition(normalizedScrollPosition);
        setCanScrollRight(!isTheLastPage);
      }
    },
    [offset],
  );

  return [
    canScrollRight,
    handleNextClick,
    handlePreviousClick,
    handleUpdatePositionBySelectedInspectionDate,
  ] as const;
};
