import { TAxiosInstance, api } from '@core/api';
import { OUTFLIER_ACCESS_TOKEN } from '@core/constants';
import { EClientErrors } from '@core/enums';
import { browserLocalStorage } from '@core/services/storage';

export const refreshTokenInterceptor = (axiosInstance: TAxiosInstance) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const config = error.config;
      const response = error?.response;

      if (['token', 'token/refresh'].includes(config.url)) return;

      if (response?.status === EClientErrors.Unauthorized) {
        await api.user
          .getRefreshToken()
          .then((data) => {
            browserLocalStorage.setItem(OUTFLIER_ACCESS_TOKEN, data.access_token);

            config.headers = {
              ...config.headers,
              Authorization: `Bearer ${browserLocalStorage.getItem(OUTFLIER_ACCESS_TOKEN)}`,
            };

            return axiosInstance(config);
          })
          .catch(() => {
            throw new Error();
          });
      }

      return Promise.reject(error);
    },
  );
};
