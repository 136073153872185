import { toast } from 'react-toastify';
import * as turf from '@turf/turf';
import { t } from 'i18next';
import { ERROR_TOAST_DELAY } from '@core/constants';
import { ISample } from '@core/interfaces';
import { IAnomaly } from '@core/interfaces/anomaly';
import { mergePolygons } from '@core/utils';

interface ITransformedSamples {
  [id: number]: ISample;
}

interface ISampleDistance {
  id: number | null;
  distance: number;
}

export const getSampleWithMinDistanceBetweenFrustumCenterAndAnomalyCenter = (
  anomaly: IAnomaly,
  samples: ISample[],
) => {
  const currentAnomalyUnionPolygon = mergePolygons(anomaly.features);
  const anomalyPolygonCenter = turf.center(currentAnomalyUnionPolygon);

  const transformedSamples = samples.reduce<ITransformedSamples>(
    (acc, sample) => ({ ...acc, [sample.id]: sample }),
    {},
  );

  const sampleWithMinDistanceBetweenFrustumCenterAndAnomalyCenter: ISampleDistance = {
    id: null,
    distance: Infinity,
  };

  samples.forEach((sample) => {
    try {
      const frustum = sample.geodetic_camera?.frustum;
      const frustumCoordinates = frustum?.map(({ lat, lon }) => [lon, lat]);

      if (frustumCoordinates?.length) {
        const polygonFrustumCoordinates = [...frustumCoordinates, frustumCoordinates[0]];
        const frustumPolygon = turf.polygon([polygonFrustumCoordinates]);
        const frustumPolygonCenter = turf.center(frustumPolygon);

        const distanceBetweenFrustumCenterAndAnomalyCenter = turf.distance(
          frustumPolygonCenter,
          anomalyPolygonCenter,
          {
            units: 'meters',
          },
        );

        if (
          !sampleWithMinDistanceBetweenFrustumCenterAndAnomalyCenter.id ||
          sampleWithMinDistanceBetweenFrustumCenterAndAnomalyCenter.distance >
            distanceBetweenFrustumCenterAndAnomalyCenter
        ) {
          sampleWithMinDistanceBetweenFrustumCenterAndAnomalyCenter.id = sample.id;
          sampleWithMinDistanceBetweenFrustumCenterAndAnomalyCenter.distance =
            distanceBetweenFrustumCenterAndAnomalyCenter;
        }
      }
    } catch {
      toast.error(t('somethingWentWrong'), { autoClose: ERROR_TOAST_DELAY });
    }
  });

  return sampleWithMinDistanceBetweenFrustumCenterAndAnomalyCenter?.id
    ? transformedSamples[sampleWithMinDistanceBetweenFrustumCenterAndAnomalyCenter?.id]
    : null;
};
