import { IProgram } from '@core/interfaces';
import { EAnomalyStatus } from '@core/interfaces/anomaly';

export function getAggregatedZonesStatuses(programs: IProgram[]) {
  return programs.reduce(
    (acc, program) => {
      if (program.statuses) {
        Object.entries(program.statuses).forEach(([status, count]) => {
          if (status in acc) {
            acc[status] += count;
          }
        });
      }
      return acc;
    },
    {
      [EAnomalyStatus.ToReview]: 0,
      [EAnomalyStatus.False]: 0,
      [EAnomalyStatus.Minor]: 0,
      [EAnomalyStatus.Major]: 0,
    },
  );
}
