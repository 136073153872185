import { Feature } from 'geojson';
import { IReport } from '@core/interfaces/report';
import { getColor } from '@core/utils';

interface ISolarPanelFeatureDeps {
  report: IReport;
  color: string | undefined;
}

export const getSolarPanelsFeatures = ({ report, color }: ISolarPanelFeatureDeps): Feature[] =>
  report?.solar_panels?.features.map((feature: Feature) => ({
    ...feature,
    id: feature.properties?.id,
    properties: { ...feature.properties, color: getColor(color || '--outflier-color2') },
  })) ?? [];
