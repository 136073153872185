import { FC } from 'react';
import cn from 'classnames';

interface IProps {
  title?: string;
  className?: string;
  percentage: number;
  isTextHidden: boolean;
  titleClassName?: string;
  textClassName?: string;
}

export const ProgressBarLayout: FC<IProps> = ({
  title,
  className,
  percentage,
  isTextHidden,
  titleClassName,
  textClassName,
}) => {
  return (
    <div className='w-full flex items-center flex-col'>
      {title && <span className={cn(titleClassName, 'text-white text-lg')}>{title}</span>}
      <div className={cn(className, 'bg-outflier-white h-[5px] w-full rounded-full')}>
        <div
          className='bg-outflier-blue h-[5px] transition-all duration-75 rounded-full'
          style={{ width: `${percentage}%` }}
        />
      </div>

      {!isTextHidden && (
        <span className={cn(textClassName ?? 'text-base text-black')}>{percentage}%</span>
      )}
    </div>
  );
};
