interface IDeleteAnomalyPopup {
  map: mapboxgl.Map;
}

export const removeAnomalyPopup = ({ map }: IDeleteAnomalyPopup) => {
  map.getCanvas().style.cursor = '';
  const popup = document.getElementById('popup-anomaly');

  if (popup) popup.remove();
};
