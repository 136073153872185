export const fileToBase64 = (file: File | Blob): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => event.target && resolve(event.target.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

// Link: https://github.com/Gimyk/resize_base64_image/blob/main/main.js
export async function reduceImageFileSize(base64Str, params = { maxWidth: 450, maxHeight: 450 }) {
  const { maxWidth, maxHeight } = params;

  return new Promise((resolve) => {
    const img = new Image();
    img.src = base64Str;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }
      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext('2d');
      ctx?.drawImage(img, 0, 0, width, height);
      // NOTE: this will return base64 image results after resize
      resolve(canvas.toDataURL());
    };
  });
}

export async function processImage(file: File, minImageSize = 300) {
  try {
    const base64Str = await fileToBase64(file);

    if (base64Str) {
      const oldSize = calcImageSize(base64Str as string);
      if (oldSize > minImageSize) {
        const resized = await reduceImageFileSize(base64Str);
        return resized;
      } else {
        return base64Str;
      }
    } else {
      throw new Error('Incorrect base64 string');
    }
  } catch (err) {
    console.error(err);
  }
}

export function calcImageSize(image: string) {
  let y = 1;
  if (image.endsWith('==')) y = 2;

  const xSize = image.length * (3 / 4) - y;
  return Math.round(xSize / 1024);
}
