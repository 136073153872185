/* eslint-disable no-prototype-builtins */
// https://stackoverflow.com/questions/4391575/how-to-find-the-size-of-localstorage
interface IOptions {
  isLogged?: boolean;
  blacklistKeys?: string[];
}

export function getLocalStorageSize(
  { isLogged = false, blacklistKeys = [] }: IOptions = {} as IOptions,
) {
  const BYTES_IN_KB = 1024;

  let keyLength = 0;
  let key = '';

  const result: Record<string, number> = {
    total: 0,
  };

  for (key in window.localStorage) {
    if (!window.localStorage.hasOwnProperty(key)) {
      continue;
    }
    keyLength = (window.localStorage[key].length + key.length) * 2;
    result[key] = keyLength;

    if (!blacklistKeys.includes(key)) {
      result.total += keyLength;
    }
  }

  if (isLogged) {
    Object.entries(result).forEach(([key, value]) => {
      console.info(`${key} = ${(value / BYTES_IN_KB).toFixed(2)} KB`);
    });
  }

  const sizeInKb = (result.total / BYTES_IN_KB).toFixed(2);
  return Number(sizeInKb);
}

export const getApproximateSizeForLocalStorageItem = (key: string, value: any) => {
  const KB = 1024;
  return ((JSON.stringify(value).length + key.length) * 2) / KB;
};
