import { Feature } from 'geojson';
import { EAnomalyStatus, IAnomaly } from '@core/interfaces/anomaly';
import { IReport } from '@core/interfaces/report';
import {
  getAnomalyColor,
  getColor,
  getRightmostCoordinateFromPolygonCoordinates,
} from '@core/utils';

interface IAnomaliesFeatureDeps {
  anomaliesType: EAnomalyStatus | null | undefined;
  report: IReport;
  selectedAnomaly?: IAnomaly;
}

const stylesHelper = {
  getBorderWidth(anomaly: IAnomaly, selectedAnomaly?: IAnomaly) {
    return anomaly.id === selectedAnomaly?.id ? 4 : 1;
  },
  getBorderColor(anomaly: IAnomaly, selectedAnomaly?: IAnomaly) {
    return getColor(anomaly.id === selectedAnomaly?.id ? '--outflier-blue' : '--outflier-color5');
  },
  getOpacity(anomaly: IAnomaly, selectedAnomaly?: IAnomaly) {
    return !selectedAnomaly?.id ? 1 : anomaly.id === selectedAnomaly?.id ? 1 : 0.7;
  },
};

export const getAnomaliesFeatures = ({
  anomaliesType,
  selectedAnomaly,
  report,
}: IAnomaliesFeatureDeps): Feature[] => {
  const reportAnomalies = anomaliesType
    ? [...(report.anomalies ?? [])].filter(({ status }) => status === anomaliesType)
    : [...(report.anomalies ?? [])];

  return reportAnomalies.flatMap((anomaly) =>
    anomaly.features.map((feature) => ({
      ...feature,
      id: anomaly.id,
      properties: {
        ...feature.properties,
        color: getAnomalyColor(anomaly, selectedAnomaly),
        borderWidth: stylesHelper.getBorderWidth(anomaly, selectedAnomaly),
        opacity: stylesHelper.getOpacity(anomaly, selectedAnomaly),
        borderColor: stylesHelper.getBorderColor(anomaly, selectedAnomaly),
        coordinates: getRightmostCoordinateFromPolygonCoordinates(
          anomaly.features[0].geometry.coordinates,
        ),
      },
    })),
  );
};
