import React, { useState } from 'react';
import eye from '@assets/icons/eye.svg';
import temperature from '@assets/icons/temperature.svg';

interface IProps {
  min: number;
  max: number;
  step?: number;
  initialValue?: number;
  onChange: (value: number) => void;
}

export const Slider: React.FC<IProps> = ({ min, max, step = 1, initialValue, onChange }) => {
  const [value, setValue] = useState(initialValue ?? (min + max) / 2);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <div className='w-[255px] h-[45px] flex items-center justify-center bg-outflier-contain-background rounded-[6px]'>
      <div className='h-[45px] min-w-[45px] flex items-center justify-center'>
        <img className='' src={eye} alt='visual anomalies' />
      </div>
      <input
        type='range'
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleChange}
        className='h-0.5 cursor-pointer appearance-none w-full bg-gray-300 rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500'
      />
      <div className='h-[45px] min-w-[45px] flex items-center justify-center'>
        <img className='' src={temperature} alt='thermal anomalies' />
      </div>
    </div>
  );
};
