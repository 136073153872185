import { Feature } from 'geojson';
import { layersStyles, setFeatureCollectionData } from './mapboxConfig';
import { EAnomalyLayers, EZonesLayers } from '../../enums/layers';
import { EAnomalySources } from '../../enums/sources';

export const setAnomalies = (map: mapboxgl.Map, features: Feature[]) => {
  map.addSource(EAnomalySources.Anomalies, {
    type: 'geojson',
    data: setFeatureCollectionData(features),
  });

  map.addLayer(layersStyles[EAnomalyLayers.Anomalies](), EZonesLayers.PolygonBorder);
  map.addLayer(layersStyles[EAnomalyLayers.AnomaliesBorder]());
};
