// TODO:

/* eslint-disable no-case-declarations */

/* eslint-disable @typescript-eslint/ban-types */
import { toast } from 'react-toastify';
import { RootState } from '..';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { location } from '@core/api/locations';
import { ERROR_TOAST_DELAY } from '@core/constants';
import { ESidebar, ESiteStatuses } from '@core/enums';
import { useSelectorTyped } from '@core/hooks';
import { IInspection, ISite } from '@core/interfaces/';
import {
  fetchProgramsByLocationId,
  resetPrograms,
  updateProgramsWorstAnomalyStatus,
} from '@core/store/slices/programs';
import { isNumber } from '@core/utils';
import { findClosestByLongLat } from '@core/utils/geospatial/distanceUtils';
import { EMapEvents } from '@modules/Viewers/views/MapViewer/enums/events';
import { resetAnomalies } from './anomalies';
import {
  fetchInspections,
  setCurrentInspectionIdBySiteId,
  updateCurrentInspectionId,
} from './inspections';
import { reloadMap } from './map';
import { setPreloader } from './preloader';
import { fetchReportsByInspections, resetReports } from './reports';
import { resetAnomalySamples } from './samples';
import { setSidebar } from './sidebar';
import { awaitStateUpdates } from '../utils/common/awaitStateUpdates';
import { compareInspectionDates } from '../utils/inspections/compareInspectionDates';
import { getLatestInspectionIdByDate } from '../utils/inspections/getLatestInspectionIdByDate';
import { getSidebarMode } from '../utils/sites/getSidebarMode';
import {
  getAggregatedCollectionBySiteIdWithProgramLatestInspectionPair,
  shouldAtLeastOneZoneBeInspected,
} from '../utils/sites/siteStatus';

interface IInitialState {
  sites: ISite[];
  sitesSidebar: ISite[];
  loading: boolean;
  currentSiteId: string | null;
}

const initialState: IInitialState = {
  sites: [],
  sitesSidebar: [],
  loading: false,
  currentSiteId: '',
};

export const addSitesInfo = createAsyncThunk(
  'sites/addSitesInfo',
  async (_, { dispatch, getState }) => {
    try {
      dispatch(setPreloader(true));
      dispatch(startDataProcessing());

      const sites = await location.getAll();
      const filteredSites = sites.filter((site) =>
        // NOTE: required fields for site
        ['name', 'lat', 'long', 'altitude', 'gps_boundaries', 'loc_id'].every(
          (field) => field in site,
        ),
      );

      dispatch(addSites(filteredSites));
      dispatch(fetchInspections());
    } catch {
      toast.error(t('errors.loadSitesDataFail'), { autoClose: ERROR_TOAST_DELAY });
    } finally {
      dispatch(finishDataProcessing());
      await awaitStateUpdates(getState, ['inspections']);
      dispatch(updateStatuses());
      dispatch(setPreloader(false));
      dispatch(reloadMap(true));
    }
  },
);

// TODO:
// eslint-disable-next-line @typescript-eslint/ban-types
export const fetchSitePerimeter = createAsyncThunk<void, string, {}>(
  'sites/fetchSitePerimeter',
  async (siteId, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;

      const response = await location.getPerimeterByLocationId(siteId);
      if (!response?.type) throw new Error('GeoJSON data is missing the "type" property');

      const sitesWithGeojson = (state.sites.sites as ISite[]).map((site) =>
        site.loc_id === siteId ? { ...site, geojson: response } : site,
      );

      dispatch(updateSites(sitesWithGeojson));
    } catch {
      toast.error(t('errors.getSiteGeojsonFail', { siteId: siteId }), {
        autoClose: ERROR_TOAST_DELAY,
      });
    }
  },
);

export const addSelectedSiteInfo = createAsyncThunk<
  void,
  { siteId: string; sidebar?: ESidebar },
  // TODO:
  // eslint-disable-next-line @typescript-eslint/ban-types
  {}
>('sites/addSelectedSiteInfo', async ({ siteId, ...restProps }, { dispatch, getState }) => {
  let state = getState() as RootState;

  // NOTE: await for inspections if there is only one site on the map
  if (state.sites.sites.length === 1) await awaitStateUpdates(getState, ['inspections'], 50);

  state = getState() as RootState;
  const inspections: IInspection[] = state.inspections.inspections;
  const siteInspections = inspections.filter((inspection) => inspection.location_id === siteId);

  try {
    dispatch(resetSite());
    dispatch(setPreloader(true));
    dispatch(setCurrentSiteId(siteId));
    dispatch(setCurrentInspectionIdBySiteId(siteId));
    dispatch(fetchSitePerimeter(siteId));
    dispatch(
      fetchProgramsByLocationId({
        locationId: siteId,
        inspectionId: (getState() as RootState).inspections.currentInspectionId,
      }),
    );
    dispatch(fetchReportsByInspections(siteInspections));
    // NOTE: Waiting for all sites data dependencies.
    await awaitStateUpdates(getState, ['programs', 'inspections', 'reports'], 100);
  } catch {
    toast.error(t('errors.loadSelectedSiteDataFail'), { autoClose: ERROR_TOAST_DELAY });
  } finally {
    const sidebarMode = getSidebarMode(
      (getState() as RootState).programs.programs,
      siteId,
      restProps.sidebar,
    );
    dispatch(setSidebar(sidebarMode));
    dispatch(updateProgramsWorstAnomalyStatus());
    dispatch(setPreloader(false));
    dispatch(reloadMap(true));
  }
});

export const updateStatuses = createAsyncThunk(
  'sites/updateStatuses',
  async (_, { dispatch, getState }) => {
    dispatch(startDataProcessing());

    const state = getState() as RootState;
    const currentInspectionId = state.inspections.currentInspectionId;
    const currentInspection = state.inspections.inspections.find(
      (inspection: IInspection) => inspection.id === currentInspectionId,
    );
    const currentDate = currentInspection ? new Date(currentInspection.date) : null;

    // Filter out inspections that occurred after the current inspection date
    const inspections = currentDate
      ? state.inspections.inspections.filter(
          (inspection: IInspection) => new Date(inspection.date).getTime() <= currentDate.getTime(),
        )
      : state.inspections.inspections;

    const aggregatedCollectionBySiteIdWithProgramLatestInspectionPair =
      getAggregatedCollectionBySiteIdWithProgramLatestInspectionPair(inspections);

    // Map over the sites and update the status
    const sites: ISite[] = state.sites.sites.map((site: ISite) => {
      // NOTE: "location_status" and "status" are mutually exclusive
      if (site.location_status) return site;

      const siteInspections: IInspection[] = inspections.filter(
        (inspection: IInspection) => inspection.location_id === site.loc_id,
      );

      if (!siteInspections.length) {
        return {
          ...site,
          status: ESiteStatuses.Inspected,
        };
      }

      // Get the latest inspection with anomalies for this site
      const latestInspection: IInspection = siteInspections.reduce<IInspection>(
        (latest, inspection) => {
          const isLatestInspection = compareInspectionDates(inspection.date, latest.date);
          return isLatestInspection ? inspection : latest;
        },
        siteInspections[0],
      );

      const isReportProcessing = latestInspection.uploaded && !latestInspection.report_generated;

      const shouldBeInspected = shouldAtLeastOneZoneBeInspected(
        aggregatedCollectionBySiteIdWithProgramLatestInspectionPair,
        site,
      );

      const tileSiteStatus = shouldBeInspected
        ? ESiteStatuses.Inspected
        : isNumber(latestInspection?.nb_anomalies) && latestInspection.nb_anomalies > 0
        ? ESiteStatuses.Issues
        : ESiteStatuses.Normal;

      const siteStatus = isReportProcessing ? ESiteStatuses.ReportProcessing : tileSiteStatus;

      return {
        ...site,
        status: siteStatus,
        last_inspection_date: latestInspection.date,
      };
    });

    dispatch(updateSites(sites));
    dispatch(finishDataProcessing());
  },
);

export const setCurrentSiteIdAndUpdatePrograms = createAsyncThunk<void, string | null, {}>(
  'sites/setCurrentSiteIdAndUpdatePrograms',
  async (siteId, { dispatch }) => {
    dispatch(setCurrentSiteId(siteId));
    dispatch(setCurrentInspectionIdBySiteId(siteId));
    dispatch(updateProgramsWorstAnomalyStatus());
  },
);

export const resetSite = createAsyncThunk<void, void, {}>(
  'sites/resetSite',
  async (_, { dispatch }) => {
    dispatch(resetAnomalies());
    dispatch(resetPrograms());
    dispatch(resetReports());
    dispatch(resetAnomalySamples());
  },
);

const updateSitesSidebarAndCurrentInspectionId = createAsyncThunk<
  void,
  { visibleSites: ISite[]; event: EMapEvents.Drag | EMapEvents.Zoom },
  {}
>(
  'sites/updateSitesSidebarAndCurrentInspectionId',
  async ({ visibleSites, event }, { getState, dispatch }) => {
    dispatch(updateSitesSidebar(visibleSites));

    const state = getState() as RootState;

    switch (state.sidebar.sidebar) {
      case ESidebar.Sites:
        const sitesSidebarIds = state.sites.sitesSidebar.map((site) => site.loc_id);
        const sitesSidebarInspections = state.inspections.inspections.filter(
          (inspection: IInspection) => sitesSidebarIds.includes(inspection.location_id ?? ''),
        );

        const shouldUpdateInspection = event === EMapEvents.Zoom;

        // NOTE: update map if sidebar is changed and fired "zoom" event
        if (shouldUpdateInspection) {
          const latestInspectionId = getLatestInspectionIdByDate(sitesSidebarInspections);
          if (latestInspectionId && latestInspectionId !== state.inspections.currentInspectionId) {
            dispatch(updateCurrentInspectionId(latestInspectionId));
            dispatch(updateStatuses());
            // NOTE: update "clusters" with re-calculated statuses for each site
            dispatch(reloadMap(true));
          }
        }
        break;
      case ESidebar.Site:
        if (state.sites.currentSiteId) {
          const currentSiteInspections = state.inspections.inspections.filter(
            (inspection: IInspection) => state.sites.currentSiteId === inspection.location_id,
          );

          const foundInspection = currentSiteInspections.find(
            (inspection) => inspection.id === state.inspections.currentInspectionId,
          );

          if (!foundInspection) {
            const latestInspectionId = getLatestInspectionIdByDate(currentSiteInspections);
            if (latestInspectionId) dispatch(updateCurrentInspectionId(latestInspectionId));
          }
        }
        break;
      case ESidebar.Zone:
      case ESidebar.Anomaly:
        if (state.programs.currentProgramId) {
          const currentProgramInspections = state.inspections.inspections.filter(
            (inspection: IInspection) => state.programs.currentProgramId === inspection.program_id,
          );

          const foundInspection = currentProgramInspections.find(
            (inspection) => inspection.id === state.inspections.currentInspectionId,
          );

          if (!foundInspection) {
            const latestInspectionId = getLatestInspectionIdByDate(currentProgramInspections);
            if (latestInspectionId) dispatch(updateCurrentInspectionId(latestInspectionId));
          }
        }
        break;
    }
  },
);

const sitesSlice = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    startDataProcessing: (state) => {
      state.loading = true;
    },
    finishDataProcessing: (state) => {
      state.loading = false;
    },
    addSites: (state, actions: PayloadAction<ISite[]>) => {
      state.sites = actions.payload;
      state.sitesSidebar = actions.payload;
    },
    updateSites: (state, actions: PayloadAction<ISite[]>) => {
      state.sites = actions.payload;
      const sidebarSites = actions.payload.filter((site) =>
        state.sitesSidebar.some((sidebarSite: ISite) => sidebarSite.loc_id === site.loc_id),
      );
      state.sitesSidebar = sidebarSites;
    },
    updateSitesSidebar: (state, actions: PayloadAction<ISite[]>) => {
      state.sitesSidebar = actions.payload;
    },
    setCurrentSiteId: (state, action: PayloadAction<string | null>) => {
      state.currentSiteId = action.payload;
    },
    resetSites: () => initialState,
  },
});

const sitesReducer = sitesSlice.reducer;

const {
  startDataProcessing,
  finishDataProcessing,
  addSites,
  updateSites,
  updateSitesSidebar,
  setCurrentSiteId,
  resetSites,
} = sitesSlice.actions;

const useSitesSelector = () => useSelectorTyped((state) => state.sites);
const useSitesSidebarSelector = () => useSelectorTyped((state) => state.sites.sitesSidebar);
const useCurrentSiteSelector = () =>
  useSelectorTyped((state) =>
    state.sites.sites.find((site) => site.loc_id === state.sites.currentSiteId),
  );

const useClosestSiteSelector = (long: number, lat: number) =>
  useSelectorTyped((state) => {
    return findClosestByLongLat<ISite>(state.sites.sites, long, lat);
  });

export {
  sitesReducer,
  addSites,
  updateSites,
  updateSitesSidebar,
  setCurrentSiteId,
  resetSites,
  updateSitesSidebarAndCurrentInspectionId,
  useSitesSelector,
  useSitesSidebarSelector,
  useCurrentSiteSelector,
  useClosestSiteSelector,
  initialState as sitesInitialState,
};
