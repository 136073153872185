import { axiosInstance } from '@core/api/axios';
import { INoFlyZone, IObstacle } from '@core/interfaces/createSite';

interface IPostNoFlyZonesResponse {
  name: string;
  altitude: number;
  id: number;
  loc_id: string;
  type: 'no_fly_zone' | 'obstacle';
  perimeter: string;
}

type INoFlyZoneWithoutID = Omit<INoFlyZone, 'id'>;
type IObstacleWithoutID = Omit<IObstacle, 'id'>;

// Note: This endpoint accepts both NoFlyZones and Obstacles
export const createNoFlyZonesByLocationId = async (
  id: string,
  noFlyZone: INoFlyZoneWithoutID | IObstacleWithoutID,
): Promise<IPostNoFlyZonesResponse> => {
  return axiosInstance
    .post<IPostNoFlyZonesResponse>(`/locations/${id}/no_fly_zones`, noFlyZone)
    .then(({ data }) => data);
};
