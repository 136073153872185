import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import mapboxgl, { MapEventType } from 'mapbox-gl';
import 'mapbox-gl-compare/dist/mapbox-gl-compare.css';
import { useDispatchTyped } from '@core/hooks';
import { useCachedValue } from '@core/hooks';
import { useMapbox } from '@core/hooks/useMapbox';
import { IProgram, ISample } from '@core/interfaces';
import { EAnomalyStatus, IAnomaly } from '@core/interfaces/anomaly';
import {
  IMapConfig,
  MapClickEvent,
  MapMouseEnterEvent,
  MapMouseLeaveEvent,
} from '@core/interfaces/map';
import { IReport } from '@core/interfaces/report';
import { DataProcessing } from '@core/services';
import {
  setCurrentAnomalyId,
  setPreloader,
  useAnomaliesSelector,
  useControlsSelector,
  useCurrentAnomalySelector,
  useCurrentInspectionIdSelector,
  useSidebarSelector,
} from '@core/store/slices';
import { useCurrentProgramSelector } from '@core/store/slices/programs';
import {
  useCurrentReportSelector,
  useReportToCompareSelector,
  useReportsSelector,
} from '@core/store/slices/reports';
import { useSamplesSelector } from '@core/store/slices/samples';
import { Controls } from '@modules/Viewers/components/Controls';
import styles from './styles.scss';
import { EAnomalyLayers } from '../MapViewer/enums/layers';
import { IAnomaliesProps, addAnomalies } from '../MapViewer/utils/anomalies/addAnomalies';
import { createAnomalyPopup } from '../MapViewer/utils/anomalies/createAnomalyPopup';
import { removeAnomalyPopup } from '../MapViewer/utils/anomalies/removeAnomalyPopup';
import { customizeCompass } from '../MapViewer/utils/customizeCompass';
import { customizeSwiper } from '../MapViewer/utils/customizeSwiper';
import { addFlightPath } from '../MapViewer/utils/flightPath/addFlightPath';
import { hideElements } from '../MapViewer/utils/hideElements';
import { addOrtho } from '../MapViewer/utils/ortho/addOrthoTiles';
import { addSamples } from '../MapViewer/utils/samples/addSamples';
import { ISolarPanelsProps, addSolarPanels } from '../MapViewer/utils/solarPanels/addSolarPanels';
import { addZones } from '../MapViewer/utils/zones/addZones';

interface IProps {
  minZoom?: number;
}

export const CompareMapViewer = ({ minZoom = 2 }: IProps) => {
  const controls = useControlsSelector();
  const reportToCompare = useReportToCompareSelector();
  const currentReport = useCurrentReportSelector();
  const program = useCurrentProgramSelector();
  const currentInspectionId = useCurrentInspectionIdSelector();
  const { reports } = useReportsSelector();
  const { sidebar, sidebarPrevious } = useSidebarSelector();
  const { anomaliesType, toCompareAnomaliesType } = useAnomaliesSelector();
  const {
    currentReportSamples,
    loading: isReportSamplesLoading,
    reportToCompareSamples,
  } = useSamplesSelector();
  const currentAnomaly = useCurrentAnomalySelector();
  const [isCompareMapStyleLoaded, setIsCompareMapStyleLoaded] = useState(false);
  const [isMainMapStyleLoaded, setIsMainMapStyleLoaded] = useState(false);
  const [mapScale, setMapScale] = useState<string | null>(null);

  const [prevAnomaliesType, setPrevAnomaliesType] = useState<EAnomalyStatus | null>(null);
  const [prevCompareAnomaliesType, setPrevCompareAnomaliesType] = useState<EAnomalyStatus | null>(
    null,
  );

  const reportToCompareCached = useCachedValue(reportToCompare);
  const currentReportCached = useCachedValue(currentReport);

  const anomalies = useMemo(
    () => [...(currentReportCached?.anomalies || []), ...(reportToCompareCached?.anomalies || [])],
    [currentReportCached, reportToCompareCached],
  );

  const currentAnomaliesRef = useRef<IAnomaly[]>([]);
  currentAnomaliesRef.current = anomalies;

  const dispatch = useDispatchTyped();

  const initialZoom = 19;

  const {
    compareMap,
    beforeMap,
    afterMap,
    compareMapContainer,
    beforeMapContainer,
    afterMapContainer,
    handleInitializing,
    handleZoomIn,
    handleZoomOut,
    handleZoomTo,
  } = useMapbox({ minZoom, compareMode: true });

  const handleDynamicZoom = () => {
    if (program?.long && program?.lat) {
      return handleZoomTo(program.long, program.lat, initialZoom, true);
    }
  };

  const handleAnomalyClick = useCallback(
    _.debounce((event: MapClickEvent) => {
      const features = event.target.queryRenderedFeatures(event.point, {
        layers: [EAnomalyLayers.Anomalies],
      });

      const anomalyId = features[0]?.id;

      if (!anomalyId) return;

      const selectedAnomaly = currentAnomaliesRef.current.find(
        (anomaly) => anomaly.id === anomalyId,
      );

      if (selectedAnomaly) {
        dispatch(setCurrentAnomalyId(Number(anomalyId)));
      }
    }),
    [],
  );

  const handleAnomalyMouseEnter = useCallback(
    _.debounce((event: MapMouseEnterEvent) => {
      const features = event.target.queryRenderedFeatures(event.point, {
        layers: [EAnomalyLayers.Anomalies],
      });
      const anomalyFeature = features.find(
        (feature) => feature.layer.id === EAnomalyLayers.Anomalies,
      );

      if (anomalyFeature && anomalyFeature.properties?.coordinates) {
        const anomalyFeatureCoordinates = anomalyFeature.properties?.coordinates;

        if (anomalyFeature && anomalyFeatureCoordinates) {
          const deserializedCoordinates: mapboxgl.LngLat =
            DataProcessing.deserialize(anomalyFeatureCoordinates);

          createAnomalyPopup({
            map: event.target,
            anomalyFeature,
            coordinates: deserializedCoordinates,
          });
        }
      }
    }),
    [],
  );

  const handleAnomalyMouseLeave = useCallback(
    _.debounce((event: MapMouseLeaveEvent) => {
      removeAnomalyPopup({ map: event.target });
    }),
    [],
  );

  const handleMapClick = useCallback((event: mapboxgl.MapMouseEvent) => {
    const features = event.target.queryRenderedFeatures(event.point, {
      layers: [EAnomalyLayers.Anomalies],
    });
    const anomalyFeature = features.find(
      (feature) => feature.layer.id === EAnomalyLayers.Anomalies,
    );

    const isCompareMap = (beforeMap.current as any)._mapId === (event.target as any)._mapId;
    const isMainMap = (afterMap.current as any)._mapId === (event.target as any)._mapId;

    if (isCompareMap || isMainMap) {
      if (!anomalyFeature) {
        dispatch(setCurrentAnomalyId(null));
        removeAnomalyPopup({ map: event.target });
        customizeSwiper();
      }
    }
  }, []);

  const handleUpdateMapScaleValue = useCallback((map: mapboxgl.Map) => {
    const mapContainer = (map.boxZoom as any)._container as HTMLElement;
    const scaleElement = mapContainer.querySelector('.mapboxgl-ctrl-scale');

    if (scaleElement) {
      setMapScale(scaleElement.textContent);
    }
  }, []);

  const handleZoomEnd = useCallback(
    (event: MapEventType['zoomend']) => {
      handleUpdateMapScaleValue(event.target);
    },
    [handleUpdateMapScaleValue],
  );

  const addMapFeatures = (map: mapboxgl.Map | null, programs: IProgram[], report: IReport) => {
    addZones({ map, programs, sidebar });
    addOrtho({
      map,
      programs,
      currentInspectionId,
      reports,
      sidebar,
      report,
      isCompareMode: true,
    });
  };

  const updateMapWithSamples = (
    isStyleLoaded: boolean | undefined,
    mapInstance: mapboxgl.Map | null,
    samples: ISample[],
    flightPath: ISample[],
  ) => {
    if (!mapInstance) return;

    const flightPathProps = {
      map: mapInstance,
      show: controls.hasFlightPath,
      flightPath: flightPath,
      drawBatteries: true,
    };

    const samplesProps = {
      samples: samples,
      map: mapInstance,
      show: controls.hasFlightPath,
      isFlightPathSamples: true,
    };

    if (!isStyleLoaded) {
      mapInstance.once('load', () => {
        addFlightPath(flightPathProps);
        addSamples(samplesProps);
      });
    } else {
      addFlightPath(flightPathProps);
      addSamples(samplesProps);
    }
  };

  const updateMapWithSolarPanels = (props: ISolarPanelsProps, isStyleLoaded: boolean) => {
    if (!props.map || !isStyleLoaded) return;
    addSolarPanels(props);
  };

  const addAnomaliesToMapInstance = (
    {
      map,
      report,
      anomaliesType,
      sidebar,
      selectedAnomaly,
      isCompareMode,
      onClick,
      onMouseEnter,
      onMouseLeave,
      withZoomToAnomaly,
    }: IAnomaliesProps,
    isStyleLoaded: boolean,
  ) => {
    if (!map || !report || !isStyleLoaded) return;

    const anomaliesProps = {
      sidebar,
      anomaliesType,
      selectedAnomaly,
      map,
      report,
      isCompareMode,
      onClick,
      onMouseEnter,
      onMouseLeave,
      withZoomToAnomaly,
    };

    addAnomalies(anomaliesProps);
  };

  const addControls = (map: mapboxgl.Map) => {
    map?.addControl(new mapboxgl.ScaleControl({ unit: 'metric' }), 'bottom-right');
    map?.addControl(
      new mapboxgl.NavigationControl({ showZoom: false, showCompass: true }),
      'bottom-right',
    );
  };

  useEffect(() => {
    if (!beforeMap.current || !afterMap.current) return;
    customizeSwiper(!!currentAnomaly);
  }, [currentAnomaly]);

  useEffect(() => {
    if (
      beforeMap.current?.getZoom() !== initialZoom ||
      afterMap.current?.getZoom() !== initialZoom
    ) {
      beforeMap?.current?.setZoom(initialZoom);
      afterMap?.current?.setZoom(initialZoom);
    }
  }, []);

  useEffect(() => {
    if (
      !program ||
      !controls.isCompareMode ||
      (beforeMap.current && afterMap.current) ||
      !compareMap
    )
      return;

    const onLoaded = () => {
      if (!afterMap.current || !beforeMap.current || !compareMap?.current) return;

      addControls(beforeMap.current);
      addControls(afterMap.current);

      hideElements();
      customizeCompass();
      customizeSwiper();
    };

    if (program.long && program.lat) {
      const config: IMapConfig = {
        initialZoom: initialZoom,
        minZoom: 17,
        maxZoom: 21,
        center: [program.long, program.lat],
      };
      handleInitializing(onLoaded, true, config);
    }
  }, [program, beforeMap.current, afterMap.current, controls.isCompareMode]);

  const handleCompareMapStylesLoad = useCallback(() => setIsCompareMapStyleLoaded(true), []);
  const handleMainMapStylesLoad = useCallback(() => setIsMainMapStyleLoaded(true), []);

  useEffect(() => {
    if (!beforeMap.current) return;
    beforeMap.current.once('style.load', handleCompareMapStylesLoad);
  }, [beforeMap.current, handleCompareMapStylesLoad]);

  useEffect(() => {
    if (!afterMap.current) return;
    afterMap.current.once('style.load', handleMainMapStylesLoad);
  }, [afterMap.current, handleMainMapStylesLoad]);

  useEffect(() => {
    if (!beforeMap.current || !program || !reportToCompareCached || !isCompareMapStyleLoaded)
      return;
    addMapFeatures(beforeMap.current, [program], reportToCompareCached);
  }, [isCompareMapStyleLoaded, program, reportToCompareCached]);

  useEffect(() => {
    if (!afterMap.current || !program || !currentReportCached || !isMainMapStyleLoaded) return;
    addMapFeatures(afterMap.current, [program], currentReportCached);
  }, [isMainMapStyleLoaded, program, currentReportCached]);

  useEffect(() => {
    if (!beforeMap.current) return;

    beforeMap.current.on('click', handleMapClick);
    beforeMap.current.on('zoomend', handleZoomEnd);
  }, [beforeMap.current, handleMapClick, handleZoomEnd]);

  useEffect(() => {
    if (!afterMap.current) return;

    afterMap.current.on('click', handleMapClick);
    afterMap.current.on('zoomend', handleZoomEnd);
  }, [afterMap.current, handleMapClick, handleZoomEnd]);

  useEffect(() => {
    if (!reportToCompareCached || !program) return;

    const typeChanged = prevCompareAnomaliesType !== toCompareAnomaliesType;

    setPrevCompareAnomaliesType(toCompareAnomaliesType);

    addAnomaliesToMapInstance(
      {
        map: beforeMap?.current,
        report: reportToCompareCached,
        anomaliesType: toCompareAnomaliesType,
        sidebar,
        selectedAnomaly: currentAnomaly,
        onClick: handleAnomalyClick,
        onMouseEnter: handleAnomalyMouseEnter,
        onMouseLeave: handleAnomalyMouseLeave,
        isCompareMode: true,
        withZoomToAnomaly: !typeChanged,
      },
      isCompareMapStyleLoaded,
    );
  }, [
    reportToCompareCached,
    currentAnomaly,
    toCompareAnomaliesType,
    isCompareMapStyleLoaded,
    handleAnomalyClick,
    handleAnomalyMouseEnter,
    handleAnomalyMouseLeave,
  ]);

  useEffect(() => {
    if (!currentReportCached || !program) return;

    const typeChanged = prevAnomaliesType !== anomaliesType;

    setPrevAnomaliesType(anomaliesType);

    addAnomaliesToMapInstance(
      {
        map: afterMap?.current,
        report: currentReportCached,
        anomaliesType,
        sidebar,
        selectedAnomaly: currentAnomaly,
        onClick: handleAnomalyClick,
        onMouseEnter: handleAnomalyMouseEnter,
        onMouseLeave: handleAnomalyMouseLeave,
        isCompareMode: true,
        withZoomToAnomaly: !typeChanged,
      },
      isMainMapStyleLoaded,
    );
  }, [
    currentReportCached,
    currentAnomaly,
    anomaliesType,
    isMainMapStyleLoaded,
    handleAnomalyClick,
    handleAnomalyMouseEnter,
    handleAnomalyMouseLeave,
  ]);

  // NOTE: add solar panels (compare map)
  useEffect(() => {
    if (!controls.isCompareMode) return;
    const color = controls.hasSolarPanels ? '--outflier-color2' : '--soft-grey';

    updateMapWithSolarPanels(
      {
        map: beforeMap.current,
        report: reportToCompareCached ?? null,
        color,
        show: controls.hasSolarPanels,
        sidebar,
        sidebarPrevious,
        withControl: true,
        isCompareMode: true,
      },
      isCompareMapStyleLoaded,
    );
  }, [
    isCompareMapStyleLoaded,
    sidebar,
    sidebarPrevious,
    reportToCompareCached,
    controls.isCompareMode,
    controls.hasSolarPanels,
  ]);

  // NOTE: add solar panels (main map)
  useEffect(() => {
    if (!controls.isCompareMode) return;
    const color = controls.hasSolarPanels ? '--outflier-color2' : '--soft-grey';

    updateMapWithSolarPanels(
      {
        map: afterMap.current,
        report: currentReportCached ?? null,
        color,
        show: controls.hasSolarPanels,
        sidebar,
        sidebarPrevious,
        withControl: true,
        isCompareMode: true,
      },
      isMainMapStyleLoaded,
    );
  }, [
    isMainMapStyleLoaded,
    currentReportCached,
    sidebar,
    sidebarPrevious,
    controls.isCompareMode,
    controls.hasSolarPanels,
  ]);

  useEffect(() => {
    if (!controls.isCompareMode) return;
    if (!currentReportSamples?.length && !reportToCompareSamples && !isReportSamplesLoading) return;

    if (
      (!currentReportSamples?.length || !reportToCompareSamples) &&
      isReportSamplesLoading &&
      controls.hasFlightPath
    ) {
      dispatch(setPreloader(true));
    }
    if (
      currentReportSamples?.length &&
      reportToCompareSamples &&
      !isReportSamplesLoading &&
      controls.hasFlightPath
    ) {
      dispatch(setPreloader(false));
    }

    if (reportToCompareSamples) {
      updateMapWithSamples(
        isCompareMapStyleLoaded,
        beforeMap?.current,
        reportToCompareSamples,
        reportToCompareSamples,
      );
    }

    if (currentReportSamples?.length) {
      updateMapWithSamples(
        isMainMapStyleLoaded,
        afterMap?.current,
        currentReportSamples,
        currentReportSamples,
      );
    }
  }, [
    controls.hasFlightPath,
    reportToCompareCached,
    currentReportCached,
    beforeMap?.current,
    afterMap?.current,
    controls.isCompareMode,
    isCompareMapStyleLoaded,
    isMainMapStyleLoaded,
    reportToCompareSamples,
    currentReportSamples,
    isReportSamplesLoading,
  ]);

  return (
    <div className={styles.mapWrapper}>
      <div ref={compareMapContainer} className={styles.mapContainer}>
        <div ref={beforeMapContainer} className={styles.compareContainer} />
        <div ref={afterMapContainer} className={styles.compareContainer} />
      </div>
      <Controls
        scale={mapScale}
        onDynamicZoom={handleDynamicZoom}
        onZoomIn={handleZoomIn}
        onZoomOut={handleZoomOut}
      />
    </div>
  );
};
