import { Feature } from 'geojson';
import { layersStyles, setFeatureCollectionData } from './mapboxConfig';
import { EAnomalyLayers, ESolarPanelsLayers } from '../../enums/layers';
import { ESolarPanelsSources } from '../../enums/sources';

export const setSolarPanels = (
  map: mapboxgl.Map,
  features: Feature[],
  showSolarPanels: boolean,
) => {
  map.addSource(ESolarPanelsSources.SolarPanels, {
    type: 'geojson',
    data: setFeatureCollectionData(features),
  });
  map.addLayer(
    layersStyles[ESolarPanelsLayers.SolarPanels]({ showSolarPanels }),
    EAnomalyLayers.Anomalies,
  );
};
