import { anomaliesStatusesColor, anomaliesStatusesColorWithGreyShade } from '@core/constants';
import { IAnomaly } from '@core/interfaces/anomaly';

const documentStyle = getComputedStyle(document.documentElement);

export const getColor = (variable: string) => documentStyle.getPropertyValue(variable);

export const getAnomalyColor = (anomaly: IAnomaly, selectedAnomaly?: IAnomaly) => {
  const nonExistentSelectedAnomaly = !selectedAnomaly;
  const isSelectedCurrentAnomaly = anomaly.id === selectedAnomaly?.id;

  const activeAnomalyColor = anomaliesStatusesColor[anomaly.status];
  const passiveAnomalyColor = anomaliesStatusesColorWithGreyShade[anomaly.status];

  return nonExistentSelectedAnomaly
    ? activeAnomalyColor
    : isSelectedCurrentAnomaly
    ? activeAnomalyColor
    : passiveAnomalyColor;
};
