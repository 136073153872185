import { Feature, Polygon } from 'geojson';
import { MAP_MAX_ZOOM } from '@core/constants/mapbox';
import { IMapConfig } from '@core/interfaces/map';
import { getPolygonCenter } from '@core/utils';

export function initializeAnomalyMapboxConfig(
  feature: Feature<Polygon>,
  minZoom: number,
): IMapConfig {
  const [lng, lat] = getPolygonCenter(feature);

  return {
    minZoom,
    maxZoom: MAP_MAX_ZOOM,
    initialZoom: minZoom,
    center: [lng, lat],
  };
}
