import React, { ButtonHTMLAttributes, memo } from 'react';
import cn from 'classnames';
import styles from '../styles.scss';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: string;
  className?: string;
  active?: boolean;
  disabled?: boolean;
}

export const ButtonWithIcon: React.FC<IProps> = memo(
  ({ icon, className, active, disabled, ...props }) => {
    const buttonClasses = cn(
      className,
      `h-[35px]
       w-[35px]
       bg-[#00000F]
       active:bg-gray-800
       box-border
       rounded-[6px]
       border
       border-[#3F3F4A]
       disabled:cursor-not-allowed
       disabled:opacity-70`,
      { [styles.active]: active },
    );

    const iconClasses = cn('h-full');

    return (
      <button className={buttonClasses} disabled={disabled} {...props}>
        <img className={iconClasses} src={icon} alt='' />
      </button>
    );
  },
);
