import { ELocationStatus } from '@core/interfaces';
import { styleSiteStatuses } from '@modules/Sidebar/views/Sites/utils/getTable';

export const getStatusStyle = (status?: string, locationStatus?: ELocationStatus) => {
  if (
    locationStatus &&
    [ELocationStatus.WaitingForApproval, ELocationStatus.UnderCreation].includes(locationStatus)
  ) {
    return styleSiteStatuses.processing;
  }
  return styleSiteStatuses[String(status)];
};
