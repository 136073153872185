export const hideElements = () => {
  const scales = document.querySelectorAll('.mapboxgl-ctrl-scale') as NodeListOf<HTMLElement>;
  const logos = document.querySelectorAll('.mapboxgl-ctrl-logo') as NodeListOf<HTMLElement>;

  logos.forEach((item) => (item.style.display = 'none'));
  scales.forEach((item) => {
    item.style.opacity = '0';
    item.style.visibility = 'hidden';
  });
};
