import { IProgram } from '@core/interfaces';
import { IReport } from '@core/interfaces/report';

// NOTE: set dependent reports to the program_id
export function getProgramsWithReports(programs: IProgram[] | null, reports) {
  return (programs || []).reduce<{ [programId: string]: IReport[] }>((acc, program) => {
    return {
      ...acc,
      [program.program_id]: reports.filter((report) => report.program_id === program.program_id),
    };
  }, {});
}
