import * as THREE from 'three';

export const createTriangleTexture = (color: string, size: number) => {
  const canvas = document.createElement('canvas');
  canvas.width = canvas.height = size;
  const context = canvas.getContext('2d');

  if (context) {
    context.beginPath();
    context.moveTo(size / 2, 0);
    context.lineTo(size, size);
    context.lineTo(0, size);
    context.closePath();
    context.fillStyle = color;
    context.fill();
  }

  const texture = new THREE.CanvasTexture(canvas);
  texture.needsUpdate = true;

  return texture;
};
