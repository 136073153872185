import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MAX_SITE_NAME_LENGTH } from '@core/constants/validation';
import { useDispatchTyped, useSelectorTyped } from '@core/hooks';
import { setName } from '@core/store/slices';
import { validateSiteName } from '@core/utils';
import NameBody from './NameBody';

const NameCreate: React.FC = () => {
  const { t } = useTranslation();
  const value = useSelectorTyped((state) => state.createSite.name);
  const [errorMessage, setErrorMessage] = useState<string | null>(validateSiteName(value));
  const dispatch = useDispatchTyped();

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.target.value;

      if (name.length < MAX_SITE_NAME_LENGTH) {
        const errorMessage = validateSiteName(name);
        setErrorMessage(errorMessage);
        dispatch(dispatch(setName(name)));
      }
    },
    [dispatch],
  );

  return (
    <NameBody
      text={{
        notification: t('sidebar.site.edit.sections.name.notification'),
        placeholder: t('sidebar.site.edit.sections.name.placeholderHintName'),
      }}
      errorMessage={errorMessage}
      value={value}
      onChange={handleChange}
    />
  );
};

export default NameCreate;
