import React from 'react';
import i18n from 'i18next';
import { IAccessTokenRequest } from '@core/api/user/tokens';
import { ELoginMode } from '@core/enums/accessControl';
import { Input } from '@components/Input';
import styles from './styles.scss';

interface IProps {
  values: IAccessTokenRequest;
  mode: ELoginMode;
}

export const FormContent: React.FC<IProps> = ({ values, mode }) => {
  if (mode === ELoginMode.SuccessNewPassword) {
    return (
      <div className={styles.sentNewPasswordText}>
        {i18n.t('loginPage.newPasswordSent', { email: values.username })}
      </div>
    );
  }

  return (
    <div className={styles.inputWrapper}>
      <Input name='username' type='text' placeholder={`${i18n.t('loginPage.placeholderEmail')}`} />
      {mode === ELoginMode.Login && (
        <Input
          name='password'
          type='password'
          placeholder={`${i18n.t('loginPage.placeholderPassword')}`}
        />
      )}
    </div>
  );
};
