import { EDetectionStatus } from '@core/enums';
import { ISampleDetection } from '@core/interfaces';

export const addStatusToDetections = (
  sampleDetections: ISampleDetection[] = [],
  anomalyDetections: number[] = [],
) => {
  const activeSampleDetections = sampleDetections
    .filter((sampleDetection) => anomalyDetections.includes(sampleDetection.id))
    .map(({ id }) => id);

  return sampleDetections.map((sampleDetection) => ({
    ...sampleDetection,
    status: activeSampleDetections.includes(sampleDetection.id)
      ? EDetectionStatus.Active
      : EDetectionStatus.NonActive,
  }));
};
