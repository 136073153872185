import { IProgram, IProgramStatuses } from '@core/interfaces';

export function setStatusesToPrograms(programs: IProgram[], statuses: IProgramStatuses[]) {
  const transformedStatuses = statuses.reduce(
    (acc, item) => ({ ...acc, [item.program_id]: item.statuses }),
    {},
  );

  return programs.map((program) => {
    const statuses = transformedStatuses[program.program_id];
    return statuses ? { ...program, statuses } : program;
  });
}
