import React from 'react';
import cn from 'classnames';
import styles from './styles.scss';

interface IProps {
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

export const TableRow: React.FC<IProps> = ({ children, className, onClick }) => {
  const classes = cn(styles.tableRow, { [styles.hover]: onClick }, className);

  return (
    <tr className={classes} onClick={onClick}>
      {children}
    </tr>
  );
};
