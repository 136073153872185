import { ESidebar } from '@core/enums';

// Zooms
export type ZoomRange = { minZoom: number; maxZoom: number };
export const mapZooms: { [key in ESidebar]?: ZoomRange } = {
  [ESidebar.Site]: {
    minZoom: 14,
    maxZoom: 17.5,
  },
  [ESidebar.Zone]: {
    minZoom: 17.5,
    maxZoom: 20,
  },
  [ESidebar.Anomaly]: {
    minZoom: 20,
    maxZoom: 21,
  },
};

export const dynamicZooms: Record<string, number> = {
  [ESidebar.Site]: 17,
  [ESidebar.EditSite]: 17,
  [ESidebar.Zone]: 19,
  [ESidebar.Anomaly]: 21,
};

export const MAP_MAX_ZOOM = 21;
export const MIN_ZOOM_FOR_ONE_SITE = 16;
export const THRESHOLD_ZOOM_IN_FOR_ONE_SITE_FROM_SEVERALS = 8;
export const CREATE_SITE_ZOOM = 15;

// Styles
export const REGULAR_STYLE_URL = 'mapbox://styles/mapbox/light-v10';
export const SATELLITE_STYLE_URL = 'mapbox://styles/mapbox/satellite-v9';
