import cn from 'classnames';
import { anomaliesStatusesColor, anomalyMode } from '@core/constants';
import i18n from '@core/i18n';
import { IAnomaly } from '@core/interfaces/anomaly';
import { createAnomalyName } from '@core/utils/formatting/stringFormat';
import styles from '../styles.scss';

const initialState = {
  columnWidth: [],
  head: [],
  body: [],
};

export const getAnomaliesTable = (anomalies: IAnomaly[] | null) => {
  if (!anomalies) return initialState;

  const tableColumnWidth = ['100px', '95px'];
  const tableHead = [
    { name: i18n.t('sidebar.anomaly.anomalyId') },
    { name: i18n.t('sidebar.anomaly.type') },
    { name: i18n.t('sidebar.status') },
  ];
  const tableBody = anomalies.map(({ id, mode, status }) => ({
    id: String(id),
    data: [
      {
        name: createAnomalyName(id),
        className: cn(styles.anomaliesTableBodyRow, styles.anomaliesTableBodyAnomaliRow),
      },
      {
        name: anomalyMode[mode],
        className: styles.anomaliesTableBodyRow,
      },
      {
        name: status,
        className: styles.anomaliesTableBodyRow,
        color: anomaliesStatusesColor[String(status)],
      },
    ],
  }));

  return {
    columnWidth: tableColumnWidth,
    head: tableHead,
    body: tableBody,
  };
};
