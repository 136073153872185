import { nonTenantAxiosInstance } from '@core/api/axios';
import { ERoles } from '@core/enums';

export interface IUsersMeResponse {
  name: string;
  tenant_name: string;
  full_name: string;
  email: string;
  roles: ERoles[];
  disabled: boolean;
  status?: number;
}

export const usersMe = async () => {
  return nonTenantAxiosInstance.get<IUsersMeResponse>('users/me').then((res) => res.data);
};
