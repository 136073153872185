import { FeatureCollection } from 'geojson';
import powerImage from '@assets/images/power.png';
import { getBearing } from './getBearing';
import { getFlightPathFeatureCollection } from './getFeatureCollections';
import { layersStyles } from './mapboxConfig';
import { EAnomalyLayers, EFlightPathLayers } from '../../enums/layers';
import { EFlightPathSources } from '../../enums/sources';

export const addBatteriesPointsToMap = (
  map: mapboxgl.Map,
  batteryPointsFeatureCollection: FeatureCollection,
) => {
  map.loadImage(powerImage, (error, image) => {
    if (error) {
      console.error('Error loading image:', error);
      return;
    }

    !map.hasImage('power-image') && image && map.addImage('power-image', image);

    if (map.getSource(EFlightPathSources.Batteries)) {
      (map.getSource(EFlightPathSources.Batteries) as mapboxgl.GeoJSONSource).setData(
        batteryPointsFeatureCollection,
      );
    } else {
      map.addSource(EFlightPathSources.Batteries, {
        type: 'geojson',
        data: batteryPointsFeatureCollection,
      });
      map.addLayer(layersStyles[EFlightPathLayers.BatteryCircle]());
      map.addLayer(layersStyles[EFlightPathLayers.BatteryData]());
    }
  });
};

export const addFlightPathToMap = (map: mapboxgl.Map, coordinates: number[][]) => {
  try {
    const flightPathFeatureCollection = getFlightPathFeatureCollection(coordinates);

    if (map.getSource(EFlightPathSources.FlightPath)) {
      (map.getSource(EFlightPathSources.FlightPath) as mapboxgl.GeoJSONSource).setData(
        flightPathFeatureCollection,
      );
    } else {
      const shouldSetFlightPath =
        map.getLayer(EAnomalyLayers.Anomalies) && !map.getLayer(EFlightPathLayers.FlightPath);

      if (shouldSetFlightPath) {
        map.addSource(EFlightPathSources.FlightPath, {
          type: 'geojson',
          data: flightPathFeatureCollection,
        });
        map.addLayer(layersStyles[EFlightPathLayers.FlightPath]());
        map.addLayer(layersStyles[EFlightPathLayers.Point]());
        map.addLayer(
          layersStyles[EFlightPathLayers.Triangle]({
            bearing: getBearing(coordinates.slice(-2)),
          }),
        );
      }
    }
  } catch (err) {
    // NOTE: fallback to catch "style is not loading" error
    console.error(err);
  }
};
