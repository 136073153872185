import { Feature, Geometry, GeoJsonProperties } from 'geojson';

export function getFeatureMetaCount(
  features: Feature<Geometry, GeoJsonProperties>[],
): Record<string, number> {
  return features.reduce((acc, feature) => {
    const meta = feature?.properties?.meta;
    return { ...acc, [meta]: acc[meta] ? acc[meta] + 1 : 1 };
  }, {});
}
