import _ from 'lodash';
import { delay } from '@core/utils/delay';
import { RootState } from '../..';

// NOTE: wait for redux state dependencies by 'loading' field
export const awaitStateUpdates = async (
  getState: () => unknown,
  deps: string[],
  delayMs = 1000,
): Promise<void> => {
  // TODO:
  // eslint-disable-next-line no-constant-condition
  while (true) {
    await delay(delayMs);
    const state = getState() as RootState;

    if (deps.every((key) => !_.get(state, `${key}.loading`))) {
      break;
    }
  }
};
