import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import chevronRight from '@assets/icons/chevron-right.svg';
import { ERoles, ESidebar, EViewer } from '@core/enums';
import { useDispatchTyped } from '@core/hooks';
import { ISite } from '@core/interfaces';
import {
  addSelectedSiteInfo,
  setSidebar,
  setViewer,
  useCurrentSiteSelector,
  useSitesSelector,
  useAccessControlSelector,
} from '@core/store/slices';
import {
  useProgramsForCurrentLocationSelector,
  useProgramsSelector,
} from '@core/store/slices/programs';
import { Navbar } from '@modules/Sidebar/components';
import { Button } from '@components/Button';
import { Statuses } from '@components/Statuses';
import { ZonesTable } from './components/ZonesTable';
import styles from './styles.scss';
import { getAggregatedZonesStatuses } from './utils/getZonesStatuses';

export const Site = () => {
  const { t } = useTranslation();
  const { user } = useAccessControlSelector();
  const { programs } = useProgramsSelector();
  const { sites } = useSitesSelector();
  const currentSite = useCurrentSiteSelector();
  const programsForCurrentLocation = useProgramsForCurrentLocationSelector();
  const dispatch = useDispatchTyped();

  const isAdminOrSuperuser = user?.roles?.some((role: ERoles) =>
    [ERoles.Admin, ERoles.Superuser].includes(role),
  );

  const handleBackClick = () => {
    dispatch(setViewer(EViewer.Map));
    dispatch(setSidebar(ESidebar.Sites));
  };

  const setEditSite = useCallback(() => {
    dispatch(setSidebar(ESidebar.EditSite));
  }, [dispatch]);

  const getIndexOfCurrentSite = (sites: ISite[], site: ISite | undefined) => {
    if (!site) return 0;
    return sites.findIndex((value: ISite) => value.loc_id === site.loc_id);
  };

  const handleClickNextSite = () => {
    const currentIndex = getIndexOfCurrentSite(sites, currentSite);
    let nextIndex = currentIndex + 1;
    if (nextIndex >= sites.length) nextIndex = 0;
    const nextSite = sites[nextIndex];
    if (!nextSite) return;
    dispatch(
      addSelectedSiteInfo({
        siteId: nextSite.loc_id,
        sidebar: ESidebar.Site,
      }),
    );
  };

  const handleClickPreviousSite = () => {
    const currentIndex = getIndexOfCurrentSite(sites, currentSite);
    let previousIndex = currentIndex - 1;
    if (previousIndex < 0) previousIndex = sites.length - 1;
    const previousSite = sites[previousIndex];
    if (!previousSite) return;
    dispatch(
      addSelectedSiteInfo({
        siteId: previousSite.loc_id,
        sidebar: ESidebar.Site,
      }),
    );
  };

  const zonesTitleWithCount = t('sidebar.anomaly.zones.keyWithCount', {
    count: programsForCurrentLocation?.length,
  });

  const aggregatedZonesStatuses = getAggregatedZonesStatuses(programs);
  const zonesStatuses = Object.entries(aggregatedZonesStatuses).map(([status, count]) => ({
    name: status,
    amount: count,
  }));

  const areDisabledSwitcherButtons = sites.length === 1;
  const isShownBack = sites.length > 1;

  return currentSite ? (
    <div className={styles.site}>
      <Navbar
        header={currentSite?.name}
        subheader={zonesTitleWithCount}
        areDisabledSwitcherButtons={areDisabledSwitcherButtons}
        isShownBack={isShownBack}
        onBackClick={handleBackClick}
        onLeftClick={handleClickPreviousSite}
        onRightClick={handleClickNextSite}
      />
      <div className={styles.siteWrapper}>
        <div className={styles.logo}>
          {currentSite?.picture && (
            <img className={styles.siteImage} src={currentSite?.picture} alt='site image' />
          )}
        </div>
        <div className={styles.siteZones}>
          <div className={styles.siteTableTitle}>{zonesTitleWithCount}</div>
          <ZonesTable programs={programsForCurrentLocation} />
        </div>
        {zonesStatuses && (
          <Statuses statuses={zonesStatuses} isStatusesClickable={false} className='mb-1' />
        )}
      </div>
      <Button disabled={!isAdminOrSuperuser} className={styles.btn} onClick={setEditSite}>
        {t('sidebar.anomaly.editSite')}
        <img src={chevronRight} alt='chevron icon' />
      </Button>
    </div>
  ) : null;
};
