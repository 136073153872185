import { FeatureCollection } from 'geojson';
import { IProgram } from '@core/interfaces';

interface IZonesOptions {
  isEditMode: boolean;
}
export const getZonesFeatureCollection = (
  programs: IProgram[] | null,
  options: IZonesOptions,
): FeatureCollection => ({
  type: 'FeatureCollection',
  features: (programs || []).map((program) => ({
    id: program.program_id,
    type: 'Feature',
    geometry: {
      ...program.display_perimeter,
    },
    properties: {
      id: program.program_id,
      isEditMode: options.isEditMode,
      isReportProcessing: program.isReportProcessing,
      worst_anomaly_status: program.worst_anomaly_status,
      program_name: program.name,
    },
  })),
});
