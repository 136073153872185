import { getColor } from '@core/utils';
import { ECreateSiteLayers, EMapImages } from '../../../enums/layers';
import { ECreateSiteSources } from '../../../enums/sources';

export const layersStyles: { [key in ECreateSiteLayers]: () => mapboxgl.AnyLayer } = {
  [ECreateSiteLayers.Perimeter]: () => ({
    id: ECreateSiteLayers.Perimeter,
    type: 'line',
    source: ECreateSiteSources.Perimeter,
    paint: {
      'line-color': getColor('--outflier-blue'),
      'line-width': 5,
    },
  }),
  [ECreateSiteLayers.PerimeterDisplay]: () => ({
    id: ECreateSiteLayers.PerimeterDisplay,
    type: 'line',
    source: ECreateSiteSources.PerimeterDisplay,
    paint: {
      'line-color': getColor('--outflier-blue'),
      'line-width': 3,
    },
  }),
  [ECreateSiteLayers.OverviewPlan]: () => ({
    id: ECreateSiteLayers.OverviewPlan,
    type: 'line',
    source: ECreateSiteSources.OverviewPlan,
    paint: {
      'line-color': getColor('--outflier-white'),
      'line-width': 2,
    },
  }),
  [ECreateSiteLayers.FirstPlan]: () => ({
    id: ECreateSiteLayers.FirstPlan,
    type: 'line',
    source: ECreateSiteSources.FirstPlan,
    paint: {
      'line-color': getColor('--outflier-white'),
      'line-width': 2,
    },
  }),
  [ECreateSiteLayers.NoFlyZonesBorder]: () => ({
    id: ECreateSiteLayers.NoFlyZonesBorder,
    type: 'line',
    source: ECreateSiteSources.NoFlyZones,
    paint: {
      'line-color': getColor('--red'),
      'line-width': 5,
    },
  }),
  [ECreateSiteLayers.NoFlyZonesFill]: () => ({
    id: ECreateSiteLayers.NoFlyZonesFill,
    type: 'fill',
    source: ECreateSiteSources.NoFlyZones,
    paint: {
      'fill-color': getColor('--red'),
      'fill-opacity': 0.1,
    },
  }),
  [ECreateSiteLayers.NoFlyZoneIcons]: () => ({
    id: ECreateSiteLayers.NoFlyZoneIcons,
    type: 'symbol',
    source: ECreateSiteSources.NoFlyZoneIcons,
    layout: {
      'icon-image': EMapImages.NoFlyZoneIconId,
      'icon-allow-overlap': true,
    },
  }),
  [ECreateSiteLayers.ObstaclesLine]: () => ({
    id: ECreateSiteLayers.ObstaclesLine,
    type: 'line',
    source: ECreateSiteSources.Obstacles,
    paint: {
      'line-color': getColor('--outflier-white'),
      'line-width': 3,
      'line-dasharray': [3, 3],
    },
  }),
  [ECreateSiteLayers.ObstaclesBuffer]: () => ({
    id: ECreateSiteLayers.ObstaclesBuffer,
    type: 'line',
    source: ECreateSiteSources.Obstacles,
    paint: {
      'line-color': 'transparent',
      'line-width': 16,
    },
  }),
  [ECreateSiteLayers.GeneratedProgramsLine]: () => ({
    id: ECreateSiteLayers.GeneratedProgramsLine,
    type: 'line',
    source: ECreateSiteSources.GeneratedPrograms,
    paint: {
      'line-color': getColor('--outflier-blue'),
      'line-width': 3,
    },
  }),
  [ECreateSiteLayers.GeneratedProgramsFill]: () => ({
    id: ECreateSiteLayers.GeneratedProgramsFill,
    type: 'fill',
    source: ECreateSiteSources.GeneratedPrograms,
    paint: {
      'fill-color': getColor('--outflier-blue'),
      'fill-opacity': 0.1,
    },
  }),
  [ECreateSiteLayers.GeneratedProgramsText]: () => ({
    id: ECreateSiteLayers.GeneratedProgramsText,
    type: 'symbol',
    source: ECreateSiteSources.GeneratedPrograms,
    layout: {
      'text-field': ['to-string', ['get', 'index']],
      'text-size': 16,
      'text-anchor': 'center',
      'text-line-height': 2,
    },
    paint: {
      'text-color': ['get', 'zoneIndexColor'],
      'text-halo-color': ['get', 'zoneIndexBackgroundColor'],
      'text-halo-width': 6,
      'text-halo-blur': 2,
    },
  }),
};
