import { FC, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { RadioGroup } from '@headlessui/react';
import cn from 'classnames';
import { EAppAction, ESidebar } from '@core/enums';
import { EReportSelector } from '@core/enums/report';
import { useDispatchTyped } from '@core/hooks';
import { useScrollControl } from '@core/hooks/useScrollControl';
import { useScrollPosition } from '@core/hooks/useScrollPosition';
import { updateStatuses, useSidebarSelector, useCurrentSiteSelector } from '@core/store/slices';
import {
  updateCurrentInspectionId,
  useCurrentInspectionIdSelector,
  useInspectionsSelector,
} from '@core/store/slices/inspections';
import { reloadMap, setIsDynamicZoom } from '@core/store/slices/map';
import {
  fetchProgramsStatuses,
  updateProgramsWorstAnomalyStatus,
} from '@core/store/slices/programs';
import {
  fetchAnomaliesAndSolarPanelsToReports,
  setCurrentReportIdByCurrentInspectionId,
} from '@core/store/slices/reports';
import { IUpdateScrollPosition } from '@modules/Layout/components/Header/interfaces';
import { TimelinePath } from '@components/Timeline/components/TimelinePath/TimelinePath';
import { timelineStylesConfig } from '@components/Timeline/configs/styles';
import { TimelineItemsList } from '@components/Timeline/widgets/TimelineItemsList/TimelineItemsList';
import styles from './styles.scss';
import { TimelineControls } from '../../widgets/TimelineControls/TimelineControls';

interface IProps {
  onSetScrollPosition: (handler: (options: IUpdateScrollPosition) => void) => void;
}

export const MainView: FC<IProps> = ({ onSetScrollPosition }) => {
  const { t } = useTranslation();
  const { inspections } = useInspectionsSelector();
  const dispatch = useDispatchTyped();
  const currentInspectionId = useCurrentInspectionIdSelector();
  const { sidebar } = useSidebarSelector();
  const currentSite = useCurrentSiteSelector();

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const scrollContainerWidth = scrollContainerRef?.current?.scrollWidth;

  const [scrollPosition, setScrollPosition] = useScrollPosition(scrollContainerRef, inspections);
  const [
    canScrollRight,
    handleNextClick,
    handlePreviousClick,
    handleUpdatePositionBySelectedInspectionDate,
  ] = useScrollControl(
    scrollPosition,
    setScrollPosition,
    scrollContainerRef,
    timelineStylesConfig.width.item,
    timelineStylesConfig.pageSize,
  );

  const allInspectionsShown =
    scrollContainerRef?.current?.scrollWidth === scrollContainerRef?.current?.clientWidth;

  const disableBackwardButton = scrollPosition === 0 || allInspectionsShown;
  const showForwardButton = canScrollRight && !allInspectionsShown;

  const handleSelect = useCallback(
    (inspectionId: string): void => {
      dispatch(updateCurrentInspectionId(inspectionId));
      dispatch(setCurrentReportIdByCurrentInspectionId(inspectionId));

      const shouldUpdateProgramsStatuses = [
        ESidebar.Site,
        ESidebar.Zone,
        ESidebar.Anomaly,
      ].includes(sidebar);
      const shouldUpdateAnomaliesAndSolarPanels = [ESidebar.Zone, ESidebar.Anomaly].includes(
        sidebar,
      );
      const shouldUpdateProgramsWorstAnomalyStatus = sidebar !== ESidebar.Sites;

      if (shouldUpdateProgramsStatuses && currentSite?.loc_id) {
        dispatch(
          fetchProgramsStatuses({
            data: {
              locationId: currentSite.loc_id,
              inspectionId,
            },
            action: EAppAction.HeaderInspectionTimelineSelect,
          }),
        );
      }

      // NOTE: get solar panels and anomalies only for "Zone", "Anomaly" levels
      if (shouldUpdateAnomaliesAndSolarPanels) {
        dispatch(
          fetchAnomaliesAndSolarPanelsToReports({
            selector: EReportSelector.GetBySelectedReport,
          }),
        );
      }

      if (shouldUpdateProgramsWorstAnomalyStatus) {
        dispatch(updateProgramsWorstAnomalyStatus());
      }

      dispatch(updateStatuses());
      // NOTE: move it before reloadMap if necessary
      dispatch(setIsDynamicZoom(false));
      dispatch(reloadMap(true));
    },
    [dispatch, sidebar, currentSite?.loc_id],
  );

  const isDisabledTimeline = sidebar === ESidebar.Anomaly;

  const timelinePathWidth = scrollContainerWidth
    ? `${
        scrollContainerWidth -
        (sidebar === ESidebar.Sites
          ? timelineStylesConfig.margin.sitesOption
          : timelineStylesConfig.margin.restOption) -
        timelineStylesConfig.width.halfOption -
        timelineStylesConfig.padding.lastOption
      }px`
    : '100%';

  useEffect(() => {
    onSetScrollPosition(handleUpdatePositionBySelectedInspectionDate);
  }, [onSetScrollPosition, handleUpdatePositionBySelectedInspectionDate]);

  return (
    <div
      className={cn(
        'w-full flex justify-start items-center hide-scrollbar',
        isDisabledTimeline ? 'pointer-events-none' : '',
      )}
    >
      <TimelineControls
        isDisabledBackward={disableBackwardButton}
        isShownForward={showForwardButton}
        onPreviousClick={handlePreviousClick}
        onNextClick={handleNextClick}
      />
      <div
        ref={scrollContainerRef}
        className={cn(styles.optionsContainer, styles.hideScrollbar, {
          [styles.withScrollRight]: canScrollRight,
        })}
      >
        <TimelinePath width={timelinePathWidth} className={styles.timelinePath} />
        <div className='w-full relative'>
          <RadioGroup value={currentInspectionId} onChange={handleSelect}>
            <RadioGroup.Label className='sr-only'>{t('header.timeline.label')}</RadioGroup.Label>
            <TimelineItemsList />
          </RadioGroup>
        </div>
      </div>
    </div>
  );
};
