import * as THREE from 'three';
import { ISample } from '@core/interfaces';
import { getColor } from '@core/utils';
import { EModelFlightPathLayer } from '../../enums/layers';

export const drawFlightPath = (samples: ISample[]) => {
  if (!samples.length) return [];

  const drawables: any[] = [];

  const pathVertices: THREE.Vector3[] = [];

  samples.forEach((sample, index, samples) => {
    const vertices = sample.enu_camera?.pose.slice(0, 3).map((pos) => pos[3]) as number[];
    pathVertices.push(new THREE.Vector3(...vertices));

    if (index === 0) {
      drawables.push({
        id: EModelFlightPathLayer.FlightPathStart,
        vertices,
        point_size: 10,
        color: getColor('--outflier-blue'),
      });
    }

    if (index === samples.length - 1) {
      drawables.push({
        id: EModelFlightPathLayer.FlightPathEnd,
        vertices,
        point_size: 18,
        color: getColor('--outflier-blue'),
      });
    }
  });

  drawables.push({
    id: EModelFlightPathLayer.FlightPath,
    vertices: pathVertices,
    alpha: 1,
    color: getColor('--outflier-flight-path-blue'),
    line_width: 0.14,
    opacity: 0.7,
    radius: 0.06,
  });

  return drawables;
};
