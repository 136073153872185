export const timelineStylesConfig = {
  padding: {
    lastOption: 30,
  },
  margin: {
    sitesOption: 5,
    restOption: 26,
  },
  width: {
    item: 25,
    option: 65,
    halfOption: 65 / 2,
  },
  pageSize: 7,
};
