import { axiosInstance } from '@core/api/axios';
import { IInspectionFlight } from '@core/interfaces';

export const getInspectionFlights = async (
  inspectionId: string | number,
): Promise<IInspectionFlight[]> => {
  return axiosInstance
    .get<IInspectionFlight[]>(`/inspections/${inspectionId}/flights`)
    .then((resp) => resp.data);
};
