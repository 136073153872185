/* eslint-disable no-constant-condition */
import { Feature, Geometry, GeoJsonProperties } from 'geojson';
import { delay } from '@core/utils/delay';

enum EDrawStyling {
  Cold = 'mapbox-gl-draw-cold',
  Hot = 'mapbox-gl-draw-hot',
}

export async function getMapboxDrawFeatures(map: mapboxgl.Map) {
  let mapboxDrawSources = map.getStyle().sources;
  let features: Feature<Geometry, GeoJsonProperties>[] = [];

  if (!mapboxDrawSources[EDrawStyling.Cold] || mapboxDrawSources[EDrawStyling.Hot]) {
    // NOTE: mapbox styles don't load immediately.
    while (true) {
      await delay(100);
      mapboxDrawSources = map.getStyle().sources;

      if (mapboxDrawSources[EDrawStyling.Cold] || mapboxDrawSources[EDrawStyling.Hot]) {
        break;
      }
    }
  }

  if (mapboxDrawSources[EDrawStyling.Cold].type === 'geojson') {
    const coldFeatures = (
      mapboxDrawSources[EDrawStyling.Cold].data as GeoJSON.FeatureCollection<GeoJSON.Geometry>
    ).features;

    if (coldFeatures.length) {
      features = coldFeatures;
    }
  }

  if (mapboxDrawSources[EDrawStyling.Hot].type === 'geojson') {
    const hotFeatures = (
      mapboxDrawSources[EDrawStyling.Hot].data as GeoJSON.FeatureCollection<GeoJSON.Geometry>
    ).features;

    if (hotFeatures.length) {
      features = hotFeatures;
    }
  }

  return features;
}
