import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { orderBy } from 'lodash';
import chevronRight from '@assets/icons/chevron-right.svg';
import { ESortOrder } from '@core/enums/table';
import { Table, TableBody, TableHead, TableRow } from '@components/Table';
import styles from './styles.scss';

interface Column {
  name: string;
}

export interface BodyItem {
  id: string | number;
  className?: string;
  data: Array<{
    name: any;
    className?: string;
    color?: string;
  }>;
}

interface ISortConfig {
  key: string | null;
  direction: ESortOrder;
}

interface IProps {
  className?: string;
  head: Column[];
  body: BodyItem[];
  columnWidth: string[];
  isSortable: boolean;
  handleTableRowClick?: (id: any) => void;
}

const INITIAL_SORT_CONFIG = { key: null, direction: ESortOrder.Desc };

export const StyledTable: React.FC<IProps> = React.memo(
  ({ className, head, body, columnWidth, isSortable, handleTableRowClick }) => {
    const [sortConfig, setSortConfig] = useState<ISortConfig>(INITIAL_SORT_CONFIG);

    const columnNames = useMemo(() => head.map((column) => column.name), [head]);
    const sortedBody = useMemo(() => {
      if (isSortable && sortConfig.key !== null && sortConfig.direction !== null) {
        const index = columnNames.indexOf(sortConfig.key);
        return orderBy(body, `data[${index}].name`, [sortConfig.direction]);
      }
      return body;
    }, [body, isSortable, sortConfig, columnNames]);

    const requestSort = (key: any) => {
      setSortConfig((sortConfig) => {
        let direction = ESortOrder.Desc;

        if (sortConfig.key === key) {
          if (sortConfig.direction === ESortOrder.Desc) {
            direction = ESortOrder.Asc;
          }
          if (sortConfig.direction === ESortOrder.Asc) {
            direction = ESortOrder.Desc;
          }
        }

        return { key, direction };
      });
    };

    useEffect(() => {
      // NOTE: set first column by DESC order (default)
      if (!columnNames.length) return;
      setSortConfig({
        key: columnNames[0],
        direction: ESortOrder.Desc,
      });
    }, [columnNames]);

    return (
      <Table className={className}>
        {head && head.length > 0 && (
          <TableHead>
            <TableRow className='!pl-4'>
              {head.map(({ name }: Column, i) => (
                <th
                  key={name}
                  style={{ minWidth: columnWidth[i] }}
                  className={'flex justify-start items-center gap-1 select-none min-h-[20px]'}
                >
                  <button
                    className='appearance-none flex justify-start items-center gap-1 min-h-[20px]'
                    onClick={() => isSortable && name && requestSort(name)}
                  >
                    {name}
                    {isSortable && sortConfig.key === name && sortConfig.direction && (
                      <img
                        className={cn('h-[18px] transform ', {
                          '-rotate-90': sortConfig.direction === ESortOrder.Asc,
                          'rotate-90': sortConfig.direction === ESortOrder.Desc,
                        })}
                        src={chevronRight}
                        alt='sort'
                      />
                    )}
                  </button>
                </th>
              ))}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {sortedBody.map(({ data, id, className }: BodyItem) => (
            <tr
              key={id}
              className={cn(className, styles.tableRow, 'w-full appearance-none flex !px-4')}
              onClick={() => handleTableRowClick && handleTableRowClick(id)}
            >
              {data.map(({ name, className, color }: any, i: number) => (
                <td
                  key={String(name + i)}
                  style={{ color, minWidth: columnWidth[i] }}
                  className={`whitespace-nowrap truncate ${className}`}
                >
                  {name}
                </td>
              ))}
            </tr>
          ))}
        </TableBody>
      </Table>
    );
  },
);
