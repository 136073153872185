import { ENetworkProtocol } from '../enums';

interface IWebSocketClient {
  ws: WebSocket | null;
}

type WebSocketClientConfig = {
  secure: boolean;
  host: string;
  port: number | string;
};

class WebSocketClient implements IWebSocketClient {
  public ws: WebSocket;

  constructor(config: WebSocketClientConfig) {
    const { host, port, secure } = config;
    const socketProtocol = secure ? ENetworkProtocol.Wss : ENetworkProtocol.Ws;
    const socketAddress = `${socketProtocol}:${host}:${port}/ws`;

    this.ws = new WebSocket(socketAddress);
  }
}

export default WebSocketClient;
