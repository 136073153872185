import { axiosInstance } from '@core/api/axios';
import { ECamera } from '@core/enums';
import { ISample } from '@core/interfaces';
import { addQueryParamsToUrl } from '@core/utils';

export const getSampleByReportId = async (
  reportId: string | number,
  sampleId: string | number,
  camera?: ECamera,
) => {
  const endpoint = addQueryParamsToUrl(`/reports/${reportId}/samples/${sampleId}`, { camera });
  return axiosInstance.get<ISample>(endpoint).then((res) => res.data);
};
