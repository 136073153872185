import React from 'react';
import crosshairIcon from '@assets/icons/crosshair.svg';
import { ESidebar } from '@core/enums';
import { useSelectorTyped } from '@core/hooks';

const Crosshair: React.FC = () => {
  const createSiteCurrentStep = useSelectorTyped((state) => state.createSite.currentStep);
  const sidebar = useSelectorTyped((state) => state.sidebar.sidebar);

  if (createSiteCurrentStep !== 0 || sidebar !== ESidebar.CreateSite) return null;

  return (
    <div className='absolute top-0 left-0 z-10 w-full h-full pointer-events-none flex items-center justify-center'>
      <img src={crosshairIcon} alt='back' />
    </div>
  );
};

export default Crosshair;
