import { EAspectRatio, EViewerType } from '@core/enums';
import { ISampleDetection } from '@core/interfaces';
import { droneDimensions } from '../constants/viewer';

interface IConvertFromPixelsToPercentsOptions {
  detection: ISampleDetection;
  aspectRatio: EAspectRatio;
}

export const convertXFromPixelsToPercents = ({
  detection,
  aspectRatio,
}: IConvertFromPixelsToPercentsOptions) => {
  return (detection.x / droneDimensions[aspectRatio][EViewerType.Viewer].width) * 100;
};

export const convertYFromPixelsToPercents = ({
  detection,
  aspectRatio,
}: IConvertFromPixelsToPercentsOptions) => {
  return (detection.y / droneDimensions[aspectRatio][EViewerType.Viewer].height) * 100;
};
