import { axiosInstance } from '@core/api/axios';

export interface ICreateCommentRequest {
  comment: string;
}
export interface ICreateCommentResponse {
  message: string;
}

export const createComment = async (anomalyId: number, comment: ICreateCommentRequest) => {
  return axiosInstance
    .post<ICreateCommentResponse>(`/anomalies/${anomalyId}/comments`, comment)
    .then((res) => res.data);
};
