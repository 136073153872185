import React, { memo } from 'react';
import { ReactSVG } from 'react-svg';
import cn from 'classnames';
import arrowLeftLg from '@assets/icons/arrow-left-lg.svg';
import chevronRight from '@assets/icons/chevron-right.svg';
import styles from './styles.scss';

interface IProps {
  header?: string;
  headerClassName?: string;
  subheader?: string;
  subheaderClassName?: string;
  switcher?: boolean;
  areDisabledSwitcherButtons?: boolean;
  isShownBack?: boolean;
  isDisabledBack?: boolean;
  onBackClick?: () => void;
  onRightClick?: () => void;
  onLeftClick?: () => void;
}

export const Navbar: React.FC<IProps> = memo(
  ({
    header = '',
    subheader = '',
    switcher = true,
    areDisabledSwitcherButtons = false,
    isShownBack = true,
    isDisabledBack = false,
    headerClassName,
    subheaderClassName,
    onBackClick,
    onLeftClick,
    onRightClick,
  }) => {
    const buttonClassname =
      'flex items-center justify-center first:pl-[8px] last:pr-[8px] first:pr-[2.5px] last:pl-[2.5px] bg-outflier-contain-background h-[45px] first:rounded-l-[6px] last:rounded-r-[6px] select-none';

    return (
      <div className='flex w-full justify-between items-center pb-3'>
        <div className='flex gap-[20px] items-center'>
          {isShownBack && (
            <button className={styles.back} onClick={onBackClick} disabled={isDisabledBack}>
              <ReactSVG src={arrowLeftLg} />
            </button>
          )}
          <div className='flex flex-col'>
            <span className={cn(headerClassName, styles.header)}>{header}</span>
            {subheader && (
              <span
                className={cn(subheaderClassName, { 'w-[300px]': !switcher }, styles.subHeader)}
              >
                {subheader}
              </span>
            )}
          </div>
        </div>

        {switcher && (
          <div className='flex gap-[1px] bg-[#1D2233] rounded-[6px]'>
            <button
              disabled={areDisabledSwitcherButtons}
              onClick={onLeftClick}
              className={buttonClassname}
            >
              <ReactSVG className='rotate-180' src={chevronRight} />
            </button>
            <button
              disabled={areDisabledSwitcherButtons}
              onClick={onRightClick}
              className={buttonClassname}
            >
              <ReactSVG src={chevronRight} />
            </button>
          </div>
        )}
      </div>
    );
  },
);
