import { getColor } from '@core/utils';
import { EFlightPathLayers } from '../../enums/layers';
import { EFlightPathSources } from '../../enums/sources';

type LayerDependencies = {
  bearing: number;
};

type LayersStyles = {
  [key in EFlightPathLayers]: (deps?: LayerDependencies) => mapboxgl.AnyLayer;
};

export const layersStyles: LayersStyles = {
  [EFlightPathLayers.FlightPath]: () => ({
    id: EFlightPathLayers.FlightPath,
    type: 'line',
    source: EFlightPathSources.FlightPath,
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': getColor('--outflier-blue'),
      'line-width': [
        'interpolate',
        ['linear'],
        ['zoom'],
        19,
        2.5, // width = 2.5 if zoom = 19
        19.5,
        4, // width = 4 if zoom = 19.5
        20,
        6, // width = 6 if zoom = 20
        21,
        8, // width = 8 if zoom >= 21
      ],
      'line-opacity': 0.7,
    },
  }),
  [EFlightPathLayers.Point]: () => ({
    id: EFlightPathLayers.Point,
    type: 'circle',
    source: EFlightPathSources.FlightPath,
    filter: ['==', ['get', 'symbol'], 'point'],
    paint: {
      'circle-color': getColor('--outflier-blue'),
      'circle-radius': [
        'interpolate',
        ['linear'],
        ['zoom'],
        18,
        4, // Radius = 4 if zoom = 18
        19,
        5, // Radius = 5 if zoom = 19
        20,
        8, // Radius = 10 if zoom = 20
        21,
        12, // Radius = 12 if zoom >= 21
      ],
      'circle-opacity': 1,
    },
  }),
  [EFlightPathLayers.Triangle]: (deps) => ({
    id: EFlightPathLayers.Triangle,
    type: 'symbol',
    source: EFlightPathSources.FlightPath,
    filter: ['==', ['get', 'symbol'], 'arrow'],
    layout: {
      'symbol-placement': 'point',
      'icon-image': 'triangle',
      'icon-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        18,
        0.4, // Size = 0.4 if zoom = 18
        19,
        0.5, // Size = 0.5 if zoom = 19
        20,
        0.7, // Size = 0.7 if zoom = 20
        21,
        1, // Size = 1 if zoom >= 21
      ],
      'icon-rotate': deps?.bearing,
    },
  }),
  [EFlightPathLayers.BatteryCircle]: () => ({
    id: EFlightPathLayers.BatteryCircle,
    type: 'circle',
    source: EFlightPathSources.Batteries,
    filter: ['==', ['get', 'symbol'], 'battery'],
    paint: {
      'circle-color': getColor('--outflier-blue'),
      'circle-radius': [
        'interpolate',
        ['linear'],
        ['zoom'],
        19,
        10, // Radius = 10 if zoom = 19
        20,
        13, // Radius = 13 if zoom = 20
        21,
        15, // Radius = 15 if zoom >= 21
      ],
    },
    minzoom: 19,
  }),
  [EFlightPathLayers.BatteryData]: () => ({
    id: EFlightPathLayers.BatteryData,
    type: 'symbol',
    source: EFlightPathSources.Batteries,
    filter: ['==', ['get', 'symbol'], 'batteryData'],
    layout: {
      'text-field': ['get', 'text'],
      'text-size': 16,
      'text-anchor': 'right',
      'icon-image': 'power-image',
      'icon-size': 0.8,
      'icon-allow-overlap': true,
      'icon-offset': [5, 0],
    },
    paint: {
      'text-color': getColor('--outflier-white'),
    },
    minzoom: 19,
  }),
};
