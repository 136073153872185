import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import plusIcon from '@assets/icons/plus.svg';
import { useDispatchTyped, useSelectorTyped } from '@core/hooks';
import { IObstacle } from '@core/interfaces/createSite';
import { addObstacle, deleteObstacle, updateObstacle } from '@core/store/slices';
import { reloadMap } from '@core/store/slices/map';
import AddInput from '@modules/Sidebar/components/AccordionView/BodyOptions/NoFlyZones/AddInput/AddInput';
import styles from './styles.scss';

interface IProps {}

const ObstaclesBody: FC<IProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatchTyped();
  const obstacles = useSelectorTyped((state) => state.createSite.obstacles);
  const lastObstacle = obstacles[obstacles?.length - 1];

  const addPowerLine = () => {
    dispatch(addObstacle());
    dispatch(reloadMap(true));
  };

  const handleChangeObstacle = (obstacle: IObstacle) => {
    dispatch(updateObstacle(obstacle));
  };

  const handleDelete = (obstacle: IObstacle) => {
    dispatch(deleteObstacle(obstacle));
    dispatch(reloadMap(true));
  };

  return (
    <div className={styles.body}>
      <h2 className={styles.notification}>
        {t('sidebar.site.edit.sections.obstacles.notification')}
      </h2>
      <div className={styles.wrapper}>
        <ul className='flex flex-col gap-[10px] mb-[10px]'>
          {obstacles?.map((obstacle) => (
            <AddInput
              key={obstacle.id}
              value={obstacle}
              onChange={handleChangeObstacle}
              onDelete={handleDelete}
            />
          ))}
        </ul>

        <button
          disabled={lastObstacle?.perimeter?.coordinates?.length === 0}
          className={styles.addBtn}
          onClick={addPowerLine}
        >
          <div className={styles.addBtnIcon}>
            <img className={styles.addIcon} src={plusIcon} alt='add' />
          </div>
          <span className={styles.btnText}>
            {t('sidebar.site.edit.sections.obstacles.buttons.addPowerLine')}
          </span>
        </button>
      </div>
    </div>
  );
};

export default memo(ObstaclesBody);
