import { axiosInstance } from '@core/api/axios';

export const getSampleRgbImageByReportId = async (
  reportId: number | string,
  sampleId: number | string,
) => {
  return axiosInstance
    .get(`/reports/${reportId}/samples/${sampleId}/rgb_image`, { responseType: 'blob' })
    .then((res) => URL.createObjectURL(res.data));
};
