import React, { memo, useState } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import type { OrbitControls as TOrbitControls } from 'three-stdlib';
import { ICamera } from '@core/interfaces/model';

const matrix4Difference = (array1: THREE.Matrix4, array2: THREE.Matrix4 | null) => {
  if (array1 === null || array2 === null) {
    return [0, 0];
  }
  const v1 = new THREE.Vector3(array1.elements[12], array1.elements[13], array1.elements[14]);
  const v2 = new THREE.Vector3(array2.elements[12], array2.elements[13], array2.elements[14]);
  const transDiff = v1.distanceTo(v2);

  const r1 = new THREE.Matrix3().setFromMatrix4(array1);
  const r2T = new THREE.Matrix3().setFromMatrix4(array2).transpose();
  const r = new THREE.Matrix3().multiplyMatrices(r1, r2T);
  const tr = r.elements[0] + r.elements[4] + r.elements[8];

  let angleDiff = Math.acos((tr - 1) / 2.0);

  if (isNaN(angleDiff)) {
    angleDiff = 0;
  }

  return [transDiff, angleDiff];
};

export type CameraPosition = {
  up: THREE.Vector3;
  center: THREE.Vector3;
  eye: THREE.Vector3;
};

interface IProps {
  onChange: (camera: ICamera) => void;
}

const CameraExport = React.forwardRef<TOrbitControls, IProps>(({ onChange }, ref) => {
  const [cameraMatrix, setCameraMatrix] = useState<any>(null);
  const { camera } = useThree();

  useFrame(() => {
    const [transErr, angleErr] = matrix4Difference(camera.matrix, cameraMatrix);

    if (onChange !== null && (cameraMatrix == null || transErr > 1e-2 || angleErr > 1e-3)) {
      setCameraMatrix(camera.matrix.clone());
      onChange({
        up: camera.up,
        // TODO:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        center: ref?.current.target,
        eye: camera.position,
      });
    }
  });

  return null;
});

export default memo(CameraExport);
