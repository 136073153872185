import { axiosInstance } from '@core/api/axios';

interface IPerimeterByLocationIdResponse {
  type: string;
  features: unknown[];
}

export const getPerimeterByLocationId = async (
  id: string | number,
): Promise<IPerimeterByLocationIdResponse> => {
  return axiosInstance
    .get<IPerimeterByLocationIdResponse>(`/locations/${id}/perimeter`)
    .then(({ data }) => data);
};
