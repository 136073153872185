import React, { FC, Fragment, useCallback, useState } from 'react';
import { Combobox } from '@headlessui/react';
import axios from 'axios';
import cn from 'classnames';
import { debounce } from 'lodash';
import mapboxgl from 'mapbox-gl';
import locationPin from '@assets/icons/location-pin.svg';
import search from '@assets/icons/search.svg';
import { ACCESS_MAPBOX_TOKEN } from '@core/constants';
import { IMapboxCityData } from '@core/interfaces/createSite';

mapboxgl.accessToken = ACCESS_MAPBOX_TOKEN;

const highlightMatchedText = (name, query) => {
  const regex = new RegExp(`(${query})`, 'ig');
  const parts = name.split(regex);

  return parts.map((part, index) => {
    if (part.toLowerCase() === query.toLowerCase()) {
      return <strong key={index}>{part}</strong>;
    }
    return part;
  });
};

interface IProps {
  onCitySelect: (city: IMapboxCityData) => void;
  selectedLocation: IMapboxCityData | null;
}

const MapboxSearch: FC<IProps> = ({ onCitySelect, selectedLocation }) => {
  const [inputValue, setInputValue] = useState<string>(selectedLocation?.name ?? '');
  const [cities, setCities] = useState<IMapboxCityData[]>([]);

  const updateCities = useCallback(
    debounce(async (query: string) => {
      if (!query) setCities([]);

      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json`,
        {
          params: {
            access_token: mapboxgl.accessToken,
            types: 'place',
          },
        },
      );

      const cities = response.data.features.map((feature) => ({
        id: feature.id,
        name: feature.place_name,
        coordinates: feature.geometry.coordinates,
      }));

      setCities(cities);
    }, 300),
    [],
  );

  const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setInputValue(query);
    updateCities(query);
  };

  const handleCitySelect = (city: IMapboxCityData) => {
    onCitySelect(city);
  };

  return (
    <Combobox value={selectedLocation} onChange={handleCitySelect}>
      <div className='relative w-full'>
        <Combobox.Input
          className={cn(
            'placeholder-outflier-soft-gray appearance-none px-4 pr-10 text-white w-full h-[45px] bg-outflier-contain-background rounded-t-[3px]',
            {
              'rounded-b-[3px]': cities.length === 0,
            },
          )}
          onChange={handleInputChange}
          displayValue={(city: any) => (city ? city.name : '')}
          placeholder='Research your area...'
        />
        <div className='pointer-events-none absolute right-[10px] top-0 h-[45px] flex items-center justify-center'>
          <img className='h-[25px] w-[25px]' src={search} alt='pin' />
        </div>
      </div>

      <Combobox.Options className='flex flex-col gap-[1px] mt-[1px] bg-transparent'>
        {cities.map((city) => (
          <Combobox.Option key={city.id} value={city} as={Fragment}>
            {({ active }) => (
              <li
                className={cn(
                  'px-2 gap-4 cursor-pointer focus-visible:bg-red-400 text-white w-full h-[45px] bg-outflier-contain-background flex items-center justify-start',
                  {
                    '!bg-outflier-alternate-background-2': active,
                  },
                )}
              >
                <img className='h-[25px] w-[25px]' src={locationPin} alt='pin' />
                <span>{highlightMatchedText(city.name, inputValue)}</span>
              </li>
            )}
          </Combobox.Option>
        ))}
      </Combobox.Options>
    </Combobox>
  );
};

export default MapboxSearch;
