import { axiosInstance } from '@core/api/axios';

export interface IUpdateCommentRequest {
  comment: string;
}
export interface IUpdateCommentResponse {
  message: string;
}

export const updateCommentById = async (commentId: number, comment: IUpdateCommentRequest) => {
  return axiosInstance
    .put<IUpdateCommentResponse>(`/programs/comments/${commentId}`, comment)
    .then((res) => res.data);
};
