import { EStorage, StorageFactory } from './factories/storage';
import { IStorage } from './interfaces/storage';

class Storage implements IStorage {
  constructor(private readonly storage: IStorage) {
    this.storage = storage;
  }

  public setItem(key: string, value: string) {
    return this.storage.setItem(key, value);
  }

  public getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  public removeItem(key: string): void {
    return this.storage.removeItem(key);
  }

  public clear(): void {
    return this.storage.clear();
  }
}

const localStorageAdapter = StorageFactory.getStorage(EStorage.LocalStorage);
const sessionStorageAdapter = StorageFactory.getStorage(EStorage.SessionStorage);
const cookiesStorageAdapter = StorageFactory.getStorage(EStorage.CookiesStorage);

const browserLocalStorage = new Storage(localStorageAdapter);
const browserSessionStorage = new Storage(sessionStorageAdapter);
const cookiesStorage = new Storage(cookiesStorageAdapter);

export { browserLocalStorage, browserSessionStorage, cookiesStorage };
