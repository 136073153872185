import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { EConnectionError, EConnectionStatus } from '@core/enums';
import { useDispatchTyped } from '@core/hooks';
import {
  setConnectionStatus,
  setIsVisibleNotification,
  updateConnectionData,
  useNetworkConnectionSelector,
} from '@core/store/slices';
import styles from './styles.scss';

const BACK_ONLINE_TIMEOUT = 3000;

interface IProps {
  className?: string;
}

export const NetworkConnectionModal: FC<IProps> = ({ className }) => {
  const { t } = useTranslation();
  const { status, statusPrevious, message, isVisible } = useNetworkConnectionSelector();
  const dispatch = useDispatchTyped();

  const notificationsText = {
    [EConnectionStatus.Online]: t('notifications.connections.regainedConnection'),
    [EConnectionStatus.Offline]: t('notifications.connections.internetConnectionLost'),
  };

  const detectInternetConnection = useCallback(() => {
    if (window.navigator.onLine) {
      if (status === EConnectionStatus.Offline) {
        dispatch(setConnectionStatus(EConnectionStatus.Online));
        dispatch(
          updateConnectionData({
            message: notificationsText[EConnectionStatus.Online],
            error: null,
            isVisible: true,
          }),
        );
      }

      if (!statusPrevious) {
        dispatch(setConnectionStatus(EConnectionStatus.Online));
      }
    } else {
      dispatch(setConnectionStatus(EConnectionStatus.Offline));
      dispatch(
        updateConnectionData({
          message: notificationsText[EConnectionStatus.Offline],
          error: EConnectionError.ConnectionLost,
          isVisible: true,
        }),
      );
    }
  }, [status, statusPrevious]);

  useEffect(() => {
    window.addEventListener('load', detectInternetConnection);
    window.addEventListener('online', detectInternetConnection);
    window.addEventListener('offline', detectInternetConnection);

    return () => {
      window.removeEventListener('load', detectInternetConnection);
      window.removeEventListener('online', detectInternetConnection);
      window.removeEventListener('offline', detectInternetConnection);
    };
  }, [detectInternetConnection]);

  useEffect(() => {
    detectInternetConnection();
  }, []);

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    const isBackConnection =
      statusPrevious === EConnectionStatus.Offline && status === EConnectionStatus.Online;

    if (isBackConnection) {
      timerId = setTimeout(() => {
        dispatch(setIsVisibleNotification(false));
      }, BACK_ONLINE_TIMEOUT);
    }
    return () => {
      timerId && clearTimeout(timerId);
    };
  }, [statusPrevious, status, dispatch]);

  return (
    <div
      className={cn(
        styles.notification,
        { [styles.visible]: isVisible },
        status && styles[status],
        className,
      )}
    >
      {message}
    </div>
  );
};
