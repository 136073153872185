import { nonTenantAxiosInstance } from '@core/api/axios';

interface IReserveMachineResponse {
  hostname: string;
  reservation_token: string;
}

export const reserveMachine = async () => {
  return nonTenantAxiosInstance.post<IReserveMachineResponse>('/renderers').then((res) => res.data);
};
