import React from 'react';
import { compareDesc, format } from 'date-fns';
import { t } from 'i18next';
import { FORMAT_DATE_LONG } from '@core/constants';
import { ESidebar } from '@core/enums';
import { useDispatchTyped } from '@core/hooks';
import { IProgram } from '@core/interfaces';
import { setSidebar } from '@core/store/slices';
import { setCurrentInspectionIdByProgramId } from '@core/store/slices/inspections';
import { setCurrentProgramId } from '@core/store/slices/programs';
import styles from '@modules/Sidebar/views/Site/styles.scss';
import StatusCircle from '@components/StatusCircle/StatusCircle';
import { Table, TableBody, TableHead, TableRow } from '@components/Table';

interface IProps {
  programs: IProgram[];
}

export const ZonesTable: React.FC<IProps> = ({ programs }) => {
  const dispatch = useDispatchTyped();
  const sortedPrograms = [...programs].sort((a, b) =>
    compareDesc(new Date(a.date), new Date(b.date)),
  );

  const columnWidth = ['157px'];
  const head = [{ name: t('sidebar.anomaly.location') }, { name: t('sidebar.anomaly.inspected') }];
  const body = sortedPrograms.map(
    ({ name, worst_anomaly_status, program_id, lastInspectionDate, isReportProcessing }) => ({
      id: program_id,
      worst_anomaly_status,
      isReportProcessing,
      data: [
        {
          name: name,
          className: '',
        },
        {
          name: isReportProcessing
            ? t('statuses.reportProcessing')
            : lastInspectionDate
            ? format(new Date(lastInspectionDate), FORMAT_DATE_LONG)
            : t('noInspections'),
          className: styles.inspected,
        },
      ],
    }),
  );

  const handleTableRowClick = (programId: string) => {
    dispatch(setCurrentProgramId(programId));
    dispatch(setCurrentInspectionIdByProgramId({ programId }));
    dispatch(setSidebar(ESidebar.Zone));
  };

  return (
    <Table>
      <TableHead>
        <TableRow className='!pl-4'>
          {head.map(({ name, className }: any, i) => (
            <th key={name} style={{ width: columnWidth[i] }} className={className}>
              {name}
            </th>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {body.map(({ data, id, worst_anomaly_status, isReportProcessing }: any) => (
          <TableRow key={id} className='!pl-4' onClick={() => handleTableRowClick(id)}>
            {data.map(({ name, className }: any, i: number) => (
              <td
                key={String(name + i)}
                style={{ width: columnWidth[i] }}
                className={`flex gap-2.5 items-center ${className}`}
              >
                {i === 0 && (
                  <StatusCircle
                    status={worst_anomaly_status}
                    isReportProcessing={isReportProcessing}
                  />
                )}
                <span className='whitespace-nowrap truncate'>{name}</span>
              </td>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ZonesTable;
