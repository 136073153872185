import { IStorage } from '../interfaces/storage';

export class CookiesStorageAdapter implements IStorage {
  public setItem(key: string, value: string, options: Record<string, any> = {}): void {
    const extendedOptions = {
      path: '/',
      ...options,
    };

    if (options?.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }

    let updatedCookie = [key, value].map(encodeURIComponent).join('=');

    for (const [k, v] of Object.entries(extendedOptions)) {
      updatedCookie += '; ' + [k, v].map(encodeURIComponent).join('=');
    }
    document.cookie = updatedCookie;
  }

  public getItem(key: string): string | null {
    const matches = document.cookie.match(
      // TODO:
      // eslint-disable-next-line no-useless-escape
      new RegExp('(?:^|; )' + key.replace(/([\.$?*|{}\(\)\\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'),
    );
    return matches ? decodeURIComponent(matches[1]) : null;
  }

  public removeItem(key: string): void {
    return this.setItem(key, '', {
      'max-age': -1,
    });
  }

  public clear(): void {
    return void 0;
  }
}
