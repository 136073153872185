import { axiosInstance } from '@core/api/axios';
import { IProgramStatuses } from '@core/interfaces';
import { addQueryParamsToUrl } from '@core/utils';

export const getProgramsStatusesByLocationId = async (
  locationId: string | number,
  inspection_id?: string | null,
): Promise<IProgramStatuses[]> => {
  const endpoint = addQueryParamsToUrl(`/locations/${locationId}/programs/status`, {
    inspection_id,
  });
  return axiosInstance.get<IProgramStatuses[]>(endpoint).then((res) => res.data);
};
