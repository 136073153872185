import { Feature, Polygon, GeoJsonProperties } from 'geojson';

export enum EAnomalyMode {
  Thermal = 'thermal',
  Visual = 'rgb',
  Mixed = 'multimodal',
  Manual = 'manual',
  String = 'string',
}

export enum EAnomalyStatus {
  ToReview = 'To Review',
  False = 'False',
  Minor = 'Minor',
  Major = 'Major',
}

export interface IAnomaly {
  id: number;
  status: EAnomalyStatus;
  mode: EAnomalyMode;
  true_positive: boolean;
  geojson: any;
  features: Feature<Polygon, GeoJsonProperties>[];
  detection_ids: number[];
  program_name?: string;
  program_id?: string;
  report_id?: string | number;
  inspection_id?: string;
  inspection_name?: string;
}
