import { siteStatusesPriority } from '@core/constants';
import { ISite } from '@core/interfaces';
import { isNumber } from '@core/utils';

export const sortGroupedSitesByStatusPriority = (groupedSites: Record<string, ISite[]>) => {
  const result: ISite[][] = [];

  Object.entries(groupedSites).forEach(([status, sites]) => {
    const priority = siteStatusesPriority.get(status);
    if (isNumber(priority)) {
      result[priority] = sites;
    }
  });

  return result.filter(Boolean).flat();
};
