import { ESortOrder } from '@core/enums';
import { isString } from '../guards';

interface ISortByGroupParams {
  groupBy: string;
  sortBy: string;
  order: ESortOrder;
}

export const sortByGroup = <T extends Record<string, any>>(
  list: T[],
  params: ISortByGroupParams,
): Record<string, T[]> => {
  const sortOrderMapping = {
    [ESortOrder.Asc]: (a, b) => {
      const current = a[params.sortBy];
      const next = b[params.sortBy];

      if (isString(current) && isString(next)) {
        return current.toLowerCase() < next.toLowerCase() ? 1 : -1;
      }

      return current < next ? 1 : -1;
    },
    [ESortOrder.Desc]: (a, b) => {
      const current = a[params.sortBy];
      const next = b[params.sortBy];

      if (isString(current) && isString(next)) {
        return current.toLowerCase() > next.toLowerCase() ? 1 : -1;
      }
      return current > next ? 1 : -1;
    },
  };

  return list.reduce((acc, item) => {
    const groupValue = item[params.groupBy];
    const hasItemFields = [params.groupBy, params.sortBy].every((field) => field in item);

    if (hasItemFields) {
      const data =
        groupValue in acc
          ? [...acc[groupValue], item].sort(sortOrderMapping[params.order])
          : [item];
      return { ...acc, [groupValue]: data };
    }

    return acc;
  }, {});
};
