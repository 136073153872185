import { IAnomaly } from '@core/interfaces/anomaly';

interface IProps {
  anomalies: IAnomaly[];
  currentAnomaly: IAnomaly;
  arrow: 'left' | 'right';
}

export const getAnomalyId = ({ anomalies, currentAnomaly, arrow }: IProps) => {
  let index = anomalies.findIndex((item) => item.id === currentAnomaly.id);

  if (arrow === 'left') {
    index = index !== 0 ? index - 1 : anomalies.length - 1;
  } else {
    index = index !== anomalies.length - 1 ? index + 1 : 0;
  }

  return anomalies[index].id;
};
