import { FC, memo } from 'react';
import { ERoles } from '@core/enums';
import { TRolesWithoutSuperuser } from '@core/interfaces';
import AddItem from './components/AddItem/AddItem';
import { IAddInputData } from './interfaces/addItem';
import { IDeleteInputData } from './interfaces/deleteItem';
import { IUpdateInputData } from './interfaces/updateItem';
import styles from './styles.scss';

export type RoleOption = { name: string; value: string; errorMessage: string | null };

interface IProps {
  text: {
    notification: string;
    addItemText: string;
    addItemPlaceholder: string;
  };
  data: { [key in TRolesWithoutSuperuser]: RoleOption[] };
  onChange: (data: IUpdateInputData) => void;
  onAdd: (data: IAddInputData) => void;
  onDelete: (data: IDeleteInputData) => void;
}

const RolesBody: FC<IProps> = ({ text, data, onChange, onAdd, onDelete }) => {
  return (
    <div className={styles.body}>
      <h2 className={styles.notification}>{text.notification}</h2>
      <div className={styles.container}>
        <AddItem
          addText={text.addItemText}
          placeholder={text.addItemPlaceholder}
          entity={ERoles.Admin}
          data={data[ERoles.Admin]}
          onChange={onChange}
          onAdd={onAdd}
          onDelete={onDelete}
        />
        <AddItem
          addText={text.addItemText}
          placeholder={text.addItemPlaceholder}
          entity={ERoles.Editor}
          data={data[ERoles.Editor]}
          onChange={onChange}
          onAdd={onAdd}
          onDelete={onDelete}
        />
        <AddItem
          addText={text.addItemText}
          placeholder={text.addItemPlaceholder}
          entity={ERoles.Viewer}
          data={data[ERoles.Viewer]}
          onChange={onChange}
          onAdd={onAdd}
          onDelete={onDelete}
        />
        <AddItem
          addText={text.addItemText}
          placeholder={text.addItemPlaceholder}
          entity={ERoles.Inspector}
          data={data[ERoles.Inspector]}
          onChange={onChange}
          onAdd={onAdd}
          onDelete={onDelete}
        />
      </div>
    </div>
  );
};

export default memo(RolesBody);
