// TODO:

/* eslint-disable @typescript-eslint/ban-types */
import { toast } from 'react-toastify';
import { RootState } from '..';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { location } from '@core/api/locations';
import { ERROR_TOAST_DELAY } from '@core/constants';
import { EAppAction, ESidebar } from '@core/enums';
import { useSelectorTyped } from '@core/hooks';
import {
  IInspection,
  IProgram,
  IProgramStatuses,
  IProgramStatusOptions,
  IRequestProgramParams,
  ISite,
} from '@core/interfaces';
import { useCurrentInspectionSelector } from '@core/store/slices/inspections';
import { useCurrentSiteSelector } from '@core/store/slices/sites';
import { isNumber } from '@core/utils';
import { sortByDate } from '@core/utils/sorting/sortByDate';
import { getProgramsWithIsReportProcessing } from '@modules/Sidebar/views/Site/utils/getProgramsWithIsReportProcessing';
import { getProgramsWithLastInspectionDate } from '@modules/Sidebar/views/Site/utils/getProgramsWithLastInspectionDate';
import { getProgramsWithWorstAnomalyStatus } from '@modules/Sidebar/views/Site/utils/getProgramsWithStatuses';
import {
  getProgramsWithFlightPlan,
  setLngLatToPrograms,
  setStatusesToPrograms,
  setAdditionalFieldsToEntity,
} from '../utils';
import { getLatestInspectionsForEachProgram } from '../utils/programs/getLatestInspectionsForEachProgram';

interface IInitialState {
  programs: Array<IProgram>;
  loading: boolean;
  currentProgramId: string | null;
}

const initialState: IInitialState = {
  programs: [],
  currentProgramId: null,
  loading: false,
};

export const fetchProgramsByLocationId = createAsyncThunk<void, IRequestProgramParams, {}>(
  'programs/fetchProgramsByLocationId',
  async ({ locationId, inspectionId }, { dispatch, getState }) => {
    const state = getState() as RootState;

    try {
      dispatch(startDataProcessing());
      const programs = await location.getProgramsByLocationId(locationId);

      let statuses: IProgramStatuses[] = [];

      // 1. Use case: if location has more than 1 program.
      if (programs.length > 1) {
        const latestInspectionsForEachProgram = getLatestInspectionsForEachProgram(
          programs,
          state.inspections.inspections,
        );

        // NOTE: get statuses for each program
        statuses = (
          await Promise.all(
            latestInspectionsForEachProgram.map(async (inspection) => {
              try {
                const data = await location.getProgramsStatusesByLocationId(
                  locationId,
                  inspection.id,
                );
                return data[0];
              } catch {
                toast.error(t('errors.generalServerError'), { autoClose: ERROR_TOAST_DELAY });
              }
            }),
          )
        ).filter(Boolean) as IProgramStatuses[];
      } else {
        statuses = await location.getProgramsStatusesByLocationId(locationId, inspectionId);
      }

      const extendedProgramsWithLocationId = setAdditionalFieldsToEntity<IProgram>(programs, {
        location_id: locationId,
      });

      const programsWithFlightPlan = await getProgramsWithFlightPlan(
        extendedProgramsWithLocationId,
      );
      const programsWithLngLat = setLngLatToPrograms(
        programsWithFlightPlan.length === extendedProgramsWithLocationId.length
          ? programsWithFlightPlan
          : extendedProgramsWithLocationId,
      );

      const updatedPrograms = setStatusesToPrograms(programsWithLngLat, statuses);
      const programsWithIsReportProcessing = getProgramsWithIsReportProcessing(
        updatedPrograms,
        sortByDate(state.inspections.inspections),
      );
      dispatch(addPrograms(programsWithIsReportProcessing));
    } catch {
      toast.error(t('errors.generalServerError'), { autoClose: ERROR_TOAST_DELAY });
    } finally {
      dispatch(finishDataProcessing());
    }
  },
);

export const fetchProgramsStatuses = createAsyncThunk<void, IProgramStatusOptions, {}>(
  'programs/fetchProgramsStatuses',
  async ({ data: { locationId, inspectionId }, action }, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;

      switch (action) {
        case EAppAction.SidebarZoneBackClick: {
          const filteredInspectionsByLocationWithAnomalies = state.inspections.inspections.filter(
            (inspection) =>
              isNumber(inspection.nb_anomalies) && inspection.location_id === locationId,
          );
          const sortedInspections = sortByDate<IInspection>(
            filteredInspectionsByLocationWithAnomalies,
          );
          const currentInspectionId = sortedInspections.at(-1)?.id ?? null;
          const programsStatuses = await location.getProgramsStatusesByLocationId(
            locationId,
            currentInspectionId,
          );
          const updatedPrograms = setStatusesToPrograms(state.programs.programs, programsStatuses);
          dispatch(addPrograms(updatedPrograms));
          break;
        }
        case EAppAction.HeaderInspectionDropdownSelect:
        case EAppAction.HeaderInspectionTimelineSelect:
          {
            const programsStatuses = await location.getProgramsStatusesByLocationId(
              locationId,
              inspectionId,
            );
            const updatedPrograms = setStatusesToPrograms(
              state.programs.programs,
              programsStatuses,
            );
            dispatch(addPrograms(updatedPrograms));
          }
          break;
      }
    } catch {
      toast.error(t('errors.generalServerError'), { autoClose: ERROR_TOAST_DELAY });
    } finally {
      dispatch(updateProgramsWorstAnomalyStatus());
    }
  },
);

export const updateProgramsWorstAnomalyStatus = createAsyncThunk<void, void, {}>(
  'programs/updateStatuses',
  async (_, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;

      if (state.sidebar.sidebar === ESidebar.Sites) return;

      dispatch(startDataProcessing());

      const currentSite = (state.sites.sites as ISite[]).find(
        (site) => site.loc_id === state.sites.currentSiteId,
      ) as ISite;
      const currentInspection =
        (state.inspections.inspections as IInspection[]).find(
          (inspection) => inspection.id === state.inspections.currentInspectionId,
        ) ?? null;
      if (!currentInspection) return;
      const programsForCurrentLocation = (state.programs.programs as IProgram[]).filter(
        (program) => program.location_id === currentSite?.loc_id,
      );
      const programIds = programsForCurrentLocation.map((program) => program?.program_id);
      const inspections: IInspection[] = (state.inspections.inspections as IInspection[]).filter(
        (inspection) => programIds.includes(inspection.program_id),
      );

      if (programsForCurrentLocation) {
        const programsForCurrentLocationWithStatuses = getProgramsWithWorstAnomalyStatus(
          programsForCurrentLocation,
        );

        // NOTE: field "date" (programs) doesn't match the last inspected date.
        const programsForCurrentLocationWithLastInspectionDate = getProgramsWithLastInspectionDate(
          programsForCurrentLocationWithStatuses,
          sortByDate(inspections),
        );

        dispatch(updatePrograms(programsForCurrentLocationWithLastInspectionDate));
      }
    } catch {
      toast.error(t('errors.somethingIsWrong'), { autoClose: ERROR_TOAST_DELAY });
    } finally {
      dispatch(finishDataProcessing());
    }
  },
);

const programsSlice = createSlice({
  name: 'programs',
  initialState,
  reducers: {
    startDataProcessing: (state) => {
      state.loading = true;
    },
    finishDataProcessing: (state) => {
      state.loading = false;
    },
    addPrograms: (state, action: PayloadAction<IProgram[]>) => {
      state.programs = action.payload;
    },
    updatePrograms: (state, action: PayloadAction<IProgram[]>) => {
      const updatedPrograms = action.payload;
      state.programs = state.programs.map(
        (program) =>
          updatedPrograms.find(
            (updatedProgram) => updatedProgram.program_id === program.program_id,
          ) || program,
      );
    },
    setCurrentProgramId: (state, action: PayloadAction<string | null>) => {
      state.currentProgramId = action.payload;
    },
    resetPrograms: () => initialState,
  },
});

const programsReducer = programsSlice.reducer;

const {
  startDataProcessing,
  finishDataProcessing,
  addPrograms,
  setCurrentProgramId,
  updatePrograms,
  resetPrograms,
} = programsSlice.actions;

const useProgramsSelector = () => useSelectorTyped((state) => state.programs);

const useProgramsForCurrentLocationSelector = () =>
  useSelectorTyped((state) =>
    state.programs.programs.filter((program) => program.location_id === state.sites.currentSiteId),
  );

const useCurrentProgramSelector = () =>
  useSelectorTyped((state) =>
    state.programs.programs.find(
      (program) => program.program_id === state.programs.currentProgramId,
    ),
  );

export const useProgramsForCurrentLocation = () => {
  const currentSite = useCurrentSiteSelector();
  const currentInspection = useCurrentInspectionSelector();
  const programsForCurrentLocation = useProgramsForCurrentLocationSelector();

  return { currentSite, currentInspection, programsForCurrentLocation };
};

export {
  addPrograms,
  startDataProcessing,
  finishDataProcessing,
  setCurrentProgramId,
  updatePrograms,
  resetPrograms,
  programsReducer,
  useProgramsSelector,
  useProgramsForCurrentLocationSelector,
  useCurrentProgramSelector,
  initialState as programsInitialState,
};
