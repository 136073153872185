import { FC, memo, useCallback } from 'react';
import compareIcon from '@assets/icons/compare.svg';
import rewindBackwardsForward from '@assets/icons/video-next.svg';
import rewindBackwardsIcon from '@assets/icons/video-previous.svg';
import { EControlMode } from '@core/enums';
import { useDispatchTyped } from '@core/hooks';
import {
  setInspectionAndReportToCompareIds,
  updateCompareModeAndView,
  updateMode,
  useCompareModeSelector,
  useControlsSelector,
} from '@core/store/slices';
import { ButtonWithIcon } from '@components/Timeline/components/ButtonWithIcon';

interface IProps {
  isDisabledBackward: boolean;
  isShownForward: boolean;
  onPreviousClick: () => void;
  onNextClick: () => void;
}

export const TimelineControls: FC<IProps> = memo(
  ({ isDisabledBackward, isShownForward, onPreviousClick, onNextClick }) => {
    const { isCompareMode } = useControlsSelector();
    const { isCompareModeDisabled, prevInspection } = useCompareModeSelector();
    const { mode } = useControlsSelector();
    const dispatch = useDispatchTyped();

    const handleCompareModeClick = useCallback(() => {
      if (mode !== EControlMode['2D']) {
        dispatch(updateMode(EControlMode['2D']));
      }
      dispatch(updateCompareModeAndView(!isCompareMode));
      dispatch(
        setInspectionAndReportToCompareIds(
          !isCompareMode && prevInspection ? prevInspection.id : null,
        ),
      );
    }, [isCompareMode, prevInspection, mode, dispatch]);

    return (
      <div className='h-[35px] gap-2.5 flex pl-6 pr-2.5 border-r border-[#3F3F4A]'>
        <ButtonWithIcon
          icon={compareIcon}
          active={isCompareMode}
          disabled={isCompareModeDisabled}
          onClick={handleCompareModeClick}
        />
        <ButtonWithIcon
          icon={rewindBackwardsIcon}
          onClick={onPreviousClick}
          disabled={isDisabledBackward}
        />
        {isShownForward && <ButtonWithIcon icon={rewindBackwardsForward} onClick={onNextClick} />}
      </div>
    );
  },
);
