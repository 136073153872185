// Example:
// Lorem Ipsum Text -> Lorem Ips...
export const sliceString = (text: string, count = 10, symbols = '...'): string => {
  if (text?.length <= count) return text;

  return text?.substring(0, count).trim() + symbols;
};

// Example:
// HalfYearly -> halfYearly
export const convertTheFirstCharToLowerCase = (str: string) => {
  const [first, ...rest] = str;
  return [first.toLowerCase(), rest.join('')].join('');
};

// Example:
//  admin -> admin-1689961614567
export const createInputName = <T extends string>(entity: T) => `${entity}-${Date.now()}`;

// Example:
// admin-1689961614567 -> admin
export const getEntityFromInputName = (inputName: string) => inputName.split('-')[0];

// Example:
// 4842 -> A4842
export const createAnomalyName = (id: number) => `A${id}`;

// Example:
// Frame A4842 - 6
export const createSampleName = (anomalyId: number, sampleIndex: number) =>
  `Frame ${createAnomalyName(anomalyId)} - ${sampleIndex + 1}`;

export const createBearerToken = (accessToken: string) => `Bearer ${accessToken}`;
