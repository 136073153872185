import { IAddNewUsersRequest } from '@core/api/user/users';

export const convertEditInfoRolesToIAddNewUsersRequest = (obj: any): IAddNewUsersRequest[] => {
  return Object.entries(obj).reduce((newUsers, [role, items]: any) => {
    const usersWithRole = items
      .filter((item) => item.value)
      .map((item) => ({ email: item.value, roles: [role] }));
    return newUsers.concat(usersWithRole);
  }, [] as IAddNewUsersRequest[]);
};
