import { anomaliesStatusesColor } from '@core/constants';
import { ESidebar } from '@core/enums';
import { IProgram } from '@core/interfaces';
import { EAnomalyStatus } from '@core/interfaces/anomaly';
import { getColor } from '@core/utils';

interface ICreateZoneLabelDeps {
  program: IProgram;
  sidebar: ESidebar;
}

export const createZoneLabel = ({ program, sidebar }: ICreateZoneLabelDeps) => {
  const label = document.createElement('div');
  const name = program.name;

  label.style.height = '35px';
  label.style.padding = '0 6px';
  label.style.marginLeft = `${(6.7 * name.length + 12) / 2}px`;
  label.style.marginTop = `17.5px`;
  label.style.fontSize = '12px';
  label.style.fontWeight = '700';
  label.style.fontFamily = 'Inter, system-ui';
  label.style.lineHeight = '35px';
  label.style.borderRadius = '3px';
  label.style.transform = 'translateX(100px)';

  label.style.backgroundColor =
    program.isReportProcessing || sidebar === ESidebar.EditSite
      ? getColor('--outflier-blue')
      : anomaliesStatusesColor[String(program.worst_anomaly_status)];

  label.style.color = '#fffdf8';
  if (
    program.worst_anomaly_status === EAnomalyStatus.ToReview ||
    program.worst_anomaly_status === EAnomalyStatus.Minor ||
    sidebar === ESidebar.EditSite
  ) {
    label.style.color = '#00000F';
  }
  label.innerText = name;

  return label;
};
